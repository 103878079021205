import performCamelcaseKeys from "camelcase-keys"
import ConventionApiError from "models/convention-api/ConventionApiError"

function flatten(data: any): any {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export default async function processResponse({
  response,
  json,
  camelCaseKeys,
}: {
  response: Response
  json: any
  camelCaseKeys?: boolean
}): Promise<any> {
  // Check for api errors
  if (response.status >= 400 || json.errors) {
    throw new ConventionApiError({ response, json })
  }

  // Data can be an array or single item, always convert to an array
  let data = json.data
  if (!Array.isArray(data)) {
    data = [data]
  }

  data = data.map((item: any) => flatten(item))
  data.meta = json.meta

  if (camelCaseKeys) {
    data = performCamelcaseKeys(data, { deep: true })
  }

  return data
}
