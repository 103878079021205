type MemoizeArgs = Array<null | boolean | number | string>
type MemoizeFunc = (...args: MemoizeArgs) => any

export default function memoize(fn: MemoizeFunc): MemoizeFunc {
  const cache = {}
  const memoized = (...args: MemoizeArgs) => {
    const key = JSON.stringify(args)
    if (key in cache) {
      // @ts-ignore PT - #180597146
      return cache[key]
    } else {
      // @ts-ignore PT - #180597146
      return (cache[key] = fn(...args))
    }
  }
  memoized.cache = cache // not sure but everything does this, maybe to keep it out of garbage
  return memoized
}
