import { StreamChatSelector } from "./StreamChatSelector"
import unreadIcon from "/public/assets/icons/unread.svg"
import ChatBubble from "@mui/icons-material/ChatBubble"
import CloseIcon from "@mui/icons-material/Close"
import Fab from "@mui/material/Fab"
import useChat from "hooks/useChat"
import useChatNotifications from "hooks/useChatNotifications"
import postUserToInstitutionConversations from "lib/post-user-to-institution-conversations"
import useCurrent from "lib/use-current"
import { useStateMachine } from "little-state-machine"
import Image from "next/image"
import { useContext, useEffect, useState, useRef } from "react"
import { updateChatStore } from "stores/chat-store"
import { ChatContext } from "stream-chat-react"
import { Cx } from "tss-react/types"

interface IChatWrapper {
  classes: Record<"root" | "button" | "buttonIcon" | "hidden", string>;
  cx: Cx;
}

export const ChatWrapper = ({ classes, cx }: IChatWrapper) => {
  const [channelView, setChannelView] = useState(false)
  const [customActiveChannel, setCustomActiveChannel] = useState("")
  const [creating, setCreating] = useState(false)

  const chatRef = useRef<HTMLDivElement>(null)

  const { state, actions } = useStateMachine({ updateChatStore })
  const { chatStore } = state
  const { open } = chatStore
  const updateChatState = actions.updateChatStore

  const { chatClient, connect } = useChat()

  const chatNotifications = useChatNotifications(chatClient)
  const count = chatNotifications || 0

  const chatContext = useContext(ChatContext)
  const setActiveChannel = chatContext ? chatContext.setActiveChannel : () => null

  const current = useCurrent()

  useEffect(() => {
    if (open && chatRef.current) {
      chatRef.current.focus()
    }
  }, [open, chatRef])

  useEffect(() => {
    if (chatStore.institutionId) {
      setCreating(true)

      const createChannel = async () => {
        if (!chatClient) {
          try {
            await connect()
          } catch (error) {
            console.error(error)
          }
        }

        // @ts-ignore
        const channelId = `${current.streamToken.cu_i_channel_type}_${current.user.id}_${chatStore.institutionId}`

        try {
          await postUserToInstitutionConversations({
            current,
            institution_id: chatStore.institutionId,
            // @ts-ignore
            convention_user_id: current.user.id,
          })
        } catch (error) {
          console.error(error)
        } finally {
          setCustomActiveChannel(channelId)
        }
      }

      createChannel().then(() => {
        updateChatState({
          institutionId: null,
          open: true,
        })
        setCreating(false)
      })
    }
  }, [chatStore])

  const handleOpen = () => {
    updateChatState({
      open: true,
    })
  }

  const handleClose = () => {
    updateChatState({
      open: false,
    })
    setCustomActiveChannel("")
    setChannelView(false)
    setActiveChannel()
  }

  return (
    <>
      {open ? (
        <Fab aria-label="Close Chat" className={classes.button} onClick={handleClose}>
          <CloseIcon />
        </Fab>
      ) : (
        <Fab
          aria-label={count > 0 ? "Open Chat, You have a new message." : "Open Chat"}
          className={classes.button}
          onClick={handleOpen}>
          {count > 0 ? (
            <Image
              role="alert"
              aria-live="polite"
              src={unreadIcon}
              className={classes.buttonIcon}
              alt="Open Chat Indicator"
            />
          ) : (
            <ChatBubble className={classes.buttonIcon} />
          )}
        </Fab>
      )}
      <div
        className={cx(classes.root, !open && classes.hidden)}
        ref={chatRef}
        role="region"
        aria-labelledby="chatregion"
        tabIndex={1}>
        <StreamChatSelector
          setChannelView={setChannelView}
          customActiveChannel={customActiveChannel}
          setCustomActiveChannel={setCustomActiveChannel}
          creating={creating}
        />
      </div>
      {channelView ? (
        <style>
          {`
            .str-chat.str-chat-channel-list {
              display: none;
              opacity: 0;
              visibility: hidden;
            }
          `}
        </style>
      ) : (
        <style>
          {`
            .str-chat.str-chat-channel {
              display: none;
              opacity: 0;
              visibility: hidden;
            }
          `}
        </style>
      )}
      <style>
        {`
          .str-chat__container {
            width: 100%;
          }

          .str-chat__input-large {
            border-radius: 0 0 10px 10px;
            padding: 10px;
          }

          .str-chat__message-inner {
            margin-right: unset !important;
          }
        `}
      </style>
    </>
  )
}
