import ResourceModel from "models/convention-api/ResourceModel"

export default class SingleTenantSSO extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/single_tenant_sso"

  auth0_client_identifier!: string
  auth0_domain!: string
  auth0_enabled!: boolean
  auth0_signin_text!: string | null

  classlink_client_identifier!: string
  classlink_domain!: string
  classlink_tenant_identifier!: string
  classlink_enabled!: boolean

  clever_client_identifier!: string
  clever_district_identifier!: string
  clever_domain!: string
  clever_enabled!: boolean

  google_client_identifier!: string
  google_district_identifier!: string
  google_domain!: string
  google_enabled!: boolean

  static isAnyEnabled(singleTenantSSO?: SingleTenantSSO) {
    if (!singleTenantSSO) return false

    return (
      singleTenantSSO.auth0_enabled ||
      singleTenantSSO.classlink_enabled ||
      singleTenantSSO.clever_enabled ||
      singleTenantSSO.google_enabled
    )
  }
}
