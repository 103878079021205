import { AuthDialog } from "app-constants/constants"
import useSearchParams from "hooks/useSearchParams"
import { useRecoilState } from "recoil"
import { openedAuthDialogAtom } from "recoil-state/atoms"

const useAuthDialog = () => {
  const [state, _set] = useRecoilState(openedAuthDialogAtom)
  const searchParams = useSearchParams()

  const set = (dialog: AuthDialog, payload?: any) => _set({ dialog, payload })
  const reset = async (resetSearchParams: boolean = true) => {
    if (resetSearchParams) await searchParams.clear()
    _set({ dialog: null })
  }

  return {
    dialog: state.dialog,
    payload: state.payload,
    set,
    _set,
    reset,
  }
}

export default useAuthDialog
