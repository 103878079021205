import { Auth0Context } from "contexts/Auth0Context"
import { useContext } from "react"

export default function useAuth0Context() {
  const context = useContext(Auth0Context)

  if (typeof context === "undefined") {
    throw new Error("useAuth0Context must be within Auth0ContextProvider")
  }

  return context
}
