import { Alert } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"

const ENABLE_COOKIE_MESSAGE = (
  <p role="alert">
    <strong>Cookies are disabled.</strong>&nbsp;
    <span>
      Your browser has cookies disabled. Make sure your cookies are enabled and try again.
    </span>
  </p>
)

const useShowEnableCookieSnackbar = () => {
  const snackbar = useSnackbar()
  return () =>
    snackbar.enqueueSnackbar(ENABLE_COOKIE_MESSAGE, {
      autoHideDuration: null,
      content: (key) => (
        <Alert onClose={() => snackbar.closeSnackbar(key)} severity="error">
          {ENABLE_COOKIE_MESSAGE}
        </Alert>
      ),
    })
}

export default useShowEnableCookieSnackbar
