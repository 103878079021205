import { Box, CircularProgress, styled } from "@mui/material"
import React from "react"
import { pxToRem } from "utils/helpers"

interface LoadingStateFallbackProps {
  className?: string
  children: React.ReactNode
  indicatorSize?: number | string
  fallbackRendered: boolean
}

const LoadingStateFallback = (props: LoadingStateFallbackProps) => {
  const { children, fallbackRendered, className, indicatorSize } = props

  if (fallbackRendered) {
    return (
      <_Container className={className}>
        <CircularProgress variant="indeterminate" size={indicatorSize} />
      </_Container>
    )
  }

  return <>{children}</>
}

export default LoadingStateFallback

const _Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: pxToRem(20),
})) as typeof Box
