import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Box, Dialog, DialogContent, styled, TextField, Typography } from "@mui/material"
import { AuthDialog } from "app-constants/constants"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import useCurrent from "lib/use-current"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as yup from "yup"
import * as Yup from "yup"

interface FormValues {
  password: string
  privacyCode?: string
}

const formValidationScheme = yup.object().shape({
  password: yup.string().min(8).required("Required."),
  privacyCode: Yup.string().notRequired(),
})

interface SignUpNewDialogProps {}

const SignUpPasswordDialog = (props: SignUpNewDialogProps) => {
  const {} = props

  const current = useCurrent()

  const authDialogs = useAuthDialog()
  const auth = useAuth()
  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      password: "",
    },
  })

  const onSubmit = async (formValues: FormValues) => {
    const requestInfoAcknowledgment = authDialogs.payload?.requestInfoAcknowledgment
    const payload = {
      email: authDialogs.payload?.email,
      password: formValues.password,
      requestInfoAcknowledgment: authDialogs.payload?.requestInfoAcknowledgment,
      privacyCode: formValues.privacyCode,
    }

    const response = await auth.performBasicRegistration(payload)
    if (response instanceof Error) return

    authDialogs.set(AuthDialog.SIGN_UP_NEW, {
      requestInfoAcknowledgment,
      registrationToken: response.data.attributes.registration_token,
      redirectUrl: authDialogs.payload?.redirectUrl,
    })
  }

  const handleClose = () => authDialogs.reset()

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Sign Up"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <_Form onSubmit={form.handleSubmit(onSubmit)}>
          <_PasswordContainer>
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="password"
                  label="Password"
                  fullWidth
                  variant="filled"
                  placeholder="Enter your password"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="password"
            />
            <Typography color="gray.darker" fontSize={pxToRem(14)}>
              Your password must be at least 8 characters
            </Typography>
          </_PasswordContainer>

          {current.config?.privacy_level === "high" && (
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Privacy Code"
                  fullWidth
                  variant="filled"
                  placeholder="Enter privacy code"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="privacyCode"
            />
          )}

          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Continue
          </LoadingButton>
        </_Form>
      </_DialogContent>
    </Dialog>
  )
}

export default SignUpPasswordDialog

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))

const _PasswordContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  gap: pxToRem(10),
}))
