import { Auth0Client, User } from "@auth0/auth0-spa-js"
import {
  GetTokenSilentlyVerboseResponse,
  IdToken,
  RedirectLoginResult,
} from "@auth0/auth0-spa-js/dist/typings/global"
import SingleTenantSSO from "models/convention-api/v1/SingleTenantSSO"
import { createContext, useState, useEffect } from "react"

interface IAuth0Context {
  isLoading: boolean
  isAuthenticated: boolean
  user: null | User
  isEnabled: boolean
  auth0Client: Auth0Client
  loginWithRedirect: Auth0Client["loginWithRedirect"]
  logout: () => Promise<void>
  handleRedirectCallback: () => Promise<RedirectLoginResult>
  getIdTokenClaims: () => Promise<IdToken>
  getTokenSilently: () => Promise<GetTokenSilentlyVerboseResponse>
  signinText: string | null
}

export const Auth0Context = createContext<IAuth0Context | undefined>(undefined)

// @ts-ignore PT - #180597146
export const Auth0Provider = ({ children }) => {
  const [auth0Client, setAuth0Client] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [user, setUser] = useState(null)

  const { data: SSOConfig } = SingleTenantSSO.useOne({})

  useEffect(() => {
    const initializeAuth0 = async () => {
      if (!SSOConfig) return

      const client = new Auth0Client({
        domain: SSOConfig?.auth0_domain,
        client_id: SSOConfig?.auth0_client_identifier,
        redirect_uri: `${window.location.origin}/auth0-callback`,
      })

      setIsEnabled(Boolean(SSOConfig?.auth0_enabled && client))

      // @ts-ignore
      setAuth0Client(client)
      const isAuthenticated = await client.isAuthenticated()
      const user = isAuthenticated ? await client.getUser() : null
      setLoading(false)
      setIsAuthenticated(isAuthenticated)
      // @ts-ignore
      setUser(user)
    }

    initializeAuth0()
  }, [SSOConfig])

  const handleRedirectCallback = async () => {
    // @ts-ignore
    return await auth0Client.handleRedirectCallback()
  }
  const loginWithRedirect: IAuth0Context["loginWithRedirect"] = async (params) => {
    const paramsWithDefaults = Object.assign(
      {},
      {
        appState: {},
        redirect_uri: `${window.location.origin}/auth0-callback`,
      },
      params,
    )
    // @ts-ignore
    return await auth0Client?.loginWithRedirect(paramsWithDefaults)
  }

  const getIdTokenClaims = async () => {
    // @ts-ignore
    return await auth0Client?.getIdTokenClaims()
  }

  const getTokenSilently = async () => {
    // @ts-ignore
    return await auth0Client?.getTokenSilently()
  }

  const logout = async () => {
    // @ts-ignore
    await auth0Client?.logout({
      returnTo: `${window.location.origin}`,
    })
  }

  const configObject = {
    isLoading: isLoading || typeof SSOConfig === "undefined",
    isAuthenticated,
    user,
    isEnabled,
    auth0Client,
    loginWithRedirect,
    logout,
    handleRedirectCallback,
    getIdTokenClaims,
    getTokenSilently,
    signinText: SSOConfig?.auth0_signin_text,
  }
  // @ts-ignore
  return <Auth0Context.Provider value={configObject}>{children}</Auth0Context.Provider>
}
