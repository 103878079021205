import isNil from "lib/isNil"

interface Query {
  [key: string]: number | number[] | string | string[] | Query
}

function buildQueryStringParams(segments: string[], key: string, value: any) {
  if (Array.isArray(value)) {
    buildQueryStringParams(segments, key, value.sort().join(","))
  } else if (typeof value === "object" && value !== null) {
    Object.keys(value)
      .sort()
      .forEach((subkey) => {
        buildQueryStringParams(segments, `${key}[${subkey}]`, value[subkey])
      })
  } else if (!isNil(value)) {
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(String(value))
    if (encodedValue) {
      segments.push(`${encodedKey}=${encodedValue}`)
    } else {
      segments.push(`${encodedKey}`)
    }
  }
}

export default function buildQueryString(params: {
  after?: string
  filter?: { [key: string]: number | number[] | string | string[] }
  limit?: number
  sort?: string | string[]
  includeCount: boolean
}) {
  const query: Query = {
    // @ts-ignore
    filter: params.filter,
    // @ts-ignore
    sort: params.sort,
    // @ts-ignore
    page: {
      size: params.limit,
      after: params.after,
    },
  }

  // Only get count for the first page
  // TODO: it might be nice for this logic to be closer to the useMore
  // functionality
  if (params.includeCount && !params.after) {
    query.results_count = "true"
  }

  const segments: string[] = []
  Object.keys(query)
    .sort()
    .forEach((key) => {
      buildQueryStringParams(segments, key, query[key])
    })

  return segments.join("&")
}
