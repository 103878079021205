'use client';

import useCurrent from "lib/use-current"
import dynamic from "next/dynamic"

type ClientOnlyProps = { children: JSX.Element };

const ClientRenderedOnly = (props: ClientOnlyProps) => {
  const { children } = props;

  const current = useCurrent()
  if (!current) {
    return null
  }

  return children;
};

export default dynamic(() => Promise.resolve(ClientRenderedOnly), {
    ssr: false,
})
