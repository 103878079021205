import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogContent, FormControl, styled } from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormQuestions, {
  createFormQuestionsSchema,
  createPayloadFromFormQuestions,
  useFormQuestions,
} from "components/FormQuestions"
import LoadingStateFallback from "components/common/LoadingStateFallback"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import useRegistrantTypes from "hooks/useRegistrantTypes"
import useSearchParams from "hooks/useSearchParams"
import useShowSnackbar from "hooks/useShowSnackbar"
import ConventionSignupQuestion from "models/convention-api/v1/ConventionSignupQuestion"
import ConventionSignupQuestionOption from "models/convention-api/v1/ConventionSignupQuestionOption"
import ProviderProgram from "models/convention-api/v1/ProviderProgram"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as Yup from "yup"

const formValidationScheme = Yup.object().shape({
  role: Yup.string().required(),
})

interface SignUpNewDialogProps {}

const SignUpNewDialog = (props: SignUpNewDialogProps) => {
  const {} = props
  const authDialogs = useAuthDialog()
  const showSnackbar = useShowSnackbar()
  const auth = useAuth()
  const router = useRouter()
  const searchParams = useSearchParams()
  const [selectedRegistrantTypeId, setSelectedRegistrantTypeId] = useState<null | string>()

  const conventionSignupQuestions = ConventionSignupQuestion.useAll(() => {
    if (selectedRegistrantTypeId === null) return null
    return {
      filter: {
        convention_registrant_type_id: `${selectedRegistrantTypeId},all`,
      },
    }
  })

  const conventionSignupQuestionsOptions = ConventionSignupQuestionOption.useAll({
    filter: {
      active: "true",
    },
  })

  const providerPrograms = ProviderProgram.useAll({})

  const questions = useFormQuestions({
    conventionSignupQuestions: conventionSignupQuestions.data ?? [],
    conventionSignupQuestionsOptions: conventionSignupQuestionsOptions.data ?? [],
    providerPrograms: providerPrograms.data ?? [],
    user: authDialogs.payload?.user,
    role: selectedRegistrantTypeId,
  })

  const form = useForm({
    resolver: yupResolver(formValidationScheme.concat(createFormQuestionsSchema(questions))),
    mode: "onBlur",
    defaultValues: {
      role: "",
    },
  })

  const formSelectedRegistrantTypeId = form.watch("role")

  const registrantTypes = useRegistrantTypes(() => {
    if (authDialogs.payload?.allRegistrantTypes) return {}

    return {
      filter: {
        convention_convention_id: authDialogs.payload?.conventionIdToRegister,
      },
    }
  })

  useEffect(() => {
    setSelectedRegistrantTypeId(formSelectedRegistrantTypeId)
  }, [formSelectedRegistrantTypeId, registrantTypes])

  useEffect(() => {
    if (registrantTypes?.length > 1) return
    if (formSelectedRegistrantTypeId) return
    form.setValue("role", registrantTypes?.[0]?.id)
  }, [registrantTypes?.length, formSelectedRegistrantTypeId])

  const onSubmit = async (values: any) => {
    try {
      const [payload, relationships, textResponses, listResponses] = createPayloadFromFormQuestions(
        {
          questions,
          values,
          role: formSelectedRegistrantTypeId,
          conventionSignupQuestions: conventionSignupQuestionsOptions.data ?? [],
        },
      )
      payload.text_responses = textResponses

      relationships.question_options = {
        data: listResponses,
      }

      payload.registration_token = authDialogs.payload.registrationToken
      payload.request_info_acknowledgment = authDialogs.payload.requestInfoAcknowledgment

      const response = await auth.registerUser({ payload, relationships })
      if (response instanceof Error) return

      const conventionIdToRegister = authDialogs.payload?.conventionIdToRegister
      if (conventionIdToRegister)
        await searchParams.set("conventionIdToRegister", conventionIdToRegister)
      if (authDialogs.payload?.redirectUrl) await router.push(authDialogs.payload?.redirectUrl)

      authDialogs.reset(false)
    } catch (error) {
      showSnackbar("Something went wrong", "error")
      console.error(error)
    }
  }

  const handleClose = () => {
    authDialogs.reset()
  }

  const questionsLoading =
    conventionSignupQuestions.isValidating || conventionSignupQuestionsOptions.isValidating
  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Sign Up"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <_Form onSubmit={form.handleSubmit(onSubmit)}>
          <LoadingStateFallback fallbackRendered={registrantTypes === null}>
            {registrantTypes?.length > 1 && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Which best describes you?</FormLabel>
                <Controller
                  control={form.control}
                  name="role"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {registrantTypes?.map((registrantType) => (
                        <FormControlLabel
                          key={registrantType.id}
                          value={registrantType.id}
                          control={<Radio />}
                          label={registrantType.name}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
            {formSelectedRegistrantTypeId && (
              <LoadingStateFallback fallbackRendered={questionsLoading}>
                <FormQuestions form={form} questions={questions} />
              </LoadingStateFallback>
            )}
          </LoadingStateFallback>

          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Continue
          </LoadingButton>
        </_Form>
      </_DialogContent>
    </Dialog>
  )
}

export default SignUpNewDialog

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))
