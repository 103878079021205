import { SelectInputProps } from "@mui/material/Select/SelectInput"
import { ApplicantTypes, ApplicantTypesCounselor } from "app-constants/constants"
import { ControlledRangeInput } from "components/ControlledRangeInput"
import PhoneInput from "components/PhoneInput"
import { ActivityIndicator } from "components/activity-indicator"
import { ControlledCheckboxGroup } from "components/checkbox-group"
import { ControlledRadioGroup } from "components/radio-group"
import { SchoolAutocomplete } from "components/school-autocomplete"
import { ControlledSelect } from "components/select"
import { ControlledTextField } from "components/text-field"
import useCurrentIPGeolocationData from "hooks/useCurrentIPGeolocationData"
import { useUser } from "hooks/useUser"
import { preferredOrderCountryNames, guessCountry } from "lib/countries"
import { isValidPhoneNumber } from "libphonenumber-js"
import ConventionSignupOptionResponse from "models/convention-api/v1/ConventionSignupOptionResponse"
import ConventionSignupQuestion from "models/convention-api/v1/ConventionSignupQuestion"
import ConventionSignupQuestionOption from "models/convention-api/v1/ConventionSignupQuestionOption"
import ConventionSignupTextResponse from "models/convention-api/v1/ConventionSignupTextResponse"
import ProviderProgram from "models/convention-api/v1/ProviderProgram"
import moment from "moment-timezone"
import React, { useEffect, useState } from "react"
import { FormQuestionOptionResponse, FormQuestion, FormQuestionType } from "types/types"
import * as yup from "yup"

interface FormQuestionsProps {
  form: any
  questions: Array<FormQuestion>
}

const FormQuestions = (props: FormQuestionsProps) => {
  const { questions, form } = props
  const { control, register, formState } = form
  const { errors } = formState
  const user = useUser()

  const geoData = useCurrentIPGeolocationData()
  // NOTE: the code is the default for phones
  const defaultCountryCode = geoData.data?.countryCode ?? "us"
  // NOTE: the selected country is the currently selected one, not just a default
  const [selectedCountryName, setSelectedCountryName] = useState("United States of America")
  const [countrySubdivisions, setCountrySubdivisions] = useState<any[]>([])

  useEffect(() => {
    if (user?.country) {
      const country = guessCountry(user.country)
      setSelectedCountryName(country.name)
    } else if (geoData.data?.country) {
      const country = guessCountry(geoData.data.country)
      setSelectedCountryName(country.name)
    }
  }, [user?.country, geoData.data?.country])

  useEffect(() => {
    let country = guessCountry(selectedCountryName)
    if (country) {
      setCountrySubdivisions(country.subdivisions)
    } else {
      setCountrySubdivisions([])
    }
  }, [selectedCountryName])

  const handleChange: SelectInputProps<string>["onChange"] = (event) => {
    setSelectedCountryName(event.target.value)
  }

  /*
   * This iterates on the field questions built on RoleWrapper component.
   * These questions are a prop and must comply with IQuestions.
   */

  if (geoData.isLoading) {
    return <ActivityIndicator />
  }

  return (
    <>
      {questions.map((question) => {
        const error = errors[question.name]
        const isInvalid = Boolean(error)

        const sharedProps: any = {
          key: question.name,
          name: question.name,
          label: question.label,
          required: question.required,
          placeholder: question.placeholder,
          defaultValue: question.defaultValue,
          error: isInvalid,
          fieldErrors: error,
        }

        const textProps = {
          ...sharedProps,
          control,
          inputProps: {
            autoComplete: question.autoComplete,
          },
        }

        const checkboxProps = {
          ...sharedProps,
          options: question.options,
          control,
          required: question.required,
        }

        const selectProps = {
          ...sharedProps,
          options: question.options,
          register,
          autoComplete: question.autoComplete,
        }

        const rangeProps = {
          ...sharedProps,
          control,
        }

        switch (question.type) {
          case "text":
            return <ControlledTextField {...textProps} />
          case "phone":
            return <PhoneInput {...textProps} defaultCountryCode={defaultCountryCode} />
          case "school":
            return <SchoolAutocomplete {...sharedProps} control={control} />
          case "radio":
            return <ControlledRadioGroup {...checkboxProps} />
          case "select":
            return <ControlledSelect {...selectProps} />
          case "date":
            return (
              <ControlledTextField {...textProps} mask="99-99-9999" maskPlaceholder="MM-DD-YYYY" />
            )
          case "address":
            const countryError = errors.country
            const isCountryInvalid = Boolean(countryError)
            const addressError = errors.address_street
            const isAddressInvalid = Boolean(addressError)
            const address2Error = errors.address_street2
            const isAddress2Invalid = Boolean(address2Error)
            const cityError = errors.city
            const isCityInvalid = Boolean(cityError)
            const stateError = errors.state
            const isStateInvalid = Boolean(stateError)
            const zipError = errors.zipcode
            const isZipInvalid = Boolean(zipError)

            return (
              <React.Fragment key={question.name}>
                <ControlledSelect
                  name="country"
                  autoComplete="country-name"
                  register={register}
                  label="Country"
                  required={true}
                  options={preferredOrderCountryNames}
                  error={isCountryInvalid}
                  fieldErrors={countryError}
                  handleChange={handleChange}
                  defaultValue={selectedCountryName}
                />
                <ControlledTextField
                  name="address_street"
                  control={control}
                  label="Address Line 1"
                  required={true}
                  error={isAddressInvalid}
                  fieldErrors={addressError}
                  defaultValue={
                    user && user.address_street ? user.address_street : question.defaultValue
                  }
                  inputProps={{
                    autoComplete: "address-line1",
                  }}
                />
                <ControlledTextField
                  name="address_street2"
                  control={control}
                  label="Address Line 2"
                  error={isAddress2Invalid}
                  fieldErrors={address2Error}
                  defaultValue={
                    user && user.address_street2 ? user.address_street2 : question.defaultValue
                  }
                  inputProps={{
                    autoComplete: "address-line2",
                  }}
                />
                <ControlledTextField
                  name="city"
                  control={control}
                  label="City"
                  required={true}
                  error={isCityInvalid}
                  fieldErrors={cityError}
                  defaultValue={user && user.city ? user.city : question.defaultValue}
                  inputProps={{
                    autoComplete: "address-level2",
                  }}
                />
                {countrySubdivisions.length ? (
                  <ControlledSelect
                    name="state"
                    autoComplete="address-level1"
                    register={register}
                    label="State / Province / Subdivision"
                    required={true}
                    placeholder=" "
                    options={countrySubdivisions.map((state) => {
                      return {
                        value: state.alpha2code,
                        label: state.name,
                      }
                    })}
                    error={isStateInvalid}
                    fieldErrors={stateError}
                    defaultValue={user && user.state ? user.state : question.defaultValue}
                  />
                ) : (
                  <ControlledTextField
                    name="state"
                    control={control}
                    label="State / Province / Subdivision"
                    required={true}
                    error={isStateInvalid}
                    fieldErrors={stateError}
                    defaultValue={user && user.state ? user.state : question.defaultValue}
                    inputProps={{
                      autoComplete: "address-level1",
                    }}
                  />
                )}
                <ControlledTextField
                  name="zipcode"
                  control={control}
                  label="Postal/Zip Code"
                  required={true}
                  error={isZipInvalid}
                  fieldErrors={zipError}
                  defaultValue={user && user.zipcode ? user.zipcode : question.defaultValue}
                  inputProps={{
                    autoComplete: "postal-code",
                  }}
                />
              </React.Fragment>
            )
          case "text_multi":
            return <ControlledTextField {...textProps} multiline />
          case "list":
            return <ControlledSelect {...selectProps} />
          case "list_multi":
            return <ControlledCheckboxGroup {...checkboxProps} />
          case "range":
            return <ControlledRangeInput {...rangeProps} />
        }
      })}
    </>
  )
}

export default FormQuestions

export const createFormQuestionsSchema = (questions: FormQuestion[]) => {
  const questionsSchema = {}

  questions.forEach((q) => {
    switch (q.type) {
      case "phone":
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup.string().test({
          name: "isPhone",
          exclusive: true,
          params: {},
          message: "Please input a valid phone number.",
          test: (value) => {
            return typeof value === "string" && isValidPhoneNumber(value)
          },
        })
        break
      case "date":
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup
          .string()
          .test({
            message: "Invalid date.",
            test: (value) => {
              if (!value) return false

              const dateFormat = "DD-MM-YYYY"
              // We need this because moment in Safari throws `Invalid Date`
              const date = new Date(value.replace(/-/g, "/"))
              return moment(moment(date).format(dateFormat), dateFormat, true).isValid()
            },
          })
          .test({
            message: "You must be at least 13 years old.",
            test: (value) => {
              if (!value) return false

              if (q.name !== "birthdate") return true
              // We need this because moment in Safari throws `Invalid Date`
              const date = new Date(value.replace(/-/g, "/"))
              return moment(date).isSameOrBefore(moment().subtract(13, "years"))
            },
          })
        break
      case "school":
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup.object({
          name: yup.string().nullable(),
          id: yup.string().nullable().notRequired(),
        })
        break
      case "address":
        // @ts-ignore PT - #180597146
        questionsSchema["country"] = yup.string().required("Required.")
        // @ts-ignore PT - #180597146
        questionsSchema["address_street"] = yup.string().required("Required.")
        // @ts-ignore PT - #180597146
        questionsSchema["address_street2"] = yup.string()
        // @ts-ignore PT - #180597146
        questionsSchema["city"] = yup.string().required("Required.")
        // @ts-ignore PT - #180597146
        questionsSchema["state"] = yup.string().required("Required.")
        // @ts-ignore PT - #180597146
        questionsSchema["zipcode"] = yup.string().required("Required.")
        break
      case "text_multi":
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup.mixed()
        break
      case "list_multi":
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup.array().test({
          message: "Required",
          // @ts-ignore PT - #180597146
          test: (value: boolean[]) => {
            if (!value) return false

            return value.indexOf(true) !== -1
          },
        })
        break
      default:
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = yup.string()
    }
    if (q.type !== "address") {
      if (q.required) {
        // @ts-ignore PT - #180597146
        questionsSchema[q.name] = questionsSchema[q.name].required("Required.")
      }
    }
  })

  return yup.object(questionsSchema)
}

interface CreateCallbackProps {
  questions: FormQuestion[]
  values: any
  role: string
  conventionSignupQuestions: ConventionSignupQuestionOption[]
}

export const createPayloadFromFormQuestions = (props: CreateCallbackProps) => {
  const { questions, values, role, conventionSignupQuestions } = props
  let payload: any = {
    convention_registrant_type_id: role,
  }
  const relationships: any = {}
  // @ts-ignore PT - #180597146
  let textResponses: ITextReponse[] = []
  // @ts-ignore PT - #180597146
  let listResponses: FormQuestionOptionResponse[] = []
  questions.forEach((q) => {
    if (!q.custom) {
      if (q.type === "date") {
        payload[q.name] = moment(values[q.name], "MM-DD-YYYY").format("YYYY-MM-DD")
      } else if (q.type === "address") {
        payload["country"] = values.country
        payload["address_street"] = values.address_street
        payload["address_street2"] = values.address_street2
        payload["city"] = values.city
        payload["state"] = values.state
        payload["zipcode"] = values.zipcode
      } else if (q.type === "school") {
        const school = values[q.name]
        if (school.id) relationships.school = { data: { type: "school", id: school.id } }
        payload[q.name] = school.name
      } else {
        payload[q.name] = values[q.name]
      }
    } else {
      if (q.type === "text" || q.type === "text_multi") {
        textResponses.push({
          // @ts-ignore
          id: q.qid,
          // @ts-ignore
          question_id: q.name,
          // @ts-ignore
          value: values[q.name],
        })
      } else {
        if (q.type === "list") {
          listResponses.push({
            // @ts-ignore
            id: values[q.name],
            // @ts-ignore
            type: "convention_signup_question_option",
          })
        } else {
          const questionOptions = conventionSignupQuestions
            .filter((o) => {
              return o.question_id === q.name && o.active && !o.deleted
            })
            .map((o) => {
              return { ...o, label: o.text, value: o.id }
            })

          values[q.name].forEach((value, i) => {
            if (value) {
              listResponses.push({
                // @ts-ignore
                id: questionOptions[i].id,
                // @ts-ignore
                type: "convention_signup_question_option",
              })
            }
          })
        }
      }
    }
  })
  return [payload, relationships, textResponses, listResponses] as const
}

interface UseQuestionsProps {
  conventionSignupQuestions: ConventionSignupQuestion[]
  conventionSignupQuestionsOptions: ConventionSignupQuestionOption[]
  providerPrograms: ProviderProgram[]
  conventionSignupTextResponses?: ConventionSignupTextResponse[]
  conventionSignupOptionResponses?: ConventionSignupOptionResponse[]
  user?: any
  role: string
}

export const useFormQuestions = (props: UseQuestionsProps) => {
  const {
    conventionSignupQuestions,
    conventionSignupQuestionsOptions,
    providerPrograms,
    conventionSignupTextResponses = [],
    conventionSignupOptionResponses = [],
    user,
    role,
  } = props

  const programs = providerPrograms.map((program) => {
    return {
      value: program.name,
      label: program.name,
    }
  })

  return conventionSignupQuestions
    .map((q) => {
      let qid = ""
      let type = "text" as FormQuestionType
      let name = q.question_type
      let options = [] as any[]
      let typeDefaultValue = "" as any
      let placeholder = ""
      let custom = false
      let valid = true
      let autoComplete = "on"

      /*
       * Here we're building a unique set of field questions to be rendered
       * q.question_type is unique for hardcoded questions
       * name is unique (q.question_type for HC questions, id for custom)
       */

      switch (q.question_type) {
        case "first_name":
          autoComplete = "given-name"
          break
        case "last_name":
          autoComplete = "family-name"
          break
        case "primary_phone":
          type = "phone"
          name = "phone_e164"
          placeholder = "(555) 555-5555"
          autoComplete = "tel"
          break
        case "primary_address":
          type = "address"
          break
        case "birthdate":
          type = "date"
          placeholder = "MM-DD-YYYY"
          autoComplete = "bday"
          break
        case "school":
          type = "school"
          name = "school_name"
          break
        case "program":
          type = programs && programs.length > 0 ? "select" : "text"
          name = "programs"
          options = programs
          break
        case "applicant_type":
          type = "radio"
          options = role === "teacher_counselor" ? ApplicantTypesCounselor : ApplicantTypes
          break
        case "student_phone":
          type = "phone"
          name = "student_phone_e164"
          placeholder = "(555) 555-5555"
          autoComplete = "tel"
          break
        case "text":
          custom = true
          name = q.id
          break
        case "list":
          type = "list"
          placeholder = " "
          custom = true
          name = q.id
          const listOptions = conventionSignupQuestionsOptions
            .filter((o) => {
              return o.question_id === q.id && o.active && !o.deleted
            })
            .map((o) => {
              return { ...o, label: o.text, value: o.id }
            })
          if (listOptions.length <= 0) valid = false
          options = listOptions
          break
        case "text_multi":
          type = "text_multi"
          custom = true
          name = q.id
          break
        case "list_multi":
          type = "list_multi"
          custom = true
          name = q.id
          const listMultiOptions = conventionSignupQuestionsOptions
            .filter((o) => {
              return o.question_id === q.id && o.active && !o.deleted
            })
            .map((o) => {
              return { ...o, label: o.text, value: o.id }
            })
          if (listMultiOptions.length <= 0) valid = false
          options = listMultiOptions
          break
      }

      let defaultValue = user && user[name] ? user[name] : typeDefaultValue

      if (type === "date" && defaultValue) {
        defaultValue = moment(defaultValue).format("MM-DD-YYYY")
      }

      if (type === "text" || type === "text_multi") {
        for (let i = 0; i < conventionSignupTextResponses.length; i++) {
          if (conventionSignupTextResponses[i].convention_signup_question_id === name) {
            qid = conventionSignupTextResponses[i].id
            defaultValue = conventionSignupTextResponses[i].value
            break
          }
        }
      }

      if (valid && type === "list") {
        for (let i = 0; i < options.length; i++) {
          let j = 0
          for (; j < conventionSignupOptionResponses.length; j++) {
            if (
              options[i].value ===
              conventionSignupOptionResponses[j].convention_signup_question_option_id
            ) {
              defaultValue = options[i].value
              break
            }
          }
          if (j < conventionSignupOptionResponses.length) break
        }
      }

      if (valid && type === "list_multi") {
        defaultValue = []
        for (let i = 0; i < options.length; i++) {
          let flag = false
          for (let j = 0; j < conventionSignupOptionResponses.length; j++) {
            if (
              options[i].value ===
              conventionSignupOptionResponses[j].convention_signup_question_option_id
            ) {
              flag = true
              break
            }
          }
          defaultValue.push(flag)
        }
      }

      return {
        qid,
        position: q.position,
        type: type,
        name: name,
        label: q.text,
        placeholder: placeholder,
        options: options,
        defaultValue: defaultValue,
        getId: (value) => value,
        required: q.required,
        custom,
        valid,
        autoComplete,
      }
    })
    .filter((q) => !!q.valid)
    .sort((a, b) => (a.position > b.position ? 1 : -1))
}
