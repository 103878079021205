import useCurrent from "lib/use-current"
import { useEffect } from "react"

export const GtmIdentifyCurrentUser = () => {
  const current = useCurrent()
  useEffect(() => {
    if (!current) return

    const script = document.createElement("script")
    script.async = true

    // @ts-ignore
    if (current.user) {
      const gtmVariables = {
        // @ts-ignore
        currentUserEmail: current.user.email,
        // @ts-ignore
        currentUserFirstName: current.user.first_name,
        // @ts-ignore
        currentUserLastName: current.user.last_name,
        // @ts-ignore
        currentUserPhone: current.user.phone_e164,
        // @ts-ignore
        currentUserRole: current.user.convention_registrant_type_id,
      }

      const variableKeys = Object.keys(gtmVariables)
      // @ts-ignore PT - #180597146
      const dataLayerStr = variableKeys.map((key) => `"${key}": "${gtmVariables[key]}"`).join(",")

      script.innerHTML = `if(window.dataLayer){ window.dataLayer.push({ ${dataLayerStr}, 'event': 'userIsLoggedIn' }); }`
    } else {
      const gtmVariables = {
        currentUserEmail: null,
        currentUserFirstName: null,
        currentUserLastName: null,
        currentUserPhone: null,
        currentUserRole: null,
      }

      const variableKeys = Object.keys(gtmVariables)
      // @ts-ignore PT - #180597146
      const dataLayerStr = variableKeys.map((key) => `"${key}": "${gtmVariables[key]}"`).join(",")

      script.innerHTML = `if(window.dataLayer){ window.dataLayer.push({ ${dataLayerStr}, 'event': 'userIsLoggedOut' }); }`
    }

    document.body.appendChild(script)
    // @ts-ignore
  }, [current.user])

  return null
}
