/**
Removes any duplicate values from an array.

Borrowed from lodash
https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L8390
*/
export default function uniq(array: any[]) {
  // @ts-ignore PT - #180597146
  const result = []
  array.forEach((item) => {
    // @ts-ignore
    if (result.indexOf(item) === -1) {
      // @ts-ignore
      result.push(item)
    }
  })
  // @ts-ignore PT - #180597146
  return result
}
