import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import { useState } from "react"
import { Controller, Control, useFormContext, FieldErrors } from "react-hook-form"
import { FormQuestion, FormOption } from "types/types"
import { makeStyles } from "tss-react/mui-compat"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      display: "flex",
    },
    label: {
      zIndex: 2,
      marginBottom: "8px",
      padding: "0 12px",
      color: "rgb(85, 96, 100)",
      ...theme.fonts.lato400,
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      opacity: 1,
    },
    box: {
      display: "flex",
      flexDirection: "row",
      padding: "16px 0",
      textAlign: "center",
      maxWidth: "320px",
    },
    tab: {
      flex: "auto",
      position: "relative",
    },
    spotBox: {
      margin: "1px",
      height: "32px",
      position: "relative",
    },
    spot: {
      position: "absolute",
      width: "100%",
      height: "32px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "transparent",
      border: "2px solid rgba(0,0,0,.3)",
      cursor: "pointer",
      transition: "ease .5s",
    },
    spotActive: {
      backgroundColor: theme.palette.primary.main,
      border: "transparent",
      color: theme.palette.grey[50],
    },
    text: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontSize: "1em",
    },
    formLabel: {
      width: "100%",
      margin: 0,
    },
    formError: {
      display: "flex",
      padding: "12px 0",
    },
  })
)

interface IControlledRangeInput extends FormQuestion {
  control: Control
  error: boolean
  fieldErrors: FieldErrors
  maxValue?: number
}

export const ControlledRangeInput = (props: IControlledRangeInput) => {
  const {
    control,
    label,
    required = false,
    name,
    defaultValue = "",
    error = false,
    fieldErrors,
    maxValue = 10,
    ...rest
  } = props

  const [myVal, setMyVal] = useState("")

  const { setValue, clearErrors, formState } = useFormContext()
  const { classes, cx } = useStyles()
  const { errors } = formState

  // Creating an options array matching IOptions interface
  // to be used for rendering the range options

  const options: Array<FormOption> = []
  for (let i = 0; i <= maxValue; i++) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    })
  }

  const handleOnClick = (newValue: string) => () => {
    // For some reason error doesn't go away after updating the value, need this to reset it
    if (errors[name]) clearErrors(name)

    setValue(name, newValue)
    setMyVal(newValue)
  }

  return (
    <FormControl error={error} component="fieldset" className={classes.root}>
      <FormLabel
        component="legend"
        aria-label={required ? `${label} required` : label}
        className={classes.label}>
        {required ? `${label} *` : label}
      </FormLabel>
      <Controller
        render={({ fieldState }) => {
          return (
            <>
              <RadioGroup className={classes.box}>
                {options.map((option) => (
                  <Box
                    key={name + option.value}
                    className={classes.tab}
                    onClick={handleOnClick(option.value)}
                    role="button">
                    <Box className={classes.spotBox}>
                      <Box
                        className={cx(
                          classes.spot,
                          myVal === option.value && classes.spotActive,
                        )}>
                        <Box component="span" className={classes.text}>
                          {option.label}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </RadioGroup>
              {!!fieldState.error?.message && (
                <FormHelperText error={!!fieldState.error?.message} className={classes.formError}>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </>
          )
        }}
        control={control}
        name={name}
        defaultValue={defaultValue}
        {...rest}
      />
    </FormControl>
  )
}
