// NOTE: vercel preview deploys use production env settings by default.
// Vercel has a UI for setting different env settings for preview, but
// then they're all encrypted so they're hard to retrieve, and it only
// allows for a single preview env whereas we're using previews for
// our multiple staging environments so we need to build a little
// infrastructure to detect the env and load the appropriate config.
// This assumes the staging environment will be part of the subdomain
// alongside the provider, such as nacac-charizard.visitdays.vercel.app

const mapping = {
  main: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  pikachu: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-pikachu.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-pikachu.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  charizard: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-charizard.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-charizard.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  ditto: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-ditto.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-ditto.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  metapod: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-metapod.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-metapod.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  abra: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-abra.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-abra.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  geodude: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-geodude.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-geodude.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  ninetails: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-ninetails.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-ninetails.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  slowpoke: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-slowpoke.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-slowpoke.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
  zapdos: {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-PG6S6FS",
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: "https://vd-api-staging-zapdos.herokuapp.com",
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: "fduy8bw2mshh",
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: "wss://vd-anycable-staging-zapdos.herokuapp.com",
    NEXT_PUBLIC_MAPBOX_API_KEY:
      "pk.eyJ1IjoidmlzaXRkYXlzLW1hcHMiLCJhIjoiY2wzZGJiaHM3MDY1djNiczB6cnllOTRhNCJ9.aqpV7WwJwC41SSReqR4IFA",
  },
}

function assign(target: object, source: object) {
  Object.keys(target).forEach((key) => {
    // @ts-ignore PT - #180597146
    const value = source[key]
    if (value) {
      // @ts-ignore PT - #180597146
      target[key] = source[key]
    }
  })
}

export default function getEnv(host: string) {
  // NOTE: process.env in browser is always an empty object,
  // keys can't be read unless you literally do process.env.KEY...
  // if you assign source = process.env then all the keys are
  // undefined.. the build step may be doing a substring replacement
  const env = {
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID:
      process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID,
    NEXT_PUBLIC_VISITDAYS_API_ENDPOINT: process.env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT,
    NEXT_PUBLIC_STREAM_CHAT_API_KEY: process.env.NEXT_PUBLIC_STREAM_CHAT_API_KEY,
    NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL: process.env.NEXT_PUBLIC_VISITDAYS_ANYCABLE_URL,
    NEXT_PUBLIC_MAPBOX_API_KEY: process.env.NEXT_PUBLIC_MAPBOX_API_KEY,
  }

  const subdomain = host.split(".")[0]
  const pokemon = subdomain.split("-").reverse()[0]
  // @ts-ignore PT - #180597146
  if (pokemon && mapping[pokemon]) {
    // @ts-ignore PT - #180597146
    assign(env, mapping[pokemon])
  }

  return env
}
