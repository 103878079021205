import { Box, Typography } from "@mui/material"
import { makeStyles } from "tss-react/mui-compat"
import React from "react"

const useStyles = makeStyles()(
  (theme) => ({
    container: {
      // TODO: figure out how to dry this color, because it's used in several places like more than 10
      background: "#262B2D",
      width: "100%",
      padding: "20px",
    },
    text: {
      color: theme.palette.gray.lightest,
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 500,
    },
  })
)

export const PreviewMessage = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}> You are currently in preview mode.</Typography>
    </Box>
  )
}
