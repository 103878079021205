import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import Color from "color"
import useCurrent from "lib/use-current"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { useMemo, useState } from "react"
import baseCuratorTheme from "theme/baseCuratorTheme"
import { createTheme } from "theme/createTheme"

interface CuratorThemeProviderProps {
  children?: React.ReactNode
}

const CuratorThemeProvider = (props: CuratorThemeProviderProps) => {
  const { query } = useRouter()
  const current = useCurrent()

  // Only set it once on load, previewColor changes to undefined on route change but we want
  // to keep the initial value if there is one until the user does a browser refresh
  const [previewColor] = useState(() => query.previewColor)

  // @ts-ignore
  const brandColor = current.config.brand_color
  const primaryColor = previewColor ?? brandColor

  useEffect(() => {
    // set primary color to nprogress
    document.documentElement.style.setProperty("--nprogress-color", primaryColor)
  }, [primaryColor])

  const theme = useMemo(() => {
    return createTheme({
      ...baseCuratorTheme,
      ...{
        palette: {
          ...baseCuratorTheme.palette,
          primary: {
            main: primaryColor,
            light: Color(primaryColor).mix(Color("#ffffff"), 0.8).hex(),
            lighter: Color(primaryColor).mix(Color("#ffffff"), 0.9).hex(),
            lightest: Color(primaryColor).mix(Color("#ffffff"), 0.95).hex(),
          },
        },
      },
    })
  }, [previewColor])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CuratorThemeProvider
