import { BuildServerSidePropsMiddleware } from "lib/build-server-side-props"
import { BuildServerSidePropsMiddlewareContext } from "lib/build-server-side-props"
import getEnvForHost from "lib/get-env-for-host"
import getHost from "lib/get-host"
import { RunParams } from "models/convention-api/types"

export default function applyModel(
  propName: string,
  params: any,
  fn: (runParams: RunParams, context: BuildServerSidePropsMiddlewareContext) => any,
): BuildServerSidePropsMiddleware {
  return async (context) => {
    const { req, props } = context
    const host = getHost({ req })
    // @ts-ignore
    const env = getEnvForHost(host)
    const baseParams = {
      host: env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT,
      pathParams: {
        provider_id: props.current.provider.id,
      },
    }
    const paramsValue = typeof params === "function" ? params(context) : params
    const result = await fn({ ...baseParams, ...paramsValue }, context)
    props[propName] = result
  }
}
