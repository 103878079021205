import { css, Global } from "@emotion/react"

export const globalStyles = (
  <Global
    styles={css`
      html,
      body {
        padding: 0;
        margin: 0;
        background: white;
        min-height: 100vh;
        font-weight: 400;
      }
      
      * {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }

      :focus {
        outline: 0;
      }

      p {
        margin: 0;
      }

      a {
        text-decoration: none;
      }

      .ReactModal__Overlay {
        width: 100%;
        background-color: rgba(38, 43, 45, 0.8) !important;
      }

      .ReactModal__Content {
        width: 95%;
        margin: 0 auto;
        padding: 0 !important;
        border: none !important;
      }

      @media screen and (min-width: 1024px) {
        .ReactModal__Content {
          width: 980px;
          height: 563px;
        }
      }

      @media screen and (max-width: 1024px) {
        .youtube_video_container {
          height: 77% !important;
        }
      }

      .youtube_video {
        width: 100%;
        height: 100%;
      }

      .youtube_video_container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        z-index: 50;
      }

      .sticky-inner-wrapper,
      .sticky-outer-wrapper {
        width: 100%;
      }

      .active .sticky-inner-wrapper {
        box-shadow: 0 7px 4px 0px rgb(0 0 0 / 14%);
        width: 100% !important; /* not sure what sets element's style.width, but it's less than 100% on small screens for some reason.. need important to override it */
        left: 0;
        right: 0;
        padding-left: 16px;
        padding-right: 16px;
        background-color: white;
        max-width: 1440px;
        margin: 0 auto;
      }

      @media screen and (min-width: 1120px) {
        .active .sticky-inner-wrapper {
        }
      }
    `}
  />
)
