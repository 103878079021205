import { Theme } from "@mui/material"
import { ThemeOptions } from "@mui/material/styles"
import { Typography } from "@mui/material/styles/createTypography"
import { pxToRem } from "utils/helpers"

const buildThemeTypography = (theme: Theme, options: ThemeOptions): Typography => {
  return {
    ...theme.typography,
    ...theme.fonts.lato400,
    ...options.typography,

    fontSize: 14,

    header1: {
      ...theme.fonts.raleway500,
      fontSize: pxToRem(36),
      lineHeight: 1.175,
    },
    header2: {
      ...theme.fonts.raleway600,
      fontSize: pxToRem(24),
      lineHeight: 1.175,
    },
    header3: {
      ...theme.fonts.lato400,
      fontSize: pxToRem(20),
      lineHeight: 1.175,
    },
    header4: {
      ...theme.fonts.raleway700,
      fontSize: pxToRem(18),
      lineHeight: 1.175,
    },
    header5: {
      ...theme.fonts.raleway700,
      fontSize: pxToRem(16),
      lineHeight: 1.175,
    },
    header6: {
      ...theme.fonts.raleway600,
      fontSize: pxToRem(16),
      lineHeight: 1.175,
    },

    paragraphRegular: {
      ...theme.fonts.lato400,
      fontSize: pxToRem(16),
      lineHeight: 1.375,
    },
    paragraphItalic: {
      ...theme.fonts.lato400,
      fontSize: pxToRem(16),
      lineHeight: 1.375,
      fontStyle: "italic",
    },
    paragraphMuted: {
      ...theme.fonts.lato300,
      fontSize: pxToRem(16),
      lineHeight: 1.375,
    },
    paragraphAllCaps: {
      ...theme.fonts.lato400,
      fontSize: pxToRem(14),
      lineHeight: 1.175,
      textTransform: "uppercase",
    },
    paragraphSmall: {
      ...theme.fonts.lato400,
      fontSize: pxToRem(14),
      lineHeight: 1.175,
    },
    paragraphBold: {
      ...theme.fonts.lato700,
      fontSize: pxToRem(14),
      lineHeight: 1.175,
    },

    microNavSelected: {
      ...theme.fonts.raleway500,
      fontSize: pxToRem(16),
      lineHeight: 1.1875,
    },
    microNavNotSelected: {
      ...theme.fonts.raleway700,
      fontSize: pxToRem(16),
      lineHeight: 1.1875,
    },

    billboard1Light: {
      ...theme.fonts.raleway300,
      fontSize: pxToRem(72),
      lineHeight: 1.175,
    },
    billboard1Bold: {
      ...theme.fonts.raleway700,
      fontSize: pxToRem(72),
      lineHeight: 1.175,
    },
    billboard2Light: {
      ...theme.fonts.raleway300,
      fontSize: pxToRem(48),
      lineHeight: 1.175,
    },
    billboard2Bold: {
      ...theme.fonts.raleway700,
      fontSize: pxToRem(48),
      lineHeight: 1.175,
    },

    button: {
      letterSpacing: "initial",
      ...theme.fonts.raleway700,
      fontSize: pxToRem(14),
      lineHeight: 1,
      textTransform: "uppercase",
    },
  }
}

export default buildThemeTypography
