import { Theme } from "@mui/material/styles"

export interface ThemeAppComponentsOptions {
  conventionCountdown?: {
    backgroundColor?: React.CSSProperties["color"]
  }
  focusTracker?: {
    color?: React.CSSProperties["color"]
  }
}

export interface ThemeAppComponents {
  conventionCountdown: {
    backgroundColor: React.CSSProperties["color"]
  }
  focusTracker: {
    color: React.CSSProperties["color"]
  }
}

export function buildAppThemeComponents(options: ThemeAppComponentsOptions, theme: Theme) {
  return {
    conventionCountdown: {
      backgroundColor: options?.conventionCountdown?.backgroundColor || "#f2f8fc",
    },
    focusTracker: {
      color: options?.focusTracker?.color || theme.palette.primary.main,
    },
  }
}
