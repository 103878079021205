import CircularProgress from "@mui/material/CircularProgress"
import { makeStyles } from "tss-react/mui-compat"
import Autocomplete from "components/Autocomplete"
import { TextField } from "components/text-field"
import { api } from "lib/api"
import { School } from "lib/types"
import { useEffect, useState } from "react"
import { Controller, Control } from "react-hook-form"
import { useDebouncedCallback } from "use-debounce"

type SimpleSchool = {
  id: string
  name: string
}

const useStyles = makeStyles()({
  loader: {
    marginBottom: "16px",
  },
  textField: {
    margin: 0,
  },
})

interface ISchoolAutocomplete {
  name: string
  control: Control
  label?: string
  error?: boolean
  inputRef?: any
  fieldErrors?: any
  defaultValue?: string
}

export const SchoolAutocomplete = (props: ISchoolAutocomplete) => {
  const {
    name = "school_autocomplete",
    control,
    label,
    error,
    inputRef,
    fieldErrors = { message: " " },
    defaultValue = "",
  } = props

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Array<SimpleSchool>>([])
  const [value, setValue] = useState<string>(defaultValue)
  const [inputValue, setInputValue] = useState<string>("")

  const debouncedSearch = useDebouncedCallback(async () => {
    const { data: schools } = await api.searchSchools({ req: undefined }, inputValue)
    const newResults = schools.map((school: School) => {
      return {
        id: school.id,
        name: school.attributes.city
          ? `${school.attributes.name} - ${school.attributes.city}, ${school.attributes.state}`
          : school.attributes.name,
      }
    })
    setLoading(false)
    setResults(newResults)
  }, 250)

  useEffect(() => {
    setLoading(true)
    debouncedSearch.callback()
  }, [inputValue])

  const { classes } = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <Autocomplete
            {...props}
            freeSolo
            id={name}
            options={results.map((school) => school.name)}
            value={value}
            onChange={(_event, newValue) => {
              setValue(newValue)
              const foundSchool = results.find((school) => school.name === newValue)
              field.onChange({ name: newValue, id: foundSchool?.id })
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue)
              const foundSchool = results.find((school) => school.name === newInputValue)
              field.onChange({ name: newInputValue, id: foundSchool?.id })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                inputRef={inputRef}
                error={error}
                label={label}
                margin="normal"
                fieldErrors={fieldErrors}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={24} className={classes.loader} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            loading={loading}
          />
        )
      }}
    />
  )
}
