import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui-compat"
import { ReactNode } from "react"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      flexGrow: 1,
      height: "100%",
    },
    grid: {
      height: "100%",
      width: "100%",
    },
    headerWrapper: {
      height: "16%",
      width: "100%",
    },
    header: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0 7px 9px 0 rgba(0,0,0,0.03), 0 1px 0 0 rgba(0,0,0,0.03)",
    },
    headerText: {
      color: "white",
      fontSize: "18px",
      letterSpacing: "0.18px",
      lineHeight: "30px",
      fontWeight: "bold",
    },
    containerWrapper: {
      height: "84%",
      width: "100%",
    },
    container: {
      height: "100%",
      overflow: "auto",
    },
  })
)

interface IListWrapper {
  children: ReactNode
}

const ListWrapper = ({ children }: IListWrapper) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={classes.headerWrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.header}>
            <Typography className={classes.headerText} align="center" variant="h2" id="chatregion">
              My Conversations <span aria-hidden={true}>💬</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.containerWrapper}>
          <Grid className={classes.container}>{children}</Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ListWrapper
