import CloseIcon from "@mui/icons-material/Close"
import { Box, styled, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import React from "react"
import { pxToRem } from "utils/helpers"

interface DialogHeaderProps {
  title: React.ReactNode
  onClose: () => void
  closeButtonDisabled?: boolean
}

const DialogHeader = (props: DialogHeaderProps) => {
  const { title, onClose, closeButtonDisabled = false } = props

  return (
    <_Container>
      <Typography component="h3" variant="header3" sx={{ textTransform: "capitalize" }}>
        {title}
      </Typography>
      <_CloseIconButton aria-label="Close dialog" disabled={closeButtonDisabled} onClick={onClose}>
        <CloseIcon />
      </_CloseIconButton>
    </_Container>
  )
}

export default DialogHeader

const _Container = styled(Box)({
  position: "sticky",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "nowrap row",
  padding: `${pxToRem(30)} ${pxToRem(40)}`,
}) as typeof Box

const _CloseIconButton = styled(IconButton)({}) as unknown as typeof IconButton
