import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, DialogContent, styled, TextField, Typography } from "@mui/material"
import Divider from "@mui/material/Divider"
import { AuthDialog } from "app-constants/constants"
import { LoginSSOButtons } from "components/LoginSSOButtons"
import LoadingStateFallback from "components/common/LoadingStateFallback"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import useSearchParams from "hooks/useSearchParams"
import useShowSnackbar from "hooks/useShowSnackbar"
import SingleTenantSSO from "models/convention-api/v1/SingleTenantSSO"
import { useRouter } from "next/router"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as yup from "yup"

interface FormValues {
  email: string
  password: string
}

const formValidationScheme = yup.object().shape({
  email: yup.string().email("Must be a valid email.").required("Required."),
  password: yup.string().required("Required."),
})

interface SignInDialogProps {}

const SignInDialog = (props: SignInDialogProps) => {
  const {} = props
  const authDialogs = useAuthDialog()
  const auth = useAuth()
  const showSnackbar = useShowSnackbar()
  const router = useRouter()
  const searchParams = useSearchParams()
  const conventionIdToRegister = authDialogs.payload?.conventionIdToRegister

  const singleTenantSSO = SingleTenantSSO.useOne({})
  const isAnySSOEnabled = SingleTenantSSO.isAnyEnabled(singleTenantSSO.data)

  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      email: authDialogs.payload?.email ?? "",
      password: "",
    },
  })

  const onSubmit = async (formValues: FormValues) => {
    const response = await auth.performBasicAuthentication(formValues)
    if (response instanceof Error) return

    if (response?.["registrationRequired"]) {
      showSnackbar("Let's get you registered")
      authDialogs.set(AuthDialog.SIGN_UP_NEW, {
        registrationToken: response.registrationToken,
        redirectUrl: authDialogs.payload?.redirectUrl,
        conventionIdToRegister,
      })
      return
    }

    if (authDialogs.payload?.redirectUrl) await router.push(authDialogs.payload?.redirectUrl)
    if (conventionIdToRegister)
      await searchParams.set("conventionIdToRegister", conventionIdToRegister)
    authDialogs.reset(false)
  }

  const handleResetPassword = () => {
    authDialogs.set(AuthDialog.RESET_PASSWORD)
  }

  const handleSignUp = () => {
    authDialogs.set(AuthDialog.SIGN_UP, {
      redirectUrl: authDialogs.payload?.redirectUrl,
    })
  }

  const handleClose = () => authDialogs.reset()

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Sign in"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <LoadingStateFallback fallbackRendered={!singleTenantSSO.data}>
          {isAnySSOEnabled && (
            <>
              <_LoginSSOButtons />
              <Divider>or</Divider>
            </>
          )}
          <_Form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  variant="filled"
                  placeholder="Enter your email"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="email"
            />
            <_PasswordContainer>
              <Controller
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="password"
                    label="Password"
                    fullWidth
                    variant="filled"
                    placeholder="Enter your password"
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
                name="password"
              />
              <Button variant="text" size="small" color="primary" onClick={handleResetPassword}>
                <Typography sx={{ textTransform: "none", fontSize: pxToRem(14) }}>
                  Forgot your password?
                </Typography>
              </Button>
            </_PasswordContainer>

            <LoadingButton
              disabled={!form.formState.isValid}
              loading={form.formState.isSubmitting}
              size="large"
              color="primary"
              variant="contained"
              type="submit">
              Continue
            </LoadingButton>
          </_Form>
        </LoadingStateFallback>
        <_SignUpText>
          <span>Don’t have an account?</span>
          <_SignUpButton size="small" onClick={handleSignUp}>
            Sign Up
          </_SignUpButton>
        </_SignUpText>
      </_DialogContent>
    </Dialog>
  )
}

export default SignInDialog

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _LoginSSOButtons = styled(LoginSSOButtons)(() => ({
  padding: 0,
  margin: 0,
})) as typeof LoginSSOButtons

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))

const _PasswordContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  gap: pxToRem(10),
}))

const _SignUpText = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  color: theme.palette.gray.darker,
  ...theme.typography.paragraphRegular,
})) as typeof Typography

const _SignUpButton = styled(Button)(({ theme }) => ({
  ...theme.typography.paragraphRegular,
  textTransform: "capitalize",
}))
