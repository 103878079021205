import classLinkIcon from "/public/assets/icons/classLink.svg"
import cleverIcon from "/public/assets/icons/clever.svg"
import visitDaysIcon from "/public/assets/icons/visitDays.svg"
import { Box, Button, ButtonProps, CircularProgress, styled } from "@mui/material"
import GoogleOneTapButton from "components/GoogleOneTapButton"
import useAuth0Context from "hooks/useAuth0Context"
import { useClassLinkSSO } from "hooks/useClassLinkSSO"
import { useCleverSSO } from "hooks/useCleverSSO"
import useShowEnableCookieSnackbar from "hooks/useShowEnableCookieSnackbar"
import Image from "next/image"
import { ReactNode } from "react"
import { isCookieDisabled, pxToRem } from "utils/helpers"
import { makeStyles } from "tss-react/mui-compat"

/*
 * Since Google Button is hard to customize to meet all of its criteria.
 * The inconsistency between the Google button and the other looks pretty bad
 * We make them at least the same height to make it look a bit better and consistent
 * */
export const GOOGLE_BUTTON_HEIGHT_PX = 43.17
export const GOOGLE_BUTTON_ICON_CONTAINER_WIDTH = 36
export const GOOGLE_BUTTON_BORDER_RADIUS = 4
export const GOOGLE_MAX_BUTTON_WIDTH_PX = 400
const ICON_SIZE = GOOGLE_BUTTON_HEIGHT_PX / 2.2

const useStyles = makeStyles()(
  (theme) => ({
    button: {
      ...theme.fonts.lato400,
      height: GOOGLE_BUTTON_HEIGHT_PX,
      padding: 0,
      justifyContent: "space-between",
      boxShadow: "none",
      width: "100%",
      maxWidth: GOOGLE_MAX_BUTTON_WIDTH_PX,
      borderRadius: GOOGLE_BUTTON_BORDER_RADIUS,
      textTransform: "capitalize",
    },
    clever: {
      border: "1px solid #4274f6",
      backgroundColor: "#4274f6",
      "&:hover": {
        backgroundColor: "#2254d6",
      },
    },
    classLink: {
      border: "1px solid #36aace",
      backgroundColor: "#36aace",
      "&:hover": {
        backgroundColor: "#169aae",
      },
    },
    auth0: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: GOOGLE_BUTTON_ICON_CONTAINER_WIDTH,
      height: "100%",
      borderRadius: `${GOOGLE_BUTTON_BORDER_RADIUS}px 0 0 ${GOOGLE_BUTTON_BORDER_RADIUS}px`,
    },
    whiteBg: {
      backgroundColor: theme.palette.background.default,
    },
  })
)

interface ILoginSSOButtons {
  className?: string
}

export const LoginSSOButtons = (props: ILoginSSOButtons) => {
  const { className } = props
  const auth0 = useAuth0Context()
  const cleverSSO = useCleverSSO()
  const classLinkSSO = useClassLinkSSO()
  const showEnableCookieSnackbar = useShowEnableCookieSnackbar()

  const withEnableCookieErrorMessage = (callback: () => void) => () => {
    if (isCookieDisabled()) {
      showEnableCookieSnackbar()
      return
    }

    callback()
  }

  return (
    <_ButtonsContainer className={className}>
      {classLinkSSO.isEnabled && (
        <LoginButton
          onClick={withEnableCookieErrorMessage(classLinkSSO.loginWithRedirect)}
          isLoading={auth0.isLoading}
          text="Log in with ClassLink"
          customClass="classLink"
        />
      )}
      {cleverSSO.isEnabled && (
        <LoginButton
          onClick={withEnableCookieErrorMessage(cleverSSO.loginWithRedirect)}
          isLoading={auth0.isLoading}
          text="Log in with Clever"
          customClass="clever"
        />
      )}
      {auth0.isEnabled && (
        <LoginButton
          onClick={withEnableCookieErrorMessage(() => auth0.loginWithRedirect({ appState: {} }))}
          isLoading={auth0.isLoading}
          text={auth0.signinText || "Log in with SSO"}
          customClass="auth0"
        />
      )}
      <GoogleOneTapButton />
    </_ButtonsContainer>
  )
}

interface ILoginButton extends ButtonProps {
  isLoading: boolean
  text: string
  customClass: "auth0" | "clever" | "classLink"
}

const LoginButton = ({ isLoading, text, customClass, ...props }: ILoginButton) => {
  const { classes, cx } = useStyles()

  let startIcon: JSX.Element | undefined = undefined

  if (customClass === "auth0") {
    startIcon = <Image src={visitDaysIcon} width={ICON_SIZE} height={ICON_SIZE} alt="Sign In with Auth0" />
  }

  if (customClass === "classLink") {
    startIcon = <Image src={classLinkIcon} width={ICON_SIZE} height={ICON_SIZE} alt="Sign In with Class Link" />
  }

  if (customClass === "clever") {
    startIcon = <Image src={cleverIcon} width={ICON_SIZE} height={ICON_SIZE} alt="Sign In with Clever" />
  }

  if (isLoading) {
    startIcon = <CircularProgress size={14} />
  }

  const IconWrapper = ({ children, white }: { children?: ReactNode; white?: boolean }) => (
    <Box className={cx(classes.iconWrapper, white && classes.whiteBg)}>{children}</Box>
  )

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading}
      className={cx(classes.button, classes[customClass])}
      {...props}>
      <IconWrapper white>{startIcon}</IconWrapper>
      {text}
      <IconWrapper />
    </Button>
  )
}

const _ButtonsContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  gap: pxToRem(20),

  width: "100%",
  marginBottom: pxToRem(20),
})) as typeof Box
