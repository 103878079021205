import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogContent, styled, TextField, Typography } from "@mui/material"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as yup from "yup"

interface FormValues {
  email: string
}

const formValidationScheme = yup.object().shape({
  email: yup.string().email("Must be a valid email.").required("Required."),
})

interface ResetPasswordDialogProps {}

const ResetPasswordDialog = (props: ResetPasswordDialogProps) => {
  const {} = props
  const authDialogs = useAuthDialog()
  const auth = useAuth()
  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  })

  const onSubmit = async (formValues: FormValues) => {
    const error = await auth.resetPassword(formValues.email)
    if (!error) authDialogs.reset()
  }

  const handleClose = () => authDialogs.reset()

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Reset Your Password"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <DialogContent>
        <_Form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography color="gray.darker" component="p">
            Enter your email and we’ll send you a link to create a new password.
          </Typography>
          <Controller
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus
                label="Email"
                fullWidth
                variant="filled"
                placeholder="Your Email"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
            name="email"
          />
          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Send
          </LoadingButton>
        </_Form>
      </DialogContent>
    </Dialog>
  )
}

export default ResetPasswordDialog

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))
