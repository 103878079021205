import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui-compat"
import React, { useContext } from "react"
import { ChannelStateContext } from "stream-chat-react"

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
    width: "100%",
  },
})

const ChatWindow = (props: any) => {
  const { children } = props

  // @ts-ignore
  const { thread } = useContext(ChannelStateContext)
  const { classes } = useStyles()

  if (thread) return null

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      {children}
    </Box>
  )
}

export default ChatWindow
