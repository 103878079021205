import ResourceModel from "models/convention-api/ResourceModel"

export default class ConventionSignupQuestion extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_signup_questions"

  text!: string
  question_type!: string
  required!: boolean
  position!: number
  provider_id!: string
  convention_registrant_type_ids!: string[]
}
