function concatParts(parts: string[]) {
  return parts.map((part) => part.trim().replace(/^\/+|\/+$/g, "")).join("/")
}

function injectData(path: string, data?: { [key: string]: string }) {
  return path.replace(/\/:(\w+)(\/|$)/g, (match, key, tail, _full) => {
    if (data && data[key]) {
      return `/${data[key]}${tail}`
    }
    console.warn(`buildUrl: '${key}' does not exist`, data)
    return match
  })
}

export default function buildUrl(params: {
  host: string
  path: string
  pathParams?: { [key: string]: string }
  id?: string
  queryString?: string
}): string {
  if (!params.host) {
    throw `buildURL: missing host, api would call a relative url without it, perhaps env config is missing`
  }
  if (!params.path) {
    throw `buildURL: missing path, perhaps API model is missing its basePath property`
  }

  let url = concatParts([params.host, "convention_api", params.path])
  if (params.pathParams) {
    url = injectData(url, params.pathParams)
  }
  if (params.id) {
    url = `${url}/${params.id}`
  }

  const queryString = params.queryString
  if (queryString) {
    if (url.includes("?")) {
      if (url.includes("#")) {
        url = url.replace(/#/, `&${queryString}#`)
      } else {
        url = `${url}&${queryString}`
      }
    } else if (url.includes("#")) {
      url = url.replace(/#/, `?${queryString}#`)
    } else {
      url = `${url}?${queryString}`
    }
  }

  return url
}
