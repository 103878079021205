import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui-compat"
import { useContext } from "react"
import { ChannelStateContext } from "stream-chat-react"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      height: "110px",
      width: "100%",
      borderRadius: "10px 10px 0 0",
      backgroundColor: "rgba(255,255,255,0.81)",
      boxShadow: "0 7px 9px 0 rgba(0,0,0,0.03), 0 1px 0 0 rgba(0,0,0,0.03)",
    },
    button: {
      height: "110px",
      width: "30px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px 0 0 0",
      color: "white",
    },
    avatarBox: {
      padding: "25px 20px",
    },
    avatar: {
      height: "60px",
      width: "60px",
      objectFit: "cover",
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
    },
    header: {
      height: "110px",
      width: "100%",
      borderRadius: "0 10px 0 0",
    },
    headerNoPadding: {
      padding: "12px 0px",
    },
    headerPadding: {
      padding: "12px 20px",
    },
    headerTextTop: {
      fontSize: "10px",
      letterSpacing: "0.1px",
      lineHeight: "25.2px",
      fontWeight: "bold",
      opacity: 0.8,
      color: "#556064",
      fontFamily: "Helvetica Neue",
      textTransform: "uppercase",
    },
    headerTextMiddle: {
      color: "#000000",
      fontFamily: "Helvetica Neue",
      fontSize: "25.6px",
      letterSpacing: "0.26px",
      lineHeight: "30.8px",
      marginTop: "4px",
    },
    headerTextBottom: {
      color: "#000000",
      fontFamily: "Helvetica Neue",
      fontSize: "15px",
      letterSpacing: "0.15px",
      lineHeight: "25.2px",
    },
  })
)

interface IHeader {
  closeChat: () => void
}

const Header = ({ closeChat }: IHeader) => {
  // @ts-ignore
  const { channel } = useContext(ChannelStateContext)

  const { classes, cx } = useStyles()

  const image = channel.data.image

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={1}>
          <IconButton
            aria-label="Back to Chat List"
            onClick={closeChat}
            className={classes.button}
            size="large">
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        {image && (
          <Grid item xs={3} className={classes.avatarBox}>
            <Avatar src={image} className={classes.avatar} />
          </Grid>
        )}
        <Grid item xs={image ? 8 : 11}>
          <Box
            className={cx(
              classes.header,
              image ? classes.headerNoPadding : classes.headerPadding,
            )}>
            <Typography className={classes.headerTextTop} variant="h2" id="chatregion">
              {channel.data.name || "Unnamed Chat"}
            </Typography>
            <Typography className={classes.headerTextMiddle}>Hello 👋</Typography>
            <Typography className={classes.headerTextBottom}>Chat with us about stuff!</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
