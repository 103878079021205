import { makeStyles } from "tss-react/mui-compat"
import useCurrent from "lib/use-current"

interface ILink {
  href: string
  text: string
}

const useStyles = makeStyles()(
  (theme) => ({
    link: {
      ...theme.fonts.lato400,
      lineHeight: 1.375,
      fontSize: "inherit",
      color: theme.palette.primary.main,
      "&:active, &:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
)

const Link = ({ href, text }: ILink) => {
  const { classes } = useStyles()
  return (
    <a href={href} className={classes.link} target="_blank" rel="noreferrer">
      {text}
    </a>
  )
}

export const ToSLink = () => {
  // @ts-ignore
  const { config: providerConfig } = useCurrent()
  if (!providerConfig) return null

  return <Link href={providerConfig.terms_of_service_url} text="Terms of Service" />
}

export const PrivacyPolicyLink = () => {
  // @ts-ignore
  const { config: providerConfig } = useCurrent()
  if (!providerConfig) return null

  return <Link href={providerConfig.privacy_policy_url} text="Privacy Policy" />
}

export const CookiePolicyLink = () => {
  // @ts-ignore
  const { config: providerConfig } = useCurrent()
  if (!providerConfig) return null

  return <Link href={providerConfig.cookie_policy_url} text="Cookie Policy" />
}

export const A11yStatementLink = () => (
  <Link
    href="https://learn.visitdays.com/en/article/visitdays-accessibility-statement-h3q5h"
    text="Accessibility Statement"
  />
)
