import VDAvatar from "./VDAvatar"
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff"
import Box from "@mui/material/Box"
import ButtonBase from "@mui/material/ButtonBase"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui-compat"
import getChatAvailability from "lib/getChatAvailability"
import useCurrent from "lib/use-current"
import { useEffect, useState } from "react"

const useStyles = makeStyles()({
  rectangle: {
    height: "60px",
    width: "100%",
    backgroundColor: "rgba(255,255,255,0)",
    boxShadow: "0 1px 0 0 rgba(0,0,0,0.07)",
    justifyContent: "flex-start",
    padding: "0 24px",
  },
  box: {
    textAlign: "left",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "17px",
  },
  message: {
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "16px",
  },
  disabled: {
    color: "#767676",
  },
  unread: {
    position: "absolute",
    right: "24px",
    bottom: "14px",
    width: "10px",
    height: "10px",
    color: "#FA4654",
    fontSize: "12px",
    background: "#FA4654",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
})

// @ts-ignore PT - #180597146
const Preview = (props) => {
  const {
    channel,
    setActiveChannel,
    customActiveChannel,
    setOpen,
    setCustomActiveChannel,
    watchers,
  } = props

  const [chatAvailable, setChatAvailable] = useState("off")

  const current = useCurrent()

  useEffect(() => {
    // @ts-ignore PT - #180597146
    const getAvailability = async (institutionId) => {
      try {
        const data = await getChatAvailability({ current, institutionId })
        if (data && data[0]) {
          setChatAvailable(data[0].status)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (channel?.data?.institution_id) {
      getAvailability(channel.data.institution_id)
    }
  }, [])

  useEffect(() => {
    if (channel.id === customActiveChannel) {
      setActiveChannel(channel, watchers)
      setOpen(true)
      setCustomActiveChannel("")
    }
  }, [customActiveChannel])

  const { classes, cx } = useStyles()

  return (
    <Grid item xs={12}>
      <ButtonBase
        onClick={() => {
          setActiveChannel(channel, watchers)
          setOpen(true)
        }}
        className={classes.rectangle}
        focusRipple={true}>
        <VDAvatar image={props.displayImage} name={props.displayTitle} />
        <Box className={classes.box}>
          <Typography className={classes.title}>{props.displayTitle || "Unnamed Chat"}</Typography>
          {chatAvailable !== "off" ? (
            <Typography className={classes.message}>
              {props?.lastMessage?.text?.substring(0, 40) || ""}
            </Typography>
          ) : (
            <Typography className={cx(classes.message, classes.disabled)}>
              <SpeakerNotesOffIcon fontSize="inherit" /> Chat is currently disabled.
            </Typography>
          )}
          {props.unread >= 1 && <Box className={classes.unread}>{props.unread}</Box>}
        </Box>
      </ButtonBase>
    </Grid>
  )
}

export default Preview
