import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import Divider from "@mui/material/Divider"
import { AuthDialog, AuthEmail } from "app-constants/constants"
import {
  A11yStatementLink,
  CookiePolicyLink,
  PrivacyPolicyLink,
  ToSLink,
} from "components/LegalLinks"
import { LoginSSOButtons } from "components/LoginSSOButtons"
import LoadingStateFallback from "components/common/LoadingStateFallback"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import SingleTenantSSO from "models/convention-api/v1/SingleTenantSSO"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as Yup from "yup"

interface FormValues {
  email: string
  requestInfoAcknowledgment: boolean
}

const formValidationScheme = Yup.object().shape({
  email: Yup.string().email("Must be a valid email.").required("Required."),
  requestInfoAcknowledgment: Yup.boolean(),
})

interface SignUpDialogProps {}

const SignUpDialog = (props: SignUpDialogProps) => {
  const {} = props
  const authDialogs = useAuthDialog()
  const auth = useAuth()
  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      email: "",
      requestInfoAcknowledgment: false,
    },
  })

  const singleTenantSSO = SingleTenantSSO.useOne({})
  const isAnySSOEnabled = SingleTenantSSO.isAnyEnabled(singleTenantSSO.data)

  const onEmailSubmit = async (formValues: FormValues) => {
    const response = await auth.checkIfEmailRegistered(formValues)
    if (response instanceof Error) return

    const { requestInfoAcknowledgment, email } = formValues
    const { need } = response.data.attributes || {}
    if (need === AuthEmail.PASSWORD) {
      authDialogs.set(AuthDialog.SIGN_IN, {
        email,
        requestInfoAcknowledgment,
        redirectUrl: authDialogs.payload?.redirectUrl,
      })
      return
    }

    if (need === AuthEmail.NOT_FOUND) {
      authDialogs.set(AuthDialog.SIGN_UP_PASSWORD, {
        email,
        requestInfoAcknowledgment,
        redirectUrl: authDialogs.payload?.redirectUrl,
      })
      return
    }
  }

  const handleClose = () => {
    authDialogs.reset()
  }

  const handleSignIn = () => {
    authDialogs.set(AuthDialog.JOIN, {
      redirectUrl: authDialogs.payload?.redirectUrl,
      preamble: '',
      dialogHeader: 'Sign In'
    })
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Sign Up"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <LoadingStateFallback fallbackRendered={!singleTenantSSO.data}>
          {isAnySSOEnabled && (
            <>
              <_LoginSSOButtons />
              <Divider>or</Divider>
            </>
          )}
          <_Form onSubmit={form.handleSubmit(onEmailSubmit)}>
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  variant="filled"
                  placeholder="Enter your email"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="email"
            />
            <FormControlLabel
              control={
                <Controller
                  name="requestInfoAcknowledgment"
                  control={form.control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <Typography color="gray.darker">
                  I would like to receive information on scholarships, internships, jobs or other
                  program opportunities.
                </Typography>
              }
            />

            <LoadingButton
              disabled={!form.formState.isValid}
              loading={form.formState.isSubmitting}
              size="large"
              color="primary"
              variant="contained"
              type="submit">
              Continue
            </LoadingButton>
            <Typography color="gray.darker" fontSize={pxToRem(14)}>
              By continuing you acknowledge you have read our <A11yStatementLink />, <ToSLink />,{" "}
              <PrivacyPolicyLink />, <CookiePolicyLink />.
            </Typography>
          </_Form>
        </LoadingStateFallback>
        <_SignInText>
          <span>Already have an account?</span>
          <_SignInButton size="small" onClick={handleSignIn}>
            Sign In
          </_SignInButton>
        </_SignInText>
      </_DialogContent>
    </Dialog>
  )
}

export default SignUpDialog

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))

const _LoginSSOButtons = styled(LoginSSOButtons)(() => ({
  padding: 0,
  margin: 0,
})) as typeof LoginSSOButtons

const _SignInText = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  color: theme.palette.gray.darker,
  ...theme.typography.paragraphRegular,
})) as typeof Typography

const _SignInButton = styled(Button)(({ theme }) => ({
  ...theme.typography.paragraphRegular,
  textTransform: "capitalize",
}))
