import { BuildServerSidePropsMiddlewareContext } from "lib/build-server-side-props"
import { camelToSnakeCase } from "utils/helpers"

export enum ROUTE {
  HOME = "/",
  LOGOUT = "/logout",
  PASSWORD_RESET = "/password-reset",
  ACCOUNT_MAIN = "/account",
  ACCOUNT_FAVORITES = "/account/favorites",
  ACCOUNT_NOTIFICATIONS = "/account/notifications",
  ACCOUNT_PERSONAL = "/account/personal",
  ACCOUNT_PREFERENCES = "/account/preferences",
  CONVENTIONS = "/conventions",
  CONVENTION = "/conventions/[convention_id]",
  CONVENTION_MEETING = "/conventions/meetings/[registration_id]",
  CONVENTION_STAGE = "/conventions/[convention_id]/stage",
  CONVENTION_SESSIONS = "/conventions/[convention_id]/sessions",
  CONVENTION_SESSION = "/conventions/[convention_id]/sessions/[session_id]",
  CONVENTION_SURVEY = "/conventions/[convention_id]/survey",
  CONVENTION_EXHIBITORS = "/conventions/[convention_id]/exhibitors",
  CONVENTION_EXHIBITOR = "/conventions/[convention_id]/exhibitors/[exhibitor_id]",
  EXHIBITORS = "/exhibitors",
  EXHIBITORS_BROWSE_ALL = "/exhibitors/browse-all",
  EXHIBITOR = "/exhibitors/[exhibitor_id]",
  ITINERARIES = "/itineraries",
  PEOPLE = "/people",
  PERSON = "/people/[person_id]",
  SIGNUP_UPCOMING_CONVENTIONS = "/signup/upcoming-conventions",
  SSO_CALLBACKS_CLEVER = "/sso-callbacks/clever",
  SSO_CALLBACKS_GOOGLE = "/sso-callbacks/google",
  VIDEOS = "/videos",
  VIDEO = "/videos/[video_id]",

  // /embed/... based routes
  EMBED_HOME = "/embed/",
  EMBED_LOGOUT = "/embed/logout",
  EMBED_PASSWORD_RESET = "/embed/password-reset",
  EMBED_ACCOUNT_MAIN = "/embed/account",
  EMBED_ACCOUNT_FAVORITES = "/embed/account/favorites",
  EMBED_ACCOUNT_NOTIFICATIONS = "/embed/account/notifications",
  EMBED_ACCOUNT_PERSONAL = "/embed/account/personal",
  EMBED_ACCOUNT_PREFERENCES = "/embed/account/preferences",
  EMBED_CONVENTIONS = "/embed/conventions",
  EMBED_CONVENTION = "/embed/conventions/[convention_id]",
  EMBED_CONVENTION_MEETING = "/embed/conventions/meetings/[registration_id]",
  EMBED_CONVENTION_STAGE = "/embed/conventions/[convention_id]/stage",
  EMBED_CONVENTION_SESSIONS = "/embed/conventions/[convention_id]/sessions",
  EMBED_CONVENTION_SESSION = "/embed/conventions/[convention_id]/sessions/[session_id]",
  EMBED_CONVENTION_SURVEY = "/embed/conventions/[convention_id]/survey",
  EMBED_CONVENTION_EXHIBITORS = "/embed/conventions/[convention_id]/exhibitors",
  EMBED_CONVENTION_EXHIBITOR = "/embed/conventions/[convention_id]/exhibitors/[exhibitor_id]",
  EMBED_EXHIBITORS = "/embed/exhibitors",
  EMBED_EXHIBITORS_BROWSE_ALL = "/embed/exhibitors/browse-all",
  EMBED_EXHIBITOR = "/embed/exhibitors/[exhibitor_id]",
  EMBED_ITINERARIES = "/embed/itineraries",
  EMBED_PEOPLE = "/embed/people",
  EMBED_PERSON = "/embed/people/[person_id]",
  EMBED_SIGNUP_UPCOMING_CONVENTIONS = "/signup/upcoming-conventions",
  EMBED_VIDEOS = "/embed/videos",
  EMBED_VIDEO = "/embed/videos/[video_id]",
}

type TArgs =
  | { path: ROUTE.HOME }
  | { path: ROUTE.LOGOUT }
  | { path: ROUTE.PASSWORD_RESET }
  | { path: ROUTE.ACCOUNT_MAIN }
  | { path: ROUTE.ACCOUNT_FAVORITES }
  | { path: ROUTE.ACCOUNT_NOTIFICATIONS }
  | { path: ROUTE.ACCOUNT_PERSONAL }
  | { path: ROUTE.ACCOUNT_PREFERENCES }
  | { path: ROUTE.CONVENTIONS }
  | { path: ROUTE.CONVENTION_MEETING; params: { registrationId: string } }
  | { path: ROUTE.CONVENTION; params: { conventionId: string } }
  | { path: ROUTE.CONVENTION_STAGE; params: { conventionId: string } }
  | { path: ROUTE.CONVENTION_SESSIONS; params: { conventionId: string } }
  | {
      path: ROUTE.CONVENTION_SESSION
      params: { conventionId: string; sessionId: string }
      query?: { sessionIdToJoin?: string }
    }
  | { path: ROUTE.CONVENTION_SURVEY; params: { conventionId: string } }
  | { path: ROUTE.CONVENTION_EXHIBITORS; params: { conventionId: string } }
  | { path: ROUTE.CONVENTION_EXHIBITOR; params: { conventionId: string; exhibitorId: string } }
  | { path: ROUTE.EXHIBITORS }
  | { path: ROUTE.EXHIBITORS_BROWSE_ALL }
  | { path: ROUTE.EXHIBITOR; params: { exhibitorId: string } }
  | { path: ROUTE.ITINERARIES }
  | { path: ROUTE.PEOPLE }
  | { path: ROUTE.PERSON; params: { personId: string } }
  | { path: ROUTE.SIGNUP_UPCOMING_CONVENTIONS; query: { registrantTypeId: string } }
  | { path: ROUTE.SSO_CALLBACKS_CLEVER }
  | { path: ROUTE.SSO_CALLBACKS_GOOGLE }
  | { path: ROUTE.VIDEOS }
  | { path: ROUTE.VIDEO; params: { videoId: string } }
  | { path: ROUTE.EMBED_HOME }
  | { path: ROUTE.EMBED_LOGOUT }
  | { path: ROUTE.EMBED_PASSWORD_RESET }
  | { path: ROUTE.EMBED_ACCOUNT_MAIN }
  | { path: ROUTE.EMBED_ACCOUNT_FAVORITES }
  | { path: ROUTE.EMBED_ACCOUNT_NOTIFICATIONS }
  | { path: ROUTE.EMBED_ACCOUNT_PERSONAL }
  | { path: ROUTE.EMBED_ACCOUNT_PREFERENCES }
  | { path: ROUTE.EMBED_CONVENTIONS }
  | { path: ROUTE.EMBED_CONVENTION_MEETING; params: { registrationId: string } }
  | { path: ROUTE.EMBED_CONVENTION; params: { conventionId: string } }
  | { path: ROUTE.EMBED_CONVENTION_STAGE; params: { conventionId: string } }
  | { path: ROUTE.EMBED_CONVENTION_SESSIONS; params: { conventionId: string } }
  | {
      path: ROUTE.EMBED_CONVENTION_SESSION
      params: { conventionId: string; sessionId: string }
      query?: { sessionIdToJoin?: string }
    }
  | { path: ROUTE.EMBED_CONVENTION_SURVEY; params: { conventionId: string } }
  | { path: ROUTE.EMBED_CONVENTION_EXHIBITORS; params: { conventionId: string } }
  | {
      path: ROUTE.EMBED_CONVENTION_EXHIBITOR
      params: { conventionId: string; exhibitorId: string }
    }
  | { path: ROUTE.EMBED_EXHIBITORS }
  | { path: ROUTE.EMBED_EXHIBITORS_BROWSE_ALL }
  | { path: ROUTE.EMBED_EXHIBITOR; params: { exhibitorId: string } }
  | { path: ROUTE.EMBED_ITINERARIES }
  | { path: ROUTE.EMBED_PEOPLE }
  | { path: ROUTE.EMBED_PERSON; params: { personId: string } }
  | { path: ROUTE.EMBED_SIGNUP_UPCOMING_CONVENTIONS; query: { registrantTypeId: string } }
  | { path: ROUTE.EMBED_VIDEOS }
  | { path: ROUTE.EMBED_VIDEO; params: { videoId: string } }

type QueryObject = Record<string, string | boolean | number | Array<string | boolean | number>>
export type ParamsObject = Record<string, string>

export function createPath(args: TArgs) {
  const { query, params, path } = args as {
    query?: QueryObject
    path: ROUTE
    params?: ParamsObject
  }

  if (params === undefined && query === undefined) return path

  if (params === undefined && query !== undefined) return addQueryData(path, query)
  if (params !== undefined && query === undefined) return addParamsData(path, params)

  if (params !== undefined && query !== undefined) {
    return addQueryData(addParamsData(path, params), query)
  }

  return path
}

export function createURL(args: TArgs): string {
  return window.location.origin + createPath(args)
}

export function createURL_SSR(context: BuildServerSidePropsMiddlewareContext, args: TArgs): string {
  return context.props.current.host + createPath(args)
}

function addQueryData(path: string, query: QueryObject): string {
  const formattedQuery = Object.entries(query)
    .map((pair) =>
      pair
        .map((keyValue) =>
          Array.isArray(keyValue) ? keyValue.join(",") : encodeURIComponent(keyValue),
        )
        .join("="),
    )
    .join("&")

  if (!formattedQuery.length) return path

  return `${path}?${formattedQuery}`
}

export function addParamsData(path: string, params: ParamsObject): string {
  return Object.entries(params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`[${camelToSnakeCase(param)}]`, "" + value),
    path,
  )
}
