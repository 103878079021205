import { CookieKeys } from "app-constants/constants"
import { parseCookies } from "nookies"

export default function getAuthToken({ req = undefined } = {}) {
  // NOTE: it's possible to have an invalid authToken set from an environment
  // change or one that's not expired but been invalidated so it shouldn't
  // be used to test if someone is logged in or not
  const cookies = parseCookies({ req })
  return cookies[CookieKeys.LOGIN_SESSION]
}
