import { api } from "lib/api"
import useCurrent from "lib/use-current"
import SingleTenantSSO from "models/convention-api/v1/SingleTenantSSO"

export const useClassLinkSSO = () => {
  const current = useCurrent()

  const { data: SSOConfig } = SingleTenantSSO.useOne({})

  const loginWithRedirect = async () => {
    if (!SSOConfig) throw new TypeError("SSOConfig is undefined")

    try {
      const conventionApiPath = await api.getConventionApiPath()

      const url = new URL("https://launchpad.classlink.com/oauth2/v2/auth")
      url.searchParams.append("scope", "full")
      url.searchParams.append("client_id", SSOConfig.classlink_client_identifier)
      url.searchParams.append("redirect_uri", conventionApiPath + "/v1/classlink_oauth2")
      url.searchParams.append("response_type", "code")
      url.searchParams.append(
        "state",
        JSON.stringify({
          convention_provider_id: current?.provider?.id,
        }),
      )
      window.location.href = url.href
    } catch {
      console.error("There was an issue with ClassLink redirect.")
    }
  }

  const isLoading = typeof SSOConfig === "undefined"
  const isEnabled = !!SSOConfig?.classlink_enabled

  return {
    loginWithRedirect,
    isEnabled,
    isLoading,
  }
}
