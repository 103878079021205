import React from "react"

export const SkipNavigation = () => {
  return (
    <>
      <div>
        <a aria-label="Skip to main content" className="skip-nav-link" href="#main-content">
          Skip to main content
        </a>
      </div>
      {/* @ts-ignore */}
      <style jsx>{`
        .skip-nav-link {
          border: 1px solid;
          border-color:#99ceff;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          background-color: #cce6ff;
        }
        .skip-nav-link:focus {
          display:flex;
          alignItems:center
          background-color: #cce6ff;
          clip: auto;
          height: auto;
          left: 45%;
          padding: 1rem;
          position: fixed;
          top: 0px;
          width: auto;
          z-index: 1001;
        }
      `}</style>
    </>
  )
}

export default SkipNavigation
