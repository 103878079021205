import { useEffect, useState } from "react"

interface Response {
  country: string
  countryCode: string
}

function useCurrentIPGeolocationData() {
  const [data, setData] = useState<Response | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true)
    fetch("https://extreme-ip-lookup.com/json/?key=RMUhdnZiGSvAGrQoxrGp")
      .then((res) => res.json())
      .then((response) => {
        setData({ country: response.country, countryCode: response.countryCode })
      })
      .catch((error) => {
        console.warn("Request failed:", error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    isLoading,
    data: data
      ? {
          country: data.country,
          countryCode: data.countryCode?.toLowerCase(),
        }
      : undefined,
  }
}

export default useCurrentIPGeolocationData
