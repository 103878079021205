import { LOGIN_TOKEN_EXPIRY, REM_IN_PX } from "app-constants/constants"
import { Current } from "lib/use-current"
import { Moment } from "moment"
import moment from "moment-timezone"
import { setCookie } from "nookies"
import { anyPass, isEmpty, isNil, keys, is } from "ramda"

export const isObjectEmpty = (object: any) => isEmpty(keys(object))

export const isNilOrEmpty = anyPass([isNil, isEmpty])

export const isString = is(String)

export function nameToInitials(fullName: string) {
  const namesArray = fullName.trim().split(" ")
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`
}

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const snakeToCamelCase = (str: string) => str.replace(/_(.)/g, (_s, c) => c.toUpperCase())

export function pxToRem(px: number): string
export function pxToRem(px: number, rawValue: false): string
export function pxToRem(px: number, rawValue: true): number
export function pxToRem(px: number, rawValue?: boolean) {
  const valueInRem = px / REM_IN_PX
  if (rawValue) return valueInRem as number

  return `${valueInRem}rem` as string
}

export const get30SecondsIntervalISOSDate = () => {
  const start = moment()
  const remainder = 30 - (start.seconds() % 30)
  return moment(start).add(remainder, "seconds").startOf("seconds").toISOString()
}

export const getElementLineHeight = (element: HTMLElement) => {
  const clonedElement = element.cloneNode() as HTMLElement

  clonedElement.style.visibility = "hidden"
  clonedElement.style.position = "absolute"
  clonedElement.innerHTML = "a<br>b"

  document.body.append(clonedElement)

  const { height } = clonedElement.getBoundingClientRect()

  clonedElement.remove()

  return height / 2
}

export const isInIframe = () => window.location !== window.parent.location

export const isCookieEnabled = () => navigator.cookieEnabled
export const isCookieDisabled = () => !isCookieEnabled()

// https://github.com/jshttp/cookie/blob/master/index.js#L111-L218
// using instead of import { CookieSerializeOptions } from "cookies"
type TCookieOptions = {
  domain?: string
  encode?: string
  expires?: Date
  httpOnly?: boolean
  maxAge?: number
  path?: string
  sameSite?: true | "strict" | "lax" | "none"
  secure?: boolean
}
// consistent way to set cookies. If embedded in iFrame allow SameSite to None; Secure
export const setSecureCookie = (
  ctx: any,
  key: string,
  value: string,
  options = {} as TCookieOptions,
) => {
  options = Object.assign({}, options) as TCookieOptions

  if (!options.path) {
    options.path = "/"
  }
  if (!options.maxAge) {
    options.maxAge = LOGIN_TOKEN_EXPIRY
  }
  if (isInIframe()) {
    options.sameSite = "none"
    options.secure = true
  }

  setCookie(ctx, key, value, options)
}

export const percentageDifference = (number1: number, number2: number): number =>
  +Math.abs(100 - (number2 / number1) * 100).toFixed(10)

export const getCurrentProviderId = (current?: Current): string | never => {
  const providerId = current?.provider?.id

  if (!providerId) throw new TypeError("providerId is undefined")

  return providerId
}

export const resetEmbeddedApp = (): null | void => {
  const inIframe = isInIframe()
  if (!window) {
    return null
  }

  if (!inIframe) {
    return null
  }

  window.parent.postMessage("close", "*")
  window.location.replace("/embed")
  return
}

interface IPostMessageProps {
  message: any
  domain?: string
}

export const sendIframeMessage = (props: IPostMessageProps): null | void => {
  const { message, domain = "*" } = props
  const inIframe = isInIframe()

  if (!window) {
    return null
  }

  if (!inIframe) {
    return null
  }

  return window.parent.postMessage(message, domain)
}

export const isEmbed = () => /^\/embed\//.test(window.location.pathname)

export enum CurrentTimePhase {
  BEFORE = "before",
  DURING = "during",
  AFTER = "after",
}

export const getCurrentTimePhase = (startAt: Moment, endAt: Moment): CurrentTimePhase => {
  const now = moment()

  if (now.isBefore(startAt)) return CurrentTimePhase.BEFORE
  if (now.isAfter(endAt)) return CurrentTimePhase.AFTER

  return CurrentTimePhase.DURING
}
