import { AuthDialog } from "app-constants/constants"
import { atom } from "recoil"

export const currentUserTokenState = atom<string | null>({
  key: "currentUserToken",
  default: null,
})

export const currentQueryFilter = atom({
  key: "currentQueryFilter",
  default: null,
})

export const lastCurrentUserUpdate = atom({
  key: "lastCurrentUserUpdate",
  default: Date.now(),
})

export const googleOneTapLoadedAtom = atom({
  key: "googleOneTapLoaded",
  default: false,
})

export const newAuthFlowFlagAtom = atom({
  key: "newAuthFlowFlag",
  default: false,
})

export interface OpenedAuthDialog {
  dialog: AuthDialog
  payload?: any
}

export const openedAuthDialogAtom = atom<OpenedAuthDialog>({
  key: "openedAuthDialog",
  default: { dialog: null },
})
