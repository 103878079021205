"use client";

import { CookieKeys, REQUEST_INFO_ACKNOWLEDGMENT_EXPIRY } from "app-constants/constants"
import useShowSnackbar from "hooks/useShowSnackbar"
import { api } from "lib/api"
import getCurrentContext from "lib/get-current-context"
import { useSetCurrent } from "lib/use-current"
import ConventionApiError from "models/convention-api/ConventionApiError"
import { useSetRecoilState } from "recoil"
import { currentUserTokenState } from "recoil-state/atoms"
import { setSecureCookie } from "utils/helpers"

interface BasicAuthenticationPayload {
  email: string
  password: string
}

interface SetPasswordPayload {
  resetPasswordToken: string
  password: string
}

interface BasicRegistrationPayload {
  email: string
  password?: string
  privacyCode?: string
  requestInfoAcknowledgment?: boolean
}

interface RegisterUserPayload {
  payload: any
  relationships: any
}

interface SendMagicLinkPayload {
  email: string
  returnUrl?: string
}

interface SendMagicLinkOptions {
  customSuccessMessage: string
}

const useAuth = () => {
  const showSnackbar = useShowSnackbar()
  const setCurrentUserToken = useSetRecoilState(currentUserTokenState)
  const setCurrent = useSetCurrent()

  const performBasicAuthentication = async (basicAuthentication: BasicAuthenticationPayload) => {
    try {
      const { email, password } = basicAuthentication
      const response = await api.loginUser({ req: undefined }, email, password)

      if (response instanceof ConventionApiError) {
        if (response.code === "record_not_found")
          showSnackbar(
            response.code === "record_not_found"
              ? "Email or password is incorrect. Please try again!"
              : response.message,
            "error",
          )
        return response
      }

      showSnackbar("You have successfully signed in")
      setCurrentUserToken(response.data)
      setSecureCookie(null, CookieKeys.LOGIN_SESSION, response.data)
      const current = await getCurrentContext()
      setCurrent(current)

      return response
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error as Error
    }
  }

  const checkIfEmailRegistered = async (payload: { email: string }) => {
    try {
      const response = await api.checkIfEmailRegistered({ req: undefined }, payload)
      if (response instanceof ConventionApiError) {
        showSnackbar(response.message, "error")
        return response
      }

      return response
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error as Error
    }
  }

  const resetPassword = async (email: string) => {
    try {
      const response = await api.resetPassword({ req: undefined }, email)
      if (response instanceof ConventionApiError) {
        showSnackbar(response.message, "error")
        return response
      }

      showSnackbar(`If we find an account, we'll send an email to ${email}`)
      return null
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error
    }
  }

  const performBasicRegistration = async (payload: BasicRegistrationPayload) => {
    try {
      const response = await api.conventionsLogin({ req: undefined }, payload)
      if (response instanceof ConventionApiError) {
        const errorMessage =
          {
            taken: "It looks your email has been already taken.",
          }[response.code] ?? response.message
        showSnackbar(errorMessage, "error")
        return response
      }

      setSecureCookie(
        null,
        CookieKeys.SIGNUP_REGISTRATION_TOKEN,
        response.data.attributes.registration_token,
      )

      setSecureCookie(
        null,
        CookieKeys.REQUEST_INFO_ACKNOWLEDGMENT,
        `${payload.requestInfoAcknowledgment}`,
        {
          maxAge: REQUEST_INFO_ACKNOWLEDGMENT_EXPIRY,
        },
      )
      return response
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error as Error
    }
  }

  const setPassword = async (setPasswordPayload: SetPasswordPayload) => {
    try {
      const response = await api.setPassword(
        { req: undefined },
        {
          reset_password_token: setPasswordPayload.resetPasswordToken,
          password: setPasswordPayload.password,
        },
      )
      if (response instanceof ConventionApiError) {
        showSnackbar(response.message, "error")
        return response
      }

      showSnackbar("Your password has been changed successfully")
      return null
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error
    }
  }

  const registerUser = async (payload: RegisterUserPayload) => {
    try {
      const response = await api.registerUser(
        { req: undefined },
        payload.payload,
        payload.relationships,
      )

      if (response instanceof ConventionApiError) {
        const errorMessage =
          {
            "": "It looks you haven't completed all the steps for an account to be created. Please start over",
          }[response.code] ?? response.message
        showSnackbar(errorMessage, "error")
        return response
      }

      // GA tracking
      window.dataLayer.push({
        userId: response.data.id,
        event: "userSignedUp",
      })

      setSecureCookie(null, CookieKeys.LOGIN_SESSION, response.meta.jwt.token)
      setCurrentUserToken(response.meta.jwt.token)

      const current = await getCurrentContext()
      setCurrent(current)

      return response
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error
    }
  }

  const sendMagicLink = async (payload: SendMagicLinkPayload, options?: SendMagicLinkOptions) => {
    try {
      const response = await api.sendMagicLink({ req: undefined }, payload)

      if (response instanceof ConventionApiError) {
        showSnackbar(response.message, "error")
        return response
      }

      if (response.data.attributes.status === "success") {
        showSnackbar(
          options?.customSuccessMessage ??
            `If we find an account, we'll send an email to ${payload.email}`,
          "info",
          { autoHideDuration: 6000 },
        )
      }

      return response
    } catch (error) {
      console.error(error)
      showSnackbar("Something went wrong", "error")
      return error
    }
  }

  return {
    performBasicAuthentication,
    resetPassword,
    setPassword,
    checkIfEmailRegistered,
    performBasicRegistration,
    registerUser,
    sendMagicLink,
  }
}

export default useAuth
