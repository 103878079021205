import camelcaseKeys from "camelcase-keys"
import fetch from "lib/fetch"
import getAuthToken from "lib/get-auth-token"
import getConventionProviderIdForHost from "lib/get-convention-provider-id-for-host"
import getEnvForHost from "lib/get-env-for-host"
import getHost from "lib/get-host"
import ConventionApiError from "models/convention-api/ConventionApiError"
import snakecaseKeys from "snakecase-keys"

// [DEPRECATED]
// Write methods under lib/ such as get-convention.ts instead
// that use sendConventionApiRequest and processApiResponse
class Api {
  constructor() {}

  async getConventionApiPath({ req = undefined } = {}) {
    const host = getHost({ req })
    // @ts-ignore
    const env = getEnvForHost(host)
    return `${env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT}/convention_api`
  }

  async getProviderApiPath({ req = undefined } = {}) {
    const host = getHost({ req })
    // @ts-ignore
    const providerId = await getConventionProviderIdForHost(host)
    // NOTE: temporary measure, will eventually need to refactor
    // all this to use the new server side middleware stuff
    // to show proper error pages
    if (!providerId) {
      throw new Error(`Invalid host: unable to get a providerId for "${host}"`)
    }
    const conventionsApiPath = await this.getConventionApiPath({ req })
    return `${conventionsApiPath}/v1/convention_providers/${providerId}`
  }

  async loginUser({ req = undefined } = {}, email: string, password: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const res = await fetch(`${providerApiPath}/tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth: {
          email,
          password,
        },
      }),
    })

    const jsonResponse = await res.json()

    const {
      errors,
      jwt,
      registration_token: registrationToken,
      registration_required: registrationRequired,
    } = jsonResponse

    if (errors?.length) {
      return new ConventionApiError({ response: res, json: jsonResponse })
    }

    return { data: jwt, registrationToken, registrationRequired, errors }
  }

  async loginUserAuth0({ req = undefined } = {}, auth0Token: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const res = await fetch(`${providerApiPath}/tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth0_token: auth0Token,
        auth: {
          email: undefined,
          password: undefined,
        },
      }),
    })
    const jsonResponse = await res.json()
    const {
      jwt,
      registration_token: registrationToken,
      registration_required: registrationRequired,
    } = jsonResponse

    return { data: jwt, registrationToken, registrationRequired }
  }

  async loginUserCleverSSO(config: { cleverCode: string; redirectURI: string }) {
    const { cleverCode, redirectURI } = config
    const providerApiPath = await this.getProviderApiPath({ req: undefined })
    const res = await fetch(`${providerApiPath}/clever_tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clever_code: cleverCode,
        redirect_uri: redirectURI,
      }),
    })
    const jsonResponse = await res.json()

    const {
      jwt,
      registration_token: registrationToken,
      registration_required: registrationRequired,
      clever_first_name,
      clever_last_name,
    } = jsonResponse

    return {
      data: jwt,
      registrationToken,
      registrationRequired,

      clever_first_name,
      clever_last_name,
    }
  }

  async conventionsLogin(
    // privacyCode is temporarily optional #183940855
    { req = undefined } = {},
    payload: { email: string; password?: string; privacyCode?: string },
  ) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const response = await fetch(`${providerApiPath}/convention_logins`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "convention_login",
          attributes: snakecaseKeys(payload, { deep: true }),
        },
      }),
    })

    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return json
  }

  async registerUser({ req = undefined } = {}, payload: any, relationships: any) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const response = await fetch(`${providerApiPath}/convention_user_registrations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "convention_user",
          attributes: payload,
          relationships: relationships,
        },
      }),
    })
    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return json
  }

  async sendMagicLink({ req = undefined }, payload: { email: string; returnUrl?: string }) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const response = await fetch(`${providerApiPath}/authentication/magic_link_emails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "needs",
          attributes: snakecaseKeys(payload, { deep: true }),
        },
      }),
    })
    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return camelcaseKeys(json, { deep: true }) as {
      data: {
        id: string
        attributes: {
          email: string
          returnUrl: string
          status: "error" | "success"
        }
        type: "magic_link_email"
      }
    }
  }

  async checkIfEmailRegistered({ req = undefined } = {}, payload: { email: string }) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const response = await fetch(`${providerApiPath}/authentication/needs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          type: "needs",
          attributes: payload,
        },
      }),
    })
    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return camelcaseKeys(json, { deep: true }) as {
      data: {
        attributes: {
          email: string
          need: string
          registrationToken: string
        }
        type: "need"
      }
    }
  }

  async setPassword({ req = undefined } = {}, payload: any) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const response = await fetch(`${providerApiPath}/convention_logins/change_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          type: "convention_login",
          attributes: payload,
        },
      }),
    })

    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return json
  }

  async searchSchools({ req = undefined } = {}, name: string) {
    const conventionsApiPath = await this.getConventionApiPath({ req })
    const res = await fetch(`${conventionsApiPath}/v1/schools?filter[q]=${name}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await res.json()
  }

  async searchPrograms({ req = undefined } = {}, name: string) {
    const conventionsApiPath = await this.getConventionApiPath({ req })
    const res = await fetch(`${conventionsApiPath}/v1/programs?filter[q]=${name}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await res.json()
  }

  async searchTags({ req = undefined } = {}, name: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const res = await fetch(`${providerApiPath}/tags?filter[q]=${name}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await res.json()
  }

  async conventionTags({ req = undefined } = {}, conventionId: string, name?: string) {
    let queryFilters = `filter[convention_id]=${conventionId}`
    if (name) {
      queryFilters += `&filter[q]=${name}`
    }
    const providerApiPath = await this.getProviderApiPath({ req })
    const res = await fetch(`${providerApiPath}/tags?${queryFilters}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await res.json()
  }

  // @ts-ignore PT - #180597146
  async resetPassword({ req = undefined } = {}, email) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const response = await fetch(`${providerApiPath}/convention_logins/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          type: "convention_login",
          attributes: { email },
        },
      }),
    })

    const json = await response.json()

    if (json?.errors?.length) {
      return new ConventionApiError({ response, json })
    }

    return json
  }

  async getEvent({ req = undefined } = {}, event_id: string | string[]) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/conventions/${event_id}`, {
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ? `Bearer ${authToken}` : null,
      },
    })
    return await res.json()
  }

  async getSessionAvailableHours({ req = undefined } = {}, event_id: string | string[]) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(
      `${providerApiPath}/convention_session_available_hours?filter[convention_id]=${event_id}`,
      {
        // @ts-ignore
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ? `Bearer ${authToken}` : null,
        },
      },
    )
    return await res.json()
  }

  async registerForSession({ req = undefined } = {}, sessionId: string | string[] | number) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_session_registrations/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          type: "convention_session",
          id: sessionId,
        },
      }),
    })
    return await res.json()
  }

  async joinMeeting({ req = undefined } = {}, meetingId: string | string[] | number) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(
      `${providerApiPath}/convention_meeting_registrations/${meetingId}/check_ins`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      },
    )
    return await res.json()
  }

  async joinSession({ req = undefined } = {}, sessionId: string | string[] | number) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_sessions/${sessionId}/join_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    return await res.json()
  }
  // @ts-ignore PT - #180597146
  async deleteSessionRegistration({ req = undefined } = {}, sessionId) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_session_registrations/${sessionId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    return await res.text()
  }

  async addUserFavoriteExhibitor(
    { req = undefined } = {},
    conventionUserId: string,
    providerExhibitorId: string,
  ) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_user_exhibitor_favorites`, {
      method: "POST",
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ? `Bearer ${authToken}` : null,
      },
      body: JSON.stringify({
        data: {
          type: "convention_user_exhibitor_favorite",
          attributes: {
            convention_user_id: conventionUserId,
            provider_exhibitor_id: providerExhibitorId,
          },
        },
      }),
    })

    return await res.json()
  }

  async removeUserFavoriteExhibitor({ req = undefined } = {}, favoriteId: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(
      `${providerApiPath}/convention_user_exhibitor_favorites/${favoriteId}`,
      {
        method: "DELETE",
        // @ts-ignore
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ? `Bearer ${authToken}` : null,
        },
      },
    )

    return await res.text()
  }

  async getVideo(
    { req = undefined } = {},
    video_id: string | string[],
    include: string | string[] = "convention,institution,tags,attachments",
  ) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const res = await fetch(`${providerApiPath}/convention_videos/${video_id}?include=${include}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return await res.json()
  }

  async createVideoView({ req = undefined }, videoId: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_video_trackers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          type: "convention_video_tracker",
          attributes: {
            convention_video_id: videoId,
          },
        },
      }),
    })
    return await res.json()
  }

  async createSessionAttachmentView({ req = undefined } = {}, attachment_id: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/session_attachment_views`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          attributes: { viewed_id: attachment_id },
        },
        type: "convention_attachment_view",
      }),
    })
    return await res.json()
  }

  async createVideoAttachmentView({ req = undefined } = {}, attachment_id: string) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/video_attachment_views`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          attributes: { viewed_id: attachment_id },
        },
        type: "convention_attachment_view",
      }),
    })
    return await res.json()
  }

  async createProfileAttachmentView(
    { req = undefined } = {},
    attachment_id: string,
    user_id: string,
  ) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/profile_attachment_views`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          attributes: {
            convention_user_id: user_id,
            viewed_id: attachment_id,
          },
        },
        type: "convention_attachment_view",
      }),
    })
    return await res.json()
  }

  async registerForConvention({ req = undefined } = {}, payload: any) {
    const providerApiPath = await this.getProviderApiPath({ req })
    const authToken = getAuthToken({ req })
    const res = await fetch(`${providerApiPath}/convention_users/convention_registrations`, {
      method: "POST",
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken ? `Bearer ${authToken}` : null,
      },
      body: JSON.stringify({
        data: {
          type: "convention_user",
          attributes: payload,
        },
      }),
    })
    return await res.text()
  }
}

export const api = new Api()
