import { Box, styled } from "@mui/material"
import { GOOGLE_MAX_BUTTON_WIDTH_PX } from "components/LoginSSOButtons"
import { useEffect, useRef } from "react"
import { useRecoilValue } from "recoil"
import { googleOneTapLoadedAtom } from "recoil-state/atoms"
import { useElementSize } from "usehooks-ts"

const GoogleOneTapButton = () => {
  const googleOneTapLoaded = useRecoilValue(googleOneTapLoadedAtom)
  const elementRef = useRef(null)
  const [elementSizeRef, { width }] = useElementSize()

  const renderButton = () => {
    if (!googleOneTapLoaded) return
    if (!elementRef.current) return
    window?.google?.accounts?.id?.renderButton(elementRef.current, {
      width,
      type: "standard",
      size: "large",
      theme: "filled_blue",
      text: "continue_with",
      shape: "rectangular",
      logo_alignment: "left",
    })
  }

  useEffect(() => {
    renderButton()
  }, [googleOneTapLoaded, width])

  const assignElementRef = (node) => {
    elementSizeRef(node)
    elementRef.current = node
  }

  if (!googleOneTapLoaded) return <></>

  return <_Container ref={assignElementRef} />
}

export default GoogleOneTapButton

const _Container = styled(Box)(() => ({
  width: "100%",
  maxWidth: GOOGLE_MAX_BUTTON_WIDTH_PX,
}))
