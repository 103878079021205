export const uiStore = {
  isFiltersModalOpen: false,
}

// @ts-ignore PT - #180597146
export function updateUiStore(state, payload) {
  return {
    ...state,
    uiStore: {
      ...state.uiStore,
      ...payload,
    },
  }
}
