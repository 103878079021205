import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import { makeStyles } from "tss-react/mui-compat"
import React from "react"
import { Controller, Control } from "react-hook-form"

const useStyles = makeStyles()(
  (theme) => ({
    formControl: {
      display: "flex",
    },
    formLabel: {
      zIndex: 2,
      marginBottom: "8px",
      padding: "0 12px",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      opacity: 1,
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
    checkbox: {
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    formError: {
      display: "flex",
      padding: "12px 0",
    },
  }),
)

type IControlledCheckboxGroup = {
  control: Control
  name: string
  rules?:
    | Partial<{
        required:
          | string
          | boolean
          | React.ReactElement<
              any,
              | string
              | ((
                  props: any,
                ) => React.ReactElement<
                  any,
                  string | any | (new (props: any) => React.Component<any, any, any>)
                > | null)
              | (new (props: any) => React.Component<any, any, any>)
            >

        min: any
        max: any
        maxLength: any
        minLength: any
        pattern: any
        validate: Record<string, any>
      }>
    | undefined
  options: Array<{ value: string; label: string; defaultValue?: boolean }>
  label?: string
  /**
   * The validity of a text-field.
   */
  error?: boolean
  required?: boolean
  showRequired?: boolean

  /**
   * Field error label
   */
  fieldErrors?: any
  defaultValue?: Array<string>
}

export const ControlledCheckboxGroup = (props: IControlledCheckboxGroup) => {
  const {
    control,
    options,
    label: groupLabel,
    fieldErrors = { message: "Required field." },
    error,
    required = false,
    showRequired = true,
    name,
    defaultValue: defaultValues = [],
  } = props

  const { classes } = useStyles()

  return (
    <FormControl error={error} component="fieldset" className={classes.formControl}>
      <FormLabel
        aria-label={required ? `${groupLabel} required` : groupLabel}
        component="legend"
        className={classes.formLabel}>
        {required && showRequired ? `${groupLabel} *` : groupLabel}
      </FormLabel>
      <FormGroup>
        {options.map(({ label }, i) => {
          const checkboxName = `${name}[${i}]`

          return (
            <FormControlLabel
              key={checkboxName}
              label={label}
              control={
                <Controller
                  control={control}
                  name={checkboxName}
                  defaultValue={defaultValues[i]}
                  render={({ field }) => (
                    <Checkbox
                      className={classes.checkbox}
                      onChange={(e) => field.onChange(e.target.checked)}
                      onBlur={field.onBlur}
                      checked={field.value}
                      title={groupLabel}
                    />
                  )}
                />
              }
            />
          )
        })}
      </FormGroup>
      {error && (
        <FormHelperText error={error} className={classes.formError}>
          {fieldErrors.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
