import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import React from "react"
import { Controller, Control } from "react-hook-form"
import { makeStyles } from "tss-react/mui-compat"

const useStyles = makeStyles()(
  (theme) => ({
    formControl: {
      display: "flex",
    },
    formLabel: {
      zIndex: 2,
      marginBottom: "8px",
      padding: "0 12px",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      opacity: 1,
      "&.Mui-focused": {
        color: theme.palette.primary.main,
      },
    },
    radioBtn: {
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    formError: {
      display: "flex",
      padding: "12px 0",
    },
  })
)

type IControlledRadioGroup = {
  control: Control
  name: string
  rules?:
    | Partial<{
        required:
          | string
          | boolean
          | React.ReactElement<
              any,
              | string
              | ((
                  props: any,
                ) => React.ReactElement<
                  any,
                  string | any | (new (props: any) => React.Component<any, any, any>)
                > | null)
              | (new (props: any) => React.Component<any, any, any>)
            >

        min: any
        max: any
        maxLength: any
        minLength: any
        pattern: any
        validate: Record<string, any>
      }>
    | undefined
  options: Array<{ value: string; label: string }>
  label?: string
  /**
   * The validity of a text-field.
   */
  error?: boolean
  required?: boolean
  showRequired?: boolean
  /**
   * Field error label
   */
  fieldErrors?: any
  defaultValue?: string
}

export const ControlledRadioGroup = (props: IControlledRadioGroup) => {
  const {
    control,
    options,
    label,
    fieldErrors = { message: "Required field." },
    error,
    required = false,
    showRequired = true,
    name,
    defaultValue = "",
  } = props

  const { classes } = useStyles()

  return (
    <FormControl error={error} component="fieldset" className={classes.formControl}>
      <FormLabel
        component="legend"
        aria-label={required ? `${label} required` : label}
        className={classes.formLabel}>
        {required && showRequired ? `${label} *` : label}
      </FormLabel>
      <Controller
        rules={{
          required,
        }}
        render={({ field }) => (
          <RadioGroup {...field}>
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio className={classes.radioBtn} />}
                  label={option.label}
                />
              )
            })}
          </RadioGroup>
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />

      {error && (
        <FormHelperText error={error} className={classes.formError}>
          {error && fieldErrors.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
