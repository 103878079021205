import sendApiRequest, { SendApiRequestParams } from "lib/send-api-request"
import { mergeLeft } from "ramda"

export type SendJsonApiRequestParams = Omit<SendApiRequestParams, "body"> & {
  body?: string | Record<string, any> | any[]
}

export default async function sendJsonApiRequest(request: SendJsonApiRequestParams) {
  // @ts-ignore
  request.headers = mergeLeft(request.headers, {
    Accept: "application/vnd.api+json, application/json",
    "Content-Type": "application/json; charset=utf-8",
  })

  if (request.body && typeof request.body !== "string") {
    request.body = JSON.stringify(request.body)
  }

  return await sendApiRequest({
    ...request,
    body: request.body as string,
  })
}
