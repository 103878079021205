import SendButton from "./SendButton"
import { useTheme } from "@mui/material"
import getChatAvailability from "lib/getChatAvailability"
import useCurrent from "lib/use-current"
import React, { useContext, useEffect, useState } from "react"
import {
  ChatAutoComplete,
  useMessageInputContext,
  ChannelStateContext,
} from "stream-chat-react"

const CustomMessageInput = () => {
  const [chatAvailable, setChatAvailable] = useState("off")

  // @ts-ignore
  const { channel } = useContext(ChannelStateContext)

  const current = useCurrent()
  const theme = useTheme()

  useEffect(() => {
    const getAvailability = async (institutionId: any) => {
      try {
        const data = await getChatAvailability({ current, institutionId })
        if (data && data[0]) {
          setChatAvailable(data[0].status)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (channel?.data?.institution_id) {
      getAvailability(channel.data.institution_id)
    }
  }, [channel])

  useEffect(() => {
    if (chatAvailable === "on") {
      const inputElement = document.querySelector(
        ".str-chat__textarea__textarea",
      ) as HTMLTextAreaElement
      inputElement.focus()
    }
  }, [chatAvailable])

  const messageInput = useMessageInputContext()

  if (chatAvailable === "off") {
    return null
  }

  return (
    <div className="str-chat__input-large">
      <div className="str-chat__input">
        <div className="str-chat__input--textarea-wrapper">
          <ChatAutoComplete
            handleSubmit={messageInput.handleSubmit}
            onChange={messageInput.handleChange}
            value={messageInput.text}
            rows={1}
            placeholder={"Type your message"}
            onPaste={messageInput.onPaste}
          />
        </div>
        <SendButton sendMessage={messageInput.handleSubmit} />
      </div>
      <style jsx>
        {`
          :global(.str-chat__textarea textarea:focus) {
            border-color: ${theme.palette.primary.main};
            box-shadow: 0 0 0 2px ${theme.palette.primary.light};
          }
        `}
      </style>
    </div>
  )
}

export default CustomMessageInput
