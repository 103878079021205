import { palette } from "./palette"

export const colors = {
  /**
   * color of our brand
   */
  brand: palette.content.brandDefault,
  /**
   * The action color (CTAs, etc), main color of app
   */
  action: palette.content.actionDefault,

  actionSecondary: "#75A478",
  /**
   * The action color as an RGBA Object (for animated API)
   */
  actionDefaultRgbaObject: palette.content.actionDefaultRgbObject,

  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * text color darker, use mainly for headers
   */
  textDark: palette.content.neutralPrimary,
  /**
   * text color inverted
   */
  textInverted: palette.content.neutralPrimaryInversed,
  /**
   * text color light, use for paragraphs
   */
  textLight: palette.content.neutralSecondary,
  /**
   * text muted, mainly for icons
   */
  textMuted: palette.content.neutralTertiary,
  /**
   * color text for warning messages
   */
  textWarning: palette.content.alertWarning,
  /**
   * text for positive feedback
   */
  textPositive: palette.content.alertPositive,
  /**
   * Color for dividing lines, borders
   */
  line: palette.content.neutralStroke,
  /**
   * The app main background color.
   */
  background: palette.backgrounds.ground,
  /**
   * Darker background separate content from actual background
   */
  backgroundContent: palette.backgrounds.underground,
  /**
   * Used for Onboarding Background
   */
  backgroundOnboarding: palette.backgrounds.underwater,
  /**
   * Background based on brand color
   */
  backgroundBrand: palette.backgrounds.brandAccent,
  /**
   * Background for highlighted text
   */
  backgroundhighlight: palette.backgrounds.highlightLight,
  /**
   * background for selected areas, like in booking
   */
  backgroundSelected: palette.backgrounds.highlightModerate,
  /**
   * background for warining messages
   */
  backgroundWarning: palette.backgrounds.alertWarning,
  /**
   * background for positive feedback text
   */
  backgroundPositive: palette.backgrounds.alertPositive,
  /**
   * background for error feedback text
   */
  backgroundError: palette.backgrounds.alertError,
  /**
   * Error messages and icons.
   */
  error: palette.content.alertNegative,
  /**
   * informative color
   */
  informative: palette.content.alertInformative,
  /**
   * Storybook background for Text stories, or any stories where
   * the text color is color.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: palette.content.neutralPrimary,

  palette: palette,
}

export type ColorTypes = keyof typeof colors
