import ResourceModel from "models/convention-api/ResourceModel"

export default class RegistrantType extends ResourceModel {
  protected static basePath = "v1/convention_providers/:provider_id/registrant_types"

  convention_provider_id!: string
  export_identifier!: string
  name!: string
  position!: number
}
