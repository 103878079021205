import { ChatWrapper } from "./ChatWrapper"
import { makeStyles } from "tss-react/mui-compat"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "fixed",
      width: "100%",
      height: "100%",
      zIndex: theme.zIndex.drawer,
      top: 0,
      padding: "7px 7px 172px 7px",
      pointerEvents: "none",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      position: "fixed",
      right: "20px",
      bottom: "87px",
      height: "60px",
      width: "60px",
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      boxShadow: "inset 0 1px 0 0 rgba(255,255,255,0.24), 0 2px 12px 0 rgba(0,0,0,0.5)",
      border: "1px solid rgba(255,255,255,0.18)",
      zIndex: theme.zIndex.drawer,
    },
    buttonIcon: {
      color: theme.palette.primary.main,
      height: "24px",
      width: "24px",
    },
    hidden: {
      display: "none",
    },
  }),
)

interface IMobileChat {}

const MobileChat = (_props: IMobileChat) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <ChatWrapper classes={classes} cx={cx} />
      <style>
        {`
          .str-chat.str-chat-channel-list, .str-chat.str-chat-channel {
            width: 100%;
            left: 0;
            position: relative;
            height: 100%;
            min-height: auto;
            border-radius: 10px;
            background-color: rgba(255,255,255,0.97);
            box-shadow: 0 10px 31px 0 rgba(0,0,0,0.5);
            pointer-events: all;
          }
        `}
      </style>
    </>
  )
}

export default MobileChat
