import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Box, Dialog, DialogContent, styled, TextField, Typography } from "@mui/material"
import { AuthDialog } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import { useRouter } from "next/router"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as Yup from "yup"

interface FormValues {
  password: string
  passwordConfirmation: string
}

const formValidationScheme = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password must be at least 8 characters"),
  passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
})

interface SetPasswordDialogProps {}

const SetPasswordDialog = (props: SetPasswordDialogProps) => {
  const {} = props
  const router = useRouter()
  const authDialogs = useAuthDialog()
  const auth = useAuth()
  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  })

  const onSubmit = async (formValues: FormValues) => {
    const resetPasswordToken = router.query.resetPasswordToken as string

    const error = await auth.setPassword({
      resetPasswordToken,
      password: formValues.password,
    })

    await router.push(createPath({ path: ROUTE.CONVENTIONS }))

    if (!error) authDialogs.set(AuthDialog.SIGN_IN)
  }

  const handleClose = async () => {
    await router.push(createPath({ path: ROUTE.CONVENTIONS }))
    authDialogs.reset()
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Reset Your Password"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <DialogContent>
        <_Form onSubmit={form.handleSubmit(onSubmit)}>
          <Box>
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  autoFocus
                  type="password"
                  label="Password"
                  fullWidth
                  variant="filled"
                  placeholder="Create a password"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="password"
            />
            <Typography
              variant="paragraphSmall"
              color="gray.darker"
              sx={{ marginTop: pxToRem(10) }}>
              Your password must be at least 8 characters
            </Typography>
          </Box>
          <Controller
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="password"
                label="Confirm your password"
                fullWidth
                variant="filled"
                placeholder="Enter your password"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
            name="passwordConfirmation"
          />
          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Send
          </LoadingButton>
        </_Form>
      </DialogContent>
    </Dialog>
  )
}

export default SetPasswordDialog

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))
