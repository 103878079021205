import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"

// @ts-ignore PT - #180597146
export default async function getChatAvailability({ current, institutionId }) {
  const response = await sendConventionApiRequest({
    current,
    method: "GET",
    url: `v1/convention_providers/${current.provider.id}/chat_availables?filter[institution_id=${institutionId}]`,
  })
  if (response.response.status === 404) {
    return undefined
  }
  return await processApiResponse<any>(response)
}
