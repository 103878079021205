const LATO_FONT_FAMILY = '"Lato", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto"'
const RALEWAY_FONT_FAMILY = '"Raleway", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto"'

type LatoFontNames = "lato300" | "lato400" | "lato600" | "lato700"
type RalewayFontNames = "raleway300" | "raleway400" | "raleway500" | "raleway600" | "raleway700"
type FontNames = LatoFontNames | RalewayFontNames
export type ThemeFonts = Record<FontNames, { fontWeight: number; fontFamily: string }>

export const buildThemeFonts = (): ThemeFonts => ({
  lato300: {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 300,
  },
  lato400: {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 400,
  },
  lato600: {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 600,
  },
  lato700: {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 700,
  },

  raleway300: {
    fontFamily: RALEWAY_FONT_FAMILY,
    fontWeight: 300,
  },
  raleway400: {
    fontFamily: RALEWAY_FONT_FAMILY,
    fontWeight: 400,
  },
  raleway500: {
    fontFamily: RALEWAY_FONT_FAMILY,
    fontWeight: 500,
  },
  raleway600: {
    fontFamily: RALEWAY_FONT_FAMILY,
    fontWeight: 600,
  },
  raleway700: {
    fontFamily: RALEWAY_FONT_FAMILY,
    fontWeight: 700,
  },
})
