/* eslint-disable react-hooks/rules-of-hooks */
// TODO: Should be fixed ASAP
import { LOAD_RECORDS_PER_PAGE } from "app-constants/constants"
import { BuildServerSidePropsMiddlewareContext } from "lib/build-server-side-props"
import { BuildServerSidePropsMiddleware } from "lib/build-server-side-props"
import getEnvForHost from "lib/get-env-for-host"
import sendApiRequest from "lib/send-api-request"
import { SendApiRequestParams } from "lib/send-api-request"
import uniq from "lib/uniq"
import useCurrent from "lib/use-current"
import BaseModel from "models/convention-api/BaseModel"
import applyModel from "models/convention-api/_internal/applyModel"
import { useModelSWR } from "models/convention-api/_internal/swr"
import { useModelSWRInfinite } from "models/convention-api/_internal/swr"
import { BuildRequestParams, UseMoreReturn } from "models/convention-api/types"
import { RunParams } from "models/convention-api/types"
import { UseOneParams } from "models/convention-api/types"
import { UseMoreParams } from "models/convention-api/types"
import { UseAllParams } from "models/convention-api/types"
import { ApplyOneParams } from "models/convention-api/types"
import { ApplyMoreParams } from "models/convention-api/types"
import { ApplyAllParams } from "models/convention-api/types"
import { ManyResult } from "models/convention-api/types"
import { isPlural } from "pluralize"
import useSWR from "swr"
import Constructor from "types/Constructor"
import { camelToSnakeCase, snakeToCamelCase } from "utils/helpers"

// Dumb method to be able to use `this.foo(...)` static class methods
// instead of doing `(this as any).foo(...)` which loses type-safety
function cast(_this: any): typeof ResourceModel {
  return _this
}

interface RelationshipData {
  filterOn?: string
  from: string
  many?: boolean
  rel?: RelationshipDefinition
  type: Constructor<ResourceModel>
}

interface RelationshipDefinition {
  [key: string]: RelationshipData
}

export default class ResourceModel extends BaseModel {
  // @ts-ignore
  protected static basePath: string = null

  protected static buildRequest(params: BuildRequestParams) {
    const headers = this.buildHeaders(params)
    const url = this.buildUrl(params)
    const request: SendApiRequestParams = {
      method: "GET",
      url: url,
      headers: headers,
    }
    return request
  }

  protected static async run<T extends ResourceModel>(
    this: Constructor<T>,
    params: RunParams,
  ): Promise<ManyResult<T>> {
    const _this = cast(this)
    const request = _this.buildRequest(params)
    const response = await sendApiRequest(request)
    const models = await _this.processResponse({
      response: response.response,
      json: response.json,
      camelCaseKeys: params.camelCaseKeys,
    })
    models.params = params

    models.hasMore = (() => {
      if (!models.after) {
        return false
      }
      if (models.length === 0) {
        return false
      }
      if (params.limit && models.length < params.limit) {
        // NOTE: assumes limit is never less than the api's hard limit
        return false
      }
      return true
    })()

    if (params.includes) {
      await _this.loadIncludes(models, params.includes, params)
    }

    return models
  }

  protected static async all<T extends ResourceModel>(
    this: Constructor<T>,
    params: RunParams,
  ): Promise<ManyResult<T>> {
    const _this = cast(this)
    const models = await _this.run(params)
    while (models.hasMore) {
      await _this.loadMore(models)
    }
    return models as ManyResult<T>
  }

  protected static async loadMore(models: ManyResult<any>): Promise<void> {
    if (!models.hasMore) {
      return
    }

    const _this = cast(this)
    const newModels: any = await _this.run({ ...models.params, after: models.after })
    newModels.forEach((model: any) => {
      const existingModel = models.find((x: any) => x.id === model.id)
      if (!existingModel) {
        models.push(model)
      }
    })

    models.after = newModels.after
    models.hasMore = newModels.hasMore
  }

  protected static async loadRelationship(
    models: any[],
    key: string,
    rel: RelationshipData,
    relParams: RunParams,
  ): Promise<void> {
    if (!models.length) {
      return
    }

    let { many = undefined, type, filterOn = "id", from, rel: nestedRel } = rel

    if (many === undefined) {
      many = isPlural(key)
    }

    const ids: string[] = uniq(
      models.map((model) => {
        const key = relParams.camelCaseKeys ? snakeToCamelCase(from) : from
        return model[key]
      }),
    )

    const filter = {
      [camelToSnakeCase(filterOn)]: ids,
    }

    const relatedModels = await (type as any).all({
      host: relParams.host,
      pathParams: relParams.pathParams,
      authToken: relParams.authToken,
      filter,
      camelCaseKeys: relParams.camelCaseKeys,
    })

    const caseSensitiveFilterOn = relParams.camelCaseKeys ? snakeToCamelCase(filterOn) : filterOn
    models.forEach((model) => {
      const id = model[relParams.camelCaseKeys ? snakeToCamelCase(from) : from]
      const filterFn = (relatedModel: any) => {
        return relatedModel[caseSensitiveFilterOn] === id
      }

      if (many) {
        model[key] = relatedModels.filter(filterFn)
      } else {
        model[key] = relatedModels.find(filterFn)
      }
    })

    if (nestedRel) {
      await (type as any).loadRelationships(relatedModels, nestedRel, relParams)
    }
  }

  protected static async loadRelationships(
    models: any[],
    rel: RelationshipDefinition = {},
    relParams: RunParams,
  ): Promise<void> {
    await Promise.all(
      Object.keys(rel).map((key: string) => {
        return this.loadRelationship(
          models,
          key,
          rel[relParams.camelCaseKeys ? snakeToCamelCase(key) : key],
          relParams,
        )
      }),
    )
  }

  protected static async loadIncludes(
    models: any[],
    includes: string[],
    params: RunParams,
  ): Promise<void> {
    if (!includes || includes.length === 0) {
      return
    }

    const relDef: RelationshipDefinition = {}
    includes.forEach((include) => {
      const keys = include.split(".")
      let rel = relDef
      let ModelClass: any = this
      keys.forEach((key) => {
        const caseSensitiveKey = params.camelCaseKeys ? snakeToCamelCase(key) : key

        if (!rel[caseSensitiveKey]) {
          const fn: any = ModelClass.rels[caseSensitiveKey] ?? ModelClass.rels[key]
          if (!fn) {
            throw `${ModelClass.name} does not have a "${key}" relationship`
          }
          rel[caseSensitiveKey] = fn()
          rel[caseSensitiveKey].rel = rel[caseSensitiveKey]?.rel ?? rel[key]?.rel ?? {}
        }
        ModelClass = rel[caseSensitiveKey].type
        // @ts-ignore
        rel = rel[caseSensitiveKey].rel
      })
    })

    await this.loadRelationships(models, relDef, params)
  }

  static applyOne<T>(
    this: Constructor<T>,
    propName: string,
    params:
      | ApplyOneParams
      | ((context: BuildServerSidePropsMiddlewareContext) => ApplyOneParams) = {},
  ): BuildServerSidePropsMiddleware {
    const _this = cast(this)
    return applyModel(propName, params, async (runParams) => {
      const models = await _this.run({ ...runParams, limit: 1 })
      return models[0]
    })
  }

  static applyMore<T>(
    this: Constructor<T>,
    propName: string,
    params: ApplyMoreParams | ((context: BuildServerSidePropsMiddlewareContext) => ApplyMoreParams),
  ): BuildServerSidePropsMiddleware {
    const _this = cast(this)
    return applyModel(propName, params, async (runParams) => {
      const models = await _this.run({ limit: LOAD_RECORDS_PER_PAGE, ...runParams })
      return models
    })
  }

  static applyAll<T>(
    this: Constructor<T>,
    propName: string,
    params:
      | ApplyAllParams
      | ((context: BuildServerSidePropsMiddlewareContext) => ApplyAllParams) = {},
  ): BuildServerSidePropsMiddleware {
    const _this = cast(this)
    return applyModel(propName, params, async (runParams) => {
      const models = await _this.all(runParams)
      return models
    })
  }

  static useOne<T extends ResourceModel, P extends UseOneParams | null>(
    this: Constructor<T>,
    params: P | (() => P),
  ) {
    const _this = cast(this)
    return useModelSWR<T>(_this.basePath, params, async (runParams) => {
      const models = await _this.run({ ...runParams, limit: 1 })
      return models[0] || null
    })
  }

  static useMore<T extends ResourceModel, P extends UseMoreParams | null>(
    this: Constructor<T>,
    params: P | (() => P),
  ): UseMoreReturn<T> {
    const _this = cast(this)
    const swrResult = useModelSWRInfinite<ManyResult<T>>(
      _this.basePath,
      params,
      async (runParams) => {
        const models = await _this.run({ limit: LOAD_RECORDS_PER_PAGE, ...runParams })
        return models
      },
    )

    // NOTE: eats the size param so that it's not returned from this hook
    const { data, setSize, size, isValidating, ...rest } = swrResult

    const flattenedData = data?.flat()
    const count = data?.[0]?.count ?? undefined
    const isLoading = isValidating
    const isLoadingInitial = isLoading && !data
    const isLoadingMore = isLoading && !!data
    const hasMore = data?.[data?.length - 1]?.hasMore
    return {
      data: flattenedData,
      count,
      isLoading,
      isLoadingInitial,
      isLoadingMore,
      hasMore,
      size,
      loadMore: () => {
        if (isLoading) {
          console.warn("loadMore called while still loading, ignoring...")
          return
        }
        if (!hasMore) {
          console.warn("loadMore called after reaching end, ignoring...")
          return
        }
        setSize((prevSize) => prevSize + 1)
      },
      ...rest,
    }
  }

  static useAll<T extends ResourceModel, P extends UseAllParams | null>(
    this: Constructor<T>,
    params: P | (() => P),
  ) {
    const _this = cast(this)
    return useModelSWR<T[]>(_this.basePath, params, async (runParams) => {
      const models = await _this.all(runParams)
      return models
    })
  }

  /**
    @deprecated
    This is still necessary to call a resources `show` endpoint,
    such as `/foos/1` - however, going forward we've phased out
    these endpoints in favor of calling `/foos?filter[id]=1`
    so new endpoints should instead call useOne with an id filter.
  */
  static useLoadOne<T extends ResourceModel>(
    this: Constructor<T>,
    id: string,
    params: { includes?: string[] } = {},
  ) {
    const _this = cast(this)
    const current = useCurrent()
    return useSWR<T>(
      () => {
        if (!current) {
          return null
        }
        return [_this.basePath, JSON.stringify(params)]
      },
      async () => {
        try {
          // @ts-ignore
          const env = getEnvForHost(current.host)
          // @ts-ignore
          const models = await _this.run({
            // @ts-ignore
            authToken: current.authToken,
            // @ts-ignore
            host: env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT,
            pathParams: {
              // @ts-ignore
              provider_id: current.provider.id,
            },
            id: id,
            ...params,
          })
          return models[0] as T
        } catch (error) {
          console.error(error)
          throw error
        }
      },
      { shouldRetryOnError: false },
    )
  }
}
