import React from "react"
import { useEffect, useState } from "react"
import { makeStyles } from "tss-react/mui-compat"

interface FocusTrackerProps {}

const useStyles = makeStyles()(
  (theme) => ({
    anchor: {
      transition: theme.transitions.create(
        [
          "color",
          "border-radius",
          "opacity",
          "top",
          "left",
          "width",
          "height",
          "visibility",
          "transform",
        ],
        {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        },
      ),
      position: "absolute",
      top: 0,
      left: 0,
      // TODO: needs a better way to ensure it's always on top of its target
      zIndex: 1000000,
      width: "100%",
      height: "100%",
      color: theme.appComponents.focusTracker.color,
      border: `2px solid ${theme.appComponents.focusTracker.color}`,
      borderRadius: 0,
      transform: "translateX(0) translateY(0)",
      visibility: "hidden",
      overflow: "hidden",
      opacity: 0,
      pointerEvents: "none",
    },
    anchorInner: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "currentColor",
      opacity: 0.1,
    },
  })
);

export const FocusTracker = (_props: FocusTrackerProps) => {
  const { classes } = useStyles()
  // @ts-ignore
  const [styles, setStyles] = useState<{ [key: string]: string }>(undefined)

  useEffect(() => {
    let mode = "mouse"

    const update = () => {
      const activeElement = document.activeElement
      const hasFocus = document.hasFocus() && activeElement !== document.body

      if (hasFocus && mode === "keyboard") {
        // @ts-ignore
        const rect = activeElement.getBoundingClientRect()
        // @ts-ignore
        const style = window.getComputedStyle(activeElement)

        const left = rect.left + window.pageXOffset - 2
        const top = rect.top + window.pageYOffset - 2
        const height = rect.height + 4
        const width = rect.width + 4

        setStyles({
          width: `${width}px`,
          height: `${height}px`,
          transform: `translateX(${left}px) translateY(${top}px)`,
          color: style.color,
          borderRadius: style.borderRadius,
          visibility: "visible",
          opacity: "1",
        })
      } else {
        // @ts-ignore
        setStyles(undefined)
      }
    }

    const handler = (event: any) => {
      if (event.type === "mousedown") {
        mode = "mouse"
      } else if (event.type === "keydown") {
        if (event.key === "Tab") {
          mode = "keyboard"
        }
      }
      update()
    }

    const eventNames = ["focusin", "focusout", "mousedown", "keydown", "resize", "scroll"]
    eventNames.forEach((eventName: string) => {
      window.addEventListener(eventName, handler)
    })

    return () => {
      eventNames.forEach((eventName: string) => {
        window.removeEventListener(eventName, handler)
      })
    }
  }, [])

  return (
    <>
      <div className={classes.anchor} style={styles}>
        <div className={classes.anchorInner}></div>
      </div>
    </>
  )
}
