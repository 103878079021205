import sendConventionApiRequest from "lib/send-convention-api-request"

export default async function postUserToInstitutionConversations({
  // @ts-ignore PT - #180597146
  current,
  // @ts-ignore PT - #180597146
  institution_id,
  // @ts-ignore PT - #180597146
  convention_user_id,
}) {
  await sendConventionApiRequest({
    current,
    method: "POST",
    url: `v1/convention_providers/${current.provider.id}/user_to_institution_conversations`,
    body: {
      data: {
        attributes: {
          institution_id,
          convention_user_id,
        },
      },
    },
  })
  return undefined
}
