import ResourceModel from "models/convention-api/ResourceModel"

export default class ConventionSignupQuestionOption extends ResourceModel {
  protected static basePath =
    "/v1/convention_providers/:provider_id/convention_signup_question_options"

  text!: string
  value!: string | null
  position!: number
  active!: boolean
  deleted!: boolean
  question_id!: string
}
