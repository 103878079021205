import getConventionProviderForHost from "lib/get-convention-provider-for-host"

const cache = {}

async function getProviderId(host: string) {
  const provider = await getConventionProviderForHost(host)
  // @ts-ignore
  return provider.id
}

export default async function getConventionProviderIdForHost(host: string) {
  // @ts-ignore PT - #180597146
  if (!cache[host]) {
    // create the cache so subsequent calls can re-use it even while it's
    // still pending
    // @ts-ignore PT - #180597146
    cache[host] = getProviderId(host).then(null, (error) => {
      // clear cache if there's an error so it can retry
      // @ts-ignore PT - #180597146
      delete cache[host]
      throw error
    })
  }

  // @ts-ignore PT - #180597146
  return await cache[host]
}
