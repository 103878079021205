export const chatStore = {
  institutionId: null,
  open: false,
}
// @ts-ignore PT - #180597146
export function updateChatStore(state, payload) {
  return {
    ...state,
    chatStore: {
      ...state.chatStore,
      ...payload,
    },
  }
}
