// Adapted from jQuery.param

function isObject(value: any) {
  return typeof value === "object" && value !== null
}

function buildParams(segments: any, key: string, value: any) {
  if (Array.isArray(value)) {
    value.forEach((subvalue, subindex) => {
      buildParams(segments, `${key}[${isObject(subvalue) ? subindex : ""}]`, subvalue)
    })
  } else if (isObject(value)) {
    Object.keys(value).forEach((subkey) => {
      buildParams(segments, `${key}[${subkey}]`, value[subkey])
    })
  } else {
    segments.push({ key, value })
  }
}

export default function stringifyQuery(query: any): string {
  // @ts-ignore PT - #180597146
  const segments = []
  const encode = encodeURIComponent

  Object.keys(query).forEach((key) => {
    // @ts-ignore PT - #180597146
    buildParams(segments, key, query[key])
  })

  // @ts-ignore PT - #180597146
  const encodedSegments = segments.map(({ key, value }) => {
    if (value === null || value === undefined) {
      // @ts-ignore
      value = ""
    }
    return `${encode(key)}=${encode(value)}`
  })

  return encodedSegments.join("&")
}
