'use client';
import { createTheme as createMuiTheme, ThemeOptions } from "@mui/material/styles"
import { buildAppThemeComponents } from "theme/buildAppThemeComponents"
import { ThemeAppComponentsOptions, ThemeAppComponents } from "theme/buildAppThemeComponents"
import buildThemeComponents from "theme/buildThemeComponents"
import { buildThemeFonts, ThemeFonts } from "theme/buildThemeFonts"
import buildThemeTypography from "theme/buildThemeTypography"

// extend the mui interfaces directly
// https://material-ui.com/guides/typescript/#customization-of-theme

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    header1: TypographyStyleOptions
    header2: TypographyStyleOptions
    header3: TypographyStyleOptions
    header4: TypographyStyleOptions
    header5: TypographyStyleOptions
    header6: TypographyStyleOptions

    paragraphRegular: TypographyStyleOptions
    paragraphItalic: TypographyStyleOptions
    paragraphMuted: TypographyStyleOptions
    paragraphAllCaps: TypographyStyleOptions
    paragraphSmall: TypographyStyleOptions
    paragraphBold: TypographyStyleOptions

    microNavSelected: TypographyStyleOptions
    microNavNotSelected: TypographyStyleOptions

    billboard1Light: TypographyStyleOptions
    billboard1Bold: TypographyStyleOptions
    billboard2Light: TypographyStyleOptions
    billboard2Bold: TypographyStyleOptions
  }
}

declare module "@mui/material/styles/createTheme" {
  interface ThemeOptions {
    appComponents?: ThemeAppComponentsOptions
  }

  interface Theme {
    fonts: ThemeFonts
    appComponents: ThemeAppComponents
  }
}

declare module "@mui/system/createTheme/createTheme" {
  interface ThemeOptions {
    appComponents?: ThemeAppComponentsOptions
  }

  interface Theme {
    fonts: ThemeFonts
    appComponents: ThemeAppComponents
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor {
    darkest?: string
    darker?: string
    main: string
    lighter?: string
    lightest?: string
    contrastText: string
  }

  interface SimplePaletteColorOptions {
    darkest?: string
    darker?: string
    main: string
    lighter?: string
    lightest?: string
    contrastText?: string
  }

  interface Palette {
    gray: SimplePaletteColorOptions
    light: {
      main: string
      contrastText: string
    }
    dark: {
      main: string
      contrastText: string
    }
  }
  interface PaletteOptions {
    gray: SimplePaletteColorOptions
    light?: {
      main?: string
      contrastText?: string
    }
    dark?: {
      main?: string
      contrastText?: string
    }
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    header1: true
    header2: true
    header3: true
    header4: true
    header5: true
    header6: true

    paragraphRegular: true
    paragraphItalic: true
    paragraphMuted: true
    paragraphAllCaps: true
    paragraphSmall: true
    paragraphBold: true

    microNavSelected: true
    microNavNotSelected: true

    billboard1Light: true
    billboard1Bold: true
    billboard2Light: true
    billboard2Bold: true
  }
}

declare module "@mui/material/Button/Button" {
  interface ButtonPropsColorOverrides {
    light: true
    dark: true
  }
}

declare module "@mui/material/IconButton/IconButton" {
  interface IconButtonPropsColorOverrides {
    light: true
    dark: true
  }
}

export function createTheme(options: ThemeOptions = {}) {
  const { appComponents = {} } = options

  // we should create fonts here and then pass it to createMuiTheme
  // so it will merge it correctly and update all theme typographies like `MuiButton-label`
  const fonts = buildThemeFonts()

  const theme = createMuiTheme(options)

  theme.fonts = fonts
  theme.typography = buildThemeTypography(theme, options)
  theme.components = buildThemeComponents(theme)
  theme.appComponents = buildAppThemeComponents(appComponents, theme)

  return theme
}
