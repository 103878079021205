import useCurrent from "lib/use-current"
import Head from "next/head"
import React from "react"

const FaviconDyn = () => {
  const current = useCurrent()
  // @ts-ignore
  const url = current?.config?.app_favicon_url

  if (!url) return null

  return (
    <Head>
      <link rel="icon" href={url} />
      <link rel="shortcut icon" href={url} />
      <link rel="mask-icon" color="#000000" href={url} />
      <link rel="apple-touch-icon" href={url} />
    </Head>
  )
}

export default FaviconDyn
