// TODO: Reconcile these lists, there are spelling and some exclusions from both lists
// console.log({countriesOGList: getAllCountriesByName().filter(x => !sortedCountryNames.includes(x)) })
// console.log({countriesNGList: sortedCountryNames.filter(x => !getAllCountriesByName().includes(x)) })
// @ts-ignore PT - #180597146
import { getNames as getAllCountriesByName } from "country-list"
import countries from "data/countries"

// @ts-ignore PT - #180597146
const countriesArray = Object.keys(countries).map((countryKey) => countries[countryKey])

// @ts-ignore PT - #180597146
export const countryNames = Object.keys(countries).map((countryKey) => countries[countryKey].name)
export const sortedCountryNames = countryNames.sort()
export const preferredOrderCountryNames = [
  ...["United States of America", "Canada"],
  ...getAllCountriesByName().filter(
    // @ts-ignore PT - #180597146
    (country) => country != "United States of America" && country != "Canada",
  ),
]

export function getCountry(countryCode: string) {
  if (!countryCode) {
    throw "country code is required"
  }
  if (countryCode === "") {
    throw "country code cannot be blank"
  }
  // @ts-ignore PT - #180597146
  return countries[countryCode]
}

export function guessCountry(value: string) {
  const code = value.toUpperCase()
  if (Object.keys(countries).includes(code)) {
    // @ts-ignore PT - #180597146
    return countries[code]
  }

  let name = value.toLowerCase()
  if (name === "united states") {
    name = "united states of america"
  }
  const countryByName = countriesArray.find((country) => {
    return country.name.toLowerCase() === name
  })
  if (countryByName) {
    return countryByName
  }

  return countries.US
}

export function getCountryByName(countryName: string) {
  if (!countryName) {
    throw "country name is required"
  }
  if (countryName === "") {
    throw "country name cannot be blank"
  }

  const country = countriesArray.find((country) => country.name === countryName)

  return country
}

// @ts-ignore PT - #180597146
export function getSubdivisions(countryCode) {
  const country = getCountry(countryCode)

  // @ts-ignore PT - #180597146
  return country.subdivisions.sort((subdivision) => subdivision.name)
}
