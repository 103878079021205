import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"

export default async function getConventionProviderForHost(host: string) {
  // NOTE: This function is generally called as part of getting
  // the current context so it can't rely on already having one and
  // must build a fake one instead.. anything else should simply
  // use the result of getCurrentContext
  // This would be better suited to live inside getCurrentContext, but
  // the old api lib also needs this functionality so it's separated for now
  const current = {
    host,
    user: {
      authToken: "", // we might have one but it's not validated
    },
  }
  const response = await sendConventionApiRequest({
    current,
    method: "GET",
    url: `v1/convention_providers`,
    query: {
      filter: {
        domain: host,
      },
    },
  })

  if (response.response.status === 404 || response.response.status === 422) {
    return null
  }

  const providers = await processApiResponse<ConventionProvider[]>(response)
  if (providers.length !== 1) {
    return null
  }

  return providers[0]
}
