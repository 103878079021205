import { Theme } from "@mui/material"
import { pxToRem } from "utils/helpers"

const buildThemeComponents = (theme: Theme): Theme["components"] => ({
  ...theme.components,
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "uppercase",
        borderRadius: pxToRem(10),
      },
      contained: {
        boxShadow: "none",
      },

      sizeMedium: {
        padding: `${pxToRem(9)} ${pxToRem(10)}`,
      },
      sizeLarge: {
        padding: `${pxToRem(16.5)} ${pxToRem(22)}`,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        background: theme.palette.gray.light,
        borderRadius: pxToRem(10),
      },
      input: {
        "&::placeholder": {
          ...theme.typography.paragraphRegular,
          color: theme.palette.gray.darker,
          opacity: 1,
        },
      },
      underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        ...theme.typography.paragraphRegular,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: pxToRem(10),
        boxShadow: `0 ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.25), 0 0 ${pxToRem(
          4,
        )} rgba(0, 0, 0, 0.12)`,
      },
      paperWidthXs: {
        maxWidth: pxToRem(480),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: `${pxToRem(30)} ${pxToRem(40)}`,
      },
    },
  },
})

export default buildThemeComponents
