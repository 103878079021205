import SendIcon from "@mui/icons-material/Send"
import IconButton from "@mui/material/IconButton"
import { makeStyles } from "tss-react/mui-compat"
import * as React from "react"

const useStyles = makeStyles()({
  button: {
    width: 40,
    padding: "0 3px",
  },
  buttonIcon: {
    marginBottom: "3px",
  },
})

const SendButton = (props: any) => {
  const { sendMessage } = props

  const { classes } = useStyles()

  return (
    <IconButton
      size="small"
      color="primary"
      aria-label="Send Message"
      onClick={sendMessage}
      className={classes.button}>
      <SendIcon className={classes.buttonIcon} />
    </IconButton>
  )
}

export default SendButton
