import { ChatWrapper } from "./ChatWrapper"
import { makeStyles } from "tss-react/mui-compat"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "fixed",
      width: "100%",
      height: "100%",
      zIndex: theme.zIndex.drawer,
      top: 0,
      padding: "40px 80px 110px 40px",
      pointerEvents: "none",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      position: "fixed",
      right: "60px",
      bottom: "40px",
      height: "60px",
      width: "60px",
      backgroundColor: "#FFFFFF",
      boxSizing: "border-box",
      boxShadow: "inset 0 1px 0 0 rgba(255,255,255,0.24), 0 2px 12px 0 rgba(0,0,0,0.5)",
      border: "1px solid rgba(255,255,255,0.18)",
    },
    buttonIcon: {
      color: theme.palette.primary.main,
      height: "24px",
      width: "24px",
    },
    hidden: {
      display: "none",
    },
  })
)

interface IDesktopChat {}

const DesktopChat = (_props: IDesktopChat) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <ChatWrapper classes={classes} cx={cx} />
      <style>
        {`
          .str-chat.str-chat-channel-list, .str-chat.str-chat-channel {
            height: 682px;
            width: 400px;
            border-radius: 10px;
            background-color: rgba(255,255,255,0.97);
            box-shadow: 0 10px 31px 0 rgba(0,0,0,0.5);
            max-height: 100%;
            pointer-events: all;
          }
        `}
      </style>
    </>
  )
}

export default DesktopChat
