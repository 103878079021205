import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

interface IActivityIndicator {
  indicatorSize?: number
  display?: string
}

export const ActivityIndicator = ({ indicatorSize, display = "flex" }: IActivityIndicator) => (
  <Box display={display} alignItems="center" justifyContent="center" pt={[4]} pb={[4]}>
    <CircularProgress variant="indeterminate" size={indicatorSize} />
  </Box>
)
