import { createPath, ROUTE } from "app-constants/routing"
import SingleTenantSSO from "models/convention-api/v1/SingleTenantSSO"

export const getCleverSSORedirectLink = () =>
  window.location.origin + createPath({ path: ROUTE.SSO_CALLBACKS_CLEVER })

export const useCleverSSO = () => {
  const { data: SSOConfig } = SingleTenantSSO.useOne({})

  const loginWithRedirect = () => {
    if (!SSOConfig) throw new TypeError("SSOConfig is undefined")

    const url = new URL("https://clever.com/oauth/authorize")
    // @ts-ignore
    url.searchParams.append("client_id", SSOConfig.clever_client_identifier)
    url.searchParams.append("district_id", SSOConfig.clever_district_identifier)
    url.searchParams.append("redirect_uri", getCleverSSORedirectLink())
    url.searchParams.append("response_type", "code")
    window.location.href = url.href
  }

  const isLoading = typeof SSOConfig === "undefined"
  const isEnabled = !!SSOConfig?.clever_enabled

  return {
    loginWithRedirect,
    isEnabled,
    isLoading,
  }
}
