import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputBase from "@mui/material/InputBase"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import { SelectInputProps } from "@mui/material/Select/SelectInput"
import { makeStyles, withStyles } from "tss-react/mui-compat"
import clsx from "clsx"
import { colors } from "deprecated-theme"
import React from "react"
import { Property } from "csstype"

const relativePosition: Property.Position = "relative"
const BootstrapInput = withStyles(
  InputBase,
  (theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: "10px",
      position: relativePosition,
      border: "1px solid #ced4da",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: "10px",
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
      },
      width: "100%",
      fontSize: "18px",
      lineHeight: "24px",
      // match MuiFilledInput-input to position text properly with label
      padding: "27px 12px 10px",
      backgroundColor: colors.background,
      borderWidth: "1px",
      borderImage: "initial",
      borderStyle: "solid",
    },
  }),
)

const useStyles = makeStyles()({
  root: {},
  labelBox: {
    zIndex: 2,
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  textWrap: {
    textWrap: 'wrap',
  },
  select: {
    width: "100%",
    "&::required:invalid": {
      color: "gray",
    },
  },
  selectPlaceholder: {
    '&:[value=""][disabled]': {
      display: "none",
    },
  },
  normalBorder: {
    borderColor: colors.line,
  },
  errorBorder: {
    borderColor: colors.error,
  },
  helperText: {
    margin: "3px 14px 0",
  },
})

interface IControlledSelect {
  register: any
  options: any
  inputProps?: any
  name: string
  label: string
  autoComplete: string
  placeholder?: string
  defaultValue?: string
  error: boolean
  required?: boolean
  showRequired?: boolean
  fieldErrors: any
  handleChange?: SelectInputProps<string>["onChange"]
}

export const ControlledSelect = (props: IControlledSelect) => {
  const {
    register,
    options,
    inputProps,
    name,
    label,
    autoComplete,
    placeholder,
    defaultValue,
    error,
    required = false,
    showRequired = true,
    fieldErrors = { message: " " },
    handleChange = () => {
      return
    },
  } = props

  const { classes } = useStyles()

  const dynamicHeightBasedOnCopyLengthRem = 1.5 * (label.length / 50 + 1)

  return (
    <FormControl error={error} className={classes.root}>
      <Box className={classes.labelBox} sx={{minHeight: `${dynamicHeightBasedOnCopyLengthRem}rem`}}>
        <InputLabel htmlFor={name} className={"MuiInputLabel-filled"} style={{ whiteSpace: 'normal' }}>
          {required && showRequired ? `${label} *` : label}
        </InputLabel>
      </Box>

      <Select
        native
        labelId={name}
        autoComplete={autoComplete}
        inputProps={{
          id: name,
          name: name,
          ...register(name),
          "aria-label": error ? `${label} has error: ${fieldErrors.message as string}` : `${label}`,
          "aria-required": required ? "true" : "false",
          "aria-describedby": "",
          ...inputProps,
        }}
        // NOTE: to not have a default value and also not have a visible
        // placeholder, can pass placeholder=" "
        // Doing it this way to keep it backward-compatible
        // We probably need a totally new component for dropdowns
        defaultValue={defaultValue ? defaultValue : placeholder ? "" : undefined}
        input={<BootstrapInput />}
        className={clsx(classes.select, error ? classes.errorBorder : classes.normalBorder)}
        onChange={handleChange}>
        {placeholder && (
          <option
            key={`${name}-placeholder-option`}
            value=""
            className={clsx(classes.selectPlaceholder)}
            disabled
            aria-label="no option selected"
            style={{ display: "none" }}>
            {placeholder}
          </option>
        )}
        {options !== null && options instanceof Array && typeof options[0] === "object"
          ? options.map((option) => (
              <option
                key={`${option.value}-${option.label}`}
                value={option.value}
                aria-label={option.label}>
                {option.label}
              </option>
            ))
          : // @ts-ignore PT - #180597146
            options.map((value) => (
              <option label={value} id={value} key={value} value={value} aria-label={value}>
                {value}
              </option>
            ))}
      </Select>

      {error && (
        <FormHelperText error={error} className={classes.helperText} {...{ "aria-hidden": "true" }}>
          {fieldErrors.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
