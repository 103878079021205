export const sessionsFiltersStore = {
  exhibitorName: "",
  international: false,
  states: [],
  majors: [],
  tags: [],
  exhibitor_type: [false, false],
  school_level: [false, false],
  school_control: [false, false],
  school_size: [false, false, false],
  when_time: "",
  when: "now_and_upcoming",
}

// @ts-ignore PT - #180597146
export function updateSessionsFilters(state, payload) {
  return {
    ...state,
    sessionsFiltersStore: {
      ...state.sessionsFiltersStore,
      ...payload,
    },
  }
}

export const defaultSessionsFiltersStore = {
  exhibitorName: "",
  international: false,
  states: Object.freeze([]),
  majors: Object.freeze([]),
  tags: Object.freeze([]),
  exhibitor_type: Object.freeze([false, false]),
  school_level: Object.freeze([false, false]),
  school_control: Object.freeze([false, false]),
  school_size: Object.freeze([false, false, false]),
}

Object.freeze(defaultSessionsFiltersStore)
