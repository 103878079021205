export interface RegisterStudentPayload {
  registration_token: string
  first_name: string
  last_name: string
  birthdate: string
  address_street: string
  address_street2: string
  city: string
  state: string
  country: string
  zipcode: string
  phone_e164: string
  applicant_type: string
  ethnicities: string
  gender: string
  referred: string
  enrollment_semester: string
  school_name: string
}

export enum RegistrationTypes {
  Student = "student_registrations",
  Counselor = "counselor_registrations",
  Parent = "parent_guardian_registrations",
}

export enum ConventionUserTypes {
  Student = "student",
  Counselor = "teacher_counselor",
  Parent = "parent_guardian",
}
