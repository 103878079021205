import { TextFieldProps } from "./text-field.props"
import Box from "@mui/material/Box"
import MUITextField from "@mui/material/TextField"
import { makeStyles } from "tss-react/mui-compat"
import React, { memo } from "react"

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      "& .MuiTextField-root": {
        width: "100%",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: theme.palette.gray.lighter,
      },
    },
  }),
)

/**
 * A component which has a label and an input together.
 */
export const TextField: React.FunctionComponent<TextFieldProps> = memo(function TextField(props) {
  const {
    id,
    placeholder,
    label,
    error,
    required = false,
    showRequired = true,
    inputProps,
    multiline = false,
    variant = "filled",
    /**
     * Blank error message ensures form don't resize after
     * failing validation by reserving space to accomodate
     * error messages when available.
     */
    fieldErrors = { message: " " },
    onSubmitEditing: onEnter,
    rootClass: rootClassOverride,
    ...rest
  } = props

  const { classes, cx } = useStyles()
  // @ts-ignore PT - #180597146
  const onKeyPress = (ev) => {
    if (ev.key === "Enter") {
      !!onEnter && onEnter()
    }
  }

  return (
    <Box className={cx(classes.root, rootClassOverride)}>
      <MUITextField
        error={error}
        placeholder={placeholder}
        id={id}
        label={required && showRequired ? `${label} *` : label}
        inputProps={{
          "aria-label": error ? `${label} has error: ${fieldErrors.message as string}` : `${label}`,
          "aria-required": required ? "true" : "false",
          "aria-describedby": "",
          ...inputProps,
        }}
        FormHelperTextProps={{
          "aria-hidden": "true",
          style: {
            ...(!error && { display: "none" }),
          },
        }}
        helperText={!!fieldErrors.message && (fieldErrors.message as string)}
        multiline={multiline}
        variant={variant}
        onKeyPress={onKeyPress}
        rows={multiline ? 3 : 1}
        {...rest}
      />
    </Box>
  )
})
