import { useTheme } from "@mui/material"
import { makeStyles } from "tss-react/mui-compat"
import { Controller } from "react-hook-form"
import PhoneInput2 from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"
import { pxToRem } from "utils/helpers"

const useStyles = makeStyles()({
  validationText: {
    marginTop: "3px",
    color: "red",
    fontSize: "0.75em",
    marginLeft: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: 1.66,
  },
})

const PhoneInput = (props: any) => {
  const { classes } = useStyles()
  const theme = useTheme()

  const {
    control,
    defaultCountryCode,
    defaultValue,
    inputProps,
    label,
    name,
    placeholder,
    required,
  } = props

  return (
    <div>
      <style>
        {`
          .react-tel-input .form-control:focus {
            border-color: ${theme.palette.primary.main};
            box-shadow: 0 0 0 1px ${theme.palette.primary.main};
          }
        `}
      </style>
      <Controller
        render={(renderProps) => {
          const { field, fieldState } = renderProps
          return (
            <>
              <PhoneInput2
                countryCodeEditable={false}
                isValid={!fieldState.error?.message}
                country={defaultCountryCode}
                value={field.value}
                specialLabel={`${label} *`}
                inputStyle={{ width: "100%" }}
                containerStyle={{ zIndex: 3 }}
                dropdownStyle={{ width: pxToRem(360) }}
                placeholder={placeholder}
                enableSearch={true}
                searchStyle={{ width: pxToRem(320) }}
                searchNotFound="No country found"
                searchPlaceholder="Search Country"
                defaultErrorMessage={
                  fieldState.error?.message
                    ? `${label} has error: ${fieldState.error?.message as string}`
                    : `${label}`
                }
                onChange={(_value, _data, event) => {
                  // We need this condition to make it work with react-hook-form
                  // It throws e.replace is not a function
                  if (!event?.target?.value) return
                  field.onChange(event)
                }}
                inputProps={{
                  onBlur: field.onBlur,
                  name: name,
                  "aria-invalid": !!fieldState.error?.message,
                  "aria-label": fieldState.error?.message
                    ? `${label} has error: ${fieldState.error?.message as string}`
                    : `${label}`,
                  "aria-required": !!fieldState.error?.message,
                  tabIndex: 0,
                  ...inputProps,
                }}
              />
              {fieldState.error?.message && (
                <p className={classes.validationText}>{fieldState.error?.message}</p>
              )}
            </>
          )
        }}
        rules={{
          required,
        }}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default PhoneInput
