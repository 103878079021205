import moment from "moment-timezone"
import { Component } from "react"

interface GenericErrorBoundaryState {
  hasError: boolean
  error?: Error
  errorTimestamp?: string
}

class GenericErrorBoundary extends Component<any, GenericErrorBoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    const now = moment().utc()
    return { hasError: true, error: error, errorTimestamp: now.format("LLL z") }
  }

  // NOTE: componentDidCatch doesn't work from server code
  // or in first render from SSR, so it's not a catch-all,
  // but it can catch things on re-renders
  // More info: https://reactjs.org/docs/error-boundaries.html
  componentDidCatch(error: Error, info: any) {
    // Send error to Airbrake
    //@ts-ignore
    if (typeof window !== "undefined" && window.airbrake) {
      //@ts-ignore
      window.airbrake.notify({
        error: error,
        context: { severity: "error", origin: "error-boundary" },
        params: { info: info },
      })
    }
  }

  render() {
    if (this.state.hasError) {
      const fullMessage = `${this.state.error.name}: ${this.state.error.message}`
      const ignoredErrorMessages = [
        "TypeError: Cannot read properties of undefined (reading 'setError')",
        "TypeError: Cannot read properties of undefined (reading 'queryInProgress')",
      ];
      if (!ignoredErrorMessages.includes(fullMessage)) {
        window.location.href = '/conventions';
      }

      return (
        <div style={{ margin: "1rem" }}>
          <h1>Oops!</h1>
          <div>An error has occurred that cannot be recovered.</div>
          <div>Please try refreshing the page.</div>
          <div style={{ marginTop: "1rem" }}>Time: {this.state.errorTimestamp}</div>
          <div>
            {/*// @ts-ignore*/}
            {this.state.error.name}: {this.state.error.message}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default GenericErrorBoundary
