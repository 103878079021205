/**
 * Just the font names.
 *
 * The various styles of fonts are defined in the <Text /> component.
 */
export const typography = {
  /**
   * The header font.  Used in most titles.
   */
  header: "Raleway, sans-serif",

  /**
   * The primary font.  Used in most places.
   */
  primary: "Lato, sans-serif",
}
