import getEnvForHost from "lib/get-env-for-host"
import useCurrent from "lib/use-current"
import { createContext, useEffect, useState } from "react"
import { StreamChat } from "stream-chat"
import { Chat } from "stream-chat-react"

type ChatClient = StreamChat | null

type ChatStatus = "disabled" | "enabled" | "nochats"

interface IChatContext {
  chatClient: ChatClient
  status: ChatStatus
  connect: () => void
}

export const ChatContext = createContext<IChatContext>({
  chatClient: null,
  connect: () => {
    throw new Error("Chat client has not yet been initialised")
  },
  status: "disabled",
})

// @ts-ignore PT - #180597146
export const ChatProvider = ({ children }) => {
  const [client, setClient] = useState<ChatClient>(null)
  const [status, setStatus] = useState<ChatStatus>("disabled")

  const current = useCurrent()
  // @ts-ignore
  const env = getEnvForHost(current.host)

  useEffect(() => {
    if (
      // @ts-ignore
      current?.config?.has_exhibitor_chat &&
      // @ts-ignore
      current.user &&
      // @ts-ignore
      current?.user?.id &&
      // @ts-ignore
      current?.user?.first_name &&
      // @ts-ignore
      current?.user?.last_name &&
      // @ts-ignore
      current?.streamToken?.cu_i_channel_type &&
      // @ts-ignore
      current?.streamToken?.handle &&
      // @ts-ignore
      current?.streamToken?.token
    ) {
      // @ts-ignore
      if (current.hasChats) {
        // @ts-ignore
        const chatClient = new StreamChat(env.NEXT_PUBLIC_STREAM_CHAT_API_KEY)

        chatClient
          .connectUser(
            {
              // @ts-ignore
              id: current.streamToken.handle,
              // @ts-ignore
              name: `${current.user.first_name} ${current.user.last_name}`.trim(),
              // @ts-ignore
              convention_user_id: current.user.id,
            },
            // @ts-ignore
            current.streamToken.token,
          )
          .then(() => {
            setClient(chatClient)
            setStatus("enabled")
          })
          .catch(() => {
            setClient(null)
          })
      } else {
        setStatus("nochats")
      }
    }

    return () => {
      if (client) {
        client.disconnectUser()
        client.closeConnection()
      }
      setClient(null)
    }
  }, [current])

  const connect = () => {
    if (!client) {
      // @ts-ignore
      const chatClient = new StreamChat(env.NEXT_PUBLIC_STREAM_CHAT_API_KEY)

      chatClient
        .connectUser(
          {
            // @ts-ignore
            id: current.streamToken.handle,
            // @ts-ignore
            name: `${current.user.first_name} ${current.user.last_name}`.trim(),
            // @ts-ignore
            convention_user_id: current.user.id,
          },
          // @ts-ignore
          current.streamToken.token,
        )
        .then(() => {
          setClient(chatClient)
          setStatus("enabled")
        })
        .catch(() => {
          setClient(null)
        })
    }
  }

  if (client) {
    return (
      <ChatContext.Provider
        value={{
          chatClient: client,
          connect,
          status,
        }}>
        <Chat client={client}>{children}</Chat>
      </ChatContext.Provider>
    )
  }

  return (
    <ChatContext.Provider
      value={{
        chatClient: null,
        connect,
        status,
      }}>
      {children}
    </ChatContext.Provider>
  )
}
