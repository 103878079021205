export default class ConventionApiError extends Error {
  public readonly json: any
  private readonly response: any

  // @ts-ignore PT - #180597146
  constructor({ response, json }) {
    super()
    this.name = "ApiError"
    this.response = response
    this.json = json
  }

  get internalError(): any {
    return this.json.errors?.[0]
  }

  get internalErrors(): any[] {
    return this.json.errors
  }

  get code(): string {
    return this?.internalError?.code
  }

  get message(): string {
    const title = this.internalError?.title
    const message = this.internalError?.message
    return title || message || "Something went wrong"
  }

  get status(): number {
    return this.response.status
  }
}
