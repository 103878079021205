import getEnvForHost from "lib/get-env-for-host"
import useCurrent from "lib/use-current"
import { RunParams } from "models/convention-api/types"
import useSWR from "swr"
import { SWRConfiguration } from "swr"
import useSWRInfinite from "swr/infinite"

const DefaultSWRConfig: SWRConfiguration = {
  shouldRetryOnError: false,
}

function useBaseParams() {
  const current = useCurrent()

  if (!current) {
    return undefined
  }

  // @ts-ignore
  const env = getEnvForHost(current.host)
  return {
    // @ts-ignore
    authToken: current.authToken,
    host: env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT,
    pathParams: {
      // @ts-ignore
      provider_id: current.provider.id,
    },
  }
}

function getModelSWRKeyFn(path: string, baseParams: any, params: any) {
  return (_index: any = null, previous: any = null) => {
    try {
      if (!baseParams) {
        return null
      }
      try {
        if (typeof params === "function") {
          params = params()
        }
      } catch (_error) {
        params = null
      }
      if (!params) {
        return null
      }
      const key = JSON.stringify({ ...baseParams, ...params, path: path, after: previous?.after })
      return key
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

function runModelSWRFn(fn: (runParams: RunParams) => any) {
  return (runParamsJson: string) => {
    try {
      const runParams = JSON.parse(runParamsJson)
      return fn(runParams)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export function useModelSWR<T>(path: string, params: any, fn: (runParams: RunParams) => any) {
  const baseParams = useBaseParams()
  return useSWR<T>(getModelSWRKeyFn(path, baseParams, params), runModelSWRFn(fn), {
    ...DefaultSWRConfig,
    ...params,
  })
}

export function useModelSWRInfinite<T>(
  path: string,
  params: any,
  fn: (runParams: RunParams) => any,
) {
  const baseParams = useBaseParams()
  return useSWRInfinite<T>(getModelSWRKeyFn(path, baseParams, params), runModelSWRFn(fn), {
    ...DefaultSWRConfig,
    ...params,
  })
}
