'use client';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import CssBaseline from "@mui/material/CssBaseline"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import Chat from "components/Chat"
import { PreviewMessage } from "components/PreviewMessage"
import AuthDialogs from "components/auth-dialogs/AuthDialogs"
import ChatErrorBoundary from "components/chat-error-boundary"
import FaviconDyn from "components/favicon-dyn"
import { FocusTracker } from "components/focus-tracker"
import GenericErrorBoundary from "components/generic-error-boundary"
import { GtmIdentifyCurrentUser } from "components/gtm-identify-current-user"
import LoadCurrentContext from "components/load-current-context"
import SkipNavigation from "components/skip-navigation/skip-navigation"
import { Auth0Provider } from "contexts/Auth0Context"
import { ChatProvider } from "contexts/ChatContext"
import { globalStyles } from "deprecated-theme"
import fetch from "lib/fetch"
import "lib/nprogress"
import { StateMachineProvider, createStore } from "little-state-machine"
import { NextSeo } from "next-seo"
import { NextSeoProps } from "next-seo/lib/types"
import { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { SnackbarProvider } from "notistack"
import React, { useEffect, useState } from "react"
import { RecoilRoot } from "recoil"
import { chatStore } from "stores/chat-store"
import { sessionsFiltersStore } from "stores/sessions-filters-store"
import { uiStore } from "stores/ui-store"
import "styles/global.css"
import { SWRConfig } from "swr"
import CuratorThemeProvider from "theme/CuratorThemeProvider"
import { isEmbed, isInIframe, sendIframeMessage } from "utils/helpers"
import { ActivityIndicator } from "components/activity-indicator"

// import it with SSR disabled
import dynamic from 'next/dynamic'
const GoogleOneTap = dynamic(() => import('components/GoogleOneTap'), {
  loading: () => <ActivityIndicator />,
  ssr: false,
})


createStore(
  {
    chatStore,
    sessionsFiltersStore,
    uiStore,
  },
  {},
)

// const clientSideEmotionCache = createCache({
//   key: "css",
//   prepend: true, // TODO: use `insertionPoint` instead
// })

interface MyAppProps extends AppProps {
  // emotionCache: EmotionCache
  pageProps: {
    nextSeoProps: NextSeoProps
  }
}

export default function App(props: MyAppProps) {
  const { Component, pageProps } = props
  const router = useRouter()
  const [appLoading, setAppLoading] = useState(false)

  const isPreview = !!router.query.previewColor

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      // @ts-ignore
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    if (isInIframe() && !isEmbed()) {
      router.replace("/embed" + router.pathname)
      return
    }

    if (!appLoading) {
      setAppLoading(true)
      sendIframeMessage({ message: "appLoaded" })
    }
  }, [appLoading])

  return (
    <AppCacheProvider {...props}>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {pageProps.nextSeoProps && <NextSeo {...pageProps.nextSeoProps} />}
      <GenericErrorBoundary>
        <AppRouterCacheProvider options={{ key: "css", prepend: true }}>
          {/*@ts-ignore children type error occurs because of upgrading to React 18*/}
          <RecoilRoot>
            <LoadCurrentContext>
              <CuratorThemeProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {isPreview && <PreviewMessage />}
                  <CssBaseline />
                  {globalStyles}
                  <GtmIdentifyCurrentUser />
                  <FaviconDyn />
                  <Auth0Provider>
                    <ChatProvider>
                      {/*@ts-ignore children type error occurs because of upgrading to React 18*/}
                      <StateMachineProvider>
                        <SnackbarProvider maxSnack={5}>
                          <SWRConfig
                            value={{
                              // disable auto-refresh
                              refreshInterval: 0,
                              // disable revalidate when tabbing out/in
                              revalidateOnFocus: false,
                              //@ts-ignore
                              fetcher: (...args) => fetch(...args).then((res) => res.json()),
                              // onErrorRetry: (error, _key, _option, revalidate, { retryCount }) => {
                              //   if (retryCount >= 10) return

                              //   // retry after (retryCount + 1)^2 seconds
                              //   const incRetryCount = retryCount + 1
                              //   setTimeout(
                              //     () => revalidate({ retryCount: incRetryCount }),
                              //     incRetryCount * incRetryCount * 1000,
                              //   )
                              // },
                            }}>
                            <SkipNavigation />
                            <GoogleOneTap />
                            <Component {...pageProps} />
                            <AuthDialogs />
                            <ChatErrorBoundary>
                              <Chat />
                            </ChatErrorBoundary>
                            <FocusTracker />
                          </SWRConfig>
                        </SnackbarProvider>
                      </StateMachineProvider>
                    </ChatProvider>
                  </Auth0Provider>
                </LocalizationProvider>
              </CuratorThemeProvider>
            </LoadCurrentContext>
          </RecoilRoot>
        </AppRouterCacheProvider>
      </GenericErrorBoundary>
      {/* @ts-ignore */}
      <style jsx global>
        {`
          .ReactModal__Overlay {
            z-index: 10000;
          }

          // for some reason we need this,
          // because preventScroll doesn't work on react-model
          .ReactModal__Body--open {
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100%;
          }

          .MuiInputLabel-filled {
            margin-right: 1em;
          }

          .MuiInputLabel-filled.MuiInputLabel-shrink {
            overflow: hidden;
            // need this for migrating MUI from v4 to v5
            // for some reason it doesn't have this
            // and the label moves strangely inside the input line
            transform: translate(12px, 10px) scale(0.75);
          }

          .makeStyles-labelSpan-5 {
            text-align: initial;
          }
        `}
      </style>
    </AppCacheProvider>
  )
}
