import { UseAllParams } from "models/convention-api/types"
import RegistrantType from "models/convention-api/v1/RegistrantType"
import { useMemo } from "react"

const useRegistrantTypes = <T extends UseAllParams | null>(params: T | (() => T)) => {
  const registrantTypes = RegistrantType.useAll(params)

  return useMemo(() => {
    if (!registrantTypes.data) return null

    return registrantTypes.data.sort((a, b) => a.position - b.position)
  }, [registrantTypes.data])
}

export default useRegistrantTypes
