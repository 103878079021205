import HiddenBox from "components/HiddenBox"
import DesktopChat from "./DesktopChat"
import MobileChat from "./MobileChat"
import useChat from "hooks/useChat"
import "stream-chat-react/dist/css/index.css"

interface IChat {}

const Chat = (_props: IChat) => {
  const { status } = useChat()

  if (status === "disabled") {
    return null
  }

  return (
    <>
      <HiddenBox variant="hiddenSMDown">
        <DesktopChat />
      </HiddenBox>
      <HiddenBox variant="hiddenSMUp">
        <MobileChat />
      </HiddenBox>
    </>
  )
}

export default Chat
