import chalk from "chalk"
import fetch from "lib/fetch"
import log from "lib/log"
import stringifyQuery from "lib/stringify-query"
import moment from "moment-timezone"

export type SendApiRequestParams = {
  method?: string
  url: string
  headers?: Record<string, string>
  query?: Record<string, any>
  body?: string
}

function buildUrlQuery(url: string, query?: any): string {
  if (query && Object.keys(query).length) {
    const queryString = stringifyQuery(query)
    if (queryString) {
      if (url.indexOf("?") !== -1) {
        url = url.replace(/\?/, `?${queryString}&`)
      } else if (url.indexOf("#") !== -1) {
        url = url.replace(/#/, `?${queryString}#`)
      } else {
        url = url + `?${queryString}`
      }
    }
  }
  return url
}

export default async function sendApiRequest({
  method = "GET",
  url,
  headers = {},
  query = {},
  body = undefined,
}: SendApiRequestParams): Promise<{ response: Response; json: any }> {
  url = buildUrlQuery(url, query)

  const startTime = moment()
  // NOTE: we have conflicting Response defs, depending on which
  // one happens to load first -- the diff is neglible so casting
  // to any and then to Response for now
  const response: Response = (await fetch(url, { method, headers, body })) as any
  const json = await response.json().catch((error) => console.error(error))
  const elapsedTime = moment().diff(startTime)

  log.server.info(async () => {
    const text = `${json}`
    const colorStatus = (status: number) => {
      if (status >= 500) {
        return chalk.red(status.toString())
      } else if (status >= 400) {
        return chalk.yellow(status.toString())
      } else if (status >= 300) {
        return chalk.blue(status.toString())
      } else if (status >= 200) {
        return chalk.green(status.toString())
      } else {
        return status
      }
    }
    const colorDuration = (duration: number) => {
      if (duration < 250) {
        return chalk.green(`${duration} milliseconds`)
      } else if (duration < 1000) {
        return chalk.yellow(`${duration} milliseconds`)
      } else {
        const seconds = duration / 1000
        const secondsStr = seconds.toFixed(2)
        return chalk.red(`${secondsStr} seconds`)
      }
    }

    return `
${chalk.cyan(method)} ${chalk.magenta(url)}
${Object.keys(headers)
  .sort()
  .map((key) => `${chalk.cyan(key)}: ${headers[key]}`)
  .join("\n")}
${chalk.cyan("STATUS")} ${colorStatus(response.status)} => ${colorDuration(elapsedTime)}
${text}
  `.trim()
  })

  return { response, json }
}
