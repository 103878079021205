import ComputerIcon from "@mui/icons-material/Computer"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import moment from "moment-timezone"

export const APP_QR_CODE_LEVEL = "M"
export const LOAD_RECORDS_PER_PAGE = 20

export const ApplicantTypes = [
  {
    value: "freshman",
    label: "First-Year College Student",
  },
  {
    value: "transfer",
    label: "Transfer Student",
  },
  {
    value: "international",
    label: "International Student",
  },
  {
    value: "us_veteran",
    label: "US Veteran Student",
  },
]

export const ApplicantTypesCounselor = [
  {
    value: "teacher",
    label: "Teacher",
  },
  {
    value: "high_school_counselor",
    label: "High School Counselor",
  },
  {
    value: "independent_counselor",
    label: "Independent Counselor",
  },
  {
    value: "other_advisor",
    label: "Other Adviser",
  },
]

export const DEFAULT_CONVENTION_MAX_PEOPLE_PER_REGISTRATION = 1
export const CONVENTION_MAX_PEOPLE_PER_REGISTRATION = 25 // https://visitdays.slack.com/archives/C0UDPET7U/p1661476799208269

// 2 weeks = sec * min * hours * days * 2
export const LOGIN_TOKEN_EXPIRY = 60 * 60 * 24 * 7 * 2

export const REM_IN_PX = 16

export const localTimezone = moment.tz.guess()

export const NAVIGATION_HEIGHT = 84 // px

export const VALIDATIONS = {
  // https://stackoverflow.com/a/33561517/205052
  PHONE: /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g,
}

export enum ConventionBooleanSetting {
  STAGE_ENABLED = "stage_enabled",
  PAID = "paid",
}

export enum ConventionLocationStyle {
  VIRTUAL = "virtual",
  INPERSON = "in_person",
}

export const ConventionLocationStyleLabel = {
  [ConventionLocationStyle.VIRTUAL]: "Virtual",
  [ConventionLocationStyle.INPERSON]: "In Person",
}

export enum ConventionRegistrationState {
  LOADING = "LOADING",
  REGISTERED_PAID = "REGISTERED_PAID",
  REGISTERED = "REGISTERED",
  READY_FOR_REGISTRATION = "READY_FOR_REGISTRATION",
  AT_CAPACITY = "AT_CAPACITY",
  CLOSED = "CLOSED",
  FEW_SPOTS_LEFT = "FEW_SPOTS_LEFT",
  PROCESSING_PAYMENT = "PROCESSING_PAYMENT",
}

export enum CookieKeys {
  GOOGLE_SECRET = "google-secret",
  LOGIN_SESSION = "login_session",
  REQUEST_INFO_ACKNOWLEDGMENT = "request_info_acknowledgment",
  SIGNUP_REGISTRATION_TOKEN = "signup_registration_token",
}

export enum MomentFormats {
  startTime = "MMM Do h:mm a",
  endTime = "MMM Do h:mm a z",
  dateTimeNoZone = "MMM Do h:mm a", //Add this for now, eventually deprecate `startTime`
  dateTimeWithZone = "MMM Do h:mm a z", //Add this for now, eventually deprecate `endTime`
  dateOnly = "MMM Do, YYYY",
  dateOnlyWithoutYear = "MMM Do",
  timeOnly = "h:mm a",
  timeOnlyWithZone = "h:mm a z",
  dateTimeFullMonth = "MMMM Do h:mm a",
}

export const REGISTRATION_COUNTDOWN = 10 * 60 * 1000 // 10 minutes

export const ADMIN_USER_MIN_VERIFIED_AT = moment().subtract(2, "years") // 2 years ago

export enum ConventionRegistrationStatus {
  UNKNOWN = "unknown",
  PAID = "paid",
  FAILED = "failed",
  PAYMENT_INTENT_NEEDED = "payment_intent_needed",
  PAYMENT_INTENT_INCOMPLETE = "payment_intent_incomplete",
  FREE = "free",
}

export enum StripePaymentStatus {
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  REQUIRES_CONFIRMATION = "requires_confirmation",
  REQUIRES_ACTION = "requires_action",
  PROCESSING = "processing",
  REQUIRES_CAPTURE = "requires_capture",
  CANCELED = "canceled",
  SUCCEEDED = "succeeded",
}

export enum VideoPlatform {
  WHEREBY = "whereby",
  ZOOM = "zoom",
}

export const VideoPlatformDetail = {
  [VideoPlatform.WHEREBY]: {
    name: "In App Live Streaming",
    Icon: ComputerIcon,
  },
  [VideoPlatform.ZOOM]: {
    name: "Zoom Video",
    Icon: OpenInNewIcon,
  },
} as const

export const SHOW_VIDEO_COUNT = false // #183118992

export enum TerritoryTagCategory {
  APPLICANT_TYPE = "applicant_type",
  COUNTRY = "country",
  US_STATE = "us_state",
  US_COUNTIES = "us_counties",
  LANGUAGE = "language",
}

export enum SessionState {
  ENDED = "ENDED",
  AT_CAPACITY = "AT_CAPACITY",
  JOINED = "JOINED",
  ZOOM = "ZOOM",
  READY_TO_JOIN = "READY_TO_JOIN",
}

export enum AuthDialog {
  SIGN_IN = "SIGN_IN",
  SIGN_UP = "SIGN_UP",
  SIGN_UP_PASSWORD = "SIGN_UP_PASSWORD",
  SIGN_UP_NEW = "SIGN_UP_NEW",
  SIGN_UP_WITHOUT_PASSWORD = "SIGN_UP_WITHOUT_PASSWORD",
  JOIN = "JOIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  SET_PASSWORD = "SET_PASSWORD",
}

export enum AuthEmail {
  NOT_FOUND = "not_found",
  PASSWORD = "password",
  COMPLETE_PROFILE = "complete_profile",
  LINK = "link",
}

// 24 hours
export const REQUEST_INFO_ACKNOWLEDGMENT_EXPIRY = 60 * 60 * 24
