import { TextField, TextFieldProps } from "./"
import React from "react"
import { Controller, Control } from "react-hook-form"
import InputMask from "react-input-mask"

type ControlledTextFieldProps = TextFieldProps & {
  control: Control<any>
  name: string
  rules?:
    | Partial<{
        required:
          | string
          | boolean
          | React.ReactElement<
              any,
              | string
              | ((
                  props: any,
                ) => React.ReactElement<
                  any,
                  string | any | (new (props: any) => React.Component<any, any, any>)
                > | null)
              | (new (props: any) => React.Component<any, any, any>)
            >

        min: any
        max: any
        maxLength: any
        minLength: any
        pattern: any
        validate: Record<string, any>
      }>
    | undefined
  onChange?: any | undefined
  onFocus?: (() => void) | undefined
  onBlur?: any | undefined
  mode?: "onChange" | "onBlur" | "onSubmit" | undefined
  multiline?: boolean
  onChangeName?: string | undefined
  onBlurName?: string | undefined
  valueName?: string | undefined
  defaultValue?: string
  type?: string
  maskPlaceholder?: string
  pattern?: string
  required?: boolean
  showRequired?: boolean
}

// @ts-ignore PT - #180597146
const CustomInputMask = (props) => {
  return (
    /*@ts-ignore children type error occurs because of upgrading to React 18*/
    <InputMask {...props}>
      <TextField
        key={props.name}
        id={props.name}
        inputProps={{ pattern: props.pattern ? props.pattern : null }}
        multiline={!!props.multiline}
      />
    </InputMask>
  )
}

export const ControlledTextField = (props: ControlledTextFieldProps) => {
  const {
    control,
    mask,
    maskPlaceholder,
    multiline = false,
    name,
    pattern,
    defaultValue = "",
    required = false,
    showRequired = true,
    ...rest
  } = props

  if (mask) {
    return (
      <Controller
        render={({ field }) => (
          <CustomInputMask
            {...field}
            maskPlaceholder={maskPlaceholder}
            multiline={!!multiline}
            id={name}
            mask={mask}
            {...rest}
          />
        )}
        control={control}
        name={name}
        rules={{
          //@ts-ignore
          pattern,
          //@ts-ignore
          required,
        }}
        required={required}
        defaultValue={defaultValue}
      />
    )
  }

  // @ts-ignore
  return (
    <>
      {/* @ts-ignore */}
      <Controller
        render={({ field }) => <TextField {...field} multiline={!!multiline} {...rest} id={name} />}
        control={control}
        rules={{
          //@ts-ignore
          required,
        }}
        name={name}
        defaultValue={defaultValue}
      />
    </>
  )
}
