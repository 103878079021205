import performCamelcaseKeys from "camelcase-keys"
import ConventionApiError from "models/convention-api/ConventionApiError"

function toModel(data: any): any {
  if (!data) {
    return undefined
  }
  const relationships = data.relationships || {}
  return {
    id: data.id,
    _type: data.type,
    ...data.attributes,
    ...Object.keys(relationships).reduce((properties, key) => {
      const relationship = relationships[key]
      const model = toModel(relationship.data)
      if (model && model.id) {
        // @ts-ignore PT - #180597146
        properties[`${key}_id`] = model.id
      }
      return properties
    }, {}),
  }
}

export default async function processApiResponse<TResult>({
  response,
  json,
  camelCaseKeys,
}: {
  response: Response
  json: any
  camelCaseKeys?: boolean
}): Promise<TResult> {
  if (response.status >= 400) {
    throw new ConventionApiError({ response, json })
  }
  const data = json?.data
  // NOTE: Using Array.isArray throws a typescript error
  // that it can't coerce TResult to an array and gets into
  // funky issues.. even if TResult is any[] then it says
  // it returns any[][] instead -- need a more
  // explicit way to say if data is expected to be
  // an array or not rather than checking if it is
  if (data && "length" in data) {
    const models = data.map((item: any) => toModel(item))
    return models as TResult
  }
  let model = toModel(data)

  if (camelCaseKeys) {
    model = performCamelcaseKeys(data, { deep: true })
  }

  return model as TResult
}
