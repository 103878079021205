import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { makeStyles } from "tss-react/mui-compat"
import React from "react"

const useStyles = makeStyles()(
  (_theme) => ({
    root: {
      width: "100%",
      height: "100%",
    },
  })
)

const Loading = () => {
  const { classes } = useStyles()

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
      <CircularProgress />
    </Box>
  )
}

export default Loading
