import ListWrapper from "./ListWrapper"
import Loading from "./Loading"
import { ChatDown } from "stream-chat-react"

const List = (props: any) => {
  const { error = false, loading, LoadingErrorIndicator = ChatDown, creating, children } = props

  if (error) {
    return <LoadingErrorIndicator type="Connection Error" />
  }

  if (loading || creating) {
    return <Loading />
  }

  return <ListWrapper>{children}</ListWrapper>
}

export default List
