import { Alert, AlertColor } from "@mui/material"
import { OptionsObject, useSnackbar } from "notistack"
import React from "react"

const useShowSnackbar = () => {
  const snackbar = useSnackbar()
  return (message: string, severity: AlertColor = "info", options?: OptionsObject) => {
    snackbar.enqueueSnackbar(message, {
      autoHideDuration: 2000,
      content: (key) => (
        <Alert onClose={() => snackbar.closeSnackbar(key)} severity={severity}>
          {message}
        </Alert>
      ),
      ...options,
    })
  }
}

export default useShowSnackbar
