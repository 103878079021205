"use client";

import { IncomingMessage } from "http"

export default function getHost({ req }: { req: IncomingMessage | void }) {
  if (process.env.NEXT_PUBLIC_HOST) {
    return process.env.NEXT_PUBLIC_HOST
  }

  if ( req ) {
    return req.headers.host;
  }
  
  if (typeof window !== "undefined") {
    return window.location.host;
  }  
  
  return "";
}
