import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogContent, styled, TextField, Typography } from "@mui/material"
import { AuthDialog, AuthEmail } from "app-constants/constants"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as Yup from "yup"

interface FormValues {
  email: string
}

const formValidationScheme = Yup.object().shape({
  email: Yup.string().email("Must be a valid email.").required("Required."),
})

interface JoinDialogProps {}

const JoinDialog = (props: JoinDialogProps) => {
  const {} = props;
  const authDialogs = useAuthDialog()
  const conventionIdToRegister = authDialogs.payload?.conventionIdToRegister
  const auth = useAuth()
  const form = useForm<FormValues>({
    resolver: yupResolver(formValidationScheme),
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  })

  const preamble = authDialogs.payload?.preamble ?? "Join from the unique link in your confirmation email. If you need it, we can send you a new one!";
  const dialogHeader = authDialogs.payload?.dialogHeader ?? 'Join'

  const onEmailSubmit = async (formValues: FormValues) => {
    const response = await auth.checkIfEmailRegistered(formValues)
    if (response instanceof Error) return

    const { email } = formValues
    const { need, registrationToken } = response.data.attributes || {}

    if (need === AuthEmail.PASSWORD) {
      authDialogs.set(AuthDialog.SIGN_IN, {
        email,
        conventionIdToRegister,
        redirectUrl: authDialogs.payload?.redirectUrl,
      })
      return
    }

    if (need === AuthEmail.COMPLETE_PROFILE) {
      authDialogs.set(AuthDialog.SIGN_UP_NEW, {
        registrationToken,
        conventionIdToRegister,
        redirectUrl: authDialogs.payload?.redirectUrl,
      })
      return
    }

    if (need === AuthEmail.NOT_FOUND) {
      authDialogs.set(AuthDialog.SIGN_UP_WITHOUT_PASSWORD, {
        email,
        conventionIdToRegister,
        redirectUrl: authDialogs.payload?.redirectUrl,
      })
      return
    }

    if (need === AuthEmail.LINK) {
      const returnUrl = new URL(window.location.href)
      conventionIdToRegister &&
        returnUrl.searchParams.set("conventionIdToRegister", conventionIdToRegister)
      await auth.sendMagicLink({
        email,
        returnUrl: returnUrl.toString(),
      })
      authDialogs.reset()
      return
    }
  }

  const handleClose = () => {
    authDialogs.reset()
  }

  const handleRegister = () => {
    authDialogs.set(AuthDialog.SIGN_UP_WITHOUT_PASSWORD, { conventionIdToRegister })
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title={dialogHeader}
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <_Form onSubmit={form.handleSubmit(onEmailSubmit)}>
          {preamble && (
            <Typography color="gray.darker" component="p">
              {preamble}
            </Typography>
          )}
          <Controller
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                variant="filled"
                placeholder="Enter your email"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
            name="email"
          />
          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Continue
          </LoadingButton>
        </_Form>
        <_RegisterTextContainer>
          <span>Not yet registered?</span>
          <_RegisterButton size="small" onClick={handleRegister}>
            Register Now
          </_RegisterButton>
        </_RegisterTextContainer>
      </_DialogContent>
    </Dialog>
  )
}

export default JoinDialog

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))

const _RegisterTextContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  color: theme.palette.gray.darker,
  ...theme.typography.paragraphRegular,
})) as typeof Typography

const _RegisterButton = styled(Button)(({ theme }) => ({
  ...theme.typography.paragraphRegular,
  textTransform: "capitalize",
}))
