import ChatWindow from "./ChatWindow"
import CustomMessageInput from "./CustomMessageInput"
import Empty from "./Empty"
import Header from "./Header"
import List from "./List"
import Preview from "./Preview"
import UnavailableMessage from "./UnavailableMessage"
import useCurrent from "lib/use-current"
import { useContext, Dispatch, SetStateAction } from "react"
import { Channel, ChannelList, MessageInput, MessageList, ChatContext } from "stream-chat-react"
import { EmojiPicker } from 'stream-chat-react/emojis';

export interface IStreamChat {
  setChannelView: Dispatch<SetStateAction<boolean>>
  customActiveChannel: string
  setCustomActiveChannel: Dispatch<SetStateAction<string>>
  creating: boolean
}

const StreamChat = ({
  setChannelView,
  customActiveChannel,
  setCustomActiveChannel,
  creating,
}: IStreamChat) => {
  const current = useCurrent()

  const chatContext = useContext(ChatContext)
  const setActiveChannel = chatContext ? chatContext.setActiveChannel : () => null

  // @ts-ignore PT - #180597146
  const VDList = (props) => <List creating={creating} {...props} />
  // @ts-ignore PT - #180597146
  const VDPreview = (props) => (
    <Preview
      customActiveChannel={customActiveChannel}
      setOpen={setChannelView}
      setCustomActiveChannel={setCustomActiveChannel}
      {...props}
    />
  )

  const filters = {
    // @ts-ignore
    type: current.streamToken.cu_i_channel_type,
    // @ts-ignore
    members: { $in: [current.streamToken.handle] },
  }

  return (
    <>
      <ChannelList
        filters={filters}
        EmptyStateIndicator={Empty}
        List={VDList}
        Preview={VDPreview}
        setActiveChannelOnMount={false}
      />
      <Channel acceptedFiles={[]} maxNumberOfFiles={0} EmojiPicker={EmojiPicker}>
        <ChatWindow>
          <Header
            closeChat={() => {
              setCustomActiveChannel("")
              setChannelView(false)
              setActiveChannel()
            }}
          />
          <MessageList messageActions={[]} />
          <UnavailableMessage />
          <MessageInput Input={CustomMessageInput} disableMentions={true} noFiles={true} />
        </ChatWindow>
      </Channel>
    </>
  )
}

export default StreamChat
