export const palette = {
  content: {
    brandDefault: "#E75440",
    actionDefault: "#0470C8",
    actionDefaultRgbObject: {
      red: 231,
      green: 84,
      blue: 64,
    },
    neutralPrimary: "#556064",
    neutralPrimaryInversed: "#ffffff",
    neutralSecondary: "#677888",
    neutralTertiary: "#556064",
    neutralStroke: "#D9D9D9",
    alertInformative: "#2170DA",
    alertNegative: "#DC4242",
    alertPositive: "#1B7843",
    alertWarning: "#9E5F00",
  },
  backgrounds: {
    brandAccent: "#F1DFD4",
    actionDark: "#007163",
    ground: "#FFFFFF",
    underground: "#FAF7F7",
    underwater: "#F2F2F3",
    overground: "#FFFFFF",
    alertInfo: "#FFF1C1",
    alertPositive: "#CBF7E2",
    alertWarning: "#FFF1C1",
    alertNegative: "#FFE2E2",
    highlightLight: "#FFEBDF",
    highlightModerate: "#FFEBDF",
    alertError: "#ffa3a3",
  },
  system: {
    green: "rgba(52, 199, 89, 1)",
    black: "#000000",
    white: "#FFFFFF",
  },
}
