import { localTimezone } from "app-constants/constants"
import isNil from "lib/isNil"
import memoize from "lib/memoize"
import moment from "moment-timezone"

export default {
  boolean: memoize(function () {
    return {
      deserialize(value: any) {
        return isNil(value) ? null : Boolean(value)
      },
      serialize(value: any) {
        return isNil(value) ? null : Boolean(value)
      },
    }
  }),
  moment: memoize(function () {
    return {
      deserialize(value: any) {
        return isNil(value) ? null : moment(value).tz(localTimezone)
      },
      serialize(value: any) {
        return isNil(value) ? null : moment(value).toISOString()
      },
    }
  }),
  number: memoize(function () {
    return {
      deserialize(value: any) {
        return isNil(value) ? null : Number(value)
      },
      serialize(value: any) {
        return isNil(value) ? null : Number(value)
      },
    }
  }),
  string: memoize(function () {
    return {
      deserialize(value: any) {
        return isNil(value) ? null : String(value)
      },
      serialize(value: any) {
        return isNil(value) ? null : String(value)
      },
    }
  }),
}
