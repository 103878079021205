const loggers = {}

function getLogger(level: string) {
  // @ts-ignore PT - #180597146
  if (!loggers[level]) {
    if (level === "info") {
      // @ts-ignore PT - #180597146
      // eslint-disable-next-line no-console
      loggers[level] = console.log.bind(console)
    } else if (["debug", "warn", "error"].includes(level)) {
      // @ts-ignore PT - #180597146
      // eslint-disable-next-line no-console
      loggers[level] = console[level].bind(console)
    } else {
      throw new Error(`invalid logger level: ${level}`)
    }
  }
  // @ts-ignore PT - #180597146
  return loggers[level]
}

function log(level: string, targetMode: string, message: any) {
  const mode = typeof window === "undefined" ? "server" : "browser"
  if (targetMode && mode !== targetMode) {
    return
  }

  const logger = getLogger(level)
  if (typeof message === "function") {
    const promiseOrMessage = message()
    if (promiseOrMessage.then) {
      promiseOrMessage.then((message: any) => logger(message))
    } else {
      logger(promiseOrMessage)
    }
  } else {
    logger(message)
  }
}

export default {
  // @ts-ignore
  debug: (message: any) => log("debug", null, message),
  // @ts-ignore
  info: (message: any) => log("info", null, message),
  // @ts-ignore
  warn: (message: any) => log("warn", null, message),
  // @ts-ignore
  error: (message: any) => log("error", null, message),
  browser: {
    debug: (message: any) => log("debug", "browser", message),
    info: (message: any) => log("info", "browser", message),
    warn: (message: any) => log("warn", "browser", message),
    error: (message: any) => log("error", "browser", message),
  },
  server: {
    debug: (message: any) => log("debug", "server", message),
    info: (message: any) => log("info", "server", message),
    warn: (message: any) => log("warn", "server", message),
    error: (message: any) => log("error", "server", message),
  },
}
