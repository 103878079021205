import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui-compat"
import React, { useState, useEffect } from "react"

const useStyles = makeStyles()(
  (theme) => ({
    box: {
      width: "40px",
      height: "40px",
      flexBasis: "40px",
      lineHeight: "40px",
      fontSize: "10px",
      marginRight: "14px",
    },
    avatar: {
      width: "40px",
      height: "40px",
      flexBasis: "40px",
      objectFit: "cover",
    },
    avatarFallback: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      color: "#FFFFFF",
      fontFamily: "Avenir",
      fontWeight: 900,
      letterSpacing: 0,
    },
  })
)

const VDAvatar = (props: any) => {
  const { name, image } = props

  const [error, setError] = useState(false)

  const { classes } = useStyles()

  useEffect(() => {
    setError(false)
  }, [image])

  const word = name || "Unnamed Chat"
  const initials =
    word.substring(0, 1).toUpperCase() +
    word
      .substring(1, word.length)
      .replace(/[a-z,\s]/g, "")
      .substring(0, 3)

  return (
    <Box data-testid="avatar" className={classes.box} title={name || "Unnamed Chat"}>
      {image && !error ? (
        <Avatar
          data-testid="avatar-img"
          src={image}
          alt={name || "Unnamed Chat"}
          className={classes.avatar}
          onError={() => setError(true)}
        />
      ) : (
        <Box data-testid="avatar-fallback" className={classes.avatarFallback}>
          {initials}
        </Box>
      )}
    </Box>
  )
}

export default VDAvatar
