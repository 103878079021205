import sendConventionApiRequest from "lib/send-convention-api-request"

// @ts-ignore PT - #180597146
export default async function getProviderConfig({ current }) {
  const { response, json } = await sendConventionApiRequest({
    current,
    method: "GET",
    url: `v1/convention_providers/${current.provider.id}/config`,
  })
  if (response.status === 404) {
    return null
  }

  const baseConfig = {
    id: json.data.id,
    ...json.data.attributes,
  }

  const { app } = baseConfig
  const { provider } = current

  if (app) {
    // should be removed from here as they get added to dynamic config payload, should work mostly as a drop-in replacement
    const additionalConfig =
      app === "nacac"
        ? {
            app_name: "NACAC Fairs",
            app_logo_url: "/assets/images/nacac-fairs.svg",
            app_favicon_url: "/assets/images/nacac-favicon.svg",
          }
        : {
            app_name: "VisitDays Conventions",
            app_logo_url: "/assets/images/visitdays-primary-logo.svg",
            app_favicon_url: "/assets/images/visitdays-square-large.png",
          }

    if (provider.favicon_url) {
      additionalConfig.app_favicon_url = provider.favicon_url
    }

    return {
      ...baseConfig,
      ...additionalConfig,
      ...provider,
    }
  }

  return baseConfig
}
