import Empty from "./Empty"
import ListWrapper from "./ListWrapper"
import StreamChat from "./StreamChat"
import { IStreamChat } from "./StreamChat"
import useChat from "hooks/useChat"

export const StreamChatSelector = (props: IStreamChat) => {
  const { status } = useChat()

  if (status === "nochats") {
    return (
      <div className="str-chat str-chat-channel-list messaging light">
        <ListWrapper>
          <Empty />
        </ListWrapper>
      </div>
    )
  }

  return <StreamChat {...props} />
}
