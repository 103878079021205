/* This list is sourced from ISO 3166
The purpose of ISO 3166 is to define internationally recognized codes of letters and/or numbers 
that we can use when we refer to countries and their subdivisions. However, it does not define 
the names of countries – this information comes from United Nations sources (Terminology Bulletin 
Country Names and the Country and Region Codes for Statistical Use maintained by the United Nations
Statistics Divisions).

See https://www.iso.org/iso-3166-country-codes.html for more information
*/

const countries = {
  AF: {
    name: "Afghanistan",
    subdivisions: [
      {
        code: "AF-BDS",
        alpha2code: "BDS",
        name: "Badakhshān",
      },
      {
        code: "AF-BDG",
        alpha2code: "BDG",
        name: "Bādghīs",
      },
      {
        code: "AF-BGL",
        alpha2code: "BGL",
        name: "Baghlān",
      },
      {
        code: "AF-BAL",
        alpha2code: "BAL",
        name: "Balkh",
      },
      {
        code: "AF-BAM",
        alpha2code: "BAM",
        name: "Bāmīān",
      },
      {
        code: "AF-FRA",
        alpha2code: "FRA",
        name: "Farāh",
      },
      {
        code: "AF-FYB",
        alpha2code: "FYB",
        name: "Fāryāb",
      },
      {
        code: "AF-GHA",
        alpha2code: "GHA",
        name: "Ghaznī",
      },
      {
        code: "AF-GHO",
        alpha2code: "GHO",
        name: "Ghowr",
      },
      {
        code: "AF-HEL",
        alpha2code: "HEL",
        name: "Helmand",
      },
      {
        code: "AF-HER",
        alpha2code: "HER",
        name: "Herāt",
      },
      {
        code: "AF-JOW",
        alpha2code: "JOW",
        name: "Jowzjān",
      },
      {
        code: "AF-KAB",
        alpha2code: "KAB",
        name: "Kabul (Kābol)",
      },
      {
        code: "AF-KAN",
        alpha2code: "KAN",
        name: "Kandahār",
      },
      {
        code: "AF-KAP",
        alpha2code: "KAP",
        name: "Kāpīsā",
      },
      {
        code: "AF-KNR",
        alpha2code: "KNR",
        name: "Konar (Kunar)",
      },
      {
        code: "AF-KDZ",
        alpha2code: "KDZ",
        name: "Kondoz (Kunduz)",
      },
      {
        code: "AF-LAG",
        alpha2code: "LAG",
        name: "Laghmān",
      },
      {
        code: "AF-LOW",
        alpha2code: "LOW",
        name: "Lowgar",
      },
      {
        code: "AF-NAN",
        alpha2code: "NAN",
        name: "Nangrahār (Nangarhār)",
      },
      {
        code: "AF-NIM",
        alpha2code: "NIM",
        name: "Nīmrūz",
      },
      {
        code: "AF-ORU",
        alpha2code: "ORU",
        name: "Orūzgān (Urūzgā",
      },
      {
        code: "AF-PIA",
        alpha2code: "PIA",
        name: "Paktīā",
      },
      {
        code: "AF-PKA",
        alpha2code: "PKA",
        name: "Paktīkā",
      },
      {
        code: "AF-PAR",
        alpha2code: "PAR",
        name: "Parwān",
      },
      {
        code: "AF-SAM",
        alpha2code: "SAM",
        name: "Samangān",
      },
      {
        code: "AF-SAR",
        alpha2code: "SAR",
        name: "Sar-e Pol",
      },
      {
        code: "AF-TAK",
        alpha2code: "TAK",
        name: "Takhār",
      },
      {
        code: "AF-WAR",
        alpha2code: "WAR",
        name: "Wardak (Wardag)",
      },
      {
        code: "AF-ZAB",
        alpha2code: "ZAB",
        name: "Zābol (Zābul)",
      },
    ],
  },
  AL: {
    name: "Albania",
    subdivisions: [
      {
        code: "AL-BR",
        alpha2code: "BR",
        name: "Berat",
      },
      {
        code: "AL-BU",
        alpha2code: "BU",
        name: "Bulqizë",
      },
      {
        code: "AL-DL",
        alpha2code: "DL",
        name: "Delvinë",
      },
      {
        code: "AL-DV",
        alpha2code: "DV",
        name: "Devoll",
      },
      {
        code: "AL-DI",
        alpha2code: "DI",
        name: "Dibër",
      },
      {
        code: "AL-DR",
        alpha2code: "DR",
        name: "Durrës",
      },
      {
        code: "AL-EL",
        alpha2code: "EL",
        name: "Elbasan",
      },
      {
        code: "AL-FR",
        alpha2code: "FR",
        name: "Fier",
      },
      {
        code: "AL-GR",
        alpha2code: "GR",
        name: "Gramsh",
      },
      {
        code: "AL-GJ",
        alpha2code: "GJ",
        name: "Gjirokastër",
      },
      {
        code: "AL-HA",
        alpha2code: "HA",
        name: "Has",
      },
      {
        code: "AL-KA",
        alpha2code: "KA",
        name: "Kavajë",
      },
      {
        code: "AL-ER",
        alpha2code: "ER",
        name: "Kolonjë",
      },
      {
        code: "AL-KO",
        alpha2code: "KO",
        name: "Korcë",
      },
      {
        code: "AL-KR",
        alpha2code: "KR",
        name: "Krujë",
      },
      {
        code: "AL-KC",
        alpha2code: "KC",
        name: "Kucovë",
      },
      {
        code: "AL-KU",
        alpha2code: "KU",
        name: "Kukës",
      },
      {
        code: "AL-LA",
        alpha2code: "LA",
        name: "Laç",
      },
      {
        code: "AL-LE",
        alpha2code: "LE",
        name: "Lezhë",
      },
      {
        code: "AL-LB",
        alpha2code: "LB",
        name: "Librazhd",
      },
      {
        code: "AL-LU",
        alpha2code: "LU",
        name: "Lushnjë",
      },
      {
        code: "AL-MM",
        alpha2code: "MM",
        name: "Malësia e Madhe",
      },
      {
        code: "AL-MK",
        alpha2code: "MK",
        name: "Mallakastër",
      },
      {
        code: "AL-MT",
        alpha2code: "MT",
        name: "Mat",
      },
      {
        code: "AL-MR",
        alpha2code: "MR",
        name: "Mirditë",
      },
      {
        code: "AL-PQ",
        alpha2code: "PQ",
        name: "Peqin",
      },
      {
        code: "AL-PR",
        alpha2code: "PR",
        name: "Përmet",
      },
      {
        code: "AL-PG",
        alpha2code: "PG",
        name: "Pogradec",
      },
      {
        code: "AL-PU",
        alpha2code: "PU",
        name: "Pukë",
      },
      {
        code: "AL-SR",
        alpha2code: "SR",
        name: "Sarandë",
      },
      {
        code: "AL-SK",
        alpha2code: "SK",
        name: "Skrapar",
      },
      {
        code: "AL-SH",
        alpha2code: "SH",
        name: "Shkodër",
      },
      {
        code: "AL-TE",
        alpha2code: "TE",
        name: "Tepelenë",
      },
      {
        code: "AL-TR",
        alpha2code: "TR",
        name: "Tiranë",
      },
      {
        code: "AL-TP",
        alpha2code: "TP",
        name: "Tropojë",
      },
      {
        code: "AL-VL",
        alpha2code: "VL",
        name: "Vlorë",
      },
    ],
  },
  DZ: {
    name: "Algeria",
    subdivisions: [
      {
        code: "DZ-01",
        alpha2code: "01",
        name: "Adrar",
      },
      {
        code: "DZ-44",
        alpha2code: "44",
        name: "Aïn Defla",
      },
      {
        code: "DZ-46",
        alpha2code: "46",
        name: "Aïn Témouchent",
      },
      {
        code: "DZ-16",
        alpha2code: "16",
        name: "Alger",
      },
      {
        code: "DZ-05",
        alpha2code: "05",
        name: "Batna",
      },
      {
        code: "DZ-08",
        alpha2code: "08",
        name: "Béchar",
      },
      {
        code: "DZ-06",
        alpha2code: "06",
        name: "Béjaïa",
      },
      {
        code: "DZ-07",
        alpha2code: "07",
        name: "Biskra",
      },
      {
        code: "DZ-09",
        alpha2code: "09",
        name: "Blida",
      },
      {
        code: "DZ-34",
        alpha2code: "34",
        name: "Bordj Bou Arréridj",
      },
      {
        code: "DZ-10",
        alpha2code: "10",
        name: "Bouira",
      },
      {
        code: "DZ-35",
        alpha2code: "35",
        name: "Boumerdès",
      },
      {
        code: "DZ-02",
        alpha2code: "02",
        name: "Chlef",
      },
      {
        code: "DZ-25",
        alpha2code: "25",
        name: "Constantine",
      },
      {
        code: "DZ-17",
        alpha2code: "17",
        name: "Djelfa",
      },
      {
        code: "DZ-32",
        alpha2code: "32",
        name: "El Bayadh",
      },
      {
        code: "DZ-39",
        alpha2code: "39",
        name: "El Oued",
      },
      {
        code: "DZ-36",
        alpha2code: "36",
        name: "El Tarf",
      },
      {
        code: "DZ-47",
        alpha2code: "47",
        name: "Ghardaïa",
      },
      {
        code: "DZ-24",
        alpha2code: "24",
        name: "Guelma",
      },
      {
        code: "DZ-33",
        alpha2code: "33",
        name: "Illizi",
      },
      {
        code: "DZ-18",
        alpha2code: "18",
        name: "Jijel",
      },
      {
        code: "DZ-40",
        alpha2code: "40",
        name: "Khenchela",
      },
      {
        code: "DZ-03",
        alpha2code: "03",
        name: "Laghouat",
      },
      {
        code: "DZ-29",
        alpha2code: "29",
        name: "Mascara",
      },
      {
        code: "DZ-26",
        alpha2code: "26",
        name: "Médéa",
      },
      {
        code: "DZ-43",
        alpha2code: "43",
        name: "Mila",
      },
      {
        code: "DZ-27",
        alpha2code: "27",
        name: "Mostaganem",
      },
      {
        code: "DZ-28",
        alpha2code: "28",
        name: "Msila",
      },
      {
        code: "DZ-45",
        alpha2code: "45",
        name: "Naama",
      },
      {
        code: "DZ-31",
        alpha2code: "31",
        name: "Oran",
      },
      {
        code: "DZ-30",
        alpha2code: "30",
        name: "Ouargla",
      },
      {
        code: "DZ-04",
        alpha2code: "04",
        name: "Oum el Bouaghi",
      },
      {
        code: "DZ-48",
        alpha2code: "48",
        name: "Relizane",
      },
      {
        code: "DZ-20",
        alpha2code: "20",
        name: "Saïda",
      },
      {
        code: "DZ-19",
        alpha2code: "19",
        name: "Sétif",
      },
      {
        code: "DZ-22",
        alpha2code: "22",
        name: "Sidi Bel Abbès",
      },
      {
        code: "DZ-21",
        alpha2code: "21",
        name: "Skikda",
      },
      {
        code: "DZ-41",
        alpha2code: "41",
        name: "Souk Ahras",
      },
      {
        code: "DZ-11",
        alpha2code: "11",
        name: "Tamanghasset",
      },
      {
        code: "DZ-12",
        alpha2code: "12",
        name: "Tébessa",
      },
      {
        code: "DZ-14",
        alpha2code: "14",
        name: "Tiaret",
      },
      {
        code: "DZ-37",
        alpha2code: "37",
        name: "Tindouf",
      },
      {
        code: "DZ-42",
        alpha2code: "42",
        name: "Tipaza",
      },
      {
        code: "DZ-38",
        alpha2code: "38",
        name: "Tissemsilt",
      },
      {
        code: "DZ-15",
        alpha2code: "15",
        name: "Tizi Ouzou",
      },
      {
        code: "DZ-13",
        alpha2code: "13",
        name: "Tlemcen",
      },
    ],
  },
  AS: {
    name: "American Samoa",
    subdivisions: [
      {
        code: "AS-AS",
        alpha2code: "AS",
        name: "American Samoa",
      },
    ],
  },
  AD: {
    name: "Andorra",
    subdivisions: [
      {
        code: "AD-AD",
        alpha2code: "AD",
        name: "Andorra",
      },
    ],
  },
  AO: {
    name: "Angola",
    subdivisions: [
      {
        code: "AO-BGO",
        alpha2code: "BGO",
        name: "Bengo",
      },
      {
        code: "AO-BGU",
        alpha2code: "BGU",
        name: "Benguela",
      },
      {
        code: "AO-BIE",
        alpha2code: "BIE",
        name: "Bié",
      },
      {
        code: "AO-CAB",
        alpha2code: "CAB",
        name: "Cabinda",
      },
      {
        code: "AO-CCU",
        alpha2code: "CCU",
        name: "Cuando-Cubango",
      },
      {
        code: "AO-CNO",
        alpha2code: "CNO",
        name: "Cuanza Norte",
      },
      {
        code: "AO-CUS",
        alpha2code: "CUS",
        name: "Cuanza Sul",
      },
      {
        code: "AO-CNN",
        alpha2code: "CNN",
        name: "Cunene",
      },
      {
        code: "AO-HUA",
        alpha2code: "HUA",
        name: "Huambo",
      },
      {
        code: "AO-HUI",
        alpha2code: "HUI",
        name: "Huíla",
      },
      {
        code: "AO-LUA",
        alpha2code: "LUA",
        name: "Luanda",
      },
      {
        code: "AO-LNO",
        alpha2code: "LNO",
        name: "Lunda Norte",
      },
      {
        code: "AO-LSU",
        alpha2code: "LSU",
        name: "Lunda Sul",
      },
      {
        code: "AO-MAL",
        alpha2code: "MAL",
        name: "Malange",
      },
      {
        code: "AO-MOX",
        alpha2code: "MOX",
        name: "Moxico",
      },
      {
        code: "AO-NAM",
        alpha2code: "NAM",
        name: "Namibe",
      },
      {
        code: "AO-UIG",
        alpha2code: "UIG",
        name: "Uíge",
      },
      {
        code: "AO-ZAI",
        alpha2code: "ZAI",
        name: "Zaïre",
      },
    ],
  },
  AI: {
    name: "Anguilla",
    subdivisions: [
      {
        code: "AI-AI",
        alpha2code: "AI",
        name: "Anguilla",
      },
    ],
  },
  AQ: {
    name: "Antarctica",
    subdivisions: [
      {
        code: "AQ-AQ",
        alpha2code: "AQ",
        name: "Antarctica",
      },
    ],
  },
  AG: {
    name: "Antigua & Barbuda",
    subdivisions: [
      {
        code: "AG-AG",
        alpha2code: "AG",
        name: "Antigua & Barbuda",
      },
    ],
  },
  AR: {
    name: "Argentina",
    subdivisions: [
      {
        code: "AR-C",
        alpha2code: "C",
        name: "Capital federal",
      },
      {
        code: "AR-B",
        alpha2code: "B",
        name: "Buenos Aires",
      },
      {
        code: "AR-K",
        alpha2code: "K",
        name: "Catamarca",
      },
      {
        code: "AR-X",
        alpha2code: "X",
        name: "Córdoba",
      },
      {
        code: "AR-W",
        alpha2code: "W",
        name: "Corrientes",
      },
      {
        code: "AR-H",
        alpha2code: "H",
        name: "Chaco",
      },
      {
        code: "AR-U",
        alpha2code: "U",
        name: "Chubut",
      },
      {
        code: "AR-E",
        alpha2code: "E",
        name: "Entre Ríos",
      },
      {
        code: "AR-P",
        alpha2code: "P",
        name: "Formosa",
      },
      {
        code: "AR-Y",
        alpha2code: "Y",
        name: "Jujuy",
      },
      {
        code: "AR-L",
        alpha2code: "L",
        name: "La Pampa",
      },
      {
        code: "AR-F",
        alpha2code: "F",
        name: "La Rioja",
      },
      {
        code: "AR-M",
        alpha2code: "M",
        name: "Mendoza",
      },
      {
        code: "AR-N",
        alpha2code: "N",
        name: "Misiones",
      },
      {
        code: "AR-Q",
        alpha2code: "Q",
        name: "Neuquén",
      },
      {
        code: "AR-R",
        alpha2code: "R",
        name: "Río Negro",
      },
      {
        code: "AR-A",
        alpha2code: "A",
        name: "Salta",
      },
      {
        code: "AR-J",
        alpha2code: "J",
        name: "San Juan",
      },
      {
        code: "AR-D",
        alpha2code: "D",
        name: "San Luis",
      },
      {
        code: "AR-Z",
        alpha2code: "Z",
        name: "Santa Cruz",
      },
      {
        code: "AR-S",
        alpha2code: "S",
        name: "Santa Fe",
      },
      {
        code: "AR-G",
        alpha2code: "G",
        name: "Santiago del Estero",
      },
      {
        code: "AR-V",
        alpha2code: "V",
        name: "Tierra del Fuego",
      },
      {
        code: "AR-T",
        alpha2code: "T",
        name: "Tucumán",
      },
    ],
  },
  AM: {
    name: "Armenia",
    subdivisions: [
      {
        code: "AM-ER",
        alpha2code: "ER",
        name: "Erevan",
      },
      {
        code: "AM-AG",
        alpha2code: "AG",
        name: "Aragacotn",
      },
      {
        code: "AM-AR",
        alpha2code: "AR",
        name: "Ararat",
      },
      {
        code: "AM-AV",
        alpha2code: "AV",
        name: "Armavir",
      },
      {
        code: "AM-GR",
        alpha2code: "GR",
        name: "Geģark'unik'",
      },
      {
        code: "AM-KT",
        alpha2code: "KT",
        name: "Kotayk'",
      },
      {
        code: "AM-LO",
        alpha2code: "LO",
        name: "Loŕy",
      },
      {
        code: "AM-SH",
        alpha2code: "SH",
        name: "Širak",
      },
      {
        code: "AM-SU",
        alpha2code: "SU",
        name: "Syunik'",
      },
      {
        code: "AM-TV",
        alpha2code: "TV",
        name: "Tavuš",
      },
      {
        code: "AM-VD",
        alpha2code: "VD",
        name: "Vayoc Jor",
      },
    ],
  },
  AW: {
    name: "Aruba",
    subdivisions: [
      {
        code: "AW-AW",
        alpha2code: "AW",
        name: "Aruba",
      },
    ],
  },
  AU: {
    name: "Australia",
    subdivisions: [
      {
        code: "AU-NSW",
        alpha2code: "NSW",
        name: "New South Wales",
      },
      {
        code: "AU-QLD",
        alpha2code: "QLD",
        name: "Queensland",
      },
      {
        code: "AU-SA",
        alpha2code: "SA",
        name: "South Australia",
      },
      {
        code: "AU-TAS",
        alpha2code: "TAS",
        name: "Tasmania",
      },
      {
        code: "AU-VIC",
        alpha2code: "VIC",
        name: "Victoria",
      },
      {
        code: "AU-WA",
        alpha2code: "WA",
        name: "Western Australia",
      },
      {
        code: "AU-ACT",
        alpha2code: "ACT",
        name: "Australian Capital Territory",
      },
      {
        code: "AU-NT",
        alpha2code: "NT",
        name: "Northern Territory",
      },
    ],
  },
  AT: {
    name: "Austria",
    subdivisions: [
      {
        code: "AT-1",
        alpha2code: "1",
        name: "Burgenland",
      },
      {
        code: "AT-2",
        alpha2code: "2",
        name: "Kärnten",
      },
      {
        code: "AT-3",
        alpha2code: "3",
        name: "Niederösterreich",
      },
      {
        code: "AT-4",
        alpha2code: "4",
        name: "Oberösterreich",
      },
      {
        code: "AT-5",
        alpha2code: "5",
        name: "Salzburg",
      },
      {
        code: "AT-6",
        alpha2code: "6",
        name: "Steiermark",
      },
      {
        code: "AT-7",
        alpha2code: "7",
        name: "Tirol",
      },
      {
        code: "AT-8",
        alpha2code: "8",
        name: "Vorarlberg",
      },
      {
        code: "AT-9",
        alpha2code: "9",
        name: "Wien",
      },
    ],
  },
  AZ: {
    name: "Azerbaijan",
    subdivisions: [
      {
        code: "AZ-MM",
        alpha2code: "MM",
        name: "Naxçivan",
      },
      {
        code: "AZ-AB",
        alpha2code: "AB",
        name: "Äli Bayramli",
      },
      {
        code: "AZ-BA",
        alpha2code: "BA",
        name: "Baki",
      },
      {
        code: "AZ-GA",
        alpha2code: "GA",
        name: "Gäncä",
      },
      {
        code: "AZ-LA",
        alpha2code: "LA",
        name: "Länkäran",
      },
      {
        code: "AZ-MI",
        alpha2code: "MI",
        name: "Mingäçevir",
      },
      {
        code: "AZ-NA",
        alpha2code: "NA",
        name: "Naftalan",
      },
      {
        code: "AZ-SA",
        alpha2code: "SA",
        name: "Şäki",
      },
      {
        code: "AZ-SM",
        alpha2code: "SM",
        name: "Sumqayit",
      },
      {
        code: "AZ-SS",
        alpha2code: "SS",
        name: "Şuşa",
      },
      {
        code: "AZ-XA",
        alpha2code: "XA",
        name: "Xankändi",
      },
      {
        code: "AZ-YE",
        alpha2code: "YE",
        name: "Yevlax",
      },
      {
        code: "AZ-ABS",
        alpha2code: "ABS",
        name: "Abşeron",
      },
      {
        code: "AZ-AGC",
        alpha2code: "AGC",
        name: "Ağcabädi",
      },
      {
        code: "AZ-AGM",
        alpha2code: "AGM",
        name: "Ağdam",
      },
      {
        code: "AZ-AGS",
        alpha2code: "AGS",
        name: "Ağdas",
      },
      {
        code: "AZ-AGA",
        alpha2code: "AGA",
        name: "Ağstafa",
      },
      {
        code: "AZ-AGU",
        alpha2code: "AGU",
        name: "Ağsu",
      },
      {
        code: "AZ-AST",
        alpha2code: "AST",
        name: "Astara",
      },
      {
        code: "AZ-BAB",
        alpha2code: "BAB",
        name: "Babäk",
      },
      {
        code: "AZ-BAL",
        alpha2code: "BAL",
        name: "Balakän",
      },
      {
        code: "AZ-BAR",
        alpha2code: "BAR",
        name: "Bärdä",
      },
      {
        code: "AZ-BEY",
        alpha2code: "BEY",
        name: "Beyläqan",
      },
      {
        code: "AZ-BIL",
        alpha2code: "BIL",
        name: "Biläsuvar",
      },
      {
        code: "AZ-CAB",
        alpha2code: "CAB",
        name: "Cäbrayil",
      },
      {
        code: "AZ-CAL",
        alpha2code: "CAL",
        name: "Cälilabad",
      },
      {
        code: "AZ-CUL",
        alpha2code: "CUL",
        name: "Culfa",
      },
      {
        code: "AZ-DAS",
        alpha2code: "DAS",
        name: "Daşkäsän",
      },
      {
        code: "AZ-DAV",
        alpha2code: "DAV",
        name: "Däväçi",
      },
      {
        code: "AZ-FUZ",
        alpha2code: "FUZ",
        name: "Füzuli",
      },
      {
        code: "AZ-GAD",
        alpha2code: "GAD",
        name: "Gädäbäy",
      },
      {
        code: "AZ-GOR",
        alpha2code: "GOR",
        name: "Goranboy",
      },
      {
        code: "AZ-GOY",
        alpha2code: "GOY",
        name: "Göyçay",
      },
      {
        code: "AZ-HAC",
        alpha2code: "HAC",
        name: "Haciqabul",
      },
      {
        code: "AZ-IMI",
        alpha2code: "IMI",
        name: "Imişli",
      },
      {
        code: "AZ-ISM",
        alpha2code: "ISM",
        name: "Ismayilli",
      },
      {
        code: "AZ-KAL",
        alpha2code: "KAL",
        name: "Kälbäcär",
      },
      {
        code: "AZ-KUR",
        alpha2code: "KUR",
        name: "Kürdämir",
      },
      {
        code: "AZ-LAC",
        alpha2code: "LAC",
        name: "Laçin",
      },
      {
        code: "AZ-LAN",
        alpha2code: "LAN",
        name: "Länkäran",
      },
      {
        code: "AZ-LER",
        alpha2code: "LER",
        name: "Lerik",
      },
      {
        code: "AZ-MAS",
        alpha2code: "MAS",
        name: "Masalli",
      },
      {
        code: "AZ-NEF",
        alpha2code: "NEF",
        name: "Neftçala",
      },
      {
        code: "AZ-OGU",
        alpha2code: "OGU",
        name: "Oğuz",
      },
      {
        code: "AZ-ORD",
        alpha2code: "ORD",
        name: "Ordubad",
      },
      {
        code: "AZ-QAB",
        alpha2code: "QAB",
        name: "Qäbälä",
      },
      {
        code: "AZ-QAX",
        alpha2code: "QAX",
        name: "Qax",
      },
      {
        code: "AZ-QAZ",
        alpha2code: "QAZ",
        name: "Qazax",
      },
      {
        code: "AZ-QOB",
        alpha2code: "QOB",
        name: "Qobustan",
      },
      {
        code: "AZ-QBA",
        alpha2code: "QBA",
        name: "Quba",
      },
      {
        code: "AZ-QBI",
        alpha2code: "QBI",
        name: "Qubadlı",
      },
      {
        code: "AZ-QUS",
        alpha2code: "QUS",
        name: "Qusar",
      },
      {
        code: "AZ-SAT",
        alpha2code: "SAT",
        name: "Saatli",
      },
      {
        code: "AZ-SAB",
        alpha2code: "SAB",
        name: "Sabirabad",
      },
      {
        code: "AZ-SAD",
        alpha2code: "SAD",
        name: "Sädäräk",
      },
      {
        code: "AZ-SAH",
        alpha2code: "SAH",
        name: "Şahbuz",
      },
      {
        code: "AZ-SAK",
        alpha2code: "SAK",
        name: "Şäki",
      },
      {
        code: "AZ-SAL",
        alpha2code: "SAL",
        name: "Salyan",
      },
      {
        code: "AZ-SMI",
        alpha2code: "SMI",
        name: "Şamaxı",
      },
      {
        code: "AZ-SKR",
        alpha2code: "SKR",
        name: "Şämkir",
      },
      {
        code: "AZ-SMX",
        alpha2code: "SMX",
        name: "Samux",
      },
      {
        code: "AZ-SAR",
        alpha2code: "SAR",
        name: "Şärur",
      },
      {
        code: "AZ-SIY",
        alpha2code: "SIY",
        name: "Siyäzän",
      },
      {
        code: "AZ-SUS",
        alpha2code: "SUS",
        name: "Şuşa",
      },
      {
        code: "AZ-TAR",
        alpha2code: "TAR",
        name: "Tärtär",
      },
      {
        code: "AZ-TOV",
        alpha2code: "TOV",
        name: "Tovuz",
      },
      {
        code: "AZ-UCA",
        alpha2code: "UCA",
        name: "Ucar",
      },
      {
        code: "AZ-XAC",
        alpha2code: "XAC",
        name: "Xaçmaz",
      },
      {
        code: "AZ-XAN",
        alpha2code: "XAN",
        name: "Xanlar",
      },
      {
        code: "AZ-XIZ",
        alpha2code: "XIZ",
        name: "Xizi",
      },
      {
        code: "AZ-XCI",
        alpha2code: "XCI",
        name: "Xocalı",
      },
      {
        code: "AZ-XVD",
        alpha2code: "XVD",
        name: "Xocavänd",
      },
      {
        code: "AZ-YAR",
        alpha2code: "YAR",
        name: "Yardimli",
      },
      {
        code: "AZ-YEV",
        alpha2code: "YEV",
        name: "Yevlax",
      },
      {
        code: "AZ-ZAN",
        alpha2code: "ZAN",
        name: "Zängılan",
      },
      {
        code: "AZ-ZAQ",
        alpha2code: "ZAQ",
        name: "Zaqatala",
      },
      {
        code: "AZ-ZAR",
        alpha2code: "ZAR",
        name: "Zärdab",
      },
    ],
  },
  BS: {
    name: "Bahamas",
    subdivisions: [
      {
        code: "BS-AC",
        alpha2code: "AC",
        name: "Acklins and Crooked Islands",
      },
      {
        code: "BS-BI",
        alpha2code: "BI",
        name: "Bimini",
      },
      {
        code: "BS-CI",
        alpha2code: "CI",
        name: "Cat Island",
      },
      {
        code: "BS-EX",
        alpha2code: "EX",
        name: "Exuma",
      },
      {
        code: "BS-FP",
        alpha2code: "FP",
        name: "Freeport",
      },
      {
        code: "BS-FC",
        alpha2code: "FC",
        name: "Fresh Creek",
      },
      {
        code: "BS-GH",
        alpha2code: "GH",
        name: "Governor's Harbour",
      },
      {
        code: "BS-GT",
        alpha2code: "GT",
        name: "Green Turtle Cay",
      },
      {
        code: "BS-HI",
        alpha2code: "HI",
        name: "Harbour Island",
      },
      {
        code: "BS-HR",
        alpha2code: "HR",
        name: "High Rock",
      },
      {
        code: "BS-IN",
        alpha2code: "IN",
        name: "Inagua",
      },
      {
        code: "BS-KB",
        alpha2code: "KB",
        name: "Kemps Bay",
      },
      {
        code: "BS-LI",
        alpha2code: "LI",
        name: "Long Island",
      },
      {
        code: "BS-MH",
        alpha2code: "MH",
        name: "Marsh Harbour",
      },
      {
        code: "BS-MG",
        alpha2code: "MG",
        name: "Mayaguana",
      },
      {
        code: "BS-NP",
        alpha2code: "NP",
        name: "New Providence",
      },
      {
        code: "BS-NB",
        alpha2code: "NB",
        name: "Nicholls Town and Berry Islands",
      },
      {
        code: "BS-RI",
        alpha2code: "RI",
        name: "Ragged Island",
      },
      {
        code: "BS-RS",
        alpha2code: "RS",
        name: "Rock Sound",
      },
      {
        code: "BS-SP",
        alpha2code: "SP",
        name: "Sandy Point",
      },
      {
        code: "BS-SR",
        alpha2code: "SR",
        name: "San Salvador and Rum Cay",
      },
    ],
  },
  BH: {
    name: "Bahrain",
    subdivisions: [
      {
        code: "BH-01",
        alpha2code: "01",
        name: "Al Ḩadd",
      },
      {
        code: "BH-03",
        alpha2code: "03",
        name: "Al Manāmah",
      },
      {
        code: "BH-10",
        alpha2code: "10",
        name: "Al Minţaqah al Gharbīyah",
      },
      {
        code: "BH-07",
        alpha2code: "07",
        name: "Al Minţaqah al Wusţa",
      },
      {
        code: "BH-05",
        alpha2code: "05",
        name: "Al Minţaqah ash Shamālīyah",
      },
      {
        code: "BH-02",
        alpha2code: "02",
        name: "Al Muḩarraq",
      },
      {
        code: "BH-09",
        alpha2code: "09",
        name: "Ar Rifā‘",
      },
      {
        code: "BH-04",
        alpha2code: "04",
        name: "Jidd Ḩafş",
      },
      {
        code: "BH-12",
        alpha2code: "12",
        name: "Madīnat Ḩamad",
      },
      {
        code: "BH-08",
        alpha2code: "08",
        name: "Madīnat ‘Īsá",
      },
      {
        code: "BH-11",
        alpha2code: "11",
        name: "Minţaqat Juzur Ḩawār",
      },
      {
        code: "BH-06",
        alpha2code: "06",
        name: "Sitrah",
      },
    ],
  },
  BD: {
    name: "Bangladesh",
    subdivisions: [
      {
        code: "BD-1",
        alpha2code: "1",
        name: "Barisal bibhag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Barisal anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Patuakhali anchal",
      },
      {
        code: "BD-2",
        alpha2code: "2",
        name: "Chittagong bibhag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bandarban anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chittagong anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chittagong Hill Tracts",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Comilla anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Noakhali anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sylhet anchal",
      },
      {
        code: "BD-3",
        alpha2code: "3",
        name: "Dhaka bibhag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dhaka anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Faridpur anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jamalpur anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mymensingh anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tangail anchal",
      },
      {
        code: "BD-4",
        alpha2code: "4",
        name: "Khulna bibhag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jessore anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Khulna anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Khustia anchal",
      },
      {
        code: "BD-5",
        alpha2code: "5",
        name: "Rajshahi bibhag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bogra anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dinajpur anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pabna anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rajshahi anchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rangpur anchal",
      },
    ],
  },
  BB: {
    name: "Barbados",
    subdivisions: [
      {
        code: "BB-BB",
        alpha2code: "BB",
        name: "Barbados",
      },
    ],
  },
  BY: {
    name: "Belarus",
    subdivisions: [
      {
        code: "BY-BR",
        alpha2code: "BR",
        name: "Brestskaya voblasts'",
      },
      {
        code: "BY-HO",
        alpha2code: "HO",
        name: "Homyel'skaya voblasts’",
      },
      {
        code: "BY-HR",
        alpha2code: "HR",
        name: "Hrodnenskaya voblasts'",
      },
      {
        code: "BY-MA",
        alpha2code: "MA",
        name: "Mahilyowskaya voblasts'",
      },
      {
        code: "BY-MI",
        alpha2code: "MI",
        name: "Minskaya voblasts'",
      },
      {
        code: "BY-VI",
        alpha2code: "VI",
        name: "Vitsyebskaya voblasts'",
      },
    ],
  },
  BE: {
    name: "Belgium",
    subdivisions: [
      {
        code: "BE-BRU",
        alpha2code: "BRU",
        name: "Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)",
      },
      {
        code: "BE-VLG",
        alpha2code: "VLG",
        name: "Vlaamse Gewest (nl)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Antwerpen (nl)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Limburg (nl)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oost-Vlaanderen (nl)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vlaams Brabant (nl)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West-Vlaanderen (nl)",
      },
      {
        code: "BE-WAL",
        alpha2code: "WAL",
        name: "Wallonne, Region (fr)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brabant Wallon (fr)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hainaut (fr)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Liège (fr)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Luxembourg (fr)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Namur (fr)",
      },
    ],
  },
  BZ: {
    name: "Belize",
    subdivisions: [
      {
        code: "BZ-BZ",
        alpha2code: "BZ",
        name: "Belize",
      },
      {
        code: "BZ-CY",
        alpha2code: "CY",
        name: "Cayo",
      },
      {
        code: "BZ-CZL",
        alpha2code: "CZL",
        name: "Corozal",
      },
      {
        code: "BZ-OW",
        alpha2code: "OW",
        name: "Orange Walk",
      },
      {
        code: "BZ-SC",
        alpha2code: "SC",
        name: "Stann Creek",
      },
      {
        code: "BZ-TOL",
        alpha2code: "TOL",
        name: "Toledo",
      },
    ],
  },
  BJ: {
    name: "Benin",
    subdivisions: [
      {
        code: "BJ-AK",
        alpha2code: "AK",
        name: "Atakora",
      },
      {
        code: "BJ-AQ",
        alpha2code: "AQ",
        name: "Atlantique",
      },
      {
        code: "BJ-BO",
        alpha2code: "BO",
        name: "Borgou",
      },
      {
        code: "BJ-MO",
        alpha2code: "MO",
        name: "Mono",
      },
      {
        code: "BJ-OU",
        alpha2code: "OU",
        name: "Ouémé",
      },
      {
        code: "BJ-ZO",
        alpha2code: "ZO",
        name: "Zou",
      },
    ],
  },
  BM: {
    name: "Bermuda",
    subdivisions: [
      {
        code: "BM-BM",
        alpha2code: "BM",
        name: "Bermuda",
      },
    ],
  },
  BT: {
    name: "Bhutan",
    subdivisions: [
      {
        code: "BT-33",
        alpha2code: "33",
        name: "Bumthang",
      },
      {
        code: "BT-12",
        alpha2code: "12",
        name: "Chhukha",
      },
      {
        code: "BT-22",
        alpha2code: "22",
        name: "Dagana",
      },
      {
        code: "BT-GA",
        alpha2code: "GA",
        name: "Gasa",
      },
      {
        code: "BT-13",
        alpha2code: "13",
        name: "Ha",
      },
      {
        code: "BT-44",
        alpha2code: "44",
        name: "Lhuentse",
      },
      {
        code: "BT-42",
        alpha2code: "42",
        name: "Monggar",
      },
      {
        code: "BT-11",
        alpha2code: "11",
        name: "Paro",
      },
      {
        code: "BT-43",
        alpha2code: "43",
        name: "Pemagatshel",
      },
      {
        code: "BT-23",
        alpha2code: "23",
        name: "Punakha",
      },
      {
        code: "BT-45",
        alpha2code: "45",
        name: "Samdrup Jongkha",
      },
      {
        code: "BT-14",
        alpha2code: "14",
        name: "Samtse",
      },
      {
        code: "BT-31",
        alpha2code: "31",
        name: "Sarpang",
      },
      {
        code: "BT-15",
        alpha2code: "15",
        name: "Thimphu",
      },
      {
        code: "BT-41",
        alpha2code: "41",
        name: "Trashigang",
      },
      {
        code: "BT-TY",
        alpha2code: "TY",
        name: "Trashi Yangtse",
      },
      {
        code: "BT-32",
        alpha2code: "32",
        name: "Trongsa",
      },
      {
        code: "BT-21",
        alpha2code: "21",
        name: "Tsirang",
      },
      {
        code: "BT-24",
        alpha2code: "24",
        name: "Wangdue Phodrang",
      },
      {
        code: "BT-34",
        alpha2code: "34",
        name: "Zhemgang",
      },
    ],
  },
  BO: {
    name: "Bolivia",
    subdivisions: [
      {
        code: "BO-C",
        alpha2code: "C",
        name: "Cochabamba",
      },
      {
        code: "BO-H",
        alpha2code: "H",
        name: "Chuquisaca",
      },
      {
        code: "BO-B",
        alpha2code: "B",
        name: "El Beni",
      },
      {
        code: "BO-L",
        alpha2code: "L",
        name: "La Paz",
      },
      {
        code: "BO-O",
        alpha2code: "O",
        name: "Oruro",
      },
      {
        code: "BO-N",
        alpha2code: "N",
        name: "Pando",
      },
      {
        code: "BO-P",
        alpha2code: "P",
        name: "Potosi",
      },
      {
        code: "BO-S",
        alpha2code: "S",
        name: "Santa Cruz",
      },
      {
        code: "BO-T",
        alpha2code: "T",
        name: "Tarija",
      },
    ],
  },
  BA: {
    name: "Bosnia & Herzegovina",
    subdivisions: [
      {
        code: "BA-BIH",
        alpha2code: "BIH",
        name: "Federacija Bosna i Hercegovina",
      },
      {
        code: "BA-SRP",
        alpha2code: "SRP",
        name: "Republika Srpska",
      },
    ],
  },
  BW: {
    name: "Botswana",
    subdivisions: [
      {
        code: "BW-CE",
        alpha2code: "CE",
        name: "Central [Serowe-Palapye]",
      },
      {
        code: "BW-CH",
        alpha2code: "CH",
        name: "Chobe",
      },
      {
        code: "BW-GH",
        alpha2code: "GH",
        name: "Ghanzi",
      },
      {
        code: "BW-KG",
        alpha2code: "KG",
        name: "Kgalagadi",
      },
      {
        code: "BW-KL",
        alpha2code: "KL",
        name: "Kgatleng",
      },
      {
        code: "BW-KW",
        alpha2code: "KW",
        name: "Kweneng",
      },
      {
        code: "BW-NG",
        alpha2code: "NG",
        name: "Ngamiland [North-West]",
      },
      {
        code: "BW-NE",
        alpha2code: "NE",
        name: "North-East",
      },
      {
        code: "BW-SE",
        alpha2code: "SE",
        name: "South-East",
      },
      {
        code: "BW-SO",
        alpha2code: "SO",
        name: "Southern [Ngwaketse]",
      },
    ],
  },
  BV: {
    name: "Bouvet Island",
    subdivisions: [
      {
        code: "BV-BV",
        alpha2code: "BV",
        name: "Bouvet Island",
      },
    ],
  },
  BR: {
    name: "Brazil",
    subdivisions: [
      {
        code: "BR-DF",
        alpha2code: "DF",
        name: "Distrito Federal",
      },
      {
        code: "BR-AC",
        alpha2code: "AC",
        name: "Acre",
      },
      {
        code: "BR-AL",
        alpha2code: "AL",
        name: "Alagoas",
      },
      {
        code: "BR-AP",
        alpha2code: "AP",
        name: "Amapá",
      },
      {
        code: "BR-AM",
        alpha2code: "AM",
        name: "Amazonas",
      },
      {
        code: "BR-BA",
        alpha2code: "BA",
        name: "Bahia",
      },
      {
        code: "BR-CE",
        alpha2code: "CE",
        name: "Ceará",
      },
      {
        code: "BR-ES",
        alpha2code: "ES",
        name: "Espírito Santo",
      },
      {
        code: "BR-GO",
        alpha2code: "GO",
        name: "Goiás",
      },
      {
        code: "BR-MA",
        alpha2code: "MA",
        name: "Maranhāo",
      },
      {
        code: "BR-MT",
        alpha2code: "MT",
        name: "Mato Grosso",
      },
      {
        code: "BR-MS",
        alpha2code: "MS",
        name: "Mato Grosso do Sul",
      },
      {
        code: "BR-MG",
        alpha2code: "MG",
        name: "Minas Gerais",
      },
      {
        code: "BR-PA",
        alpha2code: "PA",
        name: "Pará",
      },
      {
        code: "BR-PB",
        alpha2code: "PB",
        name: "Paraíba",
      },
      {
        code: "BR-PR",
        alpha2code: "PR",
        name: "Paraná",
      },
      {
        code: "BR-PE",
        alpha2code: "PE",
        name: "Pernambuco",
      },
      {
        code: "BR-PI",
        alpha2code: "PI",
        name: "Piauí",
      },
      {
        code: "BR-RJ",
        alpha2code: "RJ",
        name: "Rio de Janeiro",
      },
      {
        code: "BR-RN",
        alpha2code: "RN",
        name: "Rio Grande do Norte",
      },
      {
        code: "BR-RS",
        alpha2code: "RS",
        name: "Rio Grande do Sul",
      },
      {
        code: "BR-R0",
        alpha2code: "R0",
        name: "Rondônia",
      },
      {
        code: "BR-RR",
        alpha2code: "RR",
        name: "Roraima",
      },
      {
        code: "BR-SC",
        alpha2code: "SC",
        name: "Santa Catarina",
      },
      {
        code: "BR-SP",
        alpha2code: "SP",
        name: "São Paulo",
      },
      {
        code: "BR-SE",
        alpha2code: "SE",
        name: "Sergipe",
      },
      {
        code: "BR-TO",
        alpha2code: "TO",
        name: "Tocantins",
      },
    ],
  },
  IO: {
    name: "British Indian Ocean Territory",
    subdivisions: [
      {
        code: "IO-IO",
        alpha2code: "IO",
        name: "British Indian Ocean Territory",
      },
    ],
  },
  BN: {
    name: "Brunei Darussalam",
    subdivisions: [
      {
        code: "BN-BE",
        alpha2code: "BE",
        name: "Belait",
      },
      {
        code: "BN-BM",
        alpha2code: "BM",
        name: "Brunei-Muara",
      },
      {
        code: "BN-TE",
        alpha2code: "TE",
        name: "Temburong",
      },
      {
        code: "BN-TU",
        alpha2code: "TU",
        name: "Tutong",
      },
    ],
  },
  BG: {
    name: "Bulgaria",
    subdivisions: [
      {
        code: "BG-02",
        alpha2code: "02",
        name: "Burgas",
      },
      {
        code: "BG-09",
        alpha2code: "09",
        name: "Haskovo",
      },
      {
        code: "BG-04",
        alpha2code: "04",
        name: "Loveč",
      },
      {
        code: "BG-05",
        alpha2code: "05",
        name: "Montana",
      },
      {
        code: "BG-06",
        alpha2code: "06",
        name: "Plovdiv",
      },
      {
        code: "BG-07",
        alpha2code: "07",
        name: "Ruse",
      },
      {
        code: "BG-08",
        alpha2code: "08",
        name: "Sofija",
      },
      {
        code: "BG-01",
        alpha2code: "01",
        name: "Sofija-Grad",
      },
      {
        code: "BG-03",
        alpha2code: "03",
        name: "Varna",
      },
    ],
  },
  BF: {
    name: "Burkina Faso",
    subdivisions: [
      {
        code: "BF-BAL",
        alpha2code: "BAL",
        name: "Balé",
      },
      {
        code: "BF-BAM",
        alpha2code: "BAM",
        name: "Bam",
      },
      {
        code: "BF-BAN",
        alpha2code: "BAN",
        name: "Banwa",
      },
      {
        code: "BF-BAZ",
        alpha2code: "BAZ",
        name: "Bazèga",
      },
      {
        code: "BF-BGR",
        alpha2code: "BGR",
        name: "Bougouriba",
      },
      {
        code: "BF-BLG",
        alpha2code: "BLG",
        name: "Boulgou",
      },
      {
        code: "BF-BLK",
        alpha2code: "BLK",
        name: "Boulkiemdé",
      },
      {
        code: "BF-COM",
        alpha2code: "COM",
        name: "Comoé",
      },
      {
        code: "BF-GAN",
        alpha2code: "GAN",
        name: "Ganzourgou",
      },
      {
        code: "BF-GNA",
        alpha2code: "GNA",
        name: "Gnagna",
      },
      {
        code: "BF-GOU",
        alpha2code: "GOU",
        name: "Gourma",
      },
      {
        code: "BF-HOU",
        alpha2code: "HOU",
        name: "Houet",
      },
      {
        code: "BF-IOB",
        alpha2code: "IOB",
        name: "Ioba",
      },
      {
        code: "BF-KAD",
        alpha2code: "KAD",
        name: "Kadiogo",
      },
      {
        code: "BF-KEN",
        alpha2code: "KEN",
        name: "Kénédougou",
      },
      {
        code: "BF-KMD",
        alpha2code: "KMD",
        name: "Komondjari",
      },
      {
        code: "BF-KMP",
        alpha2code: "KMP",
        name: "Kompienga",
      },
      {
        code: "BF-KOS",
        alpha2code: "KOS",
        name: "Kossi",
      },
      {
        code: "BF-KOP",
        alpha2code: "KOP",
        name: "Koulpélogo",
      },
      {
        code: "BF-KOT",
        alpha2code: "KOT",
        name: "Kouritenga",
      },
      {
        code: "BF-KOW",
        alpha2code: "KOW",
        name: "Kourwéogo",
      },
      {
        code: "BF-LER",
        alpha2code: "LER",
        name: "Léraba",
      },
      {
        code: "BF-LOR",
        alpha2code: "LOR",
        name: "Loroum",
      },
      {
        code: "BF-MOU",
        alpha2code: "MOU",
        name: "Mouhoun",
      },
      {
        code: "BF-NAO",
        alpha2code: "NAO",
        name: "Nahouri",
      },
      {
        code: "BF-NAM",
        alpha2code: "NAM",
        name: "Namentenga",
      },
      {
        code: "BF-NAY",
        alpha2code: "NAY",
        name: "Nayala",
      },
      {
        code: "BF-NOU",
        alpha2code: "NOU",
        name: "Noumbiel",
      },
      {
        code: "BF-OUB",
        alpha2code: "OUB",
        name: "Oubritenga",
      },
      {
        code: "BF-OUD",
        alpha2code: "OUD",
        name: "Oudalan",
      },
      {
        code: "BF-PAS",
        alpha2code: "PAS",
        name: "Passoré",
      },
      {
        code: "BF-PON",
        alpha2code: "PON",
        name: "Poni",
      },
      {
        code: "BF-SNG",
        alpha2code: "SNG",
        name: "Sanguié",
      },
      {
        code: "BF-SMT",
        alpha2code: "SMT",
        name: "Sanmatenga",
      },
      {
        code: "BF-SEN",
        alpha2code: "SEN",
        name: "Séno",
      },
      {
        code: "BF-SIS",
        alpha2code: "SIS",
        name: "Sissili",
      },
      {
        code: "BF-SOM",
        alpha2code: "SOM",
        name: "Soum",
      },
      {
        code: "BF-SOR",
        alpha2code: "SOR",
        name: "Sourou",
      },
      {
        code: "BF-TAP",
        alpha2code: "TAP",
        name: "Tapoa",
      },
      {
        code: "BF-TUI",
        alpha2code: "TUI",
        name: "Tui",
      },
      {
        code: "BF-YAG",
        alpha2code: "YAG",
        name: "Yagha",
      },
      {
        code: "BF-YAT",
        alpha2code: "YAT",
        name: "Yatenga",
      },
      {
        code: "BF-ZIR",
        alpha2code: "ZIR",
        name: "Ziro",
      },
      {
        code: "BF-ZON",
        alpha2code: "ZON",
        name: "Zondoma",
      },
      {
        code: "BF-ZOU",
        alpha2code: "ZOU",
        name: "Zoundwéogo",
      },
    ],
  },
  BI: {
    name: "Burundi",
    subdivisions: [
      {
        code: "BI-BB",
        alpha2code: "BB",
        name: "Bubanza",
      },
      {
        code: "BI-BJ",
        alpha2code: "BJ",
        name: "Bujumbura",
      },
      {
        code: "BI-BR",
        alpha2code: "BR",
        name: "Bururi",
      },
      {
        code: "BI-CA",
        alpha2code: "CA",
        name: "Cankuzo",
      },
      {
        code: "BI-CI",
        alpha2code: "CI",
        name: "Cibitoke",
      },
      {
        code: "BI-GI",
        alpha2code: "GI",
        name: "Gitega",
      },
      {
        code: "BI-KR",
        alpha2code: "KR",
        name: "Karuzi",
      },
      {
        code: "BI-KY",
        alpha2code: "KY",
        name: "Kayanza",
      },
      {
        code: "BI-KI",
        alpha2code: "KI",
        name: "Kirundo",
      },
      {
        code: "BI-MA",
        alpha2code: "MA",
        name: "Makamba",
      },
      {
        code: "BI-MU",
        alpha2code: "MU",
        name: "Muramvya",
      },
      {
        code: "BI-MY",
        alpha2code: "MY",
        name: "Muyinga",
      },
      {
        code: "BI-NG",
        alpha2code: "NG",
        name: "Ngozi",
      },
      {
        code: "BI-RT",
        alpha2code: "RT",
        name: "Rutana",
      },
      {
        code: "BI-RY",
        alpha2code: "RY",
        name: "Ruyigi",
      },
    ],
  },
  KH: {
    name: "Cambodia",
    subdivisions: [
      {
        code: "KH-23",
        alpha2code: "23",
        name: "Krong Kaeb [Krŏng Kêb]",
      },
      {
        code: "KH-18",
        alpha2code: "18",
        name: "Krong Preah Sihanouk [Krŏng Preăh Sihanouk]",
      },
      {
        code: "KH-12",
        alpha2code: "12",
        name: "Phnom Penh [Phnum Pénh]",
      },
      {
        code: "KH-2",
        alpha2code: "2",
        name: "Baat Dambang [Bătdâmbâng]",
      },
      {
        code: "KH-1",
        alpha2code: "1",
        name: "Banteay Mean Chey [Bântéay Méanchey]",
      },
      {
        code: "KH-3",
        alpha2code: "3",
        name: "Kampong Chaam [Kâmpóng Cham]",
      },
      {
        code: "KH-4",
        alpha2code: "4",
        name: "Kampong Chhnang [Kâmpóng Chhnăng]",
      },
      {
        code: "KH-5",
        alpha2code: "5",
        name: "Kampong Spueu [Kâmpóng Spœ]",
      },
      {
        code: "KH-6",
        alpha2code: "6",
        name: "Kampong Thum [Kâmpóng Thum]",
      },
      {
        code: "KH-7",
        alpha2code: "7",
        name: "Kampot [Kâmpôt]",
      },
      {
        code: "KH-8",
        alpha2code: "8",
        name: "Kandaal [Kândal]",
      },
      {
        code: "KH-10",
        alpha2code: "10",
        name: "Kracheh [Krâchéh]",
      },
      {
        code: "KH-11",
        alpha2code: "11",
        name: "Mond01 Kiri [Môndól Kiri]",
      },
      {
        code: "KH-22",
        alpha2code: "22",
        name: "Otdar Mean Chey [Ŏtdâr Méanchey]",
      },
      {
        code: "KH-15",
        alpha2code: "15",
        name: "Pousaat [Poŭthĭsăt]",
      },
      {
        code: "KH-13",
        alpha2code: "13",
        name: "Preah Vihear [Preăh Vihéar]",
      },
      {
        code: "KH-14",
        alpha2code: "14",
        name: "Prey Veaeng [Prey Vêng]",
      },
      {
        code: "KH-16",
        alpha2code: "16",
        name: "Rotanak Kiri [Rôtânôkiri]",
      },
      {
        code: "KH-17",
        alpha2code: "17",
        name: "Siem Reab [Siĕmréab]",
      },
      {
        code: "KH-19",
        alpha2code: "19",
        name: "Stueng Traeng [Stœng Trêng]",
      },
      {
        code: "KH-20",
        alpha2code: "20",
        name: "Svaay Rieng [Svay Riĕng]",
      },
      {
        code: "KH-21",
        alpha2code: "21",
        name: "Taakaev [Takêv]",
      },
    ],
  },
  CM: {
    name: "Cameroon",
    subdivisions: [
      {
        code: "CM-AD",
        alpha2code: "AD",
        name: "Adamaoua",
      },
      {
        code: "CM-CE",
        alpha2code: "CE",
        name: "Centre",
      },
      {
        code: "CM-ES",
        alpha2code: "ES",
        name: "Est",
      },
      {
        code: "CM-EN",
        alpha2code: "EN",
        name: "Far North",
      },
      {
        code: "CM-LT",
        alpha2code: "LT",
        name: "Littoral",
      },
      {
        code: "CM-NO",
        alpha2code: "NO",
        name: "North",
      },
      {
        code: "CM-NW",
        alpha2code: "NW",
        name: "North-West",
      },
      {
        code: "CM-SU",
        alpha2code: "SU",
        name: "South",
      },
      {
        code: "CM-SW",
        alpha2code: "SW",
        name: "South-West",
      },
      {
        code: "CM-OU",
        alpha2code: "OU",
        name: "West",
      },
    ],
  },
  CA: {
    name: "Canada",
    subdivisions: [
      {
        code: "CA-AB",
        alpha2code: "AB",
        name: "Alberta",
      },
      {
        code: "CA-BC",
        alpha2code: "BC",
        name: "British Columbia (Colombie-Britannique)",
      },
      {
        code: "CA-MB",
        alpha2code: "MB",
        name: "Manitoba",
      },
      {
        code: "CA-NB",
        alpha2code: "NB",
        name: "New Brunswick (Nouveau-Brunswick)",
      },
      {
        code: "CA-NL",
        alpha2code: "NL",
        name: "Newfoundland and Labrador (Terre-Neuve)",
      },
      {
        code: "CA-NS",
        alpha2code: "NS",
        name: "Nova Scotia (Nouvelle-Écosse)",
      },
      {
        code: "CA-ON",
        alpha2code: "ON",
        name: "Ontario",
      },
      {
        code: "CA-PE",
        alpha2code: "PE",
        name: "Prince Edward Island (Île-du-Prince-Édouard)",
      },
      {
        code: "CA-QC",
        alpha2code: "QC",
        name: "Quebec (Québec)",
      },
      {
        code: "CA-SK",
        alpha2code: "SK",
        name: "Saskatchewan",
      },
      {
        code: "CA-NT",
        alpha2code: "NT",
        name: "Northwest Territories (Territoires du Nord-Ouest)",
      },
      {
        code: "CA-NU",
        alpha2code: "NU",
        name: "Nunavut",
      },
      {
        code: "CA-YT",
        alpha2code: "YT",
        name: "Yukon Territory (Teritoire du Yukon)",
      },
    ],
  },
  CV: {
    name: "Cape Verde",
    subdivisions: [
      {
        code: "CV-B",
        alpha2code: "B",
        name: "Ilhas de Barlavento",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Boa Vista",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Paul",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Porto Novo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ribeira Grande",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sāo Nicolau",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sāo Vicente",
      },
      {
        code: "CV-S",
        alpha2code: "S",
        name: "Ilhas de Sotavento",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brava",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fogo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Maio",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Praia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Santa Catarina",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Santa Cruz",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tarrafal",
      },
    ],
  },
  KY: {
    name: "Cayman Islands",
    subdivisions: [
      {
        code: "KY-KY",
        alpha2code: "KY",
        name: "Cayman Islands",
      },
    ],
  },
  CF: {
    name: "Central African Republic",
    subdivisions: [
      {
        code: "CF-BGF",
        alpha2code: "BGF",
        name: "Bangui",
      },
      {
        code: "CF-BB",
        alpha2code: "BB",
        name: "Bamingui-Bangoran",
      },
      {
        code: "CF-BK",
        alpha2code: "BK",
        name: "Basse-Kotto",
      },
      {
        code: "CF-HK",
        alpha2code: "HK",
        name: "Haute-Kotto",
      },
      {
        code: "CF-HM",
        alpha2code: "HM",
        name: "Haut-Mbomou",
      },
      {
        code: "CF-KG",
        alpha2code: "KG",
        name: "Kémo",
      },
      {
        code: "CF-LB",
        alpha2code: "LB",
        name: "Lobaye",
      },
      {
        code: "CF-HS",
        alpha2code: "HS",
        name: "Mambéré-Kadéï",
      },
      {
        code: "CF-MB",
        alpha2code: "MB",
        name: "Mbomou",
      },
      {
        code: "CF-KB",
        alpha2code: "KB",
        name: "Nana-Grébizi",
      },
      {
        code: "CF-NM",
        alpha2code: "NM",
        name: "Nana-Mambéré",
      },
      {
        code: "CF-MP",
        alpha2code: "MP",
        name: "Ombella-Mpoko",
      },
      {
        code: "CF-UK",
        alpha2code: "UK",
        name: "Ouaka",
      },
      {
        code: "CF-AC",
        alpha2code: "AC",
        name: "Ouham",
      },
      {
        code: "CF-OP",
        alpha2code: "OP",
        name: "Ouham-Pendé",
      },
      {
        code: "CF-SE",
        alpha2code: "SE",
        name: "Sangha-Mbaéré",
      },
      {
        code: "CF-VK",
        alpha2code: "VK",
        name: "Vakaga",
      },
    ],
  },
  TD: {
    name: "Chad",
    subdivisions: [
      {
        code: "TD-BA",
        alpha2code: "BA",
        name: "Batha",
      },
      {
        code: "TD-BI",
        alpha2code: "BI",
        name: "Biltine",
      },
      {
        code: "TD-BET",
        alpha2code: "BET",
        name: "Borkou-Ennedi-Tibesti",
      },
      {
        code: "TD-CB",
        alpha2code: "CB",
        name: "Chari-Baguirmi",
      },
      {
        code: "TD-GR",
        alpha2code: "GR",
        name: "Guéra",
      },
      {
        code: "TD-KA",
        alpha2code: "KA",
        name: "Kanem",
      },
      {
        code: "TD-LC",
        alpha2code: "LC",
        name: "Lac",
      },
      {
        code: "TD-LO",
        alpha2code: "LO",
        name: "Logone-Occidental",
      },
      {
        code: "TD-LR",
        alpha2code: "LR",
        name: "Logone-Oriental",
      },
      {
        code: "TD-MK",
        alpha2code: "MK",
        name: "Mayo-Kébbi",
      },
      {
        code: "TD-MC",
        alpha2code: "MC",
        name: "Moyen-Chari",
      },
      {
        code: "TD-OD",
        alpha2code: "OD",
        name: "Ouaddaï",
      },
      {
        code: "TD-SA",
        alpha2code: "SA",
        name: "Salamat",
      },
      {
        code: "TD-TA",
        alpha2code: "TA",
        name: "Tandjilé",
      },
    ],
  },
  CL: {
    name: "Chile",
    subdivisions: [
      {
        code: "CL-AI",
        alpha2code: "AI",
        name: "Aisén del General Carlos Ibáñiez del Campo",
      },
      {
        code: "CL-AN",
        alpha2code: "AN",
        name: "Antofagasta",
      },
      {
        code: "CL-AR",
        alpha2code: "AR",
        name: "Araucanía",
      },
      {
        code: "CL-AT",
        alpha2code: "AT",
        name: "Atacama",
      },
      {
        code: "CL-BI",
        alpha2code: "BI",
        name: "Bío-Bío",
      },
      {
        code: "CL-CO",
        alpha2code: "CO",
        name: "Coquimbo",
      },
      {
        code: "CL-LI",
        alpha2code: "LI",
        name: "Libertador General Bernardo O'Higgins",
      },
      {
        code: "CL-LL",
        alpha2code: "LL",
        name: "Los Lagos",
      },
      {
        code: "CL-MA",
        alpha2code: "MA",
        name: "Magallanes",
      },
      {
        code: "CL-ML",
        alpha2code: "ML",
        name: "Maule",
      },
      {
        code: "CL-RM",
        alpha2code: "RM",
        name: "Regíon Metropolitana de Santiago",
      },
      {
        code: "CL-TA",
        alpha2code: "TA",
        name: "Tarapacá",
      },
      {
        code: "CL-VS",
        alpha2code: "VS",
        name: "Valparaiso",
      },
    ],
  },
  CN: {
    name: "China",
    subdivisions: [
      {
        code: "CN-11",
        alpha2code: "11",
        name: "Beijing",
      },
      {
        code: "CN-50",
        alpha2code: "50",
        name: "Chongqing",
      },
      {
        code: "CN-31",
        alpha2code: "31",
        name: "Shanghai",
      },
      {
        code: "CN-12",
        alpha2code: "12",
        name: "Tianjin",
      },
      {
        code: "CN-34",
        alpha2code: "34",
        name: "Anhui",
      },
      {
        code: "CN-35",
        alpha2code: "35",
        name: "Fujian",
      },
      {
        code: "CN-62",
        alpha2code: "62",
        name: "Gansu",
      },
      {
        code: "CN-44",
        alpha2code: "44",
        name: "Guangdong",
      },
      {
        code: "CN-52",
        alpha2code: "52",
        name: "Guizhou",
      },
      {
        code: "CN-46",
        alpha2code: "46",
        name: "Hainan",
      },
      {
        code: "CN-13",
        alpha2code: "13",
        name: "Hebei",
      },
      {
        code: "CN-23",
        alpha2code: "23",
        name: "Heilongjiang",
      },
      {
        code: "CN-41",
        alpha2code: "41",
        name: "Henan",
      },
      {
        code: "CN-42",
        alpha2code: "42",
        name: "Hubei",
      },
      {
        code: "CN-43",
        alpha2code: "43",
        name: "Hunan",
      },
      {
        code: "CN-32",
        alpha2code: "32",
        name: "Jiangsu",
      },
      {
        code: "CN-36",
        alpha2code: "36",
        name: "Jiangxi",
      },
      {
        code: "CN-22",
        alpha2code: "22",
        name: "Jilin",
      },
      {
        code: "CN-21",
        alpha2code: "21",
        name: "Liaoning",
      },
      {
        code: "CN-63",
        alpha2code: "63",
        name: "Qinghai",
      },
      {
        code: "CN-61",
        alpha2code: "61",
        name: "Shaanxi",
      },
      {
        code: "CN-37",
        alpha2code: "37",
        name: "Shandong",
      },
      {
        code: "CN-14",
        alpha2code: "14",
        name: "Shanxi",
      },
      {
        code: "CN-51",
        alpha2code: "51",
        name: "Sichuan",
      },
      {
        code: "CN-71",
        alpha2code: "71",
        name: "Taiwan",
      },
      {
        code: "CN-53",
        alpha2code: "53",
        name: "Yunnan",
      },
      {
        code: "CN-33",
        alpha2code: "33",
        name: "Zhejiang",
      },
      {
        code: "CN-45",
        alpha2code: "45",
        name: "Guangxi",
      },
      {
        code: "CN-15",
        alpha2code: "15",
        name: "Nei Monggol",
      },
      {
        code: "CN-64",
        alpha2code: "64",
        name: "Ningxia",
      },
      {
        code: "CN-65",
        alpha2code: "65",
        name: "Xinjiang",
      },
      {
        code: "CN-54",
        alpha2code: "54",
        name: "Xizang",
      },
      {
        code: "CN-91",
        alpha2code: "91",
        name: "Hong Kong",
      },
    ],
  },
  CX: {
    name: "Christmas Island",
    subdivisions: [
      {
        code: "CX-CX",
        alpha2code: "CX",
        name: "Christmas Island",
      },
    ],
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    subdivisions: [
      {
        code: "CC-CC",
        alpha2code: "CC",
        name: "Cocos (Keeling) Islands",
      },
    ],
  },
  CO: {
    name: "Colombia",
    subdivisions: [
      {
        code: "CO-DC",
        alpha2code: "DC",
        name: "Distrito Capital de Santa Fe de Bogota",
      },
      {
        code: "CO-AMA",
        alpha2code: "AMA",
        name: "Amazonas",
      },
      {
        code: "CO-ANT",
        alpha2code: "ANT",
        name: "Antioguia",
      },
      {
        code: "CO-ARA",
        alpha2code: "ARA",
        name: "Arauca",
      },
      {
        code: "CO-ATL",
        alpha2code: "ATL",
        name: "Atlántico",
      },
      {
        code: "CO-BOL",
        alpha2code: "BOL",
        name: "Bolívar",
      },
      {
        code: "CO-BOY",
        alpha2code: "BOY",
        name: "Boyacá",
      },
      {
        code: "CO-CAL",
        alpha2code: "CAL",
        name: "Caldas",
      },
      {
        code: "CO-CAQ",
        alpha2code: "CAQ",
        name: "Caquetá",
      },
      {
        code: "CO-CAS",
        alpha2code: "CAS",
        name: "Casanare",
      },
      {
        code: "CO-CAU",
        alpha2code: "CAU",
        name: "Cauca",
      },
      {
        code: "CO-CES",
        alpha2code: "CES",
        name: "Cesar",
      },
      {
        code: "CO-COR",
        alpha2code: "COR",
        name: "Córdoba",
      },
      {
        code: "CO-CUN",
        alpha2code: "CUN",
        name: "Cundinamarca",
      },
      {
        code: "CO-CHO",
        alpha2code: "CHO",
        name: "Chocó",
      },
      {
        code: "CO-GUA",
        alpha2code: "GUA",
        name: "Guainía",
      },
      {
        code: "CO-GUV",
        alpha2code: "GUV",
        name: "Guaviare",
      },
      {
        code: "CO-HUI",
        alpha2code: "HUI",
        name: "Huila",
      },
      {
        code: "CO-LAG",
        alpha2code: "LAG",
        name: "La Guajira",
      },
      {
        code: "CO-MAG",
        alpha2code: "MAG",
        name: "Magdalena",
      },
      {
        code: "CO-MET",
        alpha2code: "MET",
        name: "Meta",
      },
      {
        code: "CO-NAR",
        alpha2code: "NAR",
        name: "Nariño",
      },
      {
        code: "CO-NSA",
        alpha2code: "NSA",
        name: "Norte de Santander",
      },
      {
        code: "CO-PUT",
        alpha2code: "PUT",
        name: "Putumayo",
      },
      {
        code: "CO-QUI",
        alpha2code: "QUI",
        name: "Quindío",
      },
      {
        code: "CO-RIS",
        alpha2code: "RIS",
        name: "Risaralda",
      },
      {
        code: "CO-SAP",
        alpha2code: "SAP",
        name: "San Andrés, Providencia y Santa Catalina",
      },
      {
        code: "CO-SAN",
        alpha2code: "SAN",
        name: "Santander",
      },
      {
        code: "CO-SUC",
        alpha2code: "SUC",
        name: "Sucre",
      },
      {
        code: "CO-TOL",
        alpha2code: "TOL",
        name: "Tolima",
      },
      {
        code: "CO-VAC",
        alpha2code: "VAC",
        name: "Valle del Cauca",
      },
      {
        code: "CO-VAU",
        alpha2code: "VAU",
        name: "Vaupés",
      },
      {
        code: "CO-VID",
        alpha2code: "VID",
        name: "Vichada",
      },
    ],
  },
  KM: {
    name: "Comoros",
    subdivisions: [
      {
        code: "KM-A",
        alpha2code: "A",
        name: "Anjouan (Ndzouani)",
      },
      {
        code: "KM-G",
        alpha2code: "G",
        name: "Grande Comore (Ngazidja)",
      },
      {
        code: "KM-M",
        alpha2code: "M",
        name: "Mohéli (Moili)",
      },
    ],
  },
  CG: {
    name: "Congo",
    subdivisions: [
      {
        code: "CG-BZV",
        alpha2code: "BZV",
        name: "Brazzaville",
      },
      {
        code: "CG-11",
        alpha2code: "11",
        name: "Bouenza",
      },
      {
        code: "CG-8",
        alpha2code: "8",
        name: "Cuvette",
      },
      {
        code: "CG-15",
        alpha2code: "15",
        name: "Cuvette-Ouest",
      },
      {
        code: "CG-5",
        alpha2code: "5",
        name: "Kouilou",
      },
      {
        code: "CG-2",
        alpha2code: "2",
        name: "Lékoumou",
      },
      {
        code: "CG-7",
        alpha2code: "7",
        name: "Likouala",
      },
      {
        code: "CG-9",
        alpha2code: "9",
        name: "Niari",
      },
      {
        code: "CG-14",
        alpha2code: "14",
        name: "Plateaux",
      },
      {
        code: "CG-12",
        alpha2code: "12",
        name: "Pool",
      },
      {
        code: "CG-13",
        alpha2code: "13",
        name: "Sangha",
      },
    ],
  },
  CD: {
    name: "Zaire",
    subdivisions: [
      {
        code: "CD-KN",
        alpha2code: "KN",
        name: "Kinshasa",
      },
      {
        code: "CD-BN",
        alpha2code: "BN",
        name: "Bandundu",
      },
      {
        code: "CD-BC",
        alpha2code: "BC",
        name: "Bas-Congo",
      },
      {
        code: "CD-EQ",
        alpha2code: "EQ",
        name: "Équateur",
      },
      {
        code: "CD-HC",
        alpha2code: "HC",
        name: "Haut-Congo",
      },
      {
        code: "CD-KW",
        alpha2code: "KW",
        name: "Kasai-Occidental",
      },
      {
        code: "CD-KE",
        alpha2code: "KE",
        name: "Kasai-Oriental",
      },
      {
        code: "CD-KA",
        alpha2code: "KA",
        name: "Katanga",
      },
      {
        code: "CD-MA",
        alpha2code: "MA",
        name: "Maniema",
      },
      {
        code: "CD-NK",
        alpha2code: "NK",
        name: "Nord-Kivu",
      },
      {
        code: "CD-SK",
        alpha2code: "SK",
        name: "Sud-Kivu",
      },
    ],
  },
  CK: {
    name: "Cook Islands",
    subdivisions: [
      {
        code: "CK-CK",
        alpha2code: "CK",
        name: "Cook Islands",
      },
    ],
  },
  CR: {
    name: "Costa Rica",
    subdivisions: [
      {
        code: "CR-A",
        alpha2code: "A",
        name: "Alajuela",
      },
      {
        code: "CR-C",
        alpha2code: "C",
        name: "Cartago",
      },
      {
        code: "CR-G",
        alpha2code: "G",
        name: "Guanacaste",
      },
      {
        code: "CR-H",
        alpha2code: "H",
        name: "Heredia",
      },
      {
        code: "CR-L",
        alpha2code: "L",
        name: "Limón",
      },
      {
        code: "CR-P",
        alpha2code: "P",
        name: "Puntarenas",
      },
      {
        code: "CR-SJ",
        alpha2code: "SJ",
        name: "San José",
      },
    ],
  },
  CI: {
    name: "Cote D'ivoire (Ivory Coast)",
    subdivisions: [
      {
        code: "CI-06",
        alpha2code: "06",
        name: "18 Montagnes (Région des)",
      },
      {
        code: "CI-16",
        alpha2code: "16",
        name: "Agnébi (Région de l')",
      },
      {
        code: "CI-09",
        alpha2code: "09",
        name: "Bas-Sassandra (Région du)",
      },
      {
        code: "CI-10",
        alpha2code: "10",
        name: "Denguélé (Région du)",
      },
      {
        code: "CI-02",
        alpha2code: "02",
        name: "Haut-Sassandra (Région du)",
      },
      {
        code: "CI-07",
        alpha2code: "07",
        name: "Lacs (Région des)",
      },
      {
        code: "CI-01",
        alpha2code: "01",
        name: "Lagunes (Région des)",
      },
      {
        code: "CI-12",
        alpha2code: "12",
        name: "Marahoué (Région de la)",
      },
      {
        code: "CI-05",
        alpha2code: "05",
        name: "Moyen-Comoé (Région du)",
      },
      {
        code: "CI-11",
        alpha2code: "11",
        name: "Nzi-Comoé (Région)",
      },
      {
        code: "CI-03",
        alpha2code: "03",
        name: "Savanes (Région des)",
      },
      {
        code: "CI-15",
        alpha2code: "15",
        name: "Sud-Bandama (Région du)",
      },
      {
        code: "CI-13",
        alpha2code: "13",
        name: "Sud-Comoé (Région du)",
      },
      {
        code: "CI-04",
        alpha2code: "04",
        name: "Vallée du Bandama (Région de la)",
      },
      {
        code: "CI-14",
        alpha2code: "14",
        name: "Worodougou (Région du)",
      },
      {
        code: "CI-08",
        alpha2code: "08",
        name: "Zanzan (Région du)",
      },
    ],
  },
  HR: {
    name: "Croatia (Hrvatska)",
    subdivisions: [
      {
        code: "HR-07",
        alpha2code: "07",
        name: "Bjelovarsko-bilogorska županija",
      },
      {
        code: "HR-12",
        alpha2code: "12",
        name: "Brodsko-posavska županija",
      },
      {
        code: "HR-19",
        alpha2code: "19",
        name: "Dubrovačko-neretvanska županija",
      },
      {
        code: "HR-18",
        alpha2code: "18",
        name: "Istarska županija",
      },
      {
        code: "HR-04",
        alpha2code: "04",
        name: "Karlovačka županija",
      },
      {
        code: "HR-06",
        alpha2code: "06",
        name: "Koprivničkco-križevačka županija",
      },
      {
        code: "HR-02",
        alpha2code: "02",
        name: "Krapinsko-zagorska županija",
      },
      {
        code: "HR-09",
        alpha2code: "09",
        name: "Ličko-senjska županija",
      },
      {
        code: "HR-20",
        alpha2code: "20",
        name: "Medjimurska županija",
      },
      {
        code: "HR-14",
        alpha2code: "14",
        name: "Osječko-baranjska županija",
      },
      {
        code: "HR-11",
        alpha2code: "11",
        name: "Požeško-slavonska županija",
      },
      {
        code: "HR-08",
        alpha2code: "08",
        name: "Primorsko-goranska županija",
      },
      {
        code: "HR-03",
        alpha2code: "03",
        name: "Sisaško-moslavačka županija",
      },
      {
        code: "HR-17",
        alpha2code: "17",
        name: "Splitsko-dalmatinska županija",
      },
      {
        code: "HR-15",
        alpha2code: "15",
        name: "Šibensko-kninska županija",
      },
      {
        code: "HR-05",
        alpha2code: "05",
        name: "Varaždinska županija",
      },
      {
        code: "HR-10",
        alpha2code: "10",
        name: "Virovitičko-podravska županija",
      },
      {
        code: "HR-16",
        alpha2code: "16",
        name: "Vukovarsko-srijemska županija",
      },
      {
        code: "HR-13",
        alpha2code: "13",
        name: "Zadarska županija",
      },
      {
        code: "HR-01",
        alpha2code: "01",
        name: "Zagrebačka županija",
      },
    ],
  },
  CU: {
    name: "Cuba",
    subdivisions: [
      {
        code: "CU-09",
        alpha2code: "09",
        name: "Camagüey",
      },
      {
        code: "CU-03",
        alpha2code: "03",
        name: "Ciudad de La Habana",
      },
      {
        code: "CU-12",
        alpha2code: "12",
        name: "Granma",
      },
      {
        code: "CU-14",
        alpha2code: "14",
        name: "Guantánamo",
      },
      {
        code: "CU-11",
        alpha2code: "11",
        name: "Holguín",
      },
      {
        code: "CU-02",
        alpha2code: "02",
        name: "La Habana",
      },
      {
        code: "CU-10",
        alpha2code: "10",
        name: "Las Tunas",
      },
      {
        code: "CU-04",
        alpha2code: "04",
        name: "Matanzas",
      },
      {
        code: "CU-01",
        alpha2code: "01",
        name: "Pinar del Río",
      },
      {
        code: "CU-07",
        alpha2code: "07",
        name: "Sancti Spíritus",
      },
      {
        code: "CU-13",
        alpha2code: "13",
        name: "Santiago de Cuba",
      },
      {
        code: "CU-05",
        alpha2code: "05",
        name: "Villa Clara",
      },
      {
        code: "CU-99",
        alpha2code: "99",
        name: "Isla de la Juventud",
      },
    ],
  },
  CY: {
    name: "Cyprus",
    subdivisions: [
      {
        code: "CY-04",
        alpha2code: "04",
        name: "Ammochostos (Mağusa)",
      },
      {
        code: "CY-06",
        alpha2code: "06",
        name: "Keryneia (Girne)",
      },
      {
        code: "CY-03",
        alpha2code: "03",
        name: "Larnaka",
      },
      {
        code: "CY-01",
        alpha2code: "01",
        name: "Lefkosia (Lefkoşa)",
      },
      {
        code: "CY-02",
        alpha2code: "02",
        name: "Lemesos (Leymosun)",
      },
      {
        code: "CY-05",
        alpha2code: "05",
        name: "Pafos (Baf)",
      },
    ],
  },
  CZ: {
    name: "Czech Republic",
    subdivisions: [
      {
        code: "CZ-PRG",
        alpha2code: "PRG",
        name: "Praha",
      },
      {
        code: "CZ-CJC",
        alpha2code: "CJC",
        name: "Jihočeský kraj",
      },
      {
        code: "CZ-CJM",
        alpha2code: "CJM",
        name: "Jihomoravský kraj",
      },
      {
        code: "CZ-CSC",
        alpha2code: "CSC",
        name: "Severočeský kraj",
      },
      {
        code: "CZ-CSM",
        alpha2code: "CSM",
        name: "Severomoravský kraj",
      },
      {
        code: "CZ-CST",
        alpha2code: "CST",
        name: "Středočeský kraj",
      },
      {
        code: "CZ-CVC",
        alpha2code: "CVC",
        name: "Východočeský kraj",
      },
      {
        code: "CZ-CZC",
        alpha2code: "CZC",
        name: "Západočeský kraj",
      },
    ],
  },
  DK: {
    name: "Denmark",
    subdivisions: [
      {
        code: "DK-147",
        alpha2code: "147",
        name: "Frederiksberg",
      },
      {
        code: "DK-101",
        alpha2code: "101",
        name: "Kǿbenhavn",
      },
      {
        code: "DK-040",
        alpha2code: "040",
        name: "Bornholm",
      },
      {
        code: "DK-020",
        alpha2code: "020",
        name: "Frederiksborg",
      },
      {
        code: "DK-042",
        alpha2code: "042",
        name: "Fyn",
      },
      {
        code: "DK-015",
        alpha2code: "015",
        name: "Kǿbenhavn",
      },
      {
        code: "DK-080",
        alpha2code: "080",
        name: "Nordjylland",
      },
      {
        code: "DK-055",
        alpha2code: "055",
        name: "Ribe",
      },
      {
        code: "DK-065",
        alpha2code: "065",
        name: "Ringkǿbing",
      },
      {
        code: "DK-025",
        alpha2code: "025",
        name: "Roskilde",
      },
      {
        code: "DK-035",
        alpha2code: "035",
        name: "Storstrǿm",
      },
      {
        code: "DK-050",
        alpha2code: "050",
        name: "Sǿnderjylland",
      },
      {
        code: "DK-060",
        alpha2code: "060",
        name: "Vejle",
      },
      {
        code: "DK-030",
        alpha2code: "030",
        name: "Vestsjælland",
      },
      {
        code: "DK-076",
        alpha2code: "076",
        name: "Viborg",
      },
      {
        code: "DK-070",
        alpha2code: "070",
        name: "Århus",
      },
    ],
  },
  DJ: {
    name: "Djibouti",
    subdivisions: [
      {
        code: "DJ-AS",
        alpha2code: "AS",
        name: "Ali Sabieh",
      },
      {
        code: "DJ-DI",
        alpha2code: "DI",
        name: "Dikhil",
      },
      {
        code: "DJ-DJ",
        alpha2code: "DJ",
        name: "Djibouti",
      },
      {
        code: "DJ-OB",
        alpha2code: "OB",
        name: "Obock",
      },
      {
        code: "DJ-TA",
        alpha2code: "TA",
        name: "Tadjoura",
      },
    ],
  },
  DM: {
    name: "Dominica",
    subdivisions: [
      {
        code: "DM-DM",
        alpha2code: "DM",
        name: "Dominica",
      },
    ],
  },
  DO: {
    name: "Dominican Republic",
    subdivisions: [
      {
        code: "DO-DN",
        alpha2code: "DN",
        name: "Distrito National (Santo Domingo)",
      },
      {
        code: "DO-AZ",
        alpha2code: "AZ",
        name: "Azua",
      },
      {
        code: "DO-BR",
        alpha2code: "BR",
        name: "Bahoruco",
      },
      {
        code: "DO-BH",
        alpha2code: "BH",
        name: "Barahona",
      },
      {
        code: "DO-DA",
        alpha2code: "DA",
        name: "Dajabón",
      },
      {
        code: "DO-DU",
        alpha2code: "DU",
        name: "Duarte",
      },
      {
        code: "DO-SE",
        alpha2code: "SE",
        name: "El Seibo",
      },
      {
        code: "DO-EP",
        alpha2code: "EP",
        name: "La Estrelleta [Elías Piña]",
      },
      {
        code: "DO-HM",
        alpha2code: "HM",
        name: "Hato Mayor",
      },
      {
        code: "DO-IN",
        alpha2code: "IN",
        name: "Independencia",
      },
      {
        code: "DO-AL",
        alpha2code: "AL",
        name: "La Altagracia",
      },
      {
        code: "DO-RO",
        alpha2code: "RO",
        name: "La Romana",
      },
      {
        code: "DO-VE",
        alpha2code: "VE",
        name: "La Vega",
      },
      {
        code: "DO-MT",
        alpha2code: "MT",
        name: "María Trinidad Sánchez",
      },
      {
        code: "DO-MN",
        alpha2code: "MN",
        name: "Monseñor Nouel",
      },
      {
        code: "DO-MC",
        alpha2code: "MC",
        name: "Monte Cristi",
      },
      {
        code: "DO-MP",
        alpha2code: "MP",
        name: "Monte Plata",
      },
      {
        code: "DO-PN",
        alpha2code: "PN",
        name: "Pedernales",
      },
      {
        code: "DO-PR",
        alpha2code: "PR",
        name: "Peravia",
      },
      {
        code: "DO-PP",
        alpha2code: "PP",
        name: "Puerto Plata",
      },
      {
        code: "DO-SC",
        alpha2code: "SC",
        name: "Salcedo",
      },
      {
        code: "DO-SM",
        alpha2code: "SM",
        name: "Samaná",
      },
      {
        code: "DO-SZ",
        alpha2code: "SZ",
        name: "Sanchez Ramírez",
      },
      {
        code: "DO-CR",
        alpha2code: "CR",
        name: "San Cristóbal",
      },
      {
        code: "DO-JU",
        alpha2code: "JU",
        name: "San Juan",
      },
      {
        code: "DO-PM",
        alpha2code: "PM",
        name: "San Pedro de Macorís",
      },
      {
        code: "DO-ST",
        alpha2code: "ST",
        name: "Santiago",
      },
      {
        code: "DO-SR",
        alpha2code: "SR",
        name: "Santiago Rodríguez",
      },
      {
        code: "DO-VA",
        alpha2code: "VA",
        name: "Valverde",
      },
    ],
  },
  TP: {
    name: "East Timor",
    subdivisions: [
      {
        code: "TP-TP",
        alpha2code: "TP",
        name: "East Timor",
      },
    ],
  },
  EC: {
    name: "Ecuador",
    subdivisions: [
      {
        code: "EC-A",
        alpha2code: "A",
        name: "Azuay",
      },
      {
        code: "EC-B",
        alpha2code: "B",
        name: "Bolívar",
      },
      {
        code: "EC-F",
        alpha2code: "F",
        name: "Cañar",
      },
      {
        code: "EC-C",
        alpha2code: "C",
        name: "Carchi",
      },
      {
        code: "EC-X",
        alpha2code: "X",
        name: "Cotopaxi",
      },
      {
        code: "EC-H",
        alpha2code: "H",
        name: "Chimborazo",
      },
      {
        code: "EC-O",
        alpha2code: "O",
        name: "El Oro",
      },
      {
        code: "EC-E",
        alpha2code: "E",
        name: "Esmeraldas",
      },
      {
        code: "EC-W",
        alpha2code: "W",
        name: "Galápagos",
      },
      {
        code: "EC-G",
        alpha2code: "G",
        name: "Guayas",
      },
      {
        code: "EC-I",
        alpha2code: "I",
        name: "Imbabura",
      },
      {
        code: "EC-L",
        alpha2code: "L",
        name: "Loja",
      },
      {
        code: "EC-R",
        alpha2code: "R",
        name: "Los Ríos",
      },
      {
        code: "EC-M",
        alpha2code: "M",
        name: "Manabí",
      },
      {
        code: "EC-S",
        alpha2code: "S",
        name: "Morona-Santiago",
      },
      {
        code: "EC-N",
        alpha2code: "N",
        name: "Napo",
      },
      {
        code: "EC-Y",
        alpha2code: "Y",
        name: "Pastaza",
      },
      {
        code: "EC-P",
        alpha2code: "P",
        name: "Pichincha",
      },
      {
        code: "EC-U",
        alpha2code: "U",
        name: "Sucumbíos",
      },
      {
        code: "EC-T",
        alpha2code: "T",
        name: "Tungurahua",
      },
      {
        code: "EC-Z",
        alpha2code: "Z",
        name: "Zarnora-Chinchipe",
      },
    ],
  },
  EG: {
    name: "Egypt",
    subdivisions: [
      {
        code: "EG-DK",
        alpha2code: "DK",
        name: "Ad Daqahlīyah",
      },
      {
        code: "EG-BA",
        alpha2code: "BA",
        name: "Al Baḩr al Aḩmar",
      },
      {
        code: "EG-BH",
        alpha2code: "BH",
        name: "Al Buḩayrah",
      },
      {
        code: "EG-FYM",
        alpha2code: "FYM",
        name: "Al Fayyūm",
      },
      {
        code: "EG-GH",
        alpha2code: "GH",
        name: "Al Gharbīyah",
      },
      {
        code: "EG-ALX",
        alpha2code: "ALX",
        name: "Al Iskandarīyah",
      },
      {
        code: "EG-IS",
        alpha2code: "IS",
        name: "Al Ismā‘īlīyah",
      },
      {
        code: "EG-GZ",
        alpha2code: "GZ",
        name: "Al Jīzah",
      },
      {
        code: "EG-MNF",
        alpha2code: "MNF",
        name: "Al Minūfīyah",
      },
      {
        code: "EG-MN",
        alpha2code: "MN",
        name: "Al Minyā",
      },
      {
        code: "EG-C",
        alpha2code: "C",
        name: "Al Qāhirah",
      },
      {
        code: "EG-KB",
        alpha2code: "KB",
        name: "Al Qalyūbīyah",
      },
      {
        code: "EG-WAD",
        alpha2code: "WAD",
        name: "Al Wādī al Jadīd",
      },
      {
        code: "EG-SHR",
        alpha2code: "SHR",
        name: "Ash Sharqīyah",
      },
      {
        code: "EG-SUZ",
        alpha2code: "SUZ",
        name: "As Suways",
      },
      {
        code: "EG-ASN",
        alpha2code: "ASN",
        name: "Aswān",
      },
      {
        code: "EG-AST",
        alpha2code: "AST",
        name: "Asyūţ",
      },
      {
        code: "EG-BNS",
        alpha2code: "BNS",
        name: "Banī Suwayf",
      },
      {
        code: "EG-PTS",
        alpha2code: "PTS",
        name: "Būr Sa‘īd",
      },
      {
        code: "EG-DT",
        alpha2code: "DT",
        name: "Dumyāţ",
      },
      {
        code: "EG-JS",
        alpha2code: "JS",
        name: "Janūb Sīnā'",
      },
      {
        code: "EG-KFS",
        alpha2code: "KFS",
        name: "Kafr ash Shaykh",
      },
      {
        code: "EG-MT",
        alpha2code: "MT",
        name: "Maţrūḩ",
      },
      {
        code: "EG-KN",
        alpha2code: "KN",
        name: "Qinā",
      },
      {
        code: "EG-SIN",
        alpha2code: "SIN",
        name: "Shamāl Sīnā'",
      },
      {
        code: "EG-SHG",
        alpha2code: "SHG",
        name: "Sūhāj",
      },
    ],
  },
  SV: {
    name: "El Salvador",
    subdivisions: [
      {
        code: "SV-AH",
        alpha2code: "AH",
        name: "Ahuachapán",
      },
      {
        code: "SV-CA",
        alpha2code: "CA",
        name: "Cabañas",
      },
      {
        code: "SV-CU",
        alpha2code: "CU",
        name: "Cuscatlán",
      },
      {
        code: "SV-CH",
        alpha2code: "CH",
        name: "Chalatenango",
      },
      {
        code: "SV-LI",
        alpha2code: "LI",
        name: "La Libertad",
      },
      {
        code: "SV-PA",
        alpha2code: "PA",
        name: "La Paz",
      },
      {
        code: "SV-UN",
        alpha2code: "UN",
        name: "La Unión",
      },
      {
        code: "SV-MO",
        alpha2code: "MO",
        name: "Morazán",
      },
      {
        code: "SV-SM",
        alpha2code: "SM",
        name: "San Miguel",
      },
      {
        code: "SV-SS",
        alpha2code: "SS",
        name: "San Salvador",
      },
      {
        code: "SV-SA",
        alpha2code: "SA",
        name: "Santa Ana",
      },
      {
        code: "SV-SV",
        alpha2code: "SV",
        name: "San Vicente",
      },
      {
        code: "SV-SO",
        alpha2code: "SO",
        name: "Sonsonate",
      },
      {
        code: "SV-SU",
        alpha2code: "SU",
        name: "Usulután",
      },
    ],
  },
  GQ: {
    name: "Equatorial Guinea",
    subdivisions: [
      {
        code: "GQ-C",
        alpha2code: "C",
        name: "Región Continental",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Centro Sur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kie-Ntem",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Litoral",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wele-Nzás",
      },
      {
        code: "GQ-I",
        alpha2code: "I",
        name: "Región Insular",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Annobón",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bioko Norte",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bioko Sur",
      },
    ],
  },
  ER: {
    name: "Eritrea",
    subdivisions: [
      {
        code: "ER-AG",
        alpha2code: "AG",
        name: "Akele Guzai [Akalä Guzay]",
      },
      {
        code: "ER-AS",
        alpha2code: "AS",
        name: "Asmara [Asmära]",
      },
      {
        code: "ER-BA",
        alpha2code: "BA",
        name: "Barka",
      },
      {
        code: "ER-DE",
        alpha2code: "DE",
        name: "Denkalia [Dänkali]",
      },
      {
        code: "ER-GS",
        alpha2code: "GS",
        name: "Gash-Setit [Gaš enna Sätit]",
      },
      {
        code: "ER-HA",
        alpha2code: "HA",
        name: "Hamasien [Hamasén]",
      },
      {
        code: "ER-SA",
        alpha2code: "SA",
        name: "Sahel",
      },
      {
        code: "ER-SM",
        alpha2code: "SM",
        name: "Semhar [Sämhar]",
      },
      {
        code: "ER-SN",
        alpha2code: "SN",
        name: "Senhit [Sänhet]",
      },
      {
        code: "ER-SR",
        alpha2code: "SR",
        name: "Seraye [Särayé]",
      },
    ],
  },
  EE: {
    name: "Estonia",
    subdivisions: [
      {
        code: "EE-37",
        alpha2code: "37",
        name: "Harjumaa",
      },
      {
        code: "EE-39",
        alpha2code: "39",
        name: "Hiiumaa",
      },
      {
        code: "EE-44",
        alpha2code: "44",
        name: "Ida-Virumaa",
      },
      {
        code: "EE-49",
        alpha2code: "49",
        name: "Jōgevamaa",
      },
      {
        code: "EE-51",
        alpha2code: "51",
        name: "Järvamaa",
      },
      {
        code: "EE-57",
        alpha2code: "57",
        name: "Läänemaa",
      },
      {
        code: "EE-59",
        alpha2code: "59",
        name: "Lääne-Virumaa",
      },
      {
        code: "EE-65",
        alpha2code: "65",
        name: "Pōlvamaa",
      },
      {
        code: "EE-67",
        alpha2code: "67",
        name: "Pärnumaa",
      },
      {
        code: "EE-70",
        alpha2code: "70",
        name: "Raplamaa",
      },
      {
        code: "EE-74",
        alpha2code: "74",
        name: "Saaremaa",
      },
      {
        code: "EE-78",
        alpha2code: "78",
        name: "Tartumaa",
      },
      {
        code: "EE-82",
        alpha2code: "82",
        name: "Valgamaa",
      },
      {
        code: "EE-84",
        alpha2code: "84",
        name: "Viljandimaa",
      },
      {
        code: "EE-86",
        alpha2code: "86",
        name: "Vōrumaa",
      },
    ],
  },
  ET: {
    name: "Ethiopia",
    subdivisions: [
      {
        code: "ET-AA",
        alpha2code: "AA",
        name: "Addis Ababa [Addis Abeba]",
      },
      {
        code: "ET-AF",
        alpha2code: "AF",
        name: "Afar",
      },
      {
        code: "ET-AM",
        alpha2code: "AM",
        name: "Amara [Amhara]",
      },
      {
        code: "ET-BE",
        alpha2code: "BE",
        name: "Benshangul-Gumaz [Bénishangul]",
      },
      {
        code: "ET-GA",
        alpha2code: "GA",
        name: "Gambela Peoples [Gambéla]",
      },
      {
        code: "ET-HA",
        alpha2code: "HA",
        name: "Harari People [Harer]",
      },
      {
        code: "ET-OR",
        alpha2code: "OR",
        name: "Oromia [Oromo]",
      },
      {
        code: "ET-SO",
        alpha2code: "SO",
        name: "Somali",
      },
      {
        code: "ET-SN",
        alpha2code: "SN",
        name: "Southern Nations, Nationalities and Peoples",
      },
      {
        code: "ET-TI",
        alpha2code: "TI",
        name: "Tigrai [Tegré]",
      },
    ],
  },
  FK: {
    name: "Falkland Islands (Malvinas)",
    subdivisions: [
      {
        code: "FK-FK",
        alpha2code: "FK",
        name: "Falkland Islands (Malvinas)",
      },
    ],
  },
  FO: {
    name: "Faroe Islands",
    subdivisions: [
      {
        code: "FO-FO",
        alpha2code: "FO",
        name: "Faroe Islands",
      },
    ],
  },
  FJ: {
    name: "Fiji",
    subdivisions: [
      {
        code: "FJ-C",
        alpha2code: "C",
        name: "Central",
      },
      {
        code: "FJ-E",
        alpha2code: "E",
        name: "Eastern",
      },
      {
        code: "FJ-N",
        alpha2code: "N",
        name: "Northern",
      },
      {
        code: "FJ-W",
        alpha2code: "W",
        name: "Western",
      },
      {
        code: "FJ-R",
        alpha2code: "R",
        name: "Rotuma",
      },
    ],
  },
  FI: {
    name: "Finland",
    subdivisions: [
      {
        code: "FI-AL",
        alpha2code: "AL",
        name: "Ahvenanmaan lääni",
      },
      {
        code: "FI-ES",
        alpha2code: "ES",
        name: "Etelä-Suomen lääni",
      },
      {
        code: "FI-IS",
        alpha2code: "IS",
        name: "Itä-Suomen lääni",
      },
      {
        code: "FI-LL",
        alpha2code: "LL",
        name: "Lapin lääni",
      },
      {
        code: "FI-LS",
        alpha2code: "LS",
        name: "Länsi-Suomen lääni",
      },
      {
        code: "FI-OL",
        alpha2code: "OL",
        name: "Oulun lääni",
      },
    ],
  },
  FR: {
    name: "France",
    subdivisions: [
      {
        code: "FR-A",
        alpha2code: "A",
        name: "Alsace",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bas-Rhin",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haut-Rhin",
      },
      {
        code: "FR-B",
        alpha2code: "B",
        name: "Aquitaine",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Deux-Sèvres",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dordogne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gironde",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Landes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lot-et-Garonne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pyrénées-Atlantiques",
      },
      {
        code: "FR-C",
        alpha2code: "C",
        name: "Auvergne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Allier",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cantal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Puy-de-Dôme",
      },
      {
        code: "FR-P",
        alpha2code: "P",
        name: "Basse-Normandie",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Calvados",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Manche",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Orne",
      },
      {
        code: "FR-D",
        alpha2code: "D",
        name: "Bourgogne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Côte-d'Or",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nièvre",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Saône-et-Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Yonne",
      },
      {
        code: "FR-E",
        alpha2code: "E",
        name: "Bretagne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cotes-d'Armor",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Finistère",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ille-et-Vilaine",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Morbihan",
      },
      {
        code: "FR-F",
        alpha2code: "F",
        name: "Centre",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cher",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Eure-et-Loir",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Indre",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Indre-et-Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Loir-et-Cher",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Loiret",
      },
      {
        code: "FR-G",
        alpha2code: "G",
        name: "Champagne-Ardenne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ardennes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aube",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Marne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Marne",
      },
      {
        code: "FR-H",
        alpha2code: "H",
        name: "Corse",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Corse-du-Sud",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Corse",
      },
      {
        code: "FR-I",
        alpha2code: "I",
        name: "Franche-Comté",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Doubs",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Saône",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jura",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Territoire de Belfort",
      },
      {
        code: "FR-Q",
        alpha2code: "Q",
        name: "Haute-Normandie",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Eure",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Seine-Maritime",
      },
      {
        code: "FR-J",
        alpha2code: "J",
        name: "Île-de-France",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Essonne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hauts-de-Seine",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Paris",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Seine-et-Marne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Seine-Saint-Denis",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Val-de-Marne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Val-d'Oise",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Yvelines",
      },
      {
        code: "FR-K",
        alpha2code: "K",
        name: "Languedoc-Roussillon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aude",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gard",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hérault",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lozère",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pyrénées-Orientales",
      },
      {
        code: "FR-L",
        alpha2code: "L",
        name: "Limousin",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Corrèze",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Creuse",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Vienne",
      },
      {
        code: "FR-M",
        alpha2code: "M",
        name: "Lorraine",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Meurthe-et-Moselle",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Meuse",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Moselle",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vosges",
      },
      {
        code: "FR-N",
        alpha2code: "N",
        name: "Midi-Pyrénées",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ariège",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aveyron",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gers",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Garonne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hautes-Pyrénées",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lot",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tarn",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tarn-et-Garonne",
      },
      {
        code: "FR-O",
        alpha2code: "O",
        name: "Nord-Pas-de-Calais",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nord",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pas-de-Calais",
      },
      {
        code: "FR-R",
        alpha2code: "R",
        name: "Pays de la Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Loire-Atlantique",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Maine-et-Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mayenne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sarthe",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vendée",
      },
      {
        code: "FR-S",
        alpha2code: "S",
        name: "Picardie",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aisne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oise",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Somme",
      },
      {
        code: "FR-T",
        alpha2code: "T",
        name: "Poitou-Charentes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Charente",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Charente-Maritime",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vienne",
      },
      {
        code: "FR-U",
        alpha2code: "U",
        name: "Provence-Alpes-Côte d'Azur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Alpes-de-Haute-Provence",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Alpes-Maritimes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bauches-du-Rhône",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hautes-Alpes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Var",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vaucluse",
      },
      {
        code: "FR-V",
        alpha2code: "V",
        name: "Rhône-Alpes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ain",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ardèche",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Drôme",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haute-Savoie",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Isère",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Loire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rhône",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Savoie",
      },
      {
        code: "FR-GP",
        alpha2code: "GP",
        name: "Guadeloupe",
      },
      {
        code: "FR-GF",
        alpha2code: "GF",
        name: "Guyane (francaise)",
      },
      {
        code: "FR-MQ",
        alpha2code: "MQ",
        name: "Martinique",
      },
      {
        code: "FR-RE",
        alpha2code: "RE",
        name: "Réunion",
      },
      {
        code: "FR-YT",
        alpha2code: "YT",
        name: "Mayotte",
      },
      {
        code: "FR-PM",
        alpha2code: "PM",
        name: "Saint-Pierre-et-Miquelon",
      },
      {
        code: "FR-NC",
        alpha2code: "NC",
        name: "Nouvelle-Calédonie",
      },
      {
        code: "FR-PF",
        alpha2code: "PF",
        name: "Polynésie française",
      },
      {
        code: "FR-TF",
        alpha2code: "TF",
        name: "Terres Australes",
      },
      {
        code: "FR-WF",
        alpha2code: "WF",
        name: "Wallis et Futuna",
      },
    ],
  },
  GF: {
    name: "French Guiana",
    subdivisions: [
      {
        code: "GF-GF",
        alpha2code: "GF",
        name: "French Guiana",
      },
    ],
  },
  PF: {
    name: "French Polynesia",
    subdivisions: [
      {
        code: "PF-PF",
        alpha2code: "PF",
        name: "French Polynesia",
      },
    ],
  },
  TF: {
    name: "French Southern Territories",
    subdivisions: [
      {
        code: "TF-TF",
        alpha2code: "TF",
        name: "French Southern Territories",
      },
    ],
  },
  GA: {
    name: "Gabon",
    subdivisions: [
      {
        code: "GA-1",
        alpha2code: "1",
        name: "Estuaire",
      },
      {
        code: "GA-2",
        alpha2code: "2",
        name: "Haut-Ogooué",
      },
      {
        code: "GA-3",
        alpha2code: "3",
        name: "Moyen-Ogooué",
      },
      {
        code: "GA-4",
        alpha2code: "4",
        name: "Ngounié",
      },
      {
        code: "GA-5",
        alpha2code: "5",
        name: "Nyanga",
      },
      {
        code: "GA-6",
        alpha2code: "6",
        name: "Ogooué-Ivindo",
      },
      {
        code: "GA-7",
        alpha2code: "7",
        name: "Ogooué-Lolo",
      },
      {
        code: "GA-8",
        alpha2code: "8",
        name: "Ogooué-Maritime",
      },
      {
        code: "GA-9",
        alpha2code: "9",
        name: "Woleu-Ntem",
      },
    ],
  },
  GM: {
    name: "Gambia",
    subdivisions: [
      {
        code: "GM-B",
        alpha2code: "B",
        name: "Banjul",
      },
      {
        code: "GM-L",
        alpha2code: "L",
        name: "Lower River",
      },
      {
        code: "GM-M",
        alpha2code: "M",
        name: "MacCarthy Island",
      },
      {
        code: "GM-N",
        alpha2code: "N",
        name: "North Bank",
      },
      {
        code: "GM-U",
        alpha2code: "U",
        name: "Upper River",
      },
      {
        code: "GM-W",
        alpha2code: "W",
        name: "Western",
      },
    ],
  },
  GE: {
    name: "Georgia",
    subdivisions: [
      {
        code: "GE-AB",
        alpha2code: "AB",
        name: "Ap'khazet'is Avtonomiuri Respublika [Abkhazia]",
      },
      {
        code: "GE-AJ",
        alpha2code: "AJ",
        name: "Acharis Avtonomiuri Respublika [Ajaria]",
      },
      {
        code: "GE-BUS",
        alpha2code: "BUS",
        name: "Bat'umi",
      },
      {
        code: "GE-CHI",
        alpha2code: "CHI",
        name: "Chiat'ura",
      },
      {
        code: "GE-GAG",
        alpha2code: "GAG",
        name: "Gagra",
      },
      {
        code: "GE-GOR",
        alpha2code: "GOR",
        name: "Gori",
      },
      {
        code: "GE-KUT",
        alpha2code: "KUT",
        name: "K'ut'aisi",
      },
      {
        code: "GE-PTI",
        alpha2code: "PTI",
        name: "P'ot'i",
      },
      {
        code: "GE-RUS",
        alpha2code: "RUS",
        name: "Rust'avi",
      },
      {
        code: "GE-SUI",
        alpha2code: "SUI",
        name: "Sokhumi",
      },
      {
        code: "GE-TBS",
        alpha2code: "TBS",
        name: "T'bilisi",
      },
      {
        code: "GE-TQI",
        alpha2code: "TQI",
        name: "Tqibuli",
      },
      {
        code: "GE-TQV",
        alpha2code: "TQV",
        name: "Tqvarch'eli",
      },
      {
        code: "GE-TSQ",
        alpha2code: "TSQ",
        name: "Tsqalmbo",
      },
      {
        code: "GE-ZUG",
        alpha2code: "ZUG",
        name: "Zugdidi",
      },
      {
        code: "GE-01",
        alpha2code: "01",
        name: "Abashis Raioni",
      },
      {
        code: "GE-02",
        alpha2code: "02",
        name: "Adigenis Raioni",
      },
      {
        code: "GE-03",
        alpha2code: "03",
        name: "Akhalgoris Raioni",
      },
      {
        code: "GE-04",
        alpha2code: "04",
        name: "Akhalk'alak'is Raioni",
      },
      {
        code: "GE-05",
        alpha2code: "05",
        name: "Akhalts'ikhis Raioni",
      },
      {
        code: "GE-06",
        alpha2code: "06",
        name: "Akhmetis Raioni",
      },
      {
        code: "GE-07",
        alpha2code: "07",
        name: "Ambrolauris Raioni",
      },
      {
        code: "GE-08",
        alpha2code: "08",
        name: "Aspindzis Raioni",
      },
      {
        code: "GE-09",
        alpha2code: "09",
        name: "Baghdat'is Raioni",
      },
      {
        code: "GE-10",
        alpha2code: "10",
        name: "Bolnisis Raioni",
      },
      {
        code: "GE-11",
        alpha2code: "11",
        name: "Borjomis Raioni",
      },
      {
        code: "GE-12",
        alpha2code: "12",
        name: "Ch'khorotsqus Raioni",
      },
      {
        code: "GE-13",
        alpha2code: "13",
        name: "Ch'okhatauris Raioni",
      },
      {
        code: "GE-14",
        alpha2code: "14",
        name: "Dedop'listsqaros Raioni",
      },
      {
        code: "GE-15",
        alpha2code: "15",
        name: "Dmanisis Raioni",
      },
      {
        code: "GE-16",
        alpha2code: "16",
        name: "Dushet'is Raioni",
      },
      {
        code: "GE-17",
        alpha2code: "17",
        name: "Galis Raioni",
      },
      {
        code: "GE-18",
        alpha2code: "18",
        name: "Gardabnis Raioni",
      },
      {
        code: "GE-19",
        alpha2code: "19",
        name: "Goris Raioni",
      },
      {
        code: "GE-20",
        alpha2code: "20",
        name: "Gudaut'is Raioni",
      },
      {
        code: "GE-21",
        alpha2code: "21",
        name: "Gulrip'shis Raioni",
      },
      {
        code: "GE-22",
        alpha2code: "22",
        name: "Gurjaanis Raioni",
      },
      {
        code: "GE-23",
        alpha2code: "23",
        name: "Javis Raioni",
      },
      {
        code: "GE-24",
        alpha2code: "24",
        name: "K'arelis Raioni",
      },
      {
        code: "GE-25",
        alpha2code: "25",
        name: "Kaspis Raioni",
      },
      {
        code: "GE-26",
        alpha2code: "26",
        name: "K'edis Raioni",
      },
      {
        code: "GE-27",
        alpha2code: "27",
        name: "Kharagaulis Raioni",
      },
      {
        code: "GE-28",
        alpha2code: "28",
        name: "Khashuris Raioni",
      },
      {
        code: "GE-29",
        alpha2code: "29",
        name: "Khelvach'auris Raioni",
      },
      {
        code: "GE-30",
        alpha2code: "30",
        name: "Khobis Raioni",
      },
      {
        code: "GE-31",
        alpha2code: "31",
        name: "Khonis Raioni",
      },
      {
        code: "GE-32",
        alpha2code: "32",
        name: "Khulos Raioni",
      },
      {
        code: "GE-33",
        alpha2code: "33",
        name: "K'obuletis Raioni",
      },
      {
        code: "GE-34",
        alpha2code: "34",
        name: "Lagodekhis Raioni",
      },
      {
        code: "GE-35",
        alpha2code: "35",
        name: "Lanch'khut'is Raioni",
      },
      {
        code: "GE-36",
        alpha2code: "36",
        name: "Lentekhis Raioni",
      },
      {
        code: "GE-37",
        alpha2code: "37",
        name: "Marneulis Raioni",
      },
      {
        code: "GE-38",
        alpha2code: "38",
        name: "Martvilis Raioni",
      },
      {
        code: "GE-39",
        alpha2code: "39",
        name: "Mestiis Raioni",
      },
      {
        code: "GE-40",
        alpha2code: "40",
        name: "Mts'khet'is Raioni",
      },
      {
        code: "GE-41",
        alpha2code: "41",
        name: "Ninotsmindis Raioni",
      },
      {
        code: "GE-42",
        alpha2code: "42",
        name: "Och'amch'iris Raioni",
      },
      {
        code: "GE-43",
        alpha2code: "43",
        name: "Onis Raioni",
      },
      {
        code: "GE-44",
        alpha2code: "44",
        name: "Ozurget'is Raioni",
      },
      {
        code: "GE-45",
        alpha2code: "45",
        name: "Qazbegis Raioni",
      },
      {
        code: "GE-46",
        alpha2code: "46",
        name: "Qvarlis Raioni",
      },
      {
        code: "GE-47",
        alpha2code: "47",
        name: "Sach'kheris Raioni",
      },
      {
        code: "GE-48",
        alpha2code: "48",
        name: "Sagarejos Raioni",
      },
      {
        code: "GE-49",
        alpha2code: "49",
        name: "Samtrediis Raioni",
      },
      {
        code: "GE-50",
        alpha2code: "50",
        name: "Senakis Raioni",
      },
      {
        code: "GE-51",
        alpha2code: "51",
        name: "Shuakhevis Raioni",
      },
      {
        code: "GE-52",
        alpha2code: "52",
        name: "Sighnaghis Raioni",
      },
      {
        code: "GE-53",
        alpha2code: "53",
        name: "Sokhumis Raioni",
      },
      {
        code: "GE-54",
        alpha2code: "54",
        name: "T'elavis Raioni",
      },
      {
        code: "GE-55",
        alpha2code: "55",
        name: "T'erjolis Raioni",
      },
      {
        code: "GE-56",
        alpha2code: "56",
        name: "T'et'ritsqaros Raioni",
      },
      {
        code: "GE-57",
        alpha2code: "57",
        name: "T'ianet'is Raioni",
      },
      {
        code: "GE-58",
        alpha2code: "58",
        name: "Ts'ageris Raioni",
      },
      {
        code: "GE-59",
        alpha2code: "59",
        name: "Tsalenjikhis Raioni",
      },
      {
        code: "GE-60",
        alpha2code: "60",
        name: "Tsalkis Raioni",
      },
      {
        code: "GE-61",
        alpha2code: "61",
        name: "Vanis Raioni",
      },
      {
        code: "GE-62",
        alpha2code: "62",
        name: "Zestap'onis Raioni",
      },
      {
        code: "GE-63",
        alpha2code: "63",
        name: "Zugdidis Raioni",
      },
    ],
  },
  DE: {
    name: "Germany",
    subdivisions: [
      {
        code: "DE-BW",
        alpha2code: "BW",
        name: "Baden-Württemberg",
      },
      {
        code: "DE-BY",
        alpha2code: "BY",
        name: "Bayern",
      },
      {
        code: "DE-BE",
        alpha2code: "BE",
        name: "Berlin",
      },
      {
        code: "DE-BB",
        alpha2code: "BB",
        name: "Brandenburg",
      },
      {
        code: "DE-HB",
        alpha2code: "HB",
        name: "Bremen",
      },
      {
        code: "DE-HH",
        alpha2code: "HH",
        name: "Hamburg",
      },
      {
        code: "DE-HE",
        alpha2code: "HE",
        name: "Hessen",
      },
      {
        code: "DE-MV",
        alpha2code: "MV",
        name: "Mecklenburg-Vorpommern",
      },
      {
        code: "DE-NI",
        alpha2code: "NI",
        name: "Niedersachsen",
      },
      {
        code: "DE-NW",
        alpha2code: "NW",
        name: "Nordrhein-Westfalen",
      },
      {
        code: "DE-RP",
        alpha2code: "RP",
        name: "Rheinland-Pfalz",
      },
      {
        code: "DE-SL",
        alpha2code: "SL",
        name: "Saarland",
      },
      {
        code: "DE-SN",
        alpha2code: "SN",
        name: "Sachsen",
      },
      {
        code: "DE-ST",
        alpha2code: "ST",
        name: "Sachsen-Anhalt",
      },
      {
        code: "DE-SH",
        alpha2code: "SH",
        name: "Schleswig-Holstein",
      },
      {
        code: "DE-TH",
        alpha2code: "TH",
        name: "Thüringen",
      },
    ],
  },
  GH: {
    name: "Ghana",
    subdivisions: [
      {
        code: "GH-AH",
        alpha2code: "AH",
        name: "Ashanti",
      },
      {
        code: "GH-BA",
        alpha2code: "BA",
        name: "Brong-Ahafo",
      },
      {
        code: "GH-CP",
        alpha2code: "CP",
        name: "Central",
      },
      {
        code: "GH-EP",
        alpha2code: "EP",
        name: "Eastern",
      },
      {
        code: "GH-AA",
        alpha2code: "AA",
        name: "Greater Accra",
      },
      {
        code: "GH-NP",
        alpha2code: "NP",
        name: "Northern",
      },
      {
        code: "GH-UE",
        alpha2code: "UE",
        name: "Upper East",
      },
      {
        code: "GH-UW",
        alpha2code: "UW",
        name: "Upper West",
      },
      {
        code: "GH-TV",
        alpha2code: "TV",
        name: "Volta",
      },
      {
        code: "GH-WP",
        alpha2code: "WP",
        name: "Western",
      },
    ],
  },
  GI: {
    name: "Gibraltar",
    subdivisions: [
      {
        code: "GI-GI",
        alpha2code: "GI",
        name: "Gibraltar",
      },
    ],
  },
  GB: {
    name: "United Kingdom",
    subdivisions: [
      {
        code: "GB-CHA",
        alpha2code: "CHA",
        name: "Channel Islands",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Guernsey [Guernesey]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jersey",
      },
      {
        code: "GB-ENG",
        alpha2code: "ENG",
        name: "England",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Barking and Dagenham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Barnet",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Barnsley",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bath and North East Somerset",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bedfordshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bexley",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Birmingham (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Blackburn with Darwen",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Blackpool",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bolton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bournemouth",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bracknell Forest",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bradford (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brent",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brighton and Hove",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bristol, City of",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bromley",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Buckinghamshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bury",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Calderdale",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cambridgeshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Camden",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cheshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cornwall",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Coventry (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Croydon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cumbria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Darlington",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Derby (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Derbyshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Devon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Doncaster",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dorset",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dudley",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Durharn",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ealing",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Riding of Yorkshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Sussex",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Enfield",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Essex",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gateshead",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gloucestershire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Greenwich",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hackney",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haiton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hammersmith and Fulham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hampshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Haringey",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Harrow",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hartlepool",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Havering",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Herefordshire, County of",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hertfordshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hillingdon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hounslow",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Isle of Wight",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Isles of Scilly",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Islington",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kensington and Chelsea",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kent",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kingston upon Hull, City of",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kingston upon Thames",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kirklees",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Knowsley",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lambeth",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lancashire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Leeds (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Leitester (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Leicestershire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lewisham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lincolnshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Liverpool (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "London, City of",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Luton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Manchester (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Medway",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Merton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Middlesbrough",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Milton Keynes",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Newcastle upon Tyne (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Newham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Norfolk",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North East Lincolnshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Lincolnshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Somerset",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Tyneside",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Yorkshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Northamptonshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Northumberland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nottingham (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nottinghamshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oldham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oxfordshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Peterborough",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Plymouth (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Poole",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Portsmouth (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Reading",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Redbridge",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Redcar and Cleveland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Richmond upon Thames",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rochdale",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rotherharn",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rutland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "St. Helens",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Salford (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sandweil",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sefton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sheffield (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Shropshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Slough",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Solihull",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Somerset",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "South Gloucestershire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "South Tyneside",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Southampton (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Southend-on-Sea",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Southwark",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Staffordshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Stockport",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Stockton-On-Tees",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Stoke-on-Trent (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Suffolk",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sunderland (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Surrey",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sutton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Swindon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tameside",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Telford and Wrekin",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Thurrock",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Torbay",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tower Hamlets",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trafford",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wakefield (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Walsall",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Waltham Forest",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wandsworth",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Warrington",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Warwickshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West Berkshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West Sussex",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Westminster (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wigan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wiltshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Windsor and Maidenhead",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wirral",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wokingham",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wolverhampton",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Worcestershire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "York (City of)",
      },
      {
        code: "GB-IOM",
        alpha2code: "IOM",
        name: "Isle of Man",
      },
      {
        code: "GB-NIR",
        alpha2code: "NIR",
        name: "Northern Ireland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Antrim",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ards",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Armagh",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ballymena",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ballymoney",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Banbridge",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Belfast (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Carrickfergus",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Castlereagh",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Coleraine",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cookstown",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Craigavon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Derry (City of)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Down",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dungannon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fermanagh",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Larne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Limavady",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lisburn",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Magherafelt",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Moyle",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Newry and Mourne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Newtownabbey",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Down",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Omagh",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Strabane",
      },
      {
        code: "GB-SCT",
        alpha2code: "SCT",
        name: "Scotland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aberdeen City",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aberdeenshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Angus",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Argyll and Bute",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Clackmannanshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dumfries and Galloway",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dundee City",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Ayrshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Dunbartonshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Lothian",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "East Renfrewshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Edinburgh, City of",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Eilean Siar",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Falkirk",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fife",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Glasgow City",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Highland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Inverclyde",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Midlothian",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Moray",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Ayrshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "North Lanarkshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Orkney Islands",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Perth and Kinross",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Renfrewshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Scottish Borders, The",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Shetland Islands",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "South Ayrshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "South Lanarkshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Stirling",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West Dunbartonshire",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West Lothian",
      },
      {
        code: "GB-WLS",
        alpha2code: "WLS",
        name: "Wales [Cymru]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Blaenau Gwent",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Caerphilly [Caerffili GB-CAF]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cardiff (City of) [Caerdydd GB-CRD]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ceredigion [Sir Ceredigion]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Conwy",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Denbighshire [Sir Ddinbych GB-DDB]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Flintshire [Sir y Fflint GB-FFL]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gwynedd",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Isle of Anglesey [Sir Ynys Man GB-YNM]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Monmouthshire [Sir Fynwy GB-FYN]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Newport [Casnewydd GB-CNW]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pembrokeshire [Sir Benfro CB-BNF]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Powys",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Swansea (City of) [Abertawe GB-ATA]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Torfaen [Tor-faen]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wrexham [Wrecsam GB-WRC]",
      },
    ],
  },
  GR: {
    name: "Greece",
    subdivisions: [
      {
        code: "GR-I",
        alpha2code: "I",
        name: "Anatoliki Makedonia kai Thraki",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Drama",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Evros",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kavalla",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rodopi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Xanthi",
      },
      {
        code: "GR-IX",
        alpha2code: "IX",
        name: "Attiki",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Attiki",
      },
      {
        code: "GR-VII",
        alpha2code: "VII",
        name: "Dytiki Ellada",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Achaïa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aitolia-Akarnania",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ileia",
      },
      {
        code: "GR-III",
        alpha2code: "III",
        name: "Dytiki Makedonia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Florina",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Grevena",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kastoria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kozani",
      },
      {
        code: "GR-VI",
        alpha2code: "VI",
        name: "Ionioi Nisoi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kefallinia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kerkyra",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lefkas",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Zakynthos",
      },
      {
        code: "GR-IV",
        alpha2code: "IV",
        name: "Ipeiros",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Arta",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ioannina",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Preveza",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Thesprotia",
      },
      {
        code: "GR-II",
        alpha2code: "II",
        name: "Kentriki Makedonia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chalkidiki",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Imathia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kilkis",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pella",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pieria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Serrai",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Thessaloniki",
      },
      {
        code: "GR-XIII",
        alpha2code: "XIII",
        name: "Kriti",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chania",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Irakleion",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lasithion",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rethymnon",
      },
      {
        code: "GR-XII",
        alpha2code: "XII",
        name: "Notio Aigaio",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dodekanisos",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kyklades",
      },
      {
        code: "GR-X",
        alpha2code: "X",
        name: "Peloponnisos",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Argolis",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Arkadia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Korinthia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lakonia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Messinia",
      },
      {
        code: "GR-VIII",
        alpha2code: "VIII",
        name: "Sterea Ellada",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Evrytania",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Evvoia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fokis",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fthiotis",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Voiotia",
      },
      {
        code: "GR-V",
        alpha2code: "V",
        name: "Thessalia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Karditsa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Larisa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Magnisia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trikala",
      },
      {
        code: "GR-XI",
        alpha2code: "XI",
        name: "Voreio Aigaio",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chios",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lesvos",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Samos",
      },
    ],
  },
  GL: {
    name: "Greenland",
    subdivisions: [
      {
        code: "GL-GL",
        alpha2code: "GL",
        name: "Greenland",
      },
    ],
  },
  GD: {
    name: "Grenada",
    subdivisions: [
      {
        code: "GD-GD",
        alpha2code: "GD",
        name: "Grenada",
      },
    ],
  },
  GP: {
    name: "Guadeloupe",
    subdivisions: [
      {
        code: "GP-GP",
        alpha2code: "GP",
        name: "Guadeloupe",
      },
    ],
  },
  GU: {
    name: "Guam",
    subdivisions: [
      {
        code: "GU-GU",
        alpha2code: "GU",
        name: "Guam",
      },
    ],
  },
  GT: {
    name: "Guatemala",
    subdivisions: [
      {
        code: "GT-AV",
        alpha2code: "AV",
        name: "Alta Verapaz",
      },
      {
        code: "GT-BV",
        alpha2code: "BV",
        name: "Baja Verapaz",
      },
      {
        code: "GT-CM",
        alpha2code: "CM",
        name: "Chimaltenango",
      },
      {
        code: "GT-CQ",
        alpha2code: "CQ",
        name: "Chiquimula",
      },
      {
        code: "GT-PR",
        alpha2code: "PR",
        name: "El Progreso",
      },
      {
        code: "GT-ES",
        alpha2code: "ES",
        name: "Escuintla",
      },
      {
        code: "GT-GU",
        alpha2code: "GU",
        name: "Guatemala",
      },
      {
        code: "GT-HU",
        alpha2code: "HU",
        name: "Huehuetenango",
      },
      {
        code: "GT-IZ",
        alpha2code: "IZ",
        name: "Izabal",
      },
      {
        code: "GT-JA",
        alpha2code: "JA",
        name: "Jalapa",
      },
      {
        code: "GT-JU",
        alpha2code: "JU",
        name: "Jutiapa",
      },
      {
        code: "GT-PE",
        alpha2code: "PE",
        name: "Petén",
      },
      {
        code: "GT-QZ",
        alpha2code: "QZ",
        name: "Quezaltenango",
      },
      {
        code: "GT-QC",
        alpha2code: "QC",
        name: "Quiché",
      },
      {
        code: "GT-RE",
        alpha2code: "RE",
        name: "Retalhuleu",
      },
      {
        code: "GT-SA",
        alpha2code: "SA",
        name: "Sacatepéquez",
      },
      {
        code: "GT-SM",
        alpha2code: "SM",
        name: "San Marcos",
      },
      {
        code: "GT-SR",
        alpha2code: "SR",
        name: "Santa Rosa",
      },
      {
        code: "GT-SO",
        alpha2code: "SO",
        name: "Sololá",
      },
      {
        code: "GT-SU",
        alpha2code: "SU",
        name: "Suchitepéquez",
      },
      {
        code: "GT-TO",
        alpha2code: "TO",
        name: "Totonicapán",
      },
      {
        code: "GT-ZA",
        alpha2code: "ZA",
        name: "Zacapa",
      },
    ],
  },
  GN: {
    name: "Guinea",
    subdivisions: [
      {
        code: "GN-B",
        alpha2code: "B",
        name: "Bake, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Boffa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Boké",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gaoual",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Koundara",
      },
      {
        code: "GN-C",
        alpha2code: "C",
        name: "Conakry, Gouvernorat de",
      },
      {
        code: "GN-F",
        alpha2code: "F",
        name: "Faranah, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dabola",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dinguiraye",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Faranah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kissidougou",
      },
      {
        code: "GN-K",
        alpha2code: "K",
        name: "Kankan, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kankan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kérouané",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kouroussa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mandiana",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Siguiri",
      },
      {
        code: "GN-D",
        alpha2code: "D",
        name: "Kindia, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Coyah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dubréka",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Forécariah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kindia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Télimélé",
      },
      {
        code: "GN-L",
        alpha2code: "L",
        name: "Labé, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Koubia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Labé",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lélouma",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mali",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tougué",
      },
      {
        code: "GN-M",
        alpha2code: "M",
        name: "Mamou, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dalaba",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mamou",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pita",
      },
      {
        code: "GN-N",
        alpha2code: "N",
        name: "Nzérékoré, Gouvernorat de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Beyla",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Guékédou",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lola",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Macenta",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nzérékoré",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Yomou",
      },
    ],
  },
  GW: {
    name: "Guinea-Bissau",
    subdivisions: [
      {
        code: "GW-BS",
        alpha2code: "BS",
        name: "Bissau",
      },
      {
        code: "GW-BA",
        alpha2code: "BA",
        name: "Bafatá",
      },
      {
        code: "GW-BM",
        alpha2code: "BM",
        name: "Biombo",
      },
      {
        code: "GW-BL",
        alpha2code: "BL",
        name: "Bolama",
      },
      {
        code: "GW-CA",
        alpha2code: "CA",
        name: "Cacheu",
      },
      {
        code: "GW-GA",
        alpha2code: "GA",
        name: "Gabú",
      },
      {
        code: "GW-OI",
        alpha2code: "OI",
        name: "Oio",
      },
      {
        code: "GW-QU",
        alpha2code: "QU",
        name: "Quinara",
      },
    ],
  },
  GY: {
    name: "Guyana",
    subdivisions: [
      {
        code: "GY-BA",
        alpha2code: "BA",
        name: "Barima-Waini",
      },
      {
        code: "GY-CU",
        alpha2code: "CU",
        name: "Cuyuni-Mazaruni",
      },
      {
        code: "GY-DE",
        alpha2code: "DE",
        name: "Demerara-Mahaica",
      },
      {
        code: "GY-EB",
        alpha2code: "EB",
        name: "East Berbice-Corentyne",
      },
      {
        code: "GY-ES",
        alpha2code: "ES",
        name: "Essequibo Islands-West Demerara",
      },
      {
        code: "GY-MA",
        alpha2code: "MA",
        name: "Mahaica-Berbice",
      },
      {
        code: "GY-PM",
        alpha2code: "PM",
        name: "Pomeroon-Supenaam",
      },
      {
        code: "GY-PT",
        alpha2code: "PT",
        name: "Potaro-Siparuni",
      },
      {
        code: "GY-UD",
        alpha2code: "UD",
        name: "Upper Demerara-Berbice",
      },
      {
        code: "GY-UT",
        alpha2code: "UT",
        name: "Upper Takutu-Upper Essequibo",
      },
    ],
  },
  HT: {
    name: "Haiti",
    subdivisions: [
      {
        code: "HT-AR",
        alpha2code: "AR",
        name: "Artibonite",
      },
      {
        code: "HT-CE",
        alpha2code: "CE",
        name: "Centre",
      },
      {
        code: "HT-GA",
        alpha2code: "GA",
        name: "Grande-Anse",
      },
      {
        code: "HT-ND",
        alpha2code: "ND",
        name: "Nord",
      },
      {
        code: "HT-NE",
        alpha2code: "NE",
        name: "Nord-Est",
      },
      {
        code: "HT-NO",
        alpha2code: "NO",
        name: "Nord-Ouest",
      },
      {
        code: "HT-OU",
        alpha2code: "OU",
        name: "Ouest",
      },
      {
        code: "HT-SD",
        alpha2code: "SD",
        name: "Sud",
      },
      {
        code: "HT-SE",
        alpha2code: "SE",
        name: "Sud-Est",
      },
    ],
  },
  HM: {
    name: "Heard & McDonald Islands",
    subdivisions: [
      {
        code: "HM-HM",
        alpha2code: "HM",
        name: "Heard & McDonald Islands",
      },
    ],
  },
  VA: {
    name: "Vatican City (Holy See)",
    subdivisions: [
      {
        code: "VA-VA",
        alpha2code: "VA",
        name: "Vatican City (Holy See)",
      },
    ],
  },
  HN: {
    name: "Honduras",
    subdivisions: [
      {
        code: "HN-AT",
        alpha2code: "AT",
        name: "Atlántida",
      },
      {
        code: "HN-CL",
        alpha2code: "CL",
        name: "Colón",
      },
      {
        code: "HN-CM",
        alpha2code: "CM",
        name: "Comayagua",
      },
      {
        code: "HN-CP",
        alpha2code: "CP",
        name: "Copán",
      },
      {
        code: "HN-CR",
        alpha2code: "CR",
        name: "Cortés",
      },
      {
        code: "HN-CH",
        alpha2code: "CH",
        name: "Choluteca",
      },
      {
        code: "HN-EP",
        alpha2code: "EP",
        name: "El Paraíso",
      },
      {
        code: "HN-FM",
        alpha2code: "FM",
        name: "Francisco Morazán",
      },
      {
        code: "HN-GD",
        alpha2code: "GD",
        name: "Gracias a Dios",
      },
      {
        code: "HN-IN",
        alpha2code: "IN",
        name: "Intibucá",
      },
      {
        code: "HN-IB",
        alpha2code: "IB",
        name: "Islas de la Bahía",
      },
      {
        code: "HN-LP",
        alpha2code: "LP",
        name: "La Paz",
      },
      {
        code: "HN-LE",
        alpha2code: "LE",
        name: "Lempira",
      },
      {
        code: "HN-OC",
        alpha2code: "OC",
        name: "Ocotepeque",
      },
      {
        code: "HN-OL",
        alpha2code: "OL",
        name: "Olancho",
      },
      {
        code: "HN-SB",
        alpha2code: "SB",
        name: "Santa Bárbara",
      },
      {
        code: "HN-VA",
        alpha2code: "VA",
        name: "Valle",
      },
      {
        code: "HN-YO",
        alpha2code: "YO",
        name: "Yoro",
      },
    ],
  },
  HK: {
    name: "Hong Kong",
    subdivisions: [
      {
        code: "HK-HK",
        alpha2code: "HK",
        name: "Hong Kong",
      },
    ],
  },
  HU: {
    name: "Hungary",
    subdivisions: [
      {
        code: "HU-BU",
        alpha2code: "BU",
        name: "Budapest",
      },
      {
        code: "HU-BK",
        alpha2code: "BK",
        name: "Bács-Kiskun",
      },
      {
        code: "HU-BA",
        alpha2code: "BA",
        name: "Baranya",
      },
      {
        code: "HU-BE",
        alpha2code: "BE",
        name: "Békés",
      },
      {
        code: "HU-BZ",
        alpha2code: "BZ",
        name: "Borsod-Abaúj-Zemplén",
      },
      {
        code: "HU-CS",
        alpha2code: "CS",
        name: "Csongrád",
      },
      {
        code: "HU-FE",
        alpha2code: "FE",
        name: "Fejér",
      },
      {
        code: "HU-GS",
        alpha2code: "GS",
        name: "Gyór-Moson-Sopron",
      },
      {
        code: "HU-HB",
        alpha2code: "HB",
        name: "Hajdú-Bihar",
      },
      {
        code: "HU-HE",
        alpha2code: "HE",
        name: "Heves",
      },
      {
        code: "HU-JN",
        alpha2code: "JN",
        name: "Jasz-Nagykun-Szolnok",
      },
      {
        code: "HU-KE",
        alpha2code: "KE",
        name: "Komárom-Esztergom",
      },
      {
        code: "HU-NO",
        alpha2code: "NO",
        name: "Nógrád",
      },
      {
        code: "HU-PE",
        alpha2code: "PE",
        name: "Pest",
      },
      {
        code: "HU-SO",
        alpha2code: "SO",
        name: "Somogy",
      },
      {
        code: "HU-SZ",
        alpha2code: "SZ",
        name: "Szabolcs-Szatmár-Bereg",
      },
      {
        code: "HU-TO",
        alpha2code: "TO",
        name: "Tolna",
      },
      {
        code: "HU-VA",
        alpha2code: "VA",
        name: "Vas",
      },
      {
        code: "HU-VE",
        alpha2code: "VE",
        name: "Veszprém",
      },
      {
        code: "HU-ZA",
        alpha2code: "ZA",
        name: "Zala",
      },
      {
        code: "HU-BC",
        alpha2code: "BC",
        name: "Békéscsaba",
      },
      {
        code: "HU-DE",
        alpha2code: "DE",
        name: "Debrecen",
      },
      {
        code: "HU-DU",
        alpha2code: "DU",
        name: "Dunaújváros",
      },
      {
        code: "HU-EG",
        alpha2code: "EG",
        name: "Eger",
      },
      {
        code: "HU-GY",
        alpha2code: "GY",
        name: "Gyór",
      },
      {
        code: "HU-HV",
        alpha2code: "HV",
        name: "Hódmezóvásárhely",
      },
      {
        code: "HU-KV",
        alpha2code: "KV",
        name: "Kaposvár",
      },
      {
        code: "HU-KM",
        alpha2code: "KM",
        name: "Kecskemét",
      },
      {
        code: "HU-MI",
        alpha2code: "MI",
        name: "Miskolc",
      },
      {
        code: "HU-NK",
        alpha2code: "NK",
        name: "Nagykanizsa",
      },
      {
        code: "HU-NY",
        alpha2code: "NY",
        name: "Nyíregyháza",
      },
      {
        code: "HU-PS",
        alpha2code: "PS",
        name: "Pécs",
      },
      {
        code: "HU-ST",
        alpha2code: "ST",
        name: "Salgótarján",
      },
      {
        code: "HU-SN",
        alpha2code: "SN",
        name: "Sopron",
      },
      {
        code: "HU-SD",
        alpha2code: "SD",
        name: "Szeged",
      },
      {
        code: "HU-SF",
        alpha2code: "SF",
        name: "Székesfehérvár",
      },
      {
        code: "HU-SS",
        alpha2code: "SS",
        name: "Szekszárd",
      },
      {
        code: "HU-SK",
        alpha2code: "SK",
        name: "Szolnok",
      },
      {
        code: "HU-SH",
        alpha2code: "SH",
        name: "Szombathely",
      },
      {
        code: "HU-TB",
        alpha2code: "TB",
        name: "Tatabánya",
      },
      {
        code: "HU-VM",
        alpha2code: "VM",
        name: "Veszprém",
      },
      {
        code: "HU-ZE",
        alpha2code: "ZE",
        name: "Zalaegerszeg",
      },
    ],
  },
  IS: {
    name: "Iceland",
    subdivisions: [
      {
        code: "IS-7",
        alpha2code: "7",
        name: "Austurland",
      },
      {
        code: "IS-1",
        alpha2code: "1",
        name: "Höfudborgarsvædi utan Reykjavíkur",
      },
      {
        code: "IS-6",
        alpha2code: "6",
        name: "Nordurland eystra",
      },
      {
        code: "IS-5",
        alpha2code: "5",
        name: "Nordurland vestra",
      },
      {
        code: "IS-0",
        alpha2code: "0",
        name: "Reykjavīk",
      },
      {
        code: "IS-8",
        alpha2code: "8",
        name: "Sudurland",
      },
      {
        code: "IS-2",
        alpha2code: "2",
        name: "Sudurnes",
      },
      {
        code: "IS-4",
        alpha2code: "4",
        name: "Vestfirdir",
      },
      {
        code: "IS-3",
        alpha2code: "3",
        name: "Vesturland",
      },
    ],
  },
  IN: {
    name: "India",
    subdivisions: [
      {
        code: "IN-AP",
        alpha2code: "AP",
        name: "Andhra Pradesh",
      },
      {
        code: "IN-AR",
        alpha2code: "AR",
        name: "Arunachal Pradesh",
      },
      {
        code: "IN-AS",
        alpha2code: "AS",
        name: "Assam",
      },
      {
        code: "IN-BR",
        alpha2code: "BR",
        name: "Bihar",
      },
      {
        code: "IN-GA",
        alpha2code: "GA",
        name: "Goa",
      },
      {
        code: "IN-GJ",
        alpha2code: "GJ",
        name: "Gujarat",
      },
      {
        code: "IN-HR",
        alpha2code: "HR",
        name: "Haryana",
      },
      {
        code: "IN-HP",
        alpha2code: "HP",
        name: "Himachal Pradesh",
      },
      {
        code: "IN-JK",
        alpha2code: "JK",
        name: "Jammu and Kashmir",
      },
      {
        code: "IN-KA",
        alpha2code: "KA",
        name: "Karnataka",
      },
      {
        code: "IN-KL",
        alpha2code: "KL",
        name: "Kerala",
      },
      {
        code: "IN-MP",
        alpha2code: "MP",
        name: "Madhya Pradesh",
      },
      {
        code: "IN-MH",
        alpha2code: "MH",
        name: "Maharashtra",
      },
      {
        code: "IN-MN",
        alpha2code: "MN",
        name: "Manipur",
      },
      {
        code: "IN-ML",
        alpha2code: "ML",
        name: "Meghalaya",
      },
      {
        code: "IN-MZ",
        alpha2code: "MZ",
        name: "Mizoram",
      },
      {
        code: "IN-NL",
        alpha2code: "NL",
        name: "Nagaland",
      },
      {
        code: "IN-OR",
        alpha2code: "OR",
        name: "Orissa",
      },
      {
        code: "IN-PB",
        alpha2code: "PB",
        name: "Punjab",
      },
      {
        code: "IN-RJ",
        alpha2code: "RJ",
        name: "Rajasthan",
      },
      {
        code: "IN-SK",
        alpha2code: "SK",
        name: "Sikkim",
      },
      {
        code: "IN-TN",
        alpha2code: "TN",
        name: "Tamil Nadu",
      },
      {
        code: "IN-TR",
        alpha2code: "TR",
        name: "Tripura",
      },
      {
        code: "IN-UP",
        alpha2code: "UP",
        name: "Uttar Pradesh",
      },
      {
        code: "IN-WB",
        alpha2code: "WB",
        name: "West Bengal",
      },
      {
        code: "IN-AN",
        alpha2code: "AN",
        name: "Andaman and Nicobar Islands",
      },
      {
        code: "IN-CH",
        alpha2code: "CH",
        name: "Chandigarh",
      },
      {
        code: "IN-DN",
        alpha2code: "DN",
        name: "Dadra and Nagar Haveli",
      },
      {
        code: "IN-DD",
        alpha2code: "DD",
        name: "Daman and Diu",
      },
      {
        code: "IN-DL",
        alpha2code: "DL",
        name: "Delhi",
      },
      {
        code: "IN-LD",
        alpha2code: "LD",
        name: "Lakshadweep",
      },
      {
        code: "IN-PY",
        alpha2code: "PY",
        name: "Pondicherry",
      },
    ],
  },
  ID: {
    name: "Indonesia",
    subdivisions: [
      {
        code: "ID-IJU",
        alpha2code: "IJU",
        name: "Irian Jaya",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Irian Jaya",
      },
      {
        code: "ID-JWU",
        alpha2code: "JWU",
        name: "Jawa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jawa Barat",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jawa Tengah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jawa Timur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jakarta Raya",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Yogyakarta",
      },
      {
        code: "ID-KAU",
        alpha2code: "KAU",
        name: "Kalimantan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kalimantan Barat",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kalimantan Selatan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kalimantan Tengah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kalimantan Timur",
      },
      {
        code: "ID-MAU",
        alpha2code: "MAU",
        name: "Maluku",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Maluku",
      },
      {
        code: "ID-NUU",
        alpha2code: "NUU",
        name: "Nusa Tenggara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bali",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nusa Tenggara Barat",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nusa Tenggara Timur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Timor Timur",
      },
      {
        code: "ID-SLU",
        alpha2code: "SLU",
        name: "Sulawesi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sulawesi Selatan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sulawesi Tengah",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sulawesi Tenggara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sulawesi Utara",
      },
      {
        code: "ID-SMU",
        alpha2code: "SMU",
        name: "Sumatera",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bengkulu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jambi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lampung",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Riau",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sumatera Barat",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sumatera Selatan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sumatera Utara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aceh",
      },
    ],
  },
  IR: {
    name: "Iran",
    subdivisions: [
      {
        code: "IR-03",
        alpha2code: "03",
        name: "Ardabīl",
      },
      {
        code: "IR-02",
        alpha2code: "02",
        name: "Āzarbāyjān-e-Gharbī",
      },
      {
        code: "IR-01",
        alpha2code: "01",
        name: "Āzarbāyjān-e-Sharqī",
      },
      {
        code: "IR-06",
        alpha2code: "06",
        name: "Būshehr",
      },
      {
        code: "IR-08",
        alpha2code: "08",
        name: "Chahār Maḩāll vā Bakhtīārī",
      },
      {
        code: "IR-04",
        alpha2code: "04",
        name: "Eşfahān",
      },
      {
        code: "IR-14",
        alpha2code: "14",
        name: "Fārs",
      },
      {
        code: "IR-19",
        alpha2code: "19",
        name: "Gīlān",
      },
      {
        code: "IR-24",
        alpha2code: "24",
        name: "Hamadān",
      },
      {
        code: "IR-23",
        alpha2code: "23",
        name: "Hormozgān",
      },
      {
        code: "IR-05",
        alpha2code: "05",
        name: "Īlām",
      },
      {
        code: "IR-15",
        alpha2code: "15",
        name: "Kermān",
      },
      {
        code: "IR-17",
        alpha2code: "17",
        name: "Kermānshāhān",
      },
      {
        code: "IR-09",
        alpha2code: "09",
        name: "Khorāsān",
      },
      {
        code: "IR-10",
        alpha2code: "10",
        name: "Khūzestān",
      },
      {
        code: "IR-18",
        alpha2code: "18",
        name: "Kohkīlūyeh va Būyer Aḩmadī",
      },
      {
        code: "IR-16",
        alpha2code: "16",
        name: "Kordestān",
      },
      {
        code: "IR-20",
        alpha2code: "20",
        name: "Lorestān",
      },
      {
        code: "IR-22",
        alpha2code: "22",
        name: "Markazī",
      },
      {
        code: "IR-21",
        alpha2code: "21",
        name: "Māzandarān",
      },
      {
        code: "IR-26",
        alpha2code: "26",
        name: "Qom",
      },
      {
        code: "IR-12",
        alpha2code: "12",
        name: "Semnān",
      },
      {
        code: "IR-13",
        alpha2code: "13",
        name: "Sīstān va Balūchestān",
      },
      {
        code: "IR-07",
        alpha2code: "07",
        name: "Tehrān",
      },
      {
        code: "IR-25",
        alpha2code: "25",
        name: "Yazd",
      },
      {
        code: "IR-11",
        alpha2code: "11",
        name: "Zanjān",
      },
    ],
  },
  IQ: {
    name: "Iraq",
    subdivisions: [
      {
        code: "IQ-AN",
        alpha2code: "AN",
        name: "Al Anbār",
      },
      {
        code: "IQ-BA",
        alpha2code: "BA",
        name: "Al Başrah",
      },
      {
        code: "IQ-MU",
        alpha2code: "MU",
        name: "Al Muthanná",
      },
      {
        code: "IQ-QA",
        alpha2code: "QA",
        name: "Al Qādisīyah",
      },
      {
        code: "IQ-NA",
        alpha2code: "NA",
        name: "An Najaf",
      },
      {
        code: "IQ-AR",
        alpha2code: "AR",
        name: "Arbīl",
      },
      {
        code: "IQ-SU",
        alpha2code: "SU",
        name: "As Sulaymānīyah",
      },
      {
        code: "IQ-TS",
        alpha2code: "TS",
        name: "At Ta'mīm",
      },
      {
        code: "IQ-BB",
        alpha2code: "BB",
        name: "Bābil",
      },
      {
        code: "IQ-BG",
        alpha2code: "BG",
        name: "Baghdād",
      },
      {
        code: "IQ-DA",
        alpha2code: "DA",
        name: "Dahūk",
      },
      {
        code: "IQ-DQ",
        alpha2code: "DQ",
        name: "Dhī Qār",
      },
      {
        code: "IQ-DI",
        alpha2code: "DI",
        name: "Diyālá",
      },
      {
        code: "IQ-KA",
        alpha2code: "KA",
        name: "Karbalā'",
      },
      {
        code: "IQ-MA",
        alpha2code: "MA",
        name: "Maysān",
      },
      {
        code: "IQ-NI",
        alpha2code: "NI",
        name: "Nīnawá",
      },
      {
        code: "IQ-SD",
        alpha2code: "SD",
        name: "Şalāḩ ad Dīn",
      },
      {
        code: "IQ-WA",
        alpha2code: "WA",
        name: "Wāsiţ",
      },
    ],
  },
  IE: {
    name: "Ireland",
    subdivisions: [
      {
        code: "IE-CP",
        alpha2code: "CP",
        name: "Connaught (Connacht)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Galway (Gaillimh)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Leitrim (Liatroim)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mayo (Maigh Eo)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Roscommon (Ros Comáin)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sligo (Sligeach)",
      },
      {
        code: "IE-LP",
        alpha2code: "LP",
        name: "Leinster (Laighin)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Carlow (Ceatharlach)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dublin (Baile Átha Cliath)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kildare (Cill Dara)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kilkenny (Cill Chainnigh)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Laois (Laois)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Longford (An Longfort)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Louth (Lú)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Meath (An Mhí)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Offaly (Uíbh Fhailí)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Westmeath (An Iarmhí)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wexford (Loch Garman)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wicklow (Cill Mhantáin)",
      },
      {
        code: "IE-M",
        alpha2code: "M",
        name: "Munster (An Mhumhain)",
      },
      {
        code: "IE-UP",
        alpha2code: "UP",
        name: "Ulster (Ulaidh)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cavan (An Cabhán)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Donegal (Dún na nGall)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Monaghan (Muineachán)",
      },
    ],
  },
  IL: {
    name: "Israel",
    subdivisions: [
      {
        code: "IL-D",
        alpha2code: "D",
        name: "HaDarom (El Janūbī)",
      },
      {
        code: "IL-M",
        alpha2code: "M",
        name: "HaMerkaz (El Awsat)",
      },
      {
        code: "IL-2",
        alpha2code: "2",
        name: "HaZafon (Esh Shamālī)",
      },
      {
        code: "IL-HA",
        alpha2code: "HA",
        name: "Hefa (Heifā)",
      },
      {
        code: "IL-TA",
        alpha2code: "TA",
        name: "Tel-Aviv (Tell Abīb)",
      },
      {
        code: "IL-JM",
        alpha2code: "JM",
        name: "Yerushalayim (Al Quds)",
      },
    ],
  },
  IT: {
    name: "Italy",
    subdivisions: [
      {
        code: "IT-65",
        alpha2code: "65",
        name: "Abruzzo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chieti",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "L'Aquila",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pescara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Teramo",
      },
      {
        code: "IT-77",
        alpha2code: "77",
        name: "Basilicata",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Matera",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Potenza",
      },
      {
        code: "IT-78",
        alpha2code: "78",
        name: "Calabria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Catanzaro",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cosenza",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Crotone",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Reggio Calabria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vibo Valentia",
      },
      {
        code: "IT-72",
        alpha2code: "72",
        name: "Campania",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Avellino",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Benevento",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Caserta",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Napoli",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Salerno",
      },
      {
        code: "IT-45",
        alpha2code: "45",
        name: "Emilia-Romagna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bologna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ferrara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Forlì",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Modena",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Parma",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Piacenza",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ravenna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Reggio Emilia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rimini",
      },
      {
        code: "IT-36",
        alpha2code: "36",
        name: "Friuli-Venezia Giulia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gorizia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pordenone",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trieste",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Udine",
      },
      {
        code: "IT-62",
        alpha2code: "62",
        name: "Lazio",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Frosinone",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Latina",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rieti",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Roma",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Viterbo",
      },
      {
        code: "IT-42",
        alpha2code: "42",
        name: "Liguria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Genova",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Imperia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "La Spezia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Savona",
      },
      {
        code: "IT-25",
        alpha2code: "25",
        name: "Lombardia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bergamo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brescia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Como",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cremona",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lecco",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lodi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mantova",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Milano",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pavia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sondrio",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Varese",
      },
      {
        code: "IT-57",
        alpha2code: "57",
        name: "Marche",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ancona",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ascoli Piceno",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Macerata",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pesaro",
      },
      {
        code: "IT-67",
        alpha2code: "67",
        name: "Molise",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Campobasso",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Isernia",
      },
      {
        code: "IT-21",
        alpha2code: "21",
        name: "Piemonte",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Alessandria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Asti",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Biella",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cuneo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Novara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Torino",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Verbano-Cusio-Ossola",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vercelli",
      },
      {
        code: "IT-75",
        alpha2code: "75",
        name: "Puglia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bari",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Brindisi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Foggia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lecce",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Taranto",
      },
      {
        code: "IT-88",
        alpha2code: "88",
        name: "Sardegna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cagliari",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nuoro",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oristano",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sassari",
      },
      {
        code: "IT-82",
        alpha2code: "82",
        name: "Sicilia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Agrigento",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Caltanissetta",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Catania",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Enna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mesaina",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Palermo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ragusa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Siracusa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trapani",
      },
      {
        code: "IT-52",
        alpha2code: "52",
        name: "Toscana",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Arezzo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Firenze",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Grosseto",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Livorno",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lucca",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Massa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pisa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pistoia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Prato",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Siena",
      },
      {
        code: "IT-32",
        alpha2code: "32",
        name: "Trentino-Alte Adige (Trentino-Südtirol)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bolzano (Bozen)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trento",
      },
      {
        code: "IT-55",
        alpha2code: "55",
        name: "Umbria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Perugia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Terni",
      },
      {
        code: "IT-23",
        alpha2code: "23",
        name: "Valle d'Aosta (Vallée d'Aoste)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aosta (Aoste)",
      },
      {
        code: "IT-34",
        alpha2code: "34",
        name: "Veneto",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Belluno",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Padova",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rovigo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Treviso",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Venezia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Verona",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vicenza",
      },
    ],
  },
  JM: {
    name: "Jamaica",
    subdivisions: [
      {
        code: "JM-13",
        alpha2code: "13",
        name: "Clarendon",
      },
      {
        code: "JM-09",
        alpha2code: "09",
        name: "Hanover",
      },
      {
        code: "JM-01",
        alpha2code: "01",
        name: "Kingston",
      },
      {
        code: "JM-12",
        alpha2code: "12",
        name: "Manchester",
      },
      {
        code: "JM-04",
        alpha2code: "04",
        name: "Portland",
      },
      {
        code: "JM-02",
        alpha2code: "02",
        name: "Saint Andrew",
      },
      {
        code: "JM-06",
        alpha2code: "06",
        name: "Saint Ann",
      },
      {
        code: "JM-14",
        alpha2code: "14",
        name: "Saint Catherine",
      },
      {
        code: "JM-11",
        alpha2code: "11",
        name: "Saint Elizabeth",
      },
      {
        code: "JM-08",
        alpha2code: "08",
        name: "Saint James",
      },
      {
        code: "JM-05",
        alpha2code: "05",
        name: "Saint Mary",
      },
      {
        code: "JM-03",
        alpha2code: "03",
        name: "Saint Thomas",
      },
      {
        code: "JM-07",
        alpha2code: "07",
        name: "Trelawny",
      },
      {
        code: "JM-10",
        alpha2code: "10",
        name: "Westmoreland",
      },
    ],
  },
  JP: {
    name: "Japan",
    subdivisions: [
      {
        code: "JP-23",
        alpha2code: "23",
        name: "Aiti [Aichi]",
      },
      {
        code: "JP-05",
        alpha2code: "05",
        name: "Akita",
      },
      {
        code: "JP-02",
        alpha2code: "02",
        name: "Aomori",
      },
      {
        code: "JP-38",
        alpha2code: "38",
        name: "Ehime",
      },
      {
        code: "JP-21",
        alpha2code: "21",
        name: "Gihu [Gifu]",
      },
      {
        code: "JP-10",
        alpha2code: "10",
        name: "Gunma",
      },
      {
        code: "JP-34",
        alpha2code: "34",
        name: "Hirosima [Hiroshima]",
      },
      {
        code: "JP-01",
        alpha2code: "01",
        name: "Hokkaidô [Hokkaido]",
      },
      {
        code: "JP-18",
        alpha2code: "18",
        name: "Hukui [Fukui]",
      },
      {
        code: "JP-40",
        alpha2code: "40",
        name: "Hukuoka [Fukuoka]",
      },
      {
        code: "JP-07",
        alpha2code: "07",
        name: "Hukusima [Fukushima]",
      },
      {
        code: "JP-28",
        alpha2code: "28",
        name: "Hyôgo [Hyogo]",
      },
      {
        code: "JP-08",
        alpha2code: "08",
        name: "Ibaraki",
      },
      {
        code: "JP-17",
        alpha2code: "17",
        name: "Isikawa [Ishikawa]",
      },
      {
        code: "JP-03",
        alpha2code: "03",
        name: "Iwate",
      },
      {
        code: "JP-37",
        alpha2code: "37",
        name: "Kagawa",
      },
      {
        code: "JP-46",
        alpha2code: "46",
        name: "Kagosima [Kagoshima]",
      },
      {
        code: "JP-14",
        alpha2code: "14",
        name: "Kanagawa",
      },
      {
        code: "JP-39",
        alpha2code: "39",
        name: "Kôti [Kochi]",
      },
      {
        code: "JP-43",
        alpha2code: "43",
        name: "Kumamoto",
      },
      {
        code: "JP-26",
        alpha2code: "26",
        name: "Kyôto [Kyoto]",
      },
      {
        code: "JP-24",
        alpha2code: "24",
        name: "Mie",
      },
      {
        code: "JP-04",
        alpha2code: "04",
        name: "Miyagi",
      },
      {
        code: "JP-45",
        alpha2code: "45",
        name: "Miyazaki",
      },
      {
        code: "JP-20",
        alpha2code: "20",
        name: "Nagano",
      },
      {
        code: "JP-42",
        alpha2code: "42",
        name: "Nagasaki",
      },
      {
        code: "JP-29",
        alpha2code: "29",
        name: "Nara",
      },
      {
        code: "JP-15",
        alpha2code: "15",
        name: "Niigata",
      },
      {
        code: "JP-44",
        alpha2code: "44",
        name: "Ôita [Oita]",
      },
      {
        code: "JP-33",
        alpha2code: "33",
        name: "Okayama",
      },
      {
        code: "JP-47",
        alpha2code: "47",
        name: "Okinawa",
      },
      {
        code: "JP-27",
        alpha2code: "27",
        name: "Ôsaka [Osaka]",
      },
      {
        code: "JP-41",
        alpha2code: "41",
        name: "Saga",
      },
      {
        code: "JP-11",
        alpha2code: "11",
        name: "Saitama",
      },
      {
        code: "JP-25",
        alpha2code: "25",
        name: "Siga [Shiga]",
      },
      {
        code: "JP-22",
        alpha2code: "22",
        name: "Sizuoka [Shizuoka]",
      },
      {
        code: "JP-12",
        alpha2code: "12",
        name: "Tiba [Chiba]",
      },
      {
        code: "JP-09",
        alpha2code: "09",
        name: "Totigi [Tochigi]",
      },
      {
        code: "JP-36",
        alpha2code: "36",
        name: "Tokusima [Tokushima]",
      },
      {
        code: "JP-13",
        alpha2code: "13",
        name: "Tôkyô [Tokyo]",
      },
      {
        code: "JP-31",
        alpha2code: "31",
        name: "Tottori",
      },
      {
        code: "JP-16",
        alpha2code: "16",
        name: "Toyama",
      },
      {
        code: "JP-30",
        alpha2code: "30",
        name: "Wakayama",
      },
      {
        code: "JP-06",
        alpha2code: "06",
        name: "Yamagata",
      },
      {
        code: "JP-35",
        alpha2code: "35",
        name: "Yamaguti [Yamaguchi]",
      },
      {
        code: "JP-19",
        alpha2code: "19",
        name: "Yamanasi [Yamanashi]",
      },
    ],
  },
  JO: {
    name: "Jordan",
    subdivisions: [
      {
        code: "JO-AJ",
        alpha2code: "AJ",
        name: "‘Ajlūn",
      },
      {
        code: "JO-AQ",
        alpha2code: "AQ",
        name: "Al 'Aqaba",
      },
      {
        code: "JO-BA",
        alpha2code: "BA",
        name: "Al Balqā'",
      },
      {
        code: "JO-KA",
        alpha2code: "KA",
        name: "Al Karak",
      },
      {
        code: "JO-MA",
        alpha2code: "MA",
        name: "Al Mafraq",
      },
      {
        code: "JO-AM",
        alpha2code: "AM",
        name: "‘Ammān",
      },
      {
        code: "JO-AT",
        alpha2code: "AT",
        name: "Aţ Ţafīlah",
      },
      {
        code: "JO-AZ",
        alpha2code: "AZ",
        name: "Az Zarqā'",
      },
      {
        code: "JO-IR",
        alpha2code: "IR",
        name: "Irbid",
      },
      {
        code: "JO-JA",
        alpha2code: "JA",
        name: "Jarash",
      },
      {
        code: "JO-MN",
        alpha2code: "MN",
        name: "Ma‘ān",
      },
      {
        code: "JO-MD",
        alpha2code: "MD",
        name: "Mādaba",
      },
    ],
  },
  KZ: {
    name: "Kazakhstan",
    subdivisions: [
      {
        code: "KZ-ALA",
        alpha2code: "ALA",
        name: "Almaty",
      },
      {
        code: "KZ-BAY",
        alpha2code: "BAY",
        name: "Bayqonyr (Baykonyr)",
      },
      {
        code: "KZ-ALM",
        alpha2code: "ALM",
        name: "Almaty oblysy (Almatinskaya oblast')",
      },
      {
        code: "KZ-AKM",
        alpha2code: "AKM",
        name: "Aqmola oblysy (Akmolinskaya oblast')",
      },
      {
        code: "KZ-AKT",
        alpha2code: "AKT",
        name: "Aqtöbe oblysy (Aktyubinskaya oblast')",
      },
      {
        code: "KZ-ATY",
        alpha2code: "ATY",
        name: "Atyraü oblysy (Atyrauskaya oblast')",
      },
      {
        code: "KZ-ZAP",
        alpha2code: "ZAP",
        name: "Batys Kazakstan oblysy (Zapadno-Kazakhstanskaya oblast')",
      },
      {
        code: "KZ-MAN",
        alpha2code: "MAN",
        name: "Mangghystaū oblysy (Mangystauskaya oblast')",
      },
      {
        code: "KZ-YUZ",
        alpha2code: "YUZ",
        name: "Ongtüstik Kazakstan oblysy (Yuzhno-Kazakhstanskaya oblast')",
      },
      {
        code: "KZ-PAV",
        alpha2code: "PAV",
        name: "Pavlodar oblysy (Pavlodarskaya oblast')",
      },
      {
        code: "KZ-KAR",
        alpha2code: "KAR",
        name: "Qaraghandy oblysy (Karagandinskaya oblast')",
      },
      {
        code: "KZ-KUS",
        alpha2code: "KUS",
        name: "Qostanay oblysy (Kustanayskaya oblast')",
      },
      {
        code: "KZ-KZY",
        alpha2code: "KZY",
        name: "Qyzylorda oblysy (Kzylordinskaya oblast')",
      },
      {
        code: "KZ-VOS",
        alpha2code: "VOS",
        name: "Shyghys Kazakstan oblysy (Vostochno-Kazakhstanskaya oblast')",
      },
      {
        code: "KZ-SEV",
        alpha2code: "SEV",
        name: "Soltüstik Kazakstan oblysy (Severo-Kazakhstanskaya oblast')",
      },
      {
        code: "KZ-ZHA",
        alpha2code: "ZHA",
        name: "Zhambyl oblysy (Zhambylskaya Oblast')",
      },
    ],
  },
  KE: {
    name: "Kenya",
    subdivisions: [
      {
        code: "KE-110",
        alpha2code: "110",
        name: "Nairobi Municipality",
      },
      {
        code: "KE-200",
        alpha2code: "200",
        name: "Central (Kati)",
      },
      {
        code: "KE-300",
        alpha2code: "300",
        name: "Coast (Pwani)",
      },
      {
        code: "KE-400",
        alpha2code: "400",
        name: "Eastern (Mashariki)",
      },
      {
        code: "KE-500",
        alpha2code: "500",
        name: "North-Eastern (Kaskazini Mashariki)",
      },
      {
        code: "KE-600",
        alpha2code: "600",
        name: "Nyanza",
      },
      {
        code: "KE-700",
        alpha2code: "700",
        name: "Rift Valley",
      },
      {
        code: "KE-900",
        alpha2code: "900",
        name: "Western (Magharibi)",
      },
    ],
  },
  KI: {
    name: "Kiribati",
    subdivisions: [
      {
        code: "KI-G",
        alpha2code: "G",
        name: "Gilbert Islands",
      },
      {
        code: "KI-L",
        alpha2code: "L",
        name: "Line Islands",
      },
      {
        code: "KI-P",
        alpha2code: "P",
        name: "Phoenix Islands",
      },
    ],
  },
  KP: {
    name: "Korea (North)",
    subdivisions: [
      {
        code: "KP-KAE",
        alpha2code: "KAE",
        name: "Kaesong-si",
      },
      {
        code: "KP-NAM",
        alpha2code: "NAM",
        name: "Nampo-si",
      },
      {
        code: "KP-PYO",
        alpha2code: "PYO",
        name: "Pyongyang-si",
      },
      {
        code: "KP-CHA",
        alpha2code: "CHA",
        name: "Chagang-do",
      },
      {
        code: "KP-HAB",
        alpha2code: "HAB",
        name: "Hamgyongbuk-do",
      },
      {
        code: "KP-HAN",
        alpha2code: "HAN",
        name: "Hamgyongnam-do",
      },
      {
        code: "KP-HWB",
        alpha2code: "HWB",
        name: "Hwanghaebuk-do",
      },
      {
        code: "KP-HWN",
        alpha2code: "HWN",
        name: "Hwanghaenam-do",
      },
      {
        code: "KP-KAN",
        alpha2code: "KAN",
        name: "Kangwon-do",
      },
      {
        code: "KP-PYB",
        alpha2code: "PYB",
        name: "Pyonganbuk-do",
      },
      {
        code: "KP-PYN",
        alpha2code: "PYN",
        name: "Pyongannam-do",
      },
      {
        code: "KP-YAN",
        alpha2code: "YAN",
        name: "Yanggang-do",
      },
    ],
  },
  KR: {
    name: "Korea (South)",
    subdivisions: [
      {
        code: "KR-11",
        alpha2code: "11",
        name: "Seoul Teugbyeolsi [ Seoul-T’ŭkpyŏlshi]",
      },
      {
        code: "KR-26",
        alpha2code: "26",
        name: "Busan Gwang'yeogsi [Pusan-Kwangyŏkshi]",
      },
      {
        code: "KR-27",
        alpha2code: "27",
        name: "Daegu Gwang'yeogsi [Taegu-Kwangyŏkshi)",
      },
      {
        code: "KR-30",
        alpha2code: "30",
        name: "Daejeon Gwang'yeogsi [Taejŏn-Kwangyŏkshi]",
      },
      {
        code: "KR-29",
        alpha2code: "29",
        name: "Gwangju Gwang'yeogsi [Kwangju-Kwangyŏkshi]",
      },
      {
        code: "KR-28",
        alpha2code: "28",
        name: "Incheon Gwang'yeogsi [Inchŏn-Kwangyŏkshi]",
      },
      {
        code: "KR-31",
        alpha2code: "31",
        name: "Ulsan Gwang'yeogsi [Ulsan-Kwangyŏkshi]",
      },
      {
        code: "KR-43",
        alpha2code: "43",
        name: "Chungcheongbugdo [Ch'ungch'ŏngbuk-do]",
      },
      {
        code: "KR-44",
        alpha2code: "44",
        name: "Chungcheongnamdo [Ch'ungch'ŏngnam-do]",
      },
      {
        code: "KR-42",
        alpha2code: "42",
        name: "Gang'weondo [Kang-won-do]",
      },
      {
        code: "KR-41",
        alpha2code: "41",
        name: "Gyeonggido [Kyŏnggi-do]",
      },
      {
        code: "KR-47",
        alpha2code: "47",
        name: "Gyeongsangbugdo [Kyŏngsangbuk-do]",
      },
      {
        code: "KR-48",
        alpha2code: "48",
        name: "Gyeongsangnamdo [Kyŏngsangnam-do]",
      },
      {
        code: "KR-49",
        alpha2code: "49",
        name: "Jejudo [Cheju-do]",
      },
      {
        code: "KR-45",
        alpha2code: "45",
        name: "Jeonrabugdo [Chŏllabuk-do)",
      },
      {
        code: "KR-46",
        alpha2code: "46",
        name: "Jeonranamdo [Chŏllanam-do]",
      },
    ],
  },
  KW: {
    name: "Kuwait",
    subdivisions: [
      {
        code: "KW-AH",
        alpha2code: "AH",
        name: "Al Aḩmadi",
      },
      {
        code: "KW-FA",
        alpha2code: "FA",
        name: "Al Farwānīyah",
      },
      {
        code: "KW-JA",
        alpha2code: "JA",
        name: "Al Jahrah",
      },
      {
        code: "KW-KU",
        alpha2code: "KU",
        name: "Al Kuwayt",
      },
      {
        code: "KW-HA",
        alpha2code: "HA",
        name: "Ḩawallī",
      },
    ],
  },
  KG: {
    name: "Kyrgyzstan",
    subdivisions: [
      {
        code: "KG-C",
        alpha2code: "C",
        name: "Chu (Chuyskaya oblast')",
      },
      {
        code: "KG-J",
        alpha2code: "J",
        name: "Jalal-Abad (Dzhalal-Abadskaya oblast')",
      },
      {
        code: "KG-N",
        alpha2code: "N",
        name: "Naryn (Narynskaya Oblast’)",
      },
      {
        code: "KG-O",
        alpha2code: "O",
        name: "Osh (Oshskaya oblast')",
      },
      {
        code: "KG-T",
        alpha2code: "T",
        name: "Talas (Talasskaya oblast')",
      },
      {
        code: "KG-Y",
        alpha2code: "Y",
        name: "Ysyk-Köl (Issyk-Kul'skaya oblast')",
      },
    ],
  },
  LA: {
    name: "Laos",
    subdivisions: [
      {
        code: "LA-VT",
        alpha2code: "VT",
        name: "Vientiane",
      },
      {
        code: "LA-AT",
        alpha2code: "AT",
        name: "Attapu [Attopeu]",
      },
      {
        code: "LA-BK",
        alpha2code: "BK",
        name: "Bokèo",
      },
      {
        code: "LA-BL",
        alpha2code: "BL",
        name: "Bolikhamxai [Borikhane]",
      },
      {
        code: "LA-CH",
        alpha2code: "CH",
        name: "Champasak [Champassak]",
      },
      {
        code: "LA-HO",
        alpha2code: "HO",
        name: "Houaphan",
      },
      {
        code: "LA-KH",
        alpha2code: "KH",
        name: "Khammouan",
      },
      {
        code: "LA-LM",
        alpha2code: "LM",
        name: "Louang Namtha",
      },
      {
        code: "LA-LP",
        alpha2code: "LP",
        name: "Louangphabang [Louang Prabang]",
      },
      {
        code: "LA-OU",
        alpha2code: "OU",
        name: "Oudômxai [Oudomsai]",
      },
      {
        code: "LA-PH",
        alpha2code: "PH",
        name: "Phôngsali [Phong Saly]",
      },
      {
        code: "LA-SL",
        alpha2code: "SL",
        name: "Salavan [Saravane]",
      },
      {
        code: "LA-SV",
        alpha2code: "SV",
        name: "Savannakhét",
      },
      {
        code: "LA-VI",
        alpha2code: "VI",
        name: "Vientiane",
      },
      {
        code: "LA-XA",
        alpha2code: "XA",
        name: "Xaignabouli [Sayaboury]",
      },
      {
        code: "LA-XE",
        alpha2code: "XE",
        name: "Xékong [Sékong]",
      },
      {
        code: "LA-XI",
        alpha2code: "XI",
        name: "Xiangkhoang [Xieng Khouang]",
      },
    ],
  },
  LV: {
    name: "Latvia",
    subdivisions: [
      {
        code: "LV-AI",
        alpha2code: "AI",
        name: "Aizkraukles Aprinkis",
      },
      {
        code: "LV-AL",
        alpha2code: "AL",
        name: "Alūksnes Aprinkis",
      },
      {
        code: "LV-BL",
        alpha2code: "BL",
        name: "Balvu Aprinkis",
      },
      {
        code: "LV-BU",
        alpha2code: "BU",
        name: "Bauskas Aprinkis",
      },
      {
        code: "LV-CE",
        alpha2code: "CE",
        name: "Cēsu Aprinkis",
      },
      {
        code: "LV-DA",
        alpha2code: "DA",
        name: "Daugavpils Aprinkis",
      },
      {
        code: "LV-DO",
        alpha2code: "DO",
        name: "Dobeles Aprinkis",
      },
      {
        code: "LV-GU",
        alpha2code: "GU",
        name: "Gulbenes Aprinkis",
      },
      {
        code: "LV-JL",
        alpha2code: "JL",
        name: "Jelgavas Aprinkis",
      },
      {
        code: "LV-JK",
        alpha2code: "JK",
        name: "Jēkabpils Aprinkis",
      },
      {
        code: "LV-KR",
        alpha2code: "KR",
        name: "Krāslavas Aprinkis",
      },
      {
        code: "LV-KU",
        alpha2code: "KU",
        name: "Kuldīgas Aprinkis",
      },
      {
        code: "LV-LM",
        alpha2code: "LM",
        name: "Limbažu Aprinkis",
      },
      {
        code: "LV-LE",
        alpha2code: "LE",
        name: "Liepājas Aprinkis",
      },
      {
        code: "LV-LU",
        alpha2code: "LU",
        name: "Ludzas Aprinkis",
      },
      {
        code: "LV-MA",
        alpha2code: "MA",
        name: "Madonas Aprinkis",
      },
      {
        code: "LV-OG",
        alpha2code: "OG",
        name: "Ogres Aprinkis",
      },
      {
        code: "LV-PR",
        alpha2code: "PR",
        name: "Preilu Aprinkis",
      },
      {
        code: "LV-RE",
        alpha2code: "RE",
        name: "Rēzeknes Aprinkis",
      },
      {
        code: "LV-RI",
        alpha2code: "RI",
        name: "Rīgas Aprinkis",
      },
      {
        code: "LV-SA",
        alpha2code: "SA",
        name: "Saldus Aprinkis",
      },
      {
        code: "LV-TA",
        alpha2code: "TA",
        name: "Talsu Aprinkis",
      },
      {
        code: "LV-TU",
        alpha2code: "TU",
        name: "Tukuma Aprinkis",
      },
      {
        code: "LV-VK",
        alpha2code: "VK",
        name: "Valkas Aprinkis",
      },
      {
        code: "LV-VM",
        alpha2code: "VM",
        name: "Valmieras Aprinkis",
      },
      {
        code: "LV-VE",
        alpha2code: "VE",
        name: "Ventspils Aprinkis",
      },
      {
        code: "LV-DGV",
        alpha2code: "DGV",
        name: "Daugavpils",
      },
      {
        code: "LV-JEL",
        alpha2code: "JEL",
        name: "Jelgava",
      },
      {
        code: "LV-JUR",
        alpha2code: "JUR",
        name: "Jūrmala",
      },
      {
        code: "LV-LPX",
        alpha2code: "LPX",
        name: "Liepāja",
      },
      {
        code: "LV-REZ",
        alpha2code: "REZ",
        name: "Rēzekne",
      },
      {
        code: "LV-RIX",
        alpha2code: "RIX",
        name: "Rīga",
      },
      {
        code: "LV-VEN",
        alpha2code: "VEN",
        name: "Ventspils",
      },
    ],
  },
  LB: {
    name: "Lebanon",
    subdivisions: [
      {
        code: "LB-BA",
        alpha2code: "BA",
        name: "Beiroût (Bayrūt)",
      },
      {
        code: "LB-BI",
        alpha2code: "BI",
        name: "El Béqaa (Al Biqā')",
      },
      {
        code: "LB-JL",
        alpha2code: "JL",
        name: "Jabal Loubnâne (Jabal Lubnān)",
      },
      {
        code: "LB-AS",
        alpha2code: "AS",
        name: "Loubnâne ech Chemâli (Ash Shamāl)",
      },
      {
        code: "LB-JA",
        alpha2code: "JA",
        name: "Loubnâne ej Jnoûbi (Al Janūb)",
      },
      {
        code: "LB-NA",
        alpha2code: "NA",
        name: "Nabatîyé (An Nabaţīyah",
      },
    ],
  },
  LS: {
    name: "Lesotho",
    subdivisions: [
      {
        code: "LS-D",
        alpha2code: "D",
        name: "Berea",
      },
      {
        code: "LS-B",
        alpha2code: "B",
        name: "Butha-Buthe",
      },
      {
        code: "LS-C",
        alpha2code: "C",
        name: "Leribe",
      },
      {
        code: "LS-E",
        alpha2code: "E",
        name: "Mafeteng",
      },
      {
        code: "LS-A",
        alpha2code: "A",
        name: "Maseru",
      },
      {
        code: "LS-F",
        alpha2code: "F",
        name: "Mohale's Hoek",
      },
      {
        code: "LS-J",
        alpha2code: "J",
        name: "Mokhotlong",
      },
      {
        code: "LS-H",
        alpha2code: "H",
        name: "Qacha's Nek",
      },
      {
        code: "LS-G",
        alpha2code: "G",
        name: "Quthing",
      },
      {
        code: "LS-K",
        alpha2code: "K",
        name: "Thaba-Tseka",
      },
    ],
  },
  LR: {
    name: "Liberia",
    subdivisions: [
      {
        code: "LR-BM",
        alpha2code: "BM",
        name: "Bomi",
      },
      {
        code: "LR-BG",
        alpha2code: "BG",
        name: "Bong",
      },
      {
        code: "LR-GB",
        alpha2code: "GB",
        name: "Grand Bassa",
      },
      {
        code: "LR-CM",
        alpha2code: "CM",
        name: "Grand Cape Mount",
      },
      {
        code: "LR-GG",
        alpha2code: "GG",
        name: "Grand Gedeh",
      },
      {
        code: "LR-GK",
        alpha2code: "GK",
        name: "Grand Kru",
      },
      {
        code: "LR-LO",
        alpha2code: "LO",
        name: "Lofa",
      },
      {
        code: "LR-MG",
        alpha2code: "MG",
        name: "Margibi",
      },
      {
        code: "LR-MY",
        alpha2code: "MY",
        name: "Maryland",
      },
      {
        code: "LR-MO",
        alpha2code: "MO",
        name: "Montserrado",
      },
      {
        code: "LR-NI",
        alpha2code: "NI",
        name: "Nimba",
      },
      {
        code: "LR-RI",
        alpha2code: "RI",
        name: "Rivercess",
      },
      {
        code: "LR-SI",
        alpha2code: "SI",
        name: "Sinoe",
      },
    ],
  },
  LY: {
    name: "Libya",
    subdivisions: [
      {
        code: "LY-BU",
        alpha2code: "BU",
        name: "Al Buţnān",
      },
      {
        code: "LY-JA",
        alpha2code: "JA",
        name: "Al Jabal al Akhḑar",
      },
      {
        code: "LY-JG",
        alpha2code: "JG",
        name: "Al Jabal al Gharbī",
      },
      {
        code: "LY-Ju",
        alpha2code: "Ju",
        name: "Al Jufrah",
      },
      {
        code: "LY-WA",
        alpha2code: "WA",
        name: "Al Wāḩah",
      },
      {
        code: "LY-Wu",
        alpha2code: "Wu",
        name: "Al Wusţá",
      },
      {
        code: "LY-ZA",
        alpha2code: "ZA",
        name: "Az Zāwiyah",
      },
      {
        code: "LY-BA",
        alpha2code: "BA",
        name: "Banghāzī",
      },
      {
        code: "LY-FA",
        alpha2code: "FA",
        name: "Fazzān",
      },
      {
        code: "LY-MI",
        alpha2code: "MI",
        name: "Mişrātah",
      },
      {
        code: "LY-NA",
        alpha2code: "NA",
        name: "Naggaza",
      },
      {
        code: "LY-SF",
        alpha2code: "SF",
        name: "Sawfajjin",
      },
      {
        code: "LY-TB",
        alpha2code: "TB",
        name: "Ţarābulus",
      },
    ],
  },
  LI: {
    name: "Liechtenstein",
    subdivisions: [
      {
        code: "LI-LI",
        alpha2code: "LI",
        name: "Liechtenstein",
      },
    ],
  },
  LT: {
    name: "Lithuania",
    subdivisions: [
      {
        code: "LT-AL",
        alpha2code: "AL",
        name: "Alytaus Apskritis",
      },
      {
        code: "LT-KU",
        alpha2code: "KU",
        name: "Kauno Apskritis",
      },
      {
        code: "LT-KL",
        alpha2code: "KL",
        name: "Klaipėdos Apskritis",
      },
      {
        code: "LT-MR",
        alpha2code: "MR",
        name: "Marijampolės Apskritis",
      },
      {
        code: "LT-PN",
        alpha2code: "PN",
        name: "Panevėžio Apskritis",
      },
      {
        code: "LT-SA",
        alpha2code: "SA",
        name: "Šiauliu Apskritis",
      },
      {
        code: "LT-TA",
        alpha2code: "TA",
        name: "Tauragės Apskritis",
      },
      {
        code: "LT-TE",
        alpha2code: "TE",
        name: "Telšiu Apskritis",
      },
      {
        code: "LT-UT",
        alpha2code: "UT",
        name: "Utenos Apskritis",
      },
      {
        code: "LT-VL",
        alpha2code: "VL",
        name: "Vilniaus Apskritis",
      },
    ],
  },
  LU: {
    name: "Luxembourg",
    subdivisions: [
      {
        code: "LU-D",
        alpha2code: "D",
        name: "Diekirch",
      },
      {
        code: "LU-G",
        alpha2code: "G",
        name: "Grevenmacher",
      },
      {
        code: "LU-L",
        alpha2code: "L",
        name: "Luxembourg (Luxemburg)",
      },
    ],
  },
  MO: {
    name: "Macau",
    subdivisions: [
      {
        code: "MO-MO",
        alpha2code: "MO",
        name: "Macau",
      },
    ],
  },
  MK: {
    name: "Macedonia",
    subdivisions: [
      {
        code: "MK-MK",
        alpha2code: "MK",
        name: "Macedonia",
      },
    ],
  },
  MG: {
    name: "Madagascar",
    subdivisions: [
      {
        code: "MG-T",
        alpha2code: "T",
        name: "Antananarivo",
      },
      {
        code: "MG-D",
        alpha2code: "D",
        name: "Antsiranana",
      },
      {
        code: "MG-F",
        alpha2code: "F",
        name: "Fianarantsoa",
      },
      {
        code: "MG-M",
        alpha2code: "M",
        name: "Mahajanga",
      },
      {
        code: "MG-A",
        alpha2code: "A",
        name: "Toamasina",
      },
      {
        code: "MG-U",
        alpha2code: "U",
        name: "Toliara",
      },
    ],
  },
  MW: {
    name: "Malawi",
    subdivisions: [
      {
        code: "MW-C",
        alpha2code: "C",
        name: "Central",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dedza",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dowa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kasungu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lilongwe",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mchinji",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nkhotakota",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ntcheu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ntchisi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Salima",
      },
      {
        code: "MW-N",
        alpha2code: "N",
        name: "Northern",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chitipa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Karonga",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mzimba",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nkhata Bay",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rumphi",
      },
      {
        code: "MW-S",
        alpha2code: "S",
        name: "Southern",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Blantyre",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chikwawa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chiradzulu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Machinga",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mangochi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mulanje",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mwanza",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nsanje",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Thyolo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Zomba",
      },
    ],
  },
  MY: {
    name: "Malaysia",
    subdivisions: [
      {
        code: "MY-W",
        alpha2code: "W",
        name: "Wilayah Persekutuan Kuala Lumpur",
      },
      {
        code: "MY-L",
        alpha2code: "L",
        name: "Wilayah Persekutuan Labuan",
      },
      {
        code: "MY-J",
        alpha2code: "J",
        name: "Johor",
      },
      {
        code: "MY-K",
        alpha2code: "K",
        name: "Kedah",
      },
      {
        code: "MY-D",
        alpha2code: "D",
        name: "Kelantan",
      },
      {
        code: "MY-M",
        alpha2code: "M",
        name: "Melaka",
      },
      {
        code: "MY-N",
        alpha2code: "N",
        name: "Negeri Sembilan",
      },
      {
        code: "MY-C",
        alpha2code: "C",
        name: "Pahang",
      },
      {
        code: "MY-A",
        alpha2code: "A",
        name: "Perak",
      },
      {
        code: "MY-R",
        alpha2code: "R",
        name: "Perlis",
      },
      {
        code: "MY-P",
        alpha2code: "P",
        name: "Pulau Pinang",
      },
      {
        code: "MY-SA",
        alpha2code: "SA",
        name: "Sabah",
      },
      {
        code: "MY-SK",
        alpha2code: "SK",
        name: "Sarawak",
      },
      {
        code: "MY-B",
        alpha2code: "B",
        name: "Selangor",
      },
      {
        code: "MY-T",
        alpha2code: "T",
        name: "Terengganu",
      },
    ],
  },
  MV: {
    name: "Maldives",
    subdivisions: [
      {
        code: "MV-MLE",
        alpha2code: "MLE",
        name: "Male",
      },
      {
        code: "MV-02",
        alpha2code: "02",
        name: "Alif",
      },
      {
        code: "MV-20",
        alpha2code: "20",
        name: "Baa",
      },
      {
        code: "MV-17",
        alpha2code: "17",
        name: "Dhaalu",
      },
      {
        code: "MV-14",
        alpha2code: "14",
        name: "Faafu",
      },
      {
        code: "MV-27",
        alpha2code: "27",
        name: "Gaaf Alif",
      },
      {
        code: "MV-28",
        alpha2code: "28",
        name: "Gaafu Dhaalu",
      },
      {
        code: "MV-29",
        alpha2code: "29",
        name: "Gnaviyani",
      },
      {
        code: "MV-07",
        alpha2code: "07",
        name: "Haa Alif",
      },
      {
        code: "MV-23",
        alpha2code: "23",
        name: "Haa Dhaalu",
      },
      {
        code: "MV-26",
        alpha2code: "26",
        name: "Kaafu",
      },
      {
        code: "MV-05",
        alpha2code: "05",
        name: "Laamu",
      },
      {
        code: "MV-03",
        alpha2code: "03",
        name: "Lhaviyani",
      },
      {
        code: "MV-12",
        alpha2code: "12",
        name: "Meemu",
      },
      {
        code: "MV-25",
        alpha2code: "25",
        name: "Noonu",
      },
      {
        code: "MV-13",
        alpha2code: "13",
        name: "Raa",
      },
      {
        code: "MV-01",
        alpha2code: "01",
        name: "Seenu",
      },
      {
        code: "MV-24",
        alpha2code: "24",
        name: "Shaviyani",
      },
      {
        code: "MV-08",
        alpha2code: "08",
        name: "Thaa",
      },
      {
        code: "MV-04",
        alpha2code: "04",
        name: "Vaavu",
      },
    ],
  },
  ML: {
    name: "Mali",
    subdivisions: [
      {
        code: "ML-BKO",
        alpha2code: "BKO",
        name: "Bamako",
      },
      {
        code: "ML-7",
        alpha2code: "7",
        name: "Gao",
      },
      {
        code: "ML-1",
        alpha2code: "1",
        name: "Kayes",
      },
      {
        code: "ML-8",
        alpha2code: "8",
        name: "Kidal",
      },
      {
        code: "ML-2",
        alpha2code: "2",
        name: "Koulikoro",
      },
      {
        code: "ML-5",
        alpha2code: "5",
        name: "Mopti",
      },
      {
        code: "ML-4",
        alpha2code: "4",
        name: "Ségou",
      },
      {
        code: "ML-3",
        alpha2code: "3",
        name: "Sikasso",
      },
      {
        code: "ML-6",
        alpha2code: "6",
        name: "Tombouctou",
      },
    ],
  },
  MT: {
    name: "Malta",
    subdivisions: [
      {
        code: "MT-MT",
        alpha2code: "MT",
        name: "Malta",
      },
    ],
  },
  MH: {
    name: "Marshall Islands",
    subdivisions: [
      {
        code: "MH-L",
        alpha2code: "L",
        name: "Ralik chain",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ailinglapalap",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ebon",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Eniwetok",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jaluit",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kili",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kwajalein",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lae",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lib",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Namorik",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Namu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rongelap",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ujae",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ujelang",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wotho",
      },
      {
        code: "MH-T",
        alpha2code: "T",
        name: "Ratak chain",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ailuk",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Arno",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Likiep",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Majuro",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Maloelap",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mejit",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mili",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Utirik",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wotje",
      },
    ],
  },
  MQ: {
    name: "Martinique",
    subdivisions: [
      {
        code: "MQ-MQ",
        alpha2code: "MQ",
        name: "Martinique",
      },
    ],
  },
  MR: {
    name: "Mauritania",
    subdivisions: [
      {
        code: "MR-NKC",
        alpha2code: "NKC",
        name: "Nouakchott",
      },
      {
        code: "MR-07",
        alpha2code: "07",
        name: "Adrar",
      },
      {
        code: "MR-03",
        alpha2code: "03",
        name: "Assaba",
      },
      {
        code: "MR-05",
        alpha2code: "05",
        name: "Brakna",
      },
      {
        code: "MR-08",
        alpha2code: "08",
        name: "Dakhlet Nouādhibou",
      },
      {
        code: "MR-04",
        alpha2code: "04",
        name: "Gorgol",
      },
      {
        code: "MR-10",
        alpha2code: "10",
        name: "Guidimaka",
      },
      {
        code: "MR-01",
        alpha2code: "01",
        name: "Hodh ech Chargui",
      },
      {
        code: "MR-02",
        alpha2code: "02",
        name: "Hodh el Gharbi",
      },
      {
        code: "MR-12",
        alpha2code: "12",
        name: "Inchiri",
      },
      {
        code: "MR-09",
        alpha2code: "09",
        name: "Tagant",
      },
      {
        code: "MR-11",
        alpha2code: "11",
        name: "Tiris Zemmour",
      },
      {
        code: "MR-06",
        alpha2code: "06",
        name: "Trarza",
      },
    ],
  },
  MU: {
    name: "Mauritius",
    subdivisions: [
      {
        code: "MU-BR",
        alpha2code: "BR",
        name: "Beau Bassin-Rose Hill",
      },
      {
        code: "MU-CU",
        alpha2code: "CU",
        name: "Curepipe",
      },
      {
        code: "MU-PL",
        alpha2code: "PL",
        name: "Port Louis",
      },
      {
        code: "MU-QB",
        alpha2code: "QB",
        name: "Quatre Bornes",
      },
      {
        code: "MU-VP",
        alpha2code: "VP",
        name: "Vacoas-Phoenix",
      },
      {
        code: "MU-BL",
        alpha2code: "BL",
        name: "Black River",
      },
      {
        code: "MU-FL",
        alpha2code: "FL",
        name: "Flacq",
      },
      {
        code: "MU-GP",
        alpha2code: "GP",
        name: "Grand Port",
      },
      {
        code: "MU-MO",
        alpha2code: "MO",
        name: "Moka",
      },
      {
        code: "MU-PA",
        alpha2code: "PA",
        name: "Pamplemousses",
      },
      {
        code: "MU-PW",
        alpha2code: "PW",
        name: "Plaines Wilhems",
      },
      {
        code: "MU-RR",
        alpha2code: "RR",
        name: "Rivière du Rempart",
      },
      {
        code: "MU-SA",
        alpha2code: "SA",
        name: "Savanne",
      },
      {
        code: "MU-AG",
        alpha2code: "AG",
        name: "Agalega Islands",
      },
      {
        code: "MU-CC",
        alpha2code: "CC",
        name: "Cargados Carajos Shoals [Saint Brandon Islands]",
      },
      {
        code: "MU-RO",
        alpha2code: "RO",
        name: "Rodrigues Island",
      },
    ],
  },
  YT: {
    name: "Mayotte",
    subdivisions: [
      {
        code: "YT-YT",
        alpha2code: "YT",
        name: "Mayotte",
      },
    ],
  },
  MX: {
    name: "Mexico",
    subdivisions: [
      {
        code: "MX-DIF",
        alpha2code: "DIF",
        name: "Distrito Federal",
      },
      {
        code: "MX-AGU",
        alpha2code: "AGU",
        name: "Aguascalientes",
      },
      {
        code: "MX-BCN",
        alpha2code: "BCN",
        name: "Baja California",
      },
      {
        code: "MX-BCS",
        alpha2code: "BCS",
        name: "Baja California Sur",
      },
      {
        code: "MX-CAM",
        alpha2code: "CAM",
        name: "Campeche",
      },
      {
        code: "MX-COA",
        alpha2code: "COA",
        name: "Coahuila",
      },
      {
        code: "MX-COL",
        alpha2code: "COL",
        name: "Colima",
      },
      {
        code: "MX-CHP",
        alpha2code: "CHP",
        name: "Chiapas",
      },
      {
        code: "MX-CHH",
        alpha2code: "CHH",
        name: "Chihuahua",
      },
      {
        code: "MX-DUR",
        alpha2code: "DUR",
        name: "Durango",
      },
      {
        code: "MX-GRO",
        alpha2code: "GRO",
        name: "Guerrero",
      },
      {
        code: "MX-GUA",
        alpha2code: "GUA",
        name: "Guanajuato",
      },
      {
        code: "MX-HID",
        alpha2code: "HID",
        name: "Hidalgo",
      },
      {
        code: "MX-JAL",
        alpha2code: "JAL",
        name: "Jalisco",
      },
      {
        code: "MX-MEX",
        alpha2code: "MEX",
        name: "México",
      },
      {
        code: "MX-MIC",
        alpha2code: "MIC",
        name: "Michoacán",
      },
      {
        code: "MX-MOR",
        alpha2code: "MOR",
        name: "Morelos",
      },
      {
        code: "MX-NAY",
        alpha2code: "NAY",
        name: "Nayarit",
      },
      {
        code: "MX-NLE",
        alpha2code: "NLE",
        name: "Nuevo León",
      },
      {
        code: "MX-OAX",
        alpha2code: "OAX",
        name: "Oaxaca",
      },
      {
        code: "MX-PUE",
        alpha2code: "PUE",
        name: "Puebla",
      },
      {
        code: "MX-QUE",
        alpha2code: "QUE",
        name: "Queretaro",
      },
      {
        code: "MX-ROO",
        alpha2code: "ROO",
        name: "Quintana Roo",
      },
      {
        code: "MX-SLP",
        alpha2code: "SLP",
        name: "San Luis Potosí",
      },
      {
        code: "MX-SIN",
        alpha2code: "SIN",
        name: "Sinaloa",
      },
      {
        code: "MX-SON",
        alpha2code: "SON",
        name: "Sonora",
      },
      {
        code: "MX-TAB",
        alpha2code: "TAB",
        name: "Tabasco",
      },
      {
        code: "MX-TAM",
        alpha2code: "TAM",
        name: "Tamaulipas",
      },
      {
        code: "MX-TLA",
        alpha2code: "TLA",
        name: "Tlaxcala",
      },
      {
        code: "MX-VER",
        alpha2code: "VER",
        name: "Veracruz",
      },
      {
        code: "MX-YUC",
        alpha2code: "YUC",
        name: "Yucatán",
      },
      {
        code: "MX-ZAC",
        alpha2code: "ZAC",
        name: "Zacatecas",
      },
    ],
  },
  FM: {
    name: "Micronesia",
    subdivisions: [
      {
        code: "FM-TRK",
        alpha2code: "TRK",
        name: "chuuk",
      },
      {
        code: "FM-KSA",
        alpha2code: "KSA",
        name: "Kosrae",
      },
      {
        code: "FM-PNI",
        alpha2code: "PNI",
        name: "Pohnpei",
      },
      {
        code: "FM-YAP",
        alpha2code: "YAP",
        name: "Yap",
      },
    ],
  },
  MD: {
    name: "Moldova",
    subdivisions: [
      {
        code: "MD-BAL",
        alpha2code: "BAL",
        name: "Bălţi",
      },
      {
        code: "MD-CAH",
        alpha2code: "CAH",
        name: "Cahul",
      },
      {
        code: "MD-CHI",
        alpha2code: "CHI",
        name: "Chişinău",
      },
      {
        code: "MD-DUB",
        alpha2code: "DUB",
        name: "Dubăsari",
      },
      {
        code: "MD-ORH",
        alpha2code: "ORH",
        name: "Orhei",
      },
      {
        code: "MD-RIB",
        alpha2code: "RIB",
        name: "Rîbniţa",
      },
      {
        code: "MD-SOC",
        alpha2code: "SOC",
        name: "Soroca",
      },
      {
        code: "MD-TIG",
        alpha2code: "TIG",
        name: "Tighina",
      },
      {
        code: "MD-TIR",
        alpha2code: "TIR",
        name: "Tiraspol",
      },
      {
        code: "MD-UNG",
        alpha2code: "UNG",
        name: "Ungheni",
      },
      {
        code: "MD-ANE",
        alpha2code: "ANE",
        name: "Anenii Noi",
      },
      {
        code: "MD-BAS",
        alpha2code: "BAS",
        name: "Basarabeasca",
      },
      {
        code: "MD-BRI",
        alpha2code: "BRI",
        name: "Brinceni",
      },
      {
        code: "MD-CHL",
        alpha2code: "CHL",
        name: "Cahul",
      },
      {
        code: "MD-CAM",
        alpha2code: "CAM",
        name: "Camenca",
      },
      {
        code: "MD-CAN",
        alpha2code: "CAN",
        name: "Cantemir",
      },
      {
        code: "MD-CAI",
        alpha2code: "CAI",
        name: "Căinari",
      },
      {
        code: "MD-CAL",
        alpha2code: "CAL",
        name: "Călăraşi",
      },
      {
        code: "MD-CAS",
        alpha2code: "CAS",
        name: "Căuşeni",
      },
      {
        code: "MD-CIA",
        alpha2code: "CIA",
        name: "Ciadîr-Lunga",
      },
      {
        code: "MD-CIM",
        alpha2code: "CIM",
        name: "Cimişlia",
      },
      {
        code: "MD-COM",
        alpha2code: "COM",
        name: "Comrat",
      },
      {
        code: "MD-CRI",
        alpha2code: "CRI",
        name: "Criuleni",
      },
      {
        code: "MD-DON",
        alpha2code: "DON",
        name: "Donduşeni",
      },
      {
        code: "MD-DRO",
        alpha2code: "DRO",
        name: "Drochia",
      },
      {
        code: "MD-DBI",
        alpha2code: "DBI",
        name: "Dubăsari",
      },
      {
        code: "MD-EDI",
        alpha2code: "EDI",
        name: "Edineţ",
      },
      {
        code: "MD-FAL",
        alpha2code: "FAL",
        name: "Făleşti",
      },
      {
        code: "MD-FLO",
        alpha2code: "FLO",
        name: "Floreşti",
      },
      {
        code: "MD-GLO",
        alpha2code: "GLO",
        name: "Glodeni",
      },
      {
        code: "MD-GRI",
        alpha2code: "GRI",
        name: "Grigoriopol",
      },
      {
        code: "MD-HIN",
        alpha2code: "HIN",
        name: "Hînceşti",
      },
      {
        code: "MD-IAL",
        alpha2code: "IAL",
        name: "Ialoveni",
      },
      {
        code: "MD-LEO",
        alpha2code: "LEO",
        name: "Leova",
      },
      {
        code: "MD-NIS",
        alpha2code: "NIS",
        name: "Nisporeni",
      },
      {
        code: "MD-OCN",
        alpha2code: "OCN",
        name: "Ocniţa",
      },
      {
        code: "MD-OHI",
        alpha2code: "OHI",
        name: "Orhei",
      },
      {
        code: "MD-REZ",
        alpha2code: "REZ",
        name: "Rezina",
      },
      {
        code: "MD-RIT",
        alpha2code: "RIT",
        name: "Rîbniţa",
      },
      {
        code: "MD-RIS",
        alpha2code: "RIS",
        name: "Rîşcani",
      },
      {
        code: "MD-SIN",
        alpha2code: "SIN",
        name: "Sîngerei",
      },
      {
        code: "MD-SLO",
        alpha2code: "SLO",
        name: "Slobozia",
      },
      {
        code: "MD-SOA",
        alpha2code: "SOA",
        name: "Soroca",
      },
      {
        code: "MD-STR",
        alpha2code: "STR",
        name: "Străşeni",
      },
      {
        code: "MD-SOL",
        alpha2code: "SOL",
        name: "Şoldăneşti",
      },
      {
        code: "MD-STE",
        alpha2code: "STE",
        name: "Ştefan Vodă",
      },
      {
        code: "MD-TAR",
        alpha2code: "TAR",
        name: "Taraclia",
      },
      {
        code: "MD-TEL",
        alpha2code: "TEL",
        name: "Teleneşti",
      },
      {
        code: "MD-UGI",
        alpha2code: "UGI",
        name: "Ungheni",
      },
      {
        code: "MD-VUL",
        alpha2code: "VUL",
        name: "Vulcăneşti",
      },
    ],
  },
  MC: {
    name: "Monaco",
    subdivisions: [
      {
        code: "MC-MC",
        alpha2code: "MC",
        name: "Monaco",
      },
    ],
  },
  MN: {
    name: "Mongolia",
    subdivisions: [
      {
        code: "MN-1",
        alpha2code: "1",
        name: "Ulaanbaatar",
      },
      {
        code: "MN-073",
        alpha2code: "073",
        name: "Arhangay",
      },
      {
        code: "MN-069",
        alpha2code: "069",
        name: "Bayanhongor",
      },
      {
        code: "MN-071",
        alpha2code: "071",
        name: "Bayan-Ölgiy",
      },
      {
        code: "MN-067",
        alpha2code: "067",
        name: "Bulgan",
      },
      {
        code: "MN-037",
        alpha2code: "037",
        name: "Darhan uul",
      },
      {
        code: "MN-061",
        alpha2code: "061",
        name: "Dornod",
      },
      {
        code: "MN-063",
        alpha2code: "063",
        name: "Dornogovĭ",
      },
      {
        code: "MN-059",
        alpha2code: "059",
        name: "Dundgovĭ",
      },
      {
        code: "MN-057",
        alpha2code: "057",
        name: "Dzavhan",
      },
      {
        code: "MN-065",
        alpha2code: "065",
        name: "Govĭ-Altay",
      },
      {
        code: "MN-064",
        alpha2code: "064",
        name: "Govĭ-Sümber",
      },
      {
        code: "MN-039",
        alpha2code: "039",
        name: "Hentiy",
      },
      {
        code: "MN-043",
        alpha2code: "043",
        name: "Hovd",
      },
      {
        code: "MN-041",
        alpha2code: "041",
        name: "Hövsgöl",
      },
      {
        code: "MN-053",
        alpha2code: "053",
        name: "Ömnögovĭ",
      },
      {
        code: "MN-035",
        alpha2code: "035",
        name: "Orhon",
      },
      {
        code: "MN-055",
        alpha2code: "055",
        name: "Övörhangay",
      },
      {
        code: "MN-049",
        alpha2code: "049",
        name: "Selenge",
      },
      {
        code: "MN-051",
        alpha2code: "051",
        name: "Sühbaatar",
      },
      {
        code: "MN-047",
        alpha2code: "047",
        name: "Töv",
      },
      {
        code: "MN-046",
        alpha2code: "046",
        name: "Uvs",
      },
    ],
  },
  MS: {
    name: "Montserrat",
    subdivisions: [
      {
        code: "MS-MS",
        alpha2code: "MS",
        name: "Montserrat",
      },
    ],
  },
  MA: {
    name: "Morocco",
    subdivisions: [
      {
        code: "MA-CE",
        alpha2code: "CE",
        name: "Centre",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Azilal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Beni Mellal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ben Slimane",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Boulemane",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Casablanca [Dar el Beïda]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "El Jadida",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Khouribga",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Settat",
      },
      {
        code: "MA-CN",
        alpha2code: "CN",
        name: "Centre-Nord",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Al Hoceïma",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Fès",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sefrou",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Taounate",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Taza",
      },
      {
        code: "MA-CS",
        alpha2code: "CS",
        name: "Centre-Sud",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "El Hajeb",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Errachidia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ifrane",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Khenifra",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Meknès",
      },
      {
        code: "MA-ES",
        alpha2code: "ES",
        name: "Est",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Berkane",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Figuig",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jrada",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nador",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oujda",
      },
      {
        code: "MA-NO",
        alpha2code: "NO",
        name: "Nord-Ouest",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chefchaouene",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kénitra",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Khemisset",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Larache",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rabat-Salé",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sidi Kacem",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tanger",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tétouan",
      },
      {
        code: "MA-SU",
        alpha2code: "SU",
        name: "Sud",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Agadir",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aït Baha",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Aït Melloul",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Assa-Zag",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Boujdour (EH)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Es Semara (EH)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Guelmim",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Laayoune (EH)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ouarzazate",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Oued ed Dahab (EH)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tan-Tan",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Taroudannt",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tata",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tiznit",
      },
      {
        code: "MA-TS",
        alpha2code: "TS",
        name: "Tensift",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Al Haouz",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Chichaoua",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Essaouira",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kelaat Sraghna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Marrakech",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Safi",
      },
    ],
  },
  MZ: {
    name: "Mozambique",
    subdivisions: [
      {
        code: "MZ-MPM",
        alpha2code: "MPM",
        name: "Maputo",
      },
      {
        code: "MZ-P",
        alpha2code: "P",
        name: "Cabo Delgado",
      },
      {
        code: "MZ-G",
        alpha2code: "G",
        name: "Gaza",
      },
      {
        code: "MZ-I",
        alpha2code: "I",
        name: "Inhambane",
      },
      {
        code: "MZ-B",
        alpha2code: "B",
        name: "Manica",
      },
      {
        code: "MZ-L",
        alpha2code: "L",
        name: "Maputo",
      },
      {
        code: "MZ-N",
        alpha2code: "N",
        name: "Nampula",
      },
      {
        code: "MZ-A",
        alpha2code: "A",
        name: "Niassa",
      },
      {
        code: "MZ-S",
        alpha2code: "S",
        name: "Sofala",
      },
      {
        code: "MZ-T",
        alpha2code: "T",
        name: "Tete",
      },
      {
        code: "MZ-Q",
        alpha2code: "Q",
        name: "Zambézia",
      },
    ],
  },
  MM: {
    name: "Myanmar",
    subdivisions: [
      {
        code: "MM-07",
        alpha2code: "07",
        name: "Ayeyarwady",
      },
      {
        code: "MM-02",
        alpha2code: "02",
        name: "Bago",
      },
      {
        code: "MM-03",
        alpha2code: "03",
        name: "Magway",
      },
      {
        code: "MM-04",
        alpha2code: "04",
        name: "Mandalay",
      },
      {
        code: "MM-01",
        alpha2code: "01",
        name: "Sagaing",
      },
      {
        code: "MM-05",
        alpha2code: "05",
        name: "Tanintharyi",
      },
      {
        code: "MM-06",
        alpha2code: "06",
        name: "Yangon",
      },
      {
        code: "MM-14",
        alpha2code: "14",
        name: "Chin",
      },
      {
        code: "MM-11",
        alpha2code: "11",
        name: "Kachin",
      },
      {
        code: "MM-12",
        alpha2code: "12",
        name: "Kayah",
      },
      {
        code: "MM-13",
        alpha2code: "13",
        name: "Kayin",
      },
      {
        code: "MM-15",
        alpha2code: "15",
        name: "Mon",
      },
      {
        code: "MM-16",
        alpha2code: "16",
        name: "Rakhine",
      },
      {
        code: "MM-17",
        alpha2code: "17",
        name: "Shan",
      },
    ],
  },
  NA: {
    name: "Namibia",
    subdivisions: [
      {
        code: "NA-CA",
        alpha2code: "CA",
        name: "Caprivi",
      },
      {
        code: "NA-ER",
        alpha2code: "ER",
        name: "Erongo",
      },
      {
        code: "NA-HA",
        alpha2code: "HA",
        name: "Hardap",
      },
      {
        code: "NA-KA",
        alpha2code: "KA",
        name: "Karas",
      },
      {
        code: "NA-KH",
        alpha2code: "KH",
        name: "Khomas",
      },
      {
        code: "NA-KU",
        alpha2code: "KU",
        name: "Kunene",
      },
      {
        code: "NA-OW",
        alpha2code: "OW",
        name: "Ohangwena",
      },
      {
        code: "NA-OK",
        alpha2code: "OK",
        name: "Okavango",
      },
      {
        code: "NA-OH",
        alpha2code: "OH",
        name: "Omaheke",
      },
      {
        code: "NA-OS",
        alpha2code: "OS",
        name: "Omusati",
      },
      {
        code: "NA-ON",
        alpha2code: "ON",
        name: "Oshana",
      },
      {
        code: "NA-OT",
        alpha2code: "OT",
        name: "Oshikoto",
      },
      {
        code: "NA-OD",
        alpha2code: "OD",
        name: "Otjozondjupa",
      },
    ],
  },
  NR: {
    name: "Nauru",
    subdivisions: [
      {
        code: "NR-NR",
        alpha2code: "NR",
        name: "Nauru",
      },
    ],
  },
  NP: {
    name: "Nepal",
    subdivisions: [
      {
        code: "NP-1",
        alpha2code: "1",
        name: "Madhyamanchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bagmati",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Janakpur",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Narayani",
      },
      {
        code: "NP-2",
        alpha2code: "2",
        name: "Madhya Pashchimanchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bheri",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Karnali",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Rapti",
      },
      {
        code: "NP-3",
        alpha2code: "3",
        name: "Pashchimanchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Dhawalagiri",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gandaki",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lumbini",
      },
      {
        code: "NP-4",
        alpha2code: "4",
        name: "Purwanchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kosi [Koshi]",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mechi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sagarmatha",
      },
      {
        code: "NP-5",
        alpha2code: "5",
        name: "Sudur Pashchimanchal",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mahakali",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Seti",
      },
    ],
  },
  NL: {
    name: "Netherlands",
    subdivisions: [
      {
        code: "NL-DR",
        alpha2code: "DR",
        name: "Drenthe",
      },
      {
        code: "NL-FL",
        alpha2code: "FL",
        name: "Flevoland",
      },
      {
        code: "NL-FR",
        alpha2code: "FR",
        name: "Friesland",
      },
      {
        code: "NL-GE",
        alpha2code: "GE",
        name: "Gelderland",
      },
      {
        code: "NL-GR",
        alpha2code: "GR",
        name: "Groningen",
      },
      {
        code: "NL-LI",
        alpha2code: "LI",
        name: "Limburg",
      },
      {
        code: "NL-NB",
        alpha2code: "NB",
        name: "Noord-Brabant",
      },
      {
        code: "NL-NH",
        alpha2code: "NH",
        name: "Noord-Holland",
      },
      {
        code: "NL-OV",
        alpha2code: "OV",
        name: "Overijssel",
      },
      {
        code: "NL-UT",
        alpha2code: "UT",
        name: "Utrecht",
      },
      {
        code: "NL-ZE",
        alpha2code: "ZE",
        name: "Zeeland",
      },
      {
        code: "NL-ZH",
        alpha2code: "ZH",
        name: "Zuid-Holland",
      },
    ],
  },
  AN: {
    name: "Netherlands Antilles",
    subdivisions: [
      {
        code: "AN-AN",
        alpha2code: "AN",
        name: "Netherlands Antilles",
      },
    ],
  },
  NC: {
    name: "New Caledonia",
    subdivisions: [
      {
        code: "NC-NC",
        alpha2code: "NC",
        name: "New Caledonia",
      },
    ],
  },
  NZ: {
    name: "New Zealand",
    subdivisions: [
      {
        code: "NZ-N",
        alpha2code: "N",
        name: "North Island",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Auckland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Bay of Plenty",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gisborne",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hawkes's Bay",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Manawatu-Wanganui",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Northland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Taranaki",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Waikato",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Wellington",
      },
      {
        code: "NZ-S",
        alpha2code: "S",
        name: "South Island",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Canterbury",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Marlborough",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nelson",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Otago",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Southland",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tasman",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "West Coast",
      },
    ],
  },
  NI: {
    name: "Nicaragua",
    subdivisions: [
      {
        code: "NI-BO",
        alpha2code: "BO",
        name: "Boaco",
      },
      {
        code: "NI-CA",
        alpha2code: "CA",
        name: "Carazo",
      },
      {
        code: "NI-CI",
        alpha2code: "CI",
        name: "Chinandega",
      },
      {
        code: "NI-CO",
        alpha2code: "CO",
        name: "Chontales",
      },
      {
        code: "NI-ES",
        alpha2code: "ES",
        name: "Estelí",
      },
      {
        code: "NI-GR",
        alpha2code: "GR",
        name: "Granada",
      },
      {
        code: "NI-JI",
        alpha2code: "JI",
        name: "Jinotega",
      },
      {
        code: "NI-LE",
        alpha2code: "LE",
        name: "León",
      },
      {
        code: "NI-MD",
        alpha2code: "MD",
        name: "Madriz",
      },
      {
        code: "NI-MN",
        alpha2code: "MN",
        name: "Manaqua",
      },
      {
        code: "NI-MS",
        alpha2code: "MS",
        name: "Masaya",
      },
      {
        code: "NI-MT",
        alpha2code: "MT",
        name: "Matagalpa",
      },
      {
        code: "NI-NS",
        alpha2code: "NS",
        name: "Nueva Segovia",
      },
      {
        code: "NI-SJ",
        alpha2code: "SJ",
        name: "Río San Juan",
      },
      {
        code: "NI-RI",
        alpha2code: "RI",
        name: "Rivas",
      },
      {
        code: "NI-ZE",
        alpha2code: "ZE",
        name: "Zelaya",
      },
    ],
  },
  NE: {
    name: "Niger",
    subdivisions: [
      {
        code: "NE-8",
        alpha2code: "8",
        name: "Niamey",
      },
      {
        code: "NE-1",
        alpha2code: "1",
        name: "Agadez",
      },
      {
        code: "NE-2",
        alpha2code: "2",
        name: "Diffa",
      },
      {
        code: "NE-3",
        alpha2code: "3",
        name: "Dosso",
      },
      {
        code: "NE-4",
        alpha2code: "4",
        name: "Maradi",
      },
      {
        code: "NE-5",
        alpha2code: "5",
        name: "Tahoua",
      },
      {
        code: "NE-6",
        alpha2code: "6",
        name: "Tillaberi",
      },
      {
        code: "NE-7",
        alpha2code: "7",
        name: "Zinder",
      },
    ],
  },
  NG: {
    name: "Nigeria",
    subdivisions: [
      {
        code: "NG-FC",
        alpha2code: "FC",
        name: "Abuja Capital Territory",
      },
      {
        code: "NG-AB",
        alpha2code: "AB",
        name: "Abia",
      },
      {
        code: "NG-AD",
        alpha2code: "AD",
        name: "Adamawa",
      },
      {
        code: "NG-AK",
        alpha2code: "AK",
        name: "Akwa Ibom",
      },
      {
        code: "NG-AN",
        alpha2code: "AN",
        name: "Anambra",
      },
      {
        code: "NG-BA",
        alpha2code: "BA",
        name: "Bauchi",
      },
      {
        code: "NG-BE",
        alpha2code: "BE",
        name: "Benue",
      },
      {
        code: "NG-BO",
        alpha2code: "BO",
        name: "Borno",
      },
      {
        code: "NG-CR",
        alpha2code: "CR",
        name: "Cross River",
      },
      {
        code: "NG-DE",
        alpha2code: "DE",
        name: "Delta",
      },
      {
        code: "NG-ED",
        alpha2code: "ED",
        name: "Edo",
      },
      {
        code: "NG-EN",
        alpha2code: "EN",
        name: "Enugu",
      },
      {
        code: "NG-IM",
        alpha2code: "IM",
        name: "Imo",
      },
      {
        code: "NG-JI",
        alpha2code: "JI",
        name: "Jigawa",
      },
      {
        code: "NG-KD",
        alpha2code: "KD",
        name: "Kaduna",
      },
      {
        code: "NG-KN",
        alpha2code: "KN",
        name: "Kano",
      },
      {
        code: "NG-KT",
        alpha2code: "KT",
        name: "Katsina",
      },
      {
        code: "NG-KE",
        alpha2code: "KE",
        name: "Kebbi",
      },
      {
        code: "NG-KO",
        alpha2code: "KO",
        name: "Kogi",
      },
      {
        code: "NG-KW",
        alpha2code: "KW",
        name: "Kwara",
      },
      {
        code: "NG-LA",
        alpha2code: "LA",
        name: "Lagos",
      },
      {
        code: "NG-NI",
        alpha2code: "NI",
        name: "Niger",
      },
      {
        code: "NG-OG",
        alpha2code: "OG",
        name: "Ogun",
      },
      {
        code: "NG-ON",
        alpha2code: "ON",
        name: "Ondo",
      },
      {
        code: "NG-OS",
        alpha2code: "OS",
        name: "Osun",
      },
      {
        code: "NG-OY",
        alpha2code: "OY",
        name: "Oyo",
      },
      {
        code: "NG-PL",
        alpha2code: "PL",
        name: "Plateau",
      },
      {
        code: "NG-RI",
        alpha2code: "RI",
        name: "Rivers",
      },
      {
        code: "NG-SO",
        alpha2code: "SO",
        name: "Sokoto",
      },
      {
        code: "NG-TA",
        alpha2code: "TA",
        name: "Taraba",
      },
      {
        code: "NG-YO",
        alpha2code: "YO",
        name: "Yobe",
      },
    ],
  },
  NU: {
    name: "Niue",
    subdivisions: [
      {
        code: "NU-NU",
        alpha2code: "NU",
        name: "Niue",
      },
    ],
  },
  NF: {
    name: "Norfolk Island",
    subdivisions: [
      {
        code: "NF-NF",
        alpha2code: "NF",
        name: "Norfolk Island",
      },
    ],
  },
  MP: {
    name: "Northern Mariana Islands",
    subdivisions: [
      {
        code: "MP-MP",
        alpha2code: "MP",
        name: "Northern Mariana Islands",
      },
    ],
  },
  NO: {
    name: "Norway",
    subdivisions: [
      {
        code: "NO-02",
        alpha2code: "02",
        name: "Akershus",
      },
      {
        code: "NO-09",
        alpha2code: "09",
        name: "Aust-Agder",
      },
      {
        code: "NO-06",
        alpha2code: "06",
        name: "Buskerud",
      },
      {
        code: "NO-20",
        alpha2code: "20",
        name: "Finnmark",
      },
      {
        code: "NO-04",
        alpha2code: "04",
        name: "Hedmark",
      },
      {
        code: "NO-12",
        alpha2code: "12",
        name: "Hordaland",
      },
      {
        code: "NO-15",
        alpha2code: "15",
        name: "Møre og Romsdal",
      },
      {
        code: "NO-18",
        alpha2code: "18",
        name: "Nordland",
      },
      {
        code: "NO-17",
        alpha2code: "17",
        name: "Nord-Trøndelag",
      },
      {
        code: "NO-05",
        alpha2code: "05",
        name: "Oppland",
      },
      {
        code: "NO-03",
        alpha2code: "03",
        name: "Oslo",
      },
      {
        code: "NO-11",
        alpha2code: "11",
        name: "Rogaland",
      },
      {
        code: "NO-14",
        alpha2code: "14",
        name: "Sogn og Fjordane",
      },
      {
        code: "NO-16",
        alpha2code: "16",
        name: "Sør-Trøndelag",
      },
      {
        code: "NO-08",
        alpha2code: "08",
        name: "Telemark",
      },
      {
        code: "NO-19",
        alpha2code: "19",
        name: "Troms",
      },
      {
        code: "NO-10",
        alpha2code: "10",
        name: "Vest-Agder",
      },
      {
        code: "NO-07",
        alpha2code: "07",
        name: "Vestfold",
      },
      {
        code: "NO-01",
        alpha2code: "01",
        name: "Østfold",
      },
      {
        code: "NO-22",
        alpha2code: "22",
        name: "Jan Mayen (Arctic Region)",
      },
      {
        code: "NO-21",
        alpha2code: "21",
        name: "Svalbard (Arctic Region)",
      },
    ],
  },
  OM: {
    name: "Oman",
    subdivisions: [
      {
        code: "OM-DA",
        alpha2code: "DA",
        name: "Ad Dākhilīyah",
      },
      {
        code: "OM-BA",
        alpha2code: "BA",
        name: "Al Bāţinah",
      },
      {
        code: "OM-JA",
        alpha2code: "JA",
        name: "Al Janūbīyah [Zufār]",
      },
      {
        code: "OM-WU",
        alpha2code: "WU",
        name: "Al Wusţā",
      },
      {
        code: "OM-SH",
        alpha2code: "SH",
        name: "Ash Sharqīyah",
      },
      {
        code: "OM-ZA",
        alpha2code: "ZA",
        name: "Az Zāhirah",
      },
      {
        code: "OM-MA",
        alpha2code: "MA",
        name: "Masqaţ",
      },
      {
        code: "OM-MU",
        alpha2code: "MU",
        name: "Musandam",
      },
    ],
  },
  PK: {
    name: "Pakistan",
    subdivisions: [
      {
        code: "PK-IS",
        alpha2code: "IS",
        name: "Islamabad",
      },
      {
        code: "PK-BA",
        alpha2code: "BA",
        name: "Baluchistan (Balochistān)",
      },
      {
        code: "PK-NW",
        alpha2code: "NW",
        name: "North-West Frontier",
      },
      {
        code: "PK-PB",
        alpha2code: "PB",
        name: "Punjab",
      },
      {
        code: "PK-SD",
        alpha2code: "SD",
        name: "Sind (Sindh)",
      },
      {
        code: "PK-TA",
        alpha2code: "TA",
        name: "Federally Administered Tribal Areas",
      },
      {
        code: "PK-JK",
        alpha2code: "JK",
        name: "Azad Kashmir",
      },
      {
        code: "PK-NA",
        alpha2code: "NA",
        name: "Northern Areas",
      },
    ],
  },
  PW: {
    name: "Palau",
    subdivisions: [
      {
        code: "PW-PW",
        alpha2code: "PW",
        name: "Palau",
      },
    ],
  },
  PA: {
    name: "Panama",
    subdivisions: [
      {
        code: "PA-1",
        alpha2code: "1",
        name: "Botas del Toro",
      },
      {
        code: "PA-2",
        alpha2code: "2",
        name: "Coclé",
      },
      {
        code: "PA-3",
        alpha2code: "3",
        name: "Colón",
      },
      {
        code: "PA-4",
        alpha2code: "4",
        name: "Chiriquī",
      },
      {
        code: "PA-5",
        alpha2code: "5",
        name: "Darién",
      },
      {
        code: "PA-6",
        alpha2code: "6",
        name: "Herrera",
      },
      {
        code: "PA-7",
        alpha2code: "7",
        name: "Los Santos",
      },
      {
        code: "PA-8",
        alpha2code: "8",
        name: "Panamá",
      },
      {
        code: "PA-9",
        alpha2code: "9",
        name: "Veraguas",
      },
      {
        code: "PA-0",
        alpha2code: "0",
        name: "Comarca de San Blas",
      },
    ],
  },
  PG: {
    name: "Papua New Guinea",
    subdivisions: [
      {
        code: "PG-NCD",
        alpha2code: "NCD",
        name: "National Capital District (Port Moresby)",
      },
      {
        code: "PG-CPM",
        alpha2code: "CPM",
        name: "Central",
      },
      {
        code: "PG-CPK",
        alpha2code: "CPK",
        name: "Chimbu",
      },
      {
        code: "PG-EHG",
        alpha2code: "EHG",
        name: "Eastern Highlands",
      },
      {
        code: "PG-EBR",
        alpha2code: "EBR",
        name: "East New Britain",
      },
      {
        code: "PG-ESW",
        alpha2code: "ESW",
        name: "East Sepik",
      },
      {
        code: "PG-EPW",
        alpha2code: "EPW",
        name: "Enga",
      },
      {
        code: "PG-GPK",
        alpha2code: "GPK",
        name: "Gulf",
      },
      {
        code: "PG-MPM",
        alpha2code: "MPM",
        name: "Madang",
      },
      {
        code: "PG-MRL",
        alpha2code: "MRL",
        name: "Manus",
      },
      {
        code: "PG-MBA",
        alpha2code: "MBA",
        name: "Milne Bay",
      },
      {
        code: "PG-MPL",
        alpha2code: "MPL",
        name: "Morobe",
      },
      {
        code: "PG-NIK",
        alpha2code: "NIK",
        name: "New Ireland",
      },
      {
        code: "PG-NPP",
        alpha2code: "NPP",
        name: "Northern",
      },
      {
        code: "PG-NSA",
        alpha2code: "NSA",
        name: "North Solomons",
      },
      {
        code: "PG-SAN",
        alpha2code: "SAN",
        name: "Sandaun [West Sepik]",
      },
      {
        code: "PG-SHM",
        alpha2code: "SHM",
        name: "Southern Highlands",
      },
      {
        code: "PG-WPD",
        alpha2code: "WPD",
        name: "Western",
      },
      {
        code: "PG-WHM",
        alpha2code: "WHM",
        name: "Western Highlands",
      },
      {
        code: "PG-WBK",
        alpha2code: "WBK",
        name: "West New Britain",
      },
    ],
  },
  PY: {
    name: "Paraguay",
    subdivisions: [
      {
        code: "PY-ASU",
        alpha2code: "ASU",
        name: "Asunción",
      },
      {
        code: "PY-16",
        alpha2code: "16",
        name: "Alto Paraguay",
      },
      {
        code: "PY-10",
        alpha2code: "10",
        name: "Alto Parang",
      },
      {
        code: "PY-13",
        alpha2code: "13",
        name: "Amambay",
      },
      {
        code: "PY-19",
        alpha2code: "19",
        name: "Boquerón",
      },
      {
        code: "PY-5",
        alpha2code: "5",
        name: "Caaguazú",
      },
      {
        code: "PY-6",
        alpha2code: "6",
        name: "Caazapá",
      },
      {
        code: "PY-14",
        alpha2code: "14",
        name: "Canindeyú",
      },
      {
        code: "PY-11",
        alpha2code: "11",
        name: "Central",
      },
      {
        code: "PY-1",
        alpha2code: "1",
        name: "Concepción",
      },
      {
        code: "PY-3",
        alpha2code: "3",
        name: "Cordillera",
      },
      {
        code: "PY-4",
        alpha2code: "4",
        name: "Guairá",
      },
      {
        code: "PY-7",
        alpha2code: "7",
        name: "Itapúa",
      },
      {
        code: "PY-8",
        alpha2code: "8",
        name: "Misiones",
      },
      {
        code: "PY-12",
        alpha2code: "12",
        name: "Neembucú",
      },
      {
        code: "PY-9",
        alpha2code: "9",
        name: "Paraguarī",
      },
      {
        code: "PY-15",
        alpha2code: "15",
        name: "Presidente Hayes",
      },
      {
        code: "PY-2",
        alpha2code: "2",
        name: "San Pedro",
      },
    ],
  },
  PE: {
    name: "Peru",
    subdivisions: [
      {
        code: "PE-CAL",
        alpha2code: "CAL",
        name: "El Callao",
      },
      {
        code: "PE-AMA",
        alpha2code: "AMA",
        name: "Amazonas",
      },
      {
        code: "PE-ANC",
        alpha2code: "ANC",
        name: "Ancash",
      },
      {
        code: "PE-APU",
        alpha2code: "APU",
        name: "Apurímac",
      },
      {
        code: "PE-ARE",
        alpha2code: "ARE",
        name: "Arequipa",
      },
      {
        code: "PE-AYA",
        alpha2code: "AYA",
        name: "Ayacucho",
      },
      {
        code: "PE-CAJ",
        alpha2code: "CAJ",
        name: "Cajamarca",
      },
      {
        code: "PE-CUS",
        alpha2code: "CUS",
        name: "Cuzco [Cusco]",
      },
      {
        code: "PE-HUV",
        alpha2code: "HUV",
        name: "Huancavelica",
      },
      {
        code: "PE-HUC",
        alpha2code: "HUC",
        name: "Huánuco",
      },
      {
        code: "PE-ICA",
        alpha2code: "ICA",
        name: "Ica",
      },
      {
        code: "PE-JUN",
        alpha2code: "JUN",
        name: "Junín",
      },
      {
        code: "PE-LAL",
        alpha2code: "LAL",
        name: "La Libertad",
      },
      {
        code: "PE-LAM",
        alpha2code: "LAM",
        name: "Lambayeque",
      },
      {
        code: "PE-LIM",
        alpha2code: "LIM",
        name: "Lima",
      },
      {
        code: "PE-LOR",
        alpha2code: "LOR",
        name: "Loreto",
      },
      {
        code: "PE-MDD",
        alpha2code: "MDD",
        name: "Madre de Dios",
      },
      {
        code: "PE-MOQ",
        alpha2code: "MOQ",
        name: "Moquegua",
      },
      {
        code: "PE-PAS",
        alpha2code: "PAS",
        name: "Pasco",
      },
      {
        code: "PE-PIU",
        alpha2code: "PIU",
        name: "Piura",
      },
      {
        code: "PE-PUN",
        alpha2code: "PUN",
        name: "Puno",
      },
      {
        code: "PE-SAM",
        alpha2code: "SAM",
        name: "San Martín",
      },
      {
        code: "PE-TAC",
        alpha2code: "TAC",
        name: "Tacna",
      },
      {
        code: "PE-TUM",
        alpha2code: "TUM",
        name: "Tumbes",
      },
      {
        code: "PE-UCA",
        alpha2code: "UCA",
        name: "Ucayali",
      },
    ],
  },
  PH: {
    name: "Philippines",
    subdivisions: [
      {
        code: "PH-PH",
        alpha2code: "PH",
        name: "Philippines",
      },
    ],
  },
  PN: {
    name: "Pitcairn",
    subdivisions: [
      {
        code: "PN-PN",
        alpha2code: "PN",
        name: "Pitcairn",
      },
    ],
  },
  PL: {
    name: "Poland",
    subdivisions: [
      {
        code: "PL-BP",
        alpha2code: "BP",
        name: "Biała Podlaska",
      },
      {
        code: "PL-BK",
        alpha2code: "BK",
        name: "Białystok",
      },
      {
        code: "PL-BB",
        alpha2code: "BB",
        name: "Bielsko",
      },
      {
        code: "PL-BY",
        alpha2code: "BY",
        name: "Bydgoszcz",
      },
      {
        code: "PL-CH",
        alpha2code: "CH",
        name: "Chełm",
      },
      {
        code: "PL-CI",
        alpha2code: "CI",
        name: "Ciechanów",
      },
      {
        code: "PL-CZ",
        alpha2code: "CZ",
        name: "Czestochowa",
      },
      {
        code: "PL-EL",
        alpha2code: "EL",
        name: "Elblag",
      },
      {
        code: "PL-GD",
        alpha2code: "GD",
        name: "Gdańsk",
      },
      {
        code: "PL-GO",
        alpha2code: "GO",
        name: "Gorzów",
      },
      {
        code: "PL-JG",
        alpha2code: "JG",
        name: "Jelenia Gera",
      },
      {
        code: "PL-KL",
        alpha2code: "KL",
        name: "Kalisz",
      },
      {
        code: "PL-KA",
        alpha2code: "KA",
        name: "Katowice",
      },
      {
        code: "PL-KI",
        alpha2code: "KI",
        name: "Kielce",
      },
      {
        code: "PL-KN",
        alpha2code: "KN",
        name: "Konin",
      },
      {
        code: "PL-KO",
        alpha2code: "KO",
        name: "Koszalin",
      },
      {
        code: "PL-KR",
        alpha2code: "KR",
        name: "Kraków",
      },
      {
        code: "PL-KS",
        alpha2code: "KS",
        name: "Krosno",
      },
      {
        code: "PL-LG",
        alpha2code: "LG",
        name: "Legnica",
      },
      {
        code: "PL-LE",
        alpha2code: "LE",
        name: "Leszno",
      },
      {
        code: "PL-LU",
        alpha2code: "LU",
        name: "Lublin",
      },
      {
        code: "PL-LO",
        alpha2code: "LO",
        name: "Łomia",
      },
      {
        code: "PL-LD",
        alpha2code: "LD",
        name: "Łódź",
      },
      {
        code: "PL-NS",
        alpha2code: "NS",
        name: "Nowy Sacz",
      },
      {
        code: "PL-OL",
        alpha2code: "OL",
        name: "Olsztyn",
      },
      {
        code: "PL-OP",
        alpha2code: "OP",
        name: "Opole",
      },
      {
        code: "PL-OS",
        alpha2code: "OS",
        name: "Ostrołeka",
      },
      {
        code: "PL-PI",
        alpha2code: "PI",
        name: "Piła",
      },
      {
        code: "PL-PT",
        alpha2code: "PT",
        name: "Piotrków",
      },
      {
        code: "PL-PL",
        alpha2code: "PL",
        name: "Płock",
      },
      {
        code: "PL-PO",
        alpha2code: "PO",
        name: "Poznań",
      },
      {
        code: "PL-PR",
        alpha2code: "PR",
        name: "Przemyśl",
      },
      {
        code: "PL-RA",
        alpha2code: "RA",
        name: "Radom",
      },
      {
        code: "PL-RZ",
        alpha2code: "RZ",
        name: "Rzeszów",
      },
      {
        code: "PL-SE",
        alpha2code: "SE",
        name: "Siedlce",
      },
      {
        code: "PL-SI",
        alpha2code: "SI",
        name: "Sieradz",
      },
      {
        code: "PL-SK",
        alpha2code: "SK",
        name: "Skierniewice",
      },
      {
        code: "PL-SL",
        alpha2code: "SL",
        name: "Słupsk",
      },
      {
        code: "PL-SU",
        alpha2code: "SU",
        name: "Suwałki",
      },
      {
        code: "PL-SZ",
        alpha2code: "SZ",
        name: "Szczecin",
      },
      {
        code: "PL-TG",
        alpha2code: "TG",
        name: "Tarnobrzeg",
      },
      {
        code: "PL-TA",
        alpha2code: "TA",
        name: "Tarnów",
      },
      {
        code: "PL-TO",
        alpha2code: "TO",
        name: "Toruń",
      },
      {
        code: "PL-WB",
        alpha2code: "WB",
        name: "Wałbrzych",
      },
      {
        code: "PL-WA",
        alpha2code: "WA",
        name: "Warszawa",
      },
      {
        code: "PL-WL",
        alpha2code: "WL",
        name: "Włocławek",
      },
      {
        code: "PL-WR",
        alpha2code: "WR",
        name: "Wrocław",
      },
      {
        code: "PL-ZA",
        alpha2code: "ZA",
        name: "Zamość",
      },
      {
        code: "PL-ZG",
        alpha2code: "ZG",
        name: "Zielona Góra",
      },
    ],
  },
  PT: {
    name: "Portugal",
    subdivisions: [
      {
        code: "PT-01",
        alpha2code: "01",
        name: "Aveiro",
      },
      {
        code: "PT-02",
        alpha2code: "02",
        name: "Beja",
      },
      {
        code: "PT-03",
        alpha2code: "03",
        name: "Braga",
      },
      {
        code: "PT-04",
        alpha2code: "04",
        name: "Bragança",
      },
      {
        code: "PT-05",
        alpha2code: "05",
        name: "Castelo Branco",
      },
      {
        code: "PT-06",
        alpha2code: "06",
        name: "Coimbra",
      },
      {
        code: "PT-07",
        alpha2code: "07",
        name: "Évora",
      },
      {
        code: "PT-08",
        alpha2code: "08",
        name: "Faro",
      },
      {
        code: "PT-09",
        alpha2code: "09",
        name: "Guarda",
      },
      {
        code: "PT-10",
        alpha2code: "10",
        name: "Leiria",
      },
      {
        code: "PT-11",
        alpha2code: "11",
        name: "Lisboa",
      },
      {
        code: "PT-12",
        alpha2code: "12",
        name: "Portalegre",
      },
      {
        code: "PT-13",
        alpha2code: "13",
        name: "Porto",
      },
      {
        code: "PT-14",
        alpha2code: "14",
        name: "Santarém",
      },
      {
        code: "PT-15",
        alpha2code: "15",
        name: "Setúbal",
      },
      {
        code: "PT-16",
        alpha2code: "16",
        name: "Viana do Castelo",
      },
      {
        code: "PT-17",
        alpha2code: "17",
        name: "Vila Real",
      },
      {
        code: "PT-18",
        alpha2code: "18",
        name: "Viseu",
      },
      {
        code: "PT-20",
        alpha2code: "20",
        name: "Regiāo Autónoma dos Açores",
      },
      {
        code: "PT-30",
        alpha2code: "30",
        name: "Regiāo Autónoma da Madeira",
      },
    ],
  },
  PR: {
    name: "Puerto Rico",
    subdivisions: [
      {
        code: "PR-PR",
        alpha2code: "PR",
        name: "Puerto Rico",
      },
    ],
  },
  QA: {
    name: "Qatar",
    subdivisions: [
      {
        code: "QA-DA",
        alpha2code: "DA",
        name: "Ad Dawḩah",
      },
      {
        code: "QA-GH",
        alpha2code: "GH",
        name: "Al Ghuwayrīyah",
      },
      {
        code: "QA-JU",
        alpha2code: "JU",
        name: "Al Jumaylīyah",
      },
      {
        code: "QA-KH",
        alpha2code: "KH",
        name: "Al Khawr",
      },
      {
        code: "QA-WA",
        alpha2code: "WA",
        name: "Al Wakrah",
      },
      {
        code: "QA-RA",
        alpha2code: "RA",
        name: "Ar Rayyān",
      },
      {
        code: "QA-JB",
        alpha2code: "JB",
        name: "Jarīyān al Bāţnah",
      },
      {
        code: "QA-MS",
        alpha2code: "MS",
        name: "Madīnat ash Shamāl",
      },
      {
        code: "QA-US",
        alpha2code: "US",
        name: "Umm Şalāl",
      },
    ],
  },
  RE: {
    name: "Reunion",
    subdivisions: [
      {
        code: "RE-RE",
        alpha2code: "RE",
        name: "Reunion",
      },
    ],
  },
  RO: {
    name: "Romania",
    subdivisions: [
      {
        code: "RO-B",
        alpha2code: "B",
        name: "Bucureşti",
      },
      {
        code: "RO-AB",
        alpha2code: "AB",
        name: "Alba",
      },
      {
        code: "RO-AR",
        alpha2code: "AR",
        name: "Arad",
      },
      {
        code: "RO-AG",
        alpha2code: "AG",
        name: "Argeş",
      },
      {
        code: "RO-BC",
        alpha2code: "BC",
        name: "Bacău",
      },
      {
        code: "RO-BH",
        alpha2code: "BH",
        name: "Bihor",
      },
      {
        code: "RO-BN",
        alpha2code: "BN",
        name: "Bistriţa-Năsăud",
      },
      {
        code: "RO-BT",
        alpha2code: "BT",
        name: "Botoşani",
      },
      {
        code: "RO-BV",
        alpha2code: "BV",
        name: "Braşov",
      },
      {
        code: "RO-BR",
        alpha2code: "BR",
        name: "Brăila",
      },
      {
        code: "RO-BZ",
        alpha2code: "BZ",
        name: "Buzău",
      },
      {
        code: "RO-CS",
        alpha2code: "CS",
        name: "Caraş-Severin",
      },
      {
        code: "RO-CL",
        alpha2code: "CL",
        name: "Călăraşi",
      },
      {
        code: "RO-CJ",
        alpha2code: "CJ",
        name: "Cluj",
      },
      {
        code: "RO-CT",
        alpha2code: "CT",
        name: "Constanţa",
      },
      {
        code: "RO-CV",
        alpha2code: "CV",
        name: "Covasna",
      },
      {
        code: "RO-DB",
        alpha2code: "DB",
        name: "Dâmboviţa",
      },
      {
        code: "RO-DJ",
        alpha2code: "DJ",
        name: "Dolj",
      },
      {
        code: "RO-GL",
        alpha2code: "GL",
        name: "Galaţi",
      },
      {
        code: "RO-GR",
        alpha2code: "GR",
        name: "Giurgiu",
      },
      {
        code: "RO-GJ",
        alpha2code: "GJ",
        name: "Gorj",
      },
      {
        code: "RO-HR",
        alpha2code: "HR",
        name: "Harghita",
      },
      {
        code: "RO-HD",
        alpha2code: "HD",
        name: "Hunedoara",
      },
      {
        code: "RO-IL",
        alpha2code: "IL",
        name: "Ialomiţa",
      },
      {
        code: "RO-IS",
        alpha2code: "IS",
        name: "Iaşi",
      },
      {
        code: "RO-MM",
        alpha2code: "MM",
        name: "Maramureş",
      },
      {
        code: "RO-MH",
        alpha2code: "MH",
        name: "Mehedinţi",
      },
      {
        code: "RO-MS",
        alpha2code: "MS",
        name: "Mureş",
      },
      {
        code: "RO-NT",
        alpha2code: "NT",
        name: "Neamţ",
      },
      {
        code: "RO-OT",
        alpha2code: "OT",
        name: "Olt",
      },
      {
        code: "RO-PH",
        alpha2code: "PH",
        name: "Prahova",
      },
      {
        code: "RO-SM",
        alpha2code: "SM",
        name: "Satu Mare",
      },
      {
        code: "RO-SJ",
        alpha2code: "SJ",
        name: "Sălaj",
      },
      {
        code: "RO-SB",
        alpha2code: "SB",
        name: "Sibiu",
      },
      {
        code: "RO-SV",
        alpha2code: "SV",
        name: "Suceava",
      },
      {
        code: "RO-TR",
        alpha2code: "TR",
        name: "Teleorman",
      },
      {
        code: "RO-TM",
        alpha2code: "TM",
        name: "Timiş",
      },
      {
        code: "RO-TL",
        alpha2code: "TL",
        name: "Tulcea",
      },
      {
        code: "RO-VS",
        alpha2code: "VS",
        name: "Vaslui",
      },
      {
        code: "RO-VL",
        alpha2code: "VL",
        name: "Vâlcea",
      },
      {
        code: "RO-VN",
        alpha2code: "VN",
        name: "Vrancea",
      },
    ],
  },
  RU: {
    name: "Russian Federation",
    subdivisions: [
      {
        code: "RU-AD",
        alpha2code: "AD",
        name: "Adygeya, Respublika",
      },
      {
        code: "RU-AL",
        alpha2code: "AL",
        name: "Altay, Respublika",
      },
      {
        code: "RU-BA",
        alpha2code: "BA",
        name: "Bashkortostan, Respublika",
      },
      {
        code: "RU-BU",
        alpha2code: "BU",
        name: "Buryatiya, Respublika",
      },
      {
        code: "RU-CE",
        alpha2code: "CE",
        name: "Chechenskaya Respublika",
      },
      {
        code: "RU-CU",
        alpha2code: "CU",
        name: "Chuvashskaya Respublika",
      },
      {
        code: "RU-DA",
        alpha2code: "DA",
        name: "Dagestan, Respublika",
      },
      {
        code: "RU-IN",
        alpha2code: "IN",
        name: "Ingushskaya Respublika",
      },
      {
        code: "RU-KB",
        alpha2code: "KB",
        name: "Kabardino-Balkarskaya Respublika",
      },
      {
        code: "RU-KL",
        alpha2code: "KL",
        name: "Kalmykiya, Respublika",
      },
      {
        code: "RU-KC",
        alpha2code: "KC",
        name: "Karachayevo-Cherkesskaya Respublika",
      },
      {
        code: "RU-KR",
        alpha2code: "KR",
        name: "Kareliya, Respublika",
      },
      {
        code: "RU-KK",
        alpha2code: "KK",
        name: "Khakasiya, Respublika",
      },
      {
        code: "RU-KO",
        alpha2code: "KO",
        name: "Komi, Respublika",
      },
      {
        code: "RU-ME",
        alpha2code: "ME",
        name: "Mariy El, Respublika",
      },
      {
        code: "RU-MO",
        alpha2code: "MO",
        name: "Mordoviya, Respublika",
      },
      {
        code: "RU-SA",
        alpha2code: "SA",
        name: "Sakha, Respublika [Yakutiya]",
      },
      {
        code: "RU-SE",
        alpha2code: "SE",
        name: "Severnaya Osetiya, Respublika [Alaniya]",
      },
      {
        code: "RU-TA",
        alpha2code: "TA",
        name: "Tatarstan, Respublika",
      },
      {
        code: "RU-TY",
        alpha2code: "TY",
        name: "Tyva, Respublika [Tuva]",
      },
      {
        code: "RU-UD",
        alpha2code: "UD",
        name: "Udmurtskaya Respublika",
      },
      {
        code: "RU-ALT",
        alpha2code: "ALT",
        name: "Altayskiy kray",
      },
      {
        code: "RU-KHA",
        alpha2code: "KHA",
        name: "Khabarovskiy kray",
      },
      {
        code: "RU-KDA",
        alpha2code: "KDA",
        name: "Krasnodarskiy kray",
      },
      {
        code: "RU-KYA",
        alpha2code: "KYA",
        name: "Krasnoyarskiy kray",
      },
      {
        code: "RU-PRI",
        alpha2code: "PRI",
        name: "Primorskiy kray",
      },
      {
        code: "RU-STA",
        alpha2code: "STA",
        name: "Stavropol 'skiy kray",
      },
      {
        code: "RU-AMU",
        alpha2code: "AMU",
        name: "Amurskaya Oblast'",
      },
      {
        code: "RU-ARK",
        alpha2code: "ARK",
        name: "Arkhangel'skaya Oblast'",
      },
      {
        code: "RU-AST",
        alpha2code: "AST",
        name: "Astrakhanskaya Oblast'",
      },
      {
        code: "RU-BEL",
        alpha2code: "BEL",
        name: "Belgorodskaya Oblast'",
      },
      {
        code: "RU-BRY",
        alpha2code: "BRY",
        name: "Bryanskaya Oblast'",
      },
      {
        code: "RU-CHE",
        alpha2code: "CHE",
        name: "Chelyabinskaya Oblast'",
      },
      {
        code: "RU-CHI",
        alpha2code: "CHI",
        name: "Chitinskaya Oblast'",
      },
      {
        code: "RU-IRK",
        alpha2code: "IRK",
        name: "Irkutskaya Oblast'",
      },
      {
        code: "RU-IVA",
        alpha2code: "IVA",
        name: "Ivanovskaya Oblast'",
      },
      {
        code: "RU-KGD",
        alpha2code: "KGD",
        name: "Kaliningradskaya Oblast'",
      },
      {
        code: "RU-KLU",
        alpha2code: "KLU",
        name: "Kaluzhskaya Oblast'",
      },
      {
        code: "RU-KAM",
        alpha2code: "KAM",
        name: "Kamchatskaya Oblast'",
      },
      {
        code: "RU-KEM",
        alpha2code: "KEM",
        name: "Kemerovskaya Oblast'",
      },
      {
        code: "RU-KIR",
        alpha2code: "KIR",
        name: "Kirovskaya Oblast'",
      },
      {
        code: "RU-KOS",
        alpha2code: "KOS",
        name: "Kostromskaya Oblast'",
      },
      {
        code: "RU-KGN",
        alpha2code: "KGN",
        name: "Kurganskaya Oblast'",
      },
      {
        code: "RU-KRS",
        alpha2code: "KRS",
        name: "Kurskaya Oblast'",
      },
      {
        code: "RU-LEN",
        alpha2code: "LEN",
        name: "Leningradskaya Oblast'",
      },
      {
        code: "RU-LIP",
        alpha2code: "LIP",
        name: "Lipetskaya Oblast'",
      },
      {
        code: "RU-MAG",
        alpha2code: "MAG",
        name: "Magadanskaya Oblast'",
      },
      {
        code: "RU-MOS",
        alpha2code: "MOS",
        name: "Moskovskaya Oblast'",
      },
      {
        code: "RU-MUR",
        alpha2code: "MUR",
        name: "Murmanskaya Oblast'",
      },
      {
        code: "RU-NIZ",
        alpha2code: "NIZ",
        name: "Nizhegorodskaya Oblast'",
      },
      {
        code: "RU-NGR",
        alpha2code: "NGR",
        name: "Novgorodskaya Oblast'",
      },
      {
        code: "RU-NVS",
        alpha2code: "NVS",
        name: "Novosibirskaya Oblast'",
      },
      {
        code: "RU-OMS",
        alpha2code: "OMS",
        name: "Omskaya Oblast'",
      },
      {
        code: "RU-ORE",
        alpha2code: "ORE",
        name: "Orenburgskaya Oblast'",
      },
      {
        code: "RU-ORL",
        alpha2code: "ORL",
        name: "Orlovskaya Oblast'",
      },
      {
        code: "RU-PNZ",
        alpha2code: "PNZ",
        name: "Penzenskaya Oblast'",
      },
      {
        code: "RU-PER",
        alpha2code: "PER",
        name: "Permskaya Oblast'",
      },
      {
        code: "RU-PSK",
        alpha2code: "PSK",
        name: "Pskovskaya Oblast'",
      },
      {
        code: "RU-ROS",
        alpha2code: "ROS",
        name: "Rostovskaya Oblast'",
      },
      {
        code: "RU-RYA",
        alpha2code: "RYA",
        name: "Ryazanskaya Oblast'",
      },
      {
        code: "RU-SAK",
        alpha2code: "SAK",
        name: "Sakhalinskaya Oblast'",
      },
      {
        code: "RU-SAM",
        alpha2code: "SAM",
        name: "Samarskaya Oblast’",
      },
      {
        code: "RU-SAR",
        alpha2code: "SAR",
        name: "Saratovskaya Oblast'",
      },
      {
        code: "RU-SMO",
        alpha2code: "SMO",
        name: "Smolenskaya Oblast'",
      },
      {
        code: "RU-SVE",
        alpha2code: "SVE",
        name: "Sverdlovskaya Oblast'",
      },
      {
        code: "RU-TAM",
        alpha2code: "TAM",
        name: "Tambovskaya Oblast'",
      },
      {
        code: "RU-TOM",
        alpha2code: "TOM",
        name: "Tomskaya Oblast’",
      },
      {
        code: "RU-TUL",
        alpha2code: "TUL",
        name: "Tul'skaya Oblast'",
      },
      {
        code: "RU-TVE",
        alpha2code: "TVE",
        name: "Tverskaya Oblast'",
      },
      {
        code: "RU-TYU",
        alpha2code: "TYU",
        name: "Tyumenskaya Oblast'",
      },
      {
        code: "RU-ULY",
        alpha2code: "ULY",
        name: "Ul'yanovskaya Oblast'",
      },
      {
        code: "RU-VLA",
        alpha2code: "VLA",
        name: "Vladimirskaya Oblast'",
      },
      {
        code: "RU-VGG",
        alpha2code: "VGG",
        name: "Volgogradskaya Oblast'",
      },
      {
        code: "RU-VLG",
        alpha2code: "VLG",
        name: "Vologodskaya Oblast'",
      },
      {
        code: "RU-VOR",
        alpha2code: "VOR",
        name: "Voronezhskaya Oblast'",
      },
      {
        code: "RU-YAR",
        alpha2code: "YAR",
        name: "Yaroslavskaya Oblast'",
      },
      {
        code: "RU-MOW",
        alpha2code: "MOW",
        name: "Moskva",
      },
      {
        code: "RU-SPE",
        alpha2code: "SPE",
        name: "Sankt-Peterburg",
      },
      {
        code: "RU-YEV",
        alpha2code: "YEV",
        name: "Yevreyskaya avtonomnaya oblast'",
      },
      {
        code: "RU-AGB",
        alpha2code: "AGB",
        name: "Aginskiy Buryatskiy avtonomnyy okrug",
      },
      {
        code: "RU-CHU",
        alpha2code: "CHU",
        name: "Chukotskiy avtonomnyy okrug",
      },
      {
        code: "RU-EVE",
        alpha2code: "EVE",
        name: "Evenkiyskiy avtonomnyy okrug",
      },
      {
        code: "RU-KHM",
        alpha2code: "KHM",
        name: "Khanty-Mansiyskiy avtonomnyy okrug",
      },
      {
        code: "RU-KOP",
        alpha2code: "KOP",
        name: "Komi-Permyatskiy avtonomnyy okrug",
      },
      {
        code: "RU-KOR",
        alpha2code: "KOR",
        name: "Koryakskiy avtonomnyy okrug",
      },
      {
        code: "RU-NEN",
        alpha2code: "NEN",
        name: "Nenetskiy avtonomnyy okrug",
      },
      {
        code: "RU-TAY",
        alpha2code: "TAY",
        name: "Taymyrskiy (Dolgano-Nenetskiy) avtonomnyy okrug",
      },
      {
        code: "RU-UOB",
        alpha2code: "UOB",
        name: "Ust’-Ordynskiy Buryatskiy avtonomnyy okrug",
      },
      {
        code: "RU-YAN",
        alpha2code: "YAN",
        name: "Yamalo-Nenetskiy avtonomnyy okrug",
      },
    ],
  },
  RW: {
    name: "Rwanda",
    subdivisions: [
      {
        code: "RW-C",
        alpha2code: "C",
        name: "Butare",
      },
      {
        code: "RW-I",
        alpha2code: "I",
        name: "Byumba",
      },
      {
        code: "RW-E",
        alpha2code: "E",
        name: "Cyangugu",
      },
      {
        code: "RW-D",
        alpha2code: "D",
        name: "Gikongoro",
      },
      {
        code: "RW-G",
        alpha2code: "G",
        name: "Gisenyi",
      },
      {
        code: "RW-B",
        alpha2code: "B",
        name: "Gitarama",
      },
      {
        code: "RW-J",
        alpha2code: "J",
        name: "Kibungo",
      },
      {
        code: "RW-F",
        alpha2code: "F",
        name: "Kibuye",
      },
      {
        code: "RW-K",
        alpha2code: "K",
        name: "Kigali-Rural (Kigali y’ Icyaro)",
      },
      {
        code: "RW-L",
        alpha2code: "L",
        name: "Kigali-Ville (Kilgali Ngari)",
      },
      {
        code: "RW-M",
        alpha2code: "M",
        name: "Mutara",
      },
      {
        code: "RW-H",
        alpha2code: "H",
        name: "Ruhengeri",
      },
    ],
  },
  SH: {
    name: "St. Helena",
    subdivisions: [
      {
        code: "SH-SH",
        alpha2code: "SH",
        name: "Saint Helena",
      },
      {
        code: "SH-AC",
        alpha2code: "AC",
        name: "Ascension",
      },
      {
        code: "SH-TA",
        alpha2code: "TA",
        name: "Tristan da Cunha",
      },
    ],
  },
  KN: {
    name: "Saint Kitts & Nevis",
    subdivisions: [
      {
        code: "KN-KN",
        alpha2code: "KN",
        name: "Saint Kitts & Nevis",
      },
    ],
  },
  LC: {
    name: "Saint Lucia",
    subdivisions: [
      {
        code: "LC-LC",
        alpha2code: "LC",
        name: "Saint Lucia",
      },
    ],
  },
  PM: {
    name: "St. Pierre & Miquelon",
    subdivisions: [
      {
        code: "PM-PM",
        alpha2code: "PM",
        name: "St. Pierre & Miquelon",
      },
    ],
  },
  VC: {
    name: "St. Vincent & the Grenadines",
    subdivisions: [
      {
        code: "VC-VC",
        alpha2code: "VC",
        name: "St. Vincent & the Grenadines",
      },
    ],
  },
  WS: {
    name: "Samoa",
    subdivisions: [
      {
        code: "WS-AA",
        alpha2code: "AA",
        name: "A'ana",
      },
      {
        code: "WS-AL",
        alpha2code: "AL",
        name: "Aiga-i-le-Tai",
      },
      {
        code: "WS-AT",
        alpha2code: "AT",
        name: "Atua",
      },
      {
        code: "WS-FA",
        alpha2code: "FA",
        name: "Fa'asaleleaga",
      },
      {
        code: "WS-GE",
        alpha2code: "GE",
        name: "Gaga'emauga",
      },
      {
        code: "WS-GI",
        alpha2code: "GI",
        name: "Gagaifomauga",
      },
      {
        code: "WS-PA",
        alpha2code: "PA",
        name: "Palauli",
      },
      {
        code: "WS-SA",
        alpha2code: "SA",
        name: "Satupa'itea",
      },
      {
        code: "WS-TU",
        alpha2code: "TU",
        name: "Tuamasaga",
      },
      {
        code: "WS-VF",
        alpha2code: "VF",
        name: "Va'a-o-Fonoti",
      },
      {
        code: "WS-VS",
        alpha2code: "VS",
        name: "Vaisigano",
      },
    ],
  },
  SM: {
    name: "San Marino",
    subdivisions: [
      {
        code: "SM-SM",
        alpha2code: "SM",
        name: "San Marino",
      },
    ],
  },
  ST: {
    name: "Sao Tome & Principe",
    subdivisions: [
      {
        code: "ST-P",
        alpha2code: "P",
        name: "Príncipe",
      },
      {
        code: "ST-S",
        alpha2code: "S",
        name: "Sāo Tomé",
      },
    ],
  },
  SA: {
    name: "Saudi Arabia",
    subdivisions: [
      {
        code: "SA-11",
        alpha2code: "11",
        name: "Al Bāḩah",
      },
      {
        code: "SA-08",
        alpha2code: "08",
        name: "Al Ḩudūd ash Shamālīyah",
      },
      {
        code: "SA-12",
        alpha2code: "12",
        name: "Al Jawf",
      },
      {
        code: "SA-03",
        alpha2code: "03",
        name: "Al Madīnah",
      },
      {
        code: "SA-05",
        alpha2code: "05",
        name: "Al Qaşim",
      },
      {
        code: "SA-O1",
        alpha2code: "O1",
        name: "Ar Riyāḑ",
      },
      {
        code: "SA-04",
        alpha2code: "04",
        name: "Ash Sharqīyah",
      },
      {
        code: "SA-14",
        alpha2code: "14",
        name: "‘Asīr",
      },
      {
        code: "SA-06",
        alpha2code: "06",
        name: "Ḩā'il",
      },
      {
        code: "SA-09",
        alpha2code: "09",
        name: "Jīzān",
      },
      {
        code: "SA-02",
        alpha2code: "02",
        name: "Makkah",
      },
      {
        code: "SA-10",
        alpha2code: "10",
        name: "Najrān",
      },
      {
        code: "SA-07",
        alpha2code: "07",
        name: "Tabūk",
      },
    ],
  },
  SN: {
    name: "Senegal",
    subdivisions: [
      {
        code: "SN-DK",
        alpha2code: "DK",
        name: "Dakar",
      },
      {
        code: "SN-DB",
        alpha2code: "DB",
        name: "Diourbel",
      },
      {
        code: "SN-FK",
        alpha2code: "FK",
        name: "Fatick",
      },
      {
        code: "SN-KL",
        alpha2code: "KL",
        name: "Kaolack",
      },
      {
        code: "SN-KD",
        alpha2code: "KD",
        name: "Kolda",
      },
      {
        code: "SN-LG",
        alpha2code: "LG",
        name: "Louga",
      },
      {
        code: "SN-SL",
        alpha2code: "SL",
        name: "Saint-Louis",
      },
      {
        code: "SN-TC",
        alpha2code: "TC",
        name: "Tambacounda",
      },
      {
        code: "SN-TH",
        alpha2code: "TH",
        name: "Thiès",
      },
      {
        code: "SN-ZG",
        alpha2code: "ZG",
        name: "Ziguinchor",
      },
    ],
  },
  SC: {
    name: "Seychelles",
    subdivisions: [
      {
        code: "SC-SC",
        alpha2code: "SC",
        name: "Seychelles",
      },
    ],
  },
  SL: {
    name: "Sierra Leone",
    subdivisions: [
      {
        code: "SL-W",
        alpha2code: "W",
        name: "Western Area (Freetown)",
      },
      {
        code: "SL-E",
        alpha2code: "E",
        name: "Eastern",
      },
      {
        code: "SL-N",
        alpha2code: "N",
        name: "Northern",
      },
      {
        code: "SL-S",
        alpha2code: "S",
        name: "Southern",
      },
    ],
  },
  SG: {
    name: "Singapore",
    subdivisions: [
      {
        code: "SG-SG",
        alpha2code: "SG",
        name: "Singapore",
      },
    ],
  },
  SK: {
    name: "Slovak Republic",
    subdivisions: [
      {
        code: "SK-BC",
        alpha2code: "BC",
        name: "Banskobystrický kraj",
      },
      {
        code: "SK-BL",
        alpha2code: "BL",
        name: "Bratislavský kraj",
      },
      {
        code: "SK-KI",
        alpha2code: "KI",
        name: "Košický kraj",
      },
      {
        code: "SK-NI",
        alpha2code: "NI",
        name: "Nitriansky kraj",
      },
      {
        code: "SK-PV",
        alpha2code: "PV",
        name: "Prešovský kraj",
      },
      {
        code: "SK-TC",
        alpha2code: "TC",
        name: "Trenčiansky kraj",
      },
      {
        code: "SK-TA",
        alpha2code: "TA",
        name: "Trnavský kraj",
      },
      {
        code: "SK-ZI",
        alpha2code: "ZI",
        name: "Žilinský kraj",
      },
    ],
  },
  SI: {
    name: "Slovenia",
    subdivisions: [
      {
        code: "SI-07",
        alpha2code: "07",
        name: "Dolenjska",
      },
      {
        code: "SI-09",
        alpha2code: "09",
        name: "Gorenjska",
      },
      {
        code: "SI-11",
        alpha2code: "11",
        name: "Goriška",
      },
      {
        code: "SI-03",
        alpha2code: "03",
        name: "Koroška",
      },
      {
        code: "SI-10",
        alpha2code: "10",
        name: "Notranjsko-kraška",
      },
      {
        code: "SI-12",
        alpha2code: "12",
        name: "Obalno-kraška",
      },
      {
        code: "SI-08",
        alpha2code: "08",
        name: "Osrednjeslovenska",
      },
      {
        code: "SI-02",
        alpha2code: "02",
        name: "Podravska",
      },
      {
        code: "SI-01",
        alpha2code: "01",
        name: "Pomurska",
      },
      {
        code: "SI-04",
        alpha2code: "04",
        name: "Savinjska",
      },
      {
        code: "SI-06",
        alpha2code: "06",
        name: "Spodnjeposavska",
      },
      {
        code: "SI-05",
        alpha2code: "05",
        name: "Zasavska",
      },
    ],
  },
  SB: {
    name: "Solomon Islands",
    subdivisions: [
      {
        code: "SB-CT",
        alpha2code: "CT",
        name: "Capital Territory (Honiara)",
      },
      {
        code: "SB-CE",
        alpha2code: "CE",
        name: "Central",
      },
      {
        code: "SB-GU",
        alpha2code: "GU",
        name: "Guadalcanal",
      },
      {
        code: "SB-IS",
        alpha2code: "IS",
        name: "Isabel",
      },
      {
        code: "SB-MK",
        alpha2code: "MK",
        name: "Makira",
      },
      {
        code: "SB-ML",
        alpha2code: "ML",
        name: "Malaita",
      },
      {
        code: "SB-TE",
        alpha2code: "TE",
        name: "Temotu",
      },
      {
        code: "SB-WE",
        alpha2code: "WE",
        name: "Western",
      },
    ],
  },
  SO: {
    name: "Somalia",
    subdivisions: [
      {
        code: "SO-AW",
        alpha2code: "AW",
        name: "Awdal",
      },
      {
        code: "SO-BK",
        alpha2code: "BK",
        name: "Bakool",
      },
      {
        code: "SO-BN",
        alpha2code: "BN",
        name: "Banaadir",
      },
      {
        code: "SO-BR",
        alpha2code: "BR",
        name: "Bari",
      },
      {
        code: "SO-BY",
        alpha2code: "BY",
        name: "BaY",
      },
      {
        code: "SO-GA",
        alpha2code: "GA",
        name: "Galguduud",
      },
      {
        code: "SO-GE",
        alpha2code: "GE",
        name: "Gedo",
      },
      {
        code: "SO-HI",
        alpha2code: "HI",
        name: "Hiiraan",
      },
      {
        code: "SO-JD",
        alpha2code: "JD",
        name: "Jubbada Dhexe",
      },
      {
        code: "SO-JH",
        alpha2code: "JH",
        name: "Jubbada Hoose",
      },
      {
        code: "SO-MU",
        alpha2code: "MU",
        name: "Mudug",
      },
      {
        code: "SO-NU",
        alpha2code: "NU",
        name: "Nugaal",
      },
      {
        code: "SO-SA",
        alpha2code: "SA",
        name: "Sanaag",
      },
      {
        code: "SO-SD",
        alpha2code: "SD",
        name: "Shabeellaha Dhexe",
      },
      {
        code: "SO-SH",
        alpha2code: "SH",
        name: "Shabeellaha Hoose",
      },
      {
        code: "SO-SO",
        alpha2code: "SO",
        name: "Sool",
      },
      {
        code: "SO-TO",
        alpha2code: "TO",
        name: "Togdheer",
      },
      {
        code: "SO-WO",
        alpha2code: "WO",
        name: "Woqooyi Galbeed",
      },
    ],
  },
  ZA: {
    name: "South Africa",
    subdivisions: [
      {
        code: "ZA-EC",
        alpha2code: "EC",
        name: "Eastern Cape (Oos-Kaap)",
      },
      {
        code: "ZA-FS",
        alpha2code: "FS",
        name: "Free State (Vrystaat)",
      },
      {
        code: "ZA-GT",
        alpha2code: "GT",
        name: "Gauteng",
      },
      {
        code: "ZA-NL",
        alpha2code: "NL",
        name: "Kwazulu-Natal",
      },
      {
        code: "ZA-MP",
        alpha2code: "MP",
        name: "Mpumalanga",
      },
      {
        code: "ZA-NC",
        alpha2code: "NC",
        name: "Northern Cape (Noord-Kaap)",
      },
      {
        code: "ZA-NP",
        alpha2code: "NP",
        name: "Northern Province (Noordelike Provinsie)",
      },
      {
        code: "ZA-NW",
        alpha2code: "NW",
        name: "North-West (Noord-Wes)",
      },
      {
        code: "ZA-WC",
        alpha2code: "WC",
        name: "Western Cape (Wes-Kaap)",
      },
    ],
  },
  GS: {
    name: "S.Georgia & S.Sandwich Islands",
    subdivisions: [
      {
        code: "GS-GS",
        alpha2code: "GS",
        name: "S.Georgia & S.Sandwich Islands",
      },
    ],
  },
  ES: {
    name: "Spain",
    subdivisions: [
      {
        code: "ES-AN",
        alpha2code: "AN",
        name: "Andalucía",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Almería",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cádiz",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Córdoba",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Granada",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Huelva",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jaén",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Málaga",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Sevilla",
      },
      {
        code: "ES-AR",
        alpha2code: "AR",
        name: "Aragón",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Huesca",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Teruel",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Zaragoza",
      },
      {
        code: "ES-O",
        alpha2code: "O",
        name: "Asturias, Principado de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Asturias",
      },
      {
        code: "ES-CN",
        alpha2code: "CN",
        name: "Canarias",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Las Palmas",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Santa Cruz De Tenerife",
      },
      {
        code: "ES-S",
        alpha2code: "S",
        name: "Cantabria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cantabria",
      },
      {
        code: "ES-CM",
        alpha2code: "CM",
        name: "Castilla-La Mancha",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Albacete",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ciudad Real",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cuenca",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Guadalajara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Toledo",
      },
      {
        code: "ES-CL",
        alpha2code: "CL",
        name: "Castilla y León",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ávila",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Burgos",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "León",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Palencia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Salamanca",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Segovia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Soria",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Valladolid",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Zamora",
      },
      {
        code: "ES-CT",
        alpha2code: "CT",
        name: "Cataluña",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Barcelona",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gerona",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lérida",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Tarragona",
      },
      {
        code: "ES-EX",
        alpha2code: "EX",
        name: "Extremadura",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Badajoz",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Cáceres",
      },
      {
        code: "ES-GA",
        alpha2code: "GA",
        name: "Galicia",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "La Coruña",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Lugo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Orense",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Pontevedra",
      },
      {
        code: "ES-PM",
        alpha2code: "PM",
        name: "Islas Baleares",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Baleares",
      },
      {
        code: "ES-LO",
        alpha2code: "LO",
        name: "La Rioja",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "La Rioja",
      },
      {
        code: "ES-M",
        alpha2code: "M",
        name: "Madrid, Comunidad de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Madrid",
      },
      {
        code: "ES-MU",
        alpha2code: "MU",
        name: "Murcia, Región de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Murcia",
      },
      {
        code: "ES-NA",
        alpha2code: "NA",
        name: "Navarra, Comunidad Foral de",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Navarra",
      },
      {
        code: "ES-PV",
        alpha2code: "PV",
        name: "País Vasco",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Álava",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Guipúzcoa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vizcaya",
      },
      {
        code: "ES-VC",
        alpha2code: "VC",
        name: "Valenciana, Comunidad",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Alicante",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Castellón",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Valencia",
      },
    ],
  },
  LK: {
    name: "Sri Lanka",
    subdivisions: [
      {
        code: "LK-1",
        alpha2code: "1",
        name: "Basnahira Palata (Western Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Colombo",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Gampaha",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kalutara",
      },
      {
        code: "LK-3",
        alpha2code: "3",
        name: "Dakunu Palata (Southern Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Galle",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Hambantota",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Matara",
      },
      {
        code: "LK-2",
        alpha2code: "2",
        name: "Madhyama Palata (Central Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kandy",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Matale",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Nuwara Eliya",
      },
      {
        code: "LK-5",
        alpha2code: "5",
        name: "Negenahira Palata (Eastern Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Arnpara",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Batticaloa",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Trincomalee",
      },
      {
        code: "LK-9",
        alpha2code: "9",
        name: "Sabaragamuwa Palata",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kegalla",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Ratnapura",
      },
      {
        code: "LK-7",
        alpha2code: "7",
        name: "Uturumeda Palata (North Central Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Anuradhapura",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Polonnaruwa",
      },
      {
        code: "LK-4",
        alpha2code: "4",
        name: "Uturu Palata (Northern Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Jaffna",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kilinochchi",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mannar",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Mullaittivu",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Vavuniya",
      },
      {
        code: "LK-8",
        alpha2code: "8",
        name: "Uva Palata",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Badulla",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Monaragala",
      },
      {
        code: "LK-6",
        alpha2code: "6",
        name: "Wayamba Palata (North Western Province)",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Kurunegala",
      },
      {
        code: "",
        alpha2code: undefined,
        name: "Puttalam",
      },
    ],
  },
  SD: {
    name: "Sudan",
    subdivisions: [
      {
        code: "SD-23",
        alpha2code: "23",
        name: "A‘ālī an Nīl",
      },
      {
        code: "SD-26",
        alpha2code: "26",
        name: "Al Baḩr al Aḩmar",
      },
      {
        code: "SD-18",
        alpha2code: "18",
        name: "Al Buḩayrāt",
      },
      {
        code: "SD-07",
        alpha2code: "07",
        name: "Al Jazīrah",
      },
      {
        code: "SD-03",
        alpha2code: "03",
        name: "Al Kharţūm",
      },
      {
        code: "SD-06",
        alpha2code: "06",
        name: "Al Qaḑārif",
      },
      {
        code: "SD-22",
        alpha2code: "22",
        name: "Al Waḩdah",
      },
      {
        code: "SD-04",
        alpha2code: "04",
        name: "An Nīl",
      },
      {
        code: "SD-08",
        alpha2code: "08",
        name: "An Nīl al Abyaḑ",
      },
      {
        code: "SD-24",
        alpha2code: "24",
        name: "An Nīl al Azraq",
      },
      {
        code: "SD-01",
        alpha2code: "01",
        name: "Ash Shamālīyah",
      },
      {
        code: "SD-17",
        alpha2code: "17",
        name: "Baḩr al Jabal",
      },
      {
        code: "SD-16",
        alpha2code: "16",
        name: "Gharb al Istiwā'īyah",
      },
      {
        code: "SD-14",
        alpha2code: "14",
        name: "Gharb Baḩr al Ghazāl",
      },
      {
        code: "SD-12",
        alpha2code: "12",
        name: "Gharb Dārfūr",
      },
      {
        code: "SD-10",
        alpha2code: "10",
        name: "Gharb Kurdufān",
      },
      {
        code: "SD-11",
        alpha2code: "11",
        name: "Janūb Dārfūr",
      },
      {
        code: "SD-13",
        alpha2code: "13",
        name: "Janūb Kurdufān",
      },
      {
        code: "SD-20",
        alpha2code: "20",
        name: "Jūnqalī",
      },
      {
        code: "SD-05",
        alpha2code: "05",
        name: "Kassalā",
      },
      {
        code: "SD-15",
        alpha2code: "15",
        name: "Shamāl Baḩr al Ghazāl",
      },
      {
        code: "SD-02",
        alpha2code: "02",
        name: "Shamāl Dārfūr",
      },
      {
        code: "SD-09",
        alpha2code: "09",
        name: "Shamāl Kurdufān",
      },
      {
        code: "SD-19",
        alpha2code: "19",
        name: "Sharq al Istiwā'iyah",
      },
      {
        code: "SD-25",
        alpha2code: "25",
        name: "Sinnār",
      },
      {
        code: "SD-21",
        alpha2code: "21",
        name: "Wārāb",
      },
    ],
  },
  SR: {
    name: "Suriname",
    subdivisions: [
      {
        code: "SR-BR",
        alpha2code: "BR",
        name: "Brokopondo",
      },
      {
        code: "SR-CM",
        alpha2code: "CM",
        name: "Commewijne",
      },
      {
        code: "SR-CR",
        alpha2code: "CR",
        name: "Coronie",
      },
      {
        code: "SR-MA",
        alpha2code: "MA",
        name: "Marowijne",
      },
      {
        code: "SR-NI",
        alpha2code: "NI",
        name: "Nickerie",
      },
      {
        code: "SR-PR",
        alpha2code: "PR",
        name: "Para",
      },
      {
        code: "SR-PM",
        alpha2code: "PM",
        name: "Paramaribo",
      },
      {
        code: "SR-SA",
        alpha2code: "SA",
        name: "Saramacca",
      },
      {
        code: "SR-SI",
        alpha2code: "SI",
        name: "Sipaliwini",
      },
      {
        code: "SR-WA",
        alpha2code: "WA",
        name: "Wanica",
      },
    ],
  },
  SJ: {
    name: "Svalbard & Jan Mayen Islands",
    subdivisions: [
      {
        code: "SJ-SJ",
        alpha2code: "SJ",
        name: "Svalbard & Jan Mayen Islands",
      },
    ],
  },
  SZ: {
    name: "Swaziland",
    subdivisions: [
      {
        code: "SZ-HH",
        alpha2code: "HH",
        name: "Hhohho",
      },
      {
        code: "SZ-LU",
        alpha2code: "LU",
        name: "Lubombo",
      },
      {
        code: "SZ-MA",
        alpha2code: "MA",
        name: "Manzini",
      },
      {
        code: "SZ-SH",
        alpha2code: "SH",
        name: "Shiselweni",
      },
    ],
  },
  SE: {
    name: "Sweden",
    subdivisions: [
      {
        code: "SE-K",
        alpha2code: "K",
        name: "Blekinge län",
      },
      {
        code: "SE-W",
        alpha2code: "W",
        name: "Dalarnas län",
      },
      {
        code: "SE-I",
        alpha2code: "I",
        name: "Gotlands län",
      },
      {
        code: "SE-X",
        alpha2code: "X",
        name: "Gävleborgs län",
      },
      {
        code: "SE-N",
        alpha2code: "N",
        name: "Hallands län",
      },
      {
        code: "SE-Z",
        alpha2code: "Z",
        name: "Jämtlands län",
      },
      {
        code: "SE-F",
        alpha2code: "F",
        name: "Jönköpings län",
      },
      {
        code: "SE-H",
        alpha2code: "H",
        name: "Kalmar län",
      },
      {
        code: "SE-G",
        alpha2code: "G",
        name: "Kronobergs län",
      },
      {
        code: "SE-BD",
        alpha2code: "BD",
        name: "Norrbottens län",
      },
      {
        code: "SE-M",
        alpha2code: "M",
        name: "Skåne län",
      },
      {
        code: "SE-AB",
        alpha2code: "AB",
        name: "Stockholms län",
      },
      {
        code: "SE-D",
        alpha2code: "D",
        name: "Södermanlands län",
      },
      {
        code: "SE-C",
        alpha2code: "C",
        name: "Uppsala län",
      },
      {
        code: "SE-S",
        alpha2code: "S",
        name: "Värmlands län",
      },
      {
        code: "SE-AC",
        alpha2code: "AC",
        name: "Västerbottens län",
      },
      {
        code: "SE-Y",
        alpha2code: "Y",
        name: "Västernorrlands län",
      },
      {
        code: "SE-U",
        alpha2code: "U",
        name: "Västmanlands län",
      },
      {
        code: "SE-O",
        alpha2code: "O",
        name: "Västra Götalands län",
      },
      {
        code: "SE-T",
        alpha2code: "T",
        name: "Örebro län",
      },
      {
        code: "SE-E",
        alpha2code: "E",
        name: "Östergötlands län",
      },
    ],
  },
  CH: {
    name: "Switzerland",
    subdivisions: [
      {
        code: "CH-AG",
        alpha2code: "AG",
        name: "Aargau",
      },
      {
        code: "CH-AR",
        alpha2code: "AR",
        name: "Appenzell Ausser-Rhoden",
      },
      {
        code: "CH-AI",
        alpha2code: "AI",
        name: "Appenzell Inner-Rhoden",
      },
      {
        code: "CH-BL",
        alpha2code: "BL",
        name: "Basel-Landschaft",
      },
      {
        code: "CH-BS",
        alpha2code: "BS",
        name: "Basel-Stadt",
      },
      {
        code: "CH-BE",
        alpha2code: "BE",
        name: "Bern",
      },
      {
        code: "CH-FR",
        alpha2code: "FR",
        name: "Freiburg",
      },
      {
        code: "CH-GE",
        alpha2code: "GE",
        name: "Geneve",
      },
      {
        code: "CH-GL",
        alpha2code: "GL",
        name: "Glarus",
      },
      {
        code: "CH-GR",
        alpha2code: "GR",
        name: "Graubünden",
      },
      {
        code: "CH-JU",
        alpha2code: "JU",
        name: "Jura",
      },
      {
        code: "CH-LU",
        alpha2code: "LU",
        name: "Luzern",
      },
      {
        code: "CH-NE",
        alpha2code: "NE",
        name: "Neuchatel",
      },
      {
        code: "CH-NW",
        alpha2code: "NW",
        name: "Nidwalden",
      },
      {
        code: "CH-OW",
        alpha2code: "OW",
        name: "Obwalden",
      },
      {
        code: "CH-SG",
        alpha2code: "SG",
        name: "Sankt Gallen",
      },
      {
        code: "CH-SH",
        alpha2code: "SH",
        name: "Schaffhausen",
      },
      {
        code: "CH-SZ",
        alpha2code: "SZ",
        name: "Schwyz",
      },
      {
        code: "CH-SO",
        alpha2code: "SO",
        name: "Solothurn",
      },
      {
        code: "CH-TG",
        alpha2code: "TG",
        name: "Thurgau",
      },
      {
        code: "CH-TI",
        alpha2code: "TI",
        name: "Ticino",
      },
      {
        code: "CH-UR",
        alpha2code: "UR",
        name: "Uri",
      },
      {
        code: "CH-VS",
        alpha2code: "VS",
        name: "Wallis",
      },
      {
        code: "CH-VD",
        alpha2code: "VD",
        name: "Vaud",
      },
      {
        code: "CH-ZG",
        alpha2code: "ZG",
        name: "Zug",
      },
      {
        code: "CH-ZH",
        alpha2code: "ZH",
        name: "Zürich",
      },
    ],
  },
  SY: {
    name: "Syria",
    subdivisions: [
      {
        code: "SY-HA",
        alpha2code: "HA",
        name: "Al Ḩasakah",
      },
      {
        code: "SY-LA",
        alpha2code: "LA",
        name: "Al Lādhiqīyah",
      },
      {
        code: "SY-QU",
        alpha2code: "QU",
        name: "Al Qunayţirah",
      },
      {
        code: "SY-RA",
        alpha2code: "RA",
        name: "Ar Raqqah",
      },
      {
        code: "SY-SU",
        alpha2code: "SU",
        name: "As Suwaydā'",
      },
      {
        code: "SY-DR",
        alpha2code: "DR",
        name: "Dar’ā",
      },
      {
        code: "SY-DY",
        alpha2code: "DY",
        name: "Dayr az Zawr",
      },
      {
        code: "SY-DI",
        alpha2code: "DI",
        name: "Dimashq",
      },
      {
        code: "SY-HL",
        alpha2code: "HL",
        name: "Ḩalab",
      },
      {
        code: "SY-HM",
        alpha2code: "HM",
        name: "Ḩamāh",
      },
      {
        code: "SY-HI",
        alpha2code: "HI",
        name: "Ḩimş",
      },
      {
        code: "SY-ID",
        alpha2code: "ID",
        name: "Idlib",
      },
      {
        code: "SY-RD",
        alpha2code: "RD",
        name: "Rīf Dimashq",
      },
      {
        code: "SY-TA",
        alpha2code: "TA",
        name: "Ţarţūs",
      },
    ],
  },
  TW: {
    name: "Taiwan",
    subdivisions: [
      {
        code: "TW-KHH",
        alpha2code: "KHH",
        name: "Kaohsiung",
      },
      {
        code: "TW-TPE",
        alpha2code: "TPE",
        name: "Taipei",
      },
      {
        code: "TW-CYI",
        alpha2code: "CYI",
        name: "Chiayi",
      },
      {
        code: "TW-HSZ",
        alpha2code: "HSZ",
        name: "Hsinchu",
      },
      {
        code: "TW-KEE",
        alpha2code: "KEE",
        name: "Keelung",
      },
      {
        code: "TW-TXG",
        alpha2code: "TXG",
        name: "Taichung",
      },
      {
        code: "TW-TNN",
        alpha2code: "TNN",
        name: "Tainan",
      },
      {
        code: "TW-CHA",
        alpha2code: "CHA",
        name: "Changhua",
      },
      {
        code: "TW-HUA",
        alpha2code: "HUA",
        name: "Hualien",
      },
      {
        code: "TW-ILA",
        alpha2code: "ILA",
        name: "Ilan",
      },
      {
        code: "TW-MIA",
        alpha2code: "MIA",
        name: "Miaoli",
      },
      {
        code: "TW-NAN",
        alpha2code: "NAN",
        name: "Nantou",
      },
      {
        code: "TW-PEN",
        alpha2code: "PEN",
        name: "Penghu",
      },
      {
        code: "TW-PIF",
        alpha2code: "PIF",
        name: "Pingtung",
      },
      {
        code: "TW-TTT",
        alpha2code: "TTT",
        name: "Taitung",
      },
      {
        code: "TW-TAO",
        alpha2code: "TAO",
        name: "Taoyuan",
      },
      {
        code: "TW-YUN",
        alpha2code: "YUN",
        name: "Yunlin",
      },
    ],
  },
  TJ: {
    name: "Tajikistan",
    subdivisions: [
      {
        code: "TJ-KR",
        alpha2code: "KR",
        name: "Karategin",
      },
      {
        code: "TJ-KT",
        alpha2code: "KT",
        name: "Khatlon",
      },
      {
        code: "TJ-LN",
        alpha2code: "LN",
        name: "Leninabad",
      },
      {
        code: "TJ-GB",
        alpha2code: "GB",
        name: "Gorno-Badakhshan",
      },
    ],
  },
  TZ: {
    name: "Tanzania",
    subdivisions: [
      {
        code: "TZ-01",
        alpha2code: "01",
        name: "Arusha",
      },
      {
        code: "TZ-02",
        alpha2code: "02",
        name: "Dar-es-Salaam",
      },
      {
        code: "TZ-03",
        alpha2code: "03",
        name: "Dodoma",
      },
      {
        code: "TZ-04",
        alpha2code: "04",
        name: "Iringa",
      },
      {
        code: "TZ-05",
        alpha2code: "05",
        name: "Kagera",
      },
      {
        code: "TZ-06",
        alpha2code: "06",
        name: "Kaskazini Pemba (Pemba North)",
      },
      {
        code: "TZ-07",
        alpha2code: "07",
        name: "Kaskazini Unguja (Zanzibar North)",
      },
      {
        code: "TZ-08",
        alpha2code: "08",
        name: "Kigoma",
      },
      {
        code: "TZ-09",
        alpha2code: "09",
        name: "Kilimanjaro",
      },
      {
        code: "TZ-10",
        alpha2code: "10",
        name: "Kusini Pemba (Pemba South)",
      },
      {
        code: "TZ-11",
        alpha2code: "11",
        name: "Kusini Unguja (Zanzibar South)",
      },
      {
        code: "TZ-12",
        alpha2code: "12",
        name: "Lindi",
      },
      {
        code: "TZ-13",
        alpha2code: "13",
        name: "Mara",
      },
      {
        code: "TZ-14",
        alpha2code: "14",
        name: "Mbeya",
      },
      {
        code: "TZ-15",
        alpha2code: "15",
        name: "Mjini Magharibi (Zanzibar West)",
      },
      {
        code: "TZ-16",
        alpha2code: "16",
        name: "Morogoro",
      },
      {
        code: "TZ-17",
        alpha2code: "17",
        name: "Mtwara",
      },
      {
        code: "TZ-18",
        alpha2code: "18",
        name: "Mwanza",
      },
      {
        code: "TZ-19",
        alpha2code: "19",
        name: "Pwani (Coast)",
      },
      {
        code: "TZ-20",
        alpha2code: "20",
        name: "Rukwa",
      },
      {
        code: "TZ-21",
        alpha2code: "21",
        name: "Ruvuma",
      },
      {
        code: "TZ-22",
        alpha2code: "22",
        name: "Shinyanga",
      },
      {
        code: "TZ-23",
        alpha2code: "23",
        name: "Singida",
      },
      {
        code: "TZ-24",
        alpha2code: "24",
        name: "Tabora",
      },
      {
        code: "TZ-25",
        alpha2code: "25",
        name: "Tanga",
      },
    ],
  },
  TH: {
    name: "Thailand",
    subdivisions: [
      {
        code: "TH-10",
        alpha2code: "10",
        name: "Krung Thep Maha Nakhon [Bangkok]",
      },
      {
        code: "TH-S",
        alpha2code: "S",
        name: "Phatthaya",
      },
      {
        code: "TH-37",
        alpha2code: "37",
        name: "Amnat Charoen",
      },
      {
        code: "TH-15",
        alpha2code: "15",
        name: "Ang Thong",
      },
      {
        code: "TH-31",
        alpha2code: "31",
        name: "Buri Ram",
      },
      {
        code: "TH-24",
        alpha2code: "24",
        name: "Chachoengsao",
      },
      {
        code: "TH-18",
        alpha2code: "18",
        name: "Chai Nat",
      },
      {
        code: "TH-36",
        alpha2code: "36",
        name: "Chaiyaphum",
      },
      {
        code: "TH-22",
        alpha2code: "22",
        name: "Chanthaburi",
      },
      {
        code: "TH-50",
        alpha2code: "50",
        name: "Chiang Mai",
      },
      {
        code: "TH-57",
        alpha2code: "57",
        name: "Chiang Rai",
      },
      {
        code: "TH-20",
        alpha2code: "20",
        name: "Chon Buri",
      },
      {
        code: "TH-86",
        alpha2code: "86",
        name: "Chumphon",
      },
      {
        code: "TH-46",
        alpha2code: "46",
        name: "Kalasin",
      },
      {
        code: "TH-62",
        alpha2code: "62",
        name: "Kamphaeng Phet",
      },
      {
        code: "TH-71",
        alpha2code: "71",
        name: "Kanchanaburi",
      },
      {
        code: "TH-40",
        alpha2code: "40",
        name: "Khon Kaen",
      },
      {
        code: "TH-81",
        alpha2code: "81",
        name: "Krabi",
      },
      {
        code: "TH-52",
        alpha2code: "52",
        name: "Lampang",
      },
      {
        code: "TH-51",
        alpha2code: "51",
        name: "Lamphun",
      },
      {
        code: "TH-42",
        alpha2code: "42",
        name: "Loei",
      },
      {
        code: "TH-16",
        alpha2code: "16",
        name: "Lop Buri",
      },
      {
        code: "TH-58",
        alpha2code: "58",
        name: "Mae Hong Son",
      },
      {
        code: "TH-44",
        alpha2code: "44",
        name: "Maha Sarakham",
      },
      {
        code: "TH-49",
        alpha2code: "49",
        name: "Mukdahan",
      },
      {
        code: "TH-26",
        alpha2code: "26",
        name: "Nakhon Nayok",
      },
      {
        code: "TH-73",
        alpha2code: "73",
        name: "Nakhon Pathom",
      },
      {
        code: "TH-48",
        alpha2code: "48",
        name: "Nakhon Phanom",
      },
      {
        code: "TH-30",
        alpha2code: "30",
        name: "Nakhon Ratchasima",
      },
      {
        code: "TH-60",
        alpha2code: "60",
        name: "Nakhon Sawan",
      },
      {
        code: "TH-80",
        alpha2code: "80",
        name: "Nakhon Si Thammarat",
      },
      {
        code: "TH-55",
        alpha2code: "55",
        name: "Nan",
      },
      {
        code: "TH-96",
        alpha2code: "96",
        name: "Narathiwat",
      },
      {
        code: "TH-39",
        alpha2code: "39",
        name: "Nong Bua Lam Phu",
      },
      {
        code: "TH-43",
        alpha2code: "43",
        name: "Nong Khai",
      },
      {
        code: "TH-12",
        alpha2code: "12",
        name: "Nonthaburi",
      },
      {
        code: "TH-13",
        alpha2code: "13",
        name: "Pathum Thani",
      },
      {
        code: "TH-94",
        alpha2code: "94",
        name: "Pattani",
      },
      {
        code: "TH-82",
        alpha2code: "82",
        name: "Phangnga",
      },
      {
        code: "TH-93",
        alpha2code: "93",
        name: "Phatthalung",
      },
      {
        code: "TH-56",
        alpha2code: "56",
        name: "Phayao",
      },
      {
        code: "TH-67",
        alpha2code: "67",
        name: "Phetchabun",
      },
      {
        code: "TH-76",
        alpha2code: "76",
        name: "Phetchaburi",
      },
      {
        code: "TH-66",
        alpha2code: "66",
        name: "Phichit",
      },
      {
        code: "TH-65",
        alpha2code: "65",
        name: "Phitsanulok",
      },
      {
        code: "TH-54",
        alpha2code: "54",
        name: "Phrae",
      },
      {
        code: "TH-14",
        alpha2code: "14",
        name: "Phra Nakhon Si Ayutthaya",
      },
      {
        code: "TH-83",
        alpha2code: "83",
        name: "Phuket",
      },
      {
        code: "TH-25",
        alpha2code: "25",
        name: "Prachin Buri",
      },
      {
        code: "TH-77",
        alpha2code: "77",
        name: "Prachuap Khiri Khan",
      },
      {
        code: "TH-85",
        alpha2code: "85",
        name: "Ranong",
      },
      {
        code: "TH-70",
        alpha2code: "70",
        name: "Ratchaburi",
      },
      {
        code: "TH-21",
        alpha2code: "21",
        name: "Rayong",
      },
      {
        code: "TH-45",
        alpha2code: "45",
        name: "Roi Et",
      },
      {
        code: "TH-27",
        alpha2code: "27",
        name: "Sa Kaeo",
      },
      {
        code: "TH-47",
        alpha2code: "47",
        name: "Sakon Nakhon",
      },
      {
        code: "TH-11",
        alpha2code: "11",
        name: "Samut Prakan",
      },
      {
        code: "TH-74",
        alpha2code: "74",
        name: "Samut Sakhon",
      },
      {
        code: "TH-75",
        alpha2code: "75",
        name: "Samut Songkhram",
      },
      {
        code: "TH-19",
        alpha2code: "19",
        name: "Saraburi",
      },
      {
        code: "TH-91",
        alpha2code: "91",
        name: "Satun",
      },
      {
        code: "TH-17",
        alpha2code: "17",
        name: "Sing Buri",
      },
      {
        code: "TH-33",
        alpha2code: "33",
        name: "Si Sa Ket",
      },
      {
        code: "TH-90",
        alpha2code: "90",
        name: "Songkhla",
      },
      {
        code: "TH-64",
        alpha2code: "64",
        name: "Sukhothai",
      },
      {
        code: "TH-72",
        alpha2code: "72",
        name: "Suphan Buri",
      },
      {
        code: "TH-84",
        alpha2code: "84",
        name: "Surat Thani",
      },
      {
        code: "TH-32",
        alpha2code: "32",
        name: "Surin",
      },
      {
        code: "TH-63",
        alpha2code: "63",
        name: "Tak",
      },
      {
        code: "TH-92",
        alpha2code: "92",
        name: "Trang",
      },
      {
        code: "TH-23",
        alpha2code: "23",
        name: "Trat",
      },
      {
        code: "TH-34",
        alpha2code: "34",
        name: "Ubon Ratchathani",
      },
      {
        code: "TH-41",
        alpha2code: "41",
        name: "Udon Thani",
      },
      {
        code: "TH-61",
        alpha2code: "61",
        name: "Uthai Thani",
      },
      {
        code: "TH-53",
        alpha2code: "53",
        name: "Uttaradit",
      },
      {
        code: "TH-95",
        alpha2code: "95",
        name: "Yala",
      },
      {
        code: "TH-35",
        alpha2code: "35",
        name: "Yasothon",
      },
    ],
  },
  TG: {
    name: "Togo",
    subdivisions: [
      {
        code: "TG-C",
        alpha2code: "C",
        name: "Centre",
      },
      {
        code: "TG-K",
        alpha2code: "K",
        name: "Kara",
      },
      {
        code: "TG-M",
        alpha2code: "M",
        name: "Maritime (Région)",
      },
      {
        code: "TG-P",
        alpha2code: "P",
        name: "Plateaux",
      },
      {
        code: "TG-S",
        alpha2code: "S",
        name: "Savannes",
      },
    ],
  },
  TK: {
    name: "Tokelau",
    subdivisions: [
      {
        code: "TK-TK",
        alpha2code: "TK",
        name: "Tokelau",
      },
    ],
  },
  TO: {
    name: "Tonga",
    subdivisions: [
      {
        code: "TO-TO",
        alpha2code: "TO",
        name: "Tonga",
      },
    ],
  },
  TT: {
    name: "Trinidad & Tobago",
    subdivisions: [
      {
        code: "TT-CTT",
        alpha2code: "CTT",
        name: "Couva-Tabaquite-Talparo",
      },
      {
        code: "TT-DMN",
        alpha2code: "DMN",
        name: "Diego Martin",
      },
      {
        code: "TT-ETO",
        alpha2code: "ETO",
        name: "Eastern Tobago",
      },
      {
        code: "TT-PED",
        alpha2code: "PED",
        name: "Penal-Debe",
      },
      {
        code: "TT-PRT",
        alpha2code: "PRT",
        name: "Princes Town",
      },
      {
        code: "TT-RCM",
        alpha2code: "RCM",
        name: "Rio Claro-Mayaro",
      },
      {
        code: "TT-SGE",
        alpha2code: "SGE",
        name: "Sangre Grande",
      },
      {
        code: "TT-SJL",
        alpha2code: "SJL",
        name: "San Juan-Laventille",
      },
      {
        code: "TT-SIP",
        alpha2code: "SIP",
        name: "Siparia",
      },
      {
        code: "TT-TUP",
        alpha2code: "TUP",
        name: "Tunapuna-Piarco",
      },
      {
        code: "TT-WTO",
        alpha2code: "WTO",
        name: "Western Tobago",
      },
      {
        code: "TT-ARI",
        alpha2code: "ARI",
        name: "Arima",
      },
      {
        code: "TT-CHA",
        alpha2code: "CHA",
        name: "Chaguanas",
      },
      {
        code: "TT-PTF",
        alpha2code: "PTF",
        name: "Point Fortin",
      },
      {
        code: "TT-POS",
        alpha2code: "POS",
        name: "Port of Spain",
      },
      {
        code: "TT-SFO",
        alpha2code: "SFO",
        name: "San Fernando",
      },
    ],
  },
  TN: {
    name: "Tunisia",
    subdivisions: [
      {
        code: "TN-31",
        alpha2code: "31",
        name: "Béja",
      },
      {
        code: "TN-13",
        alpha2code: "13",
        name: "Ben Arous",
      },
      {
        code: "TN-23",
        alpha2code: "23",
        name: "Bizerte",
      },
      {
        code: "TN-81",
        alpha2code: "81",
        name: "Gabès",
      },
      {
        code: "TN-71",
        alpha2code: "71",
        name: "Gafsa",
      },
      {
        code: "TN-32",
        alpha2code: "32",
        name: "Jendouba",
      },
      {
        code: "TN-41",
        alpha2code: "41",
        name: "Kairouan",
      },
      {
        code: "TN-42",
        alpha2code: "42",
        name: "Kasserine",
      },
      {
        code: "TN-73",
        alpha2code: "73",
        name: "Kebili",
      },
      {
        code: "TN-12",
        alpha2code: "12",
        name: "L'Ariana",
      },
      {
        code: "TN-33",
        alpha2code: "33",
        name: "Le Kef",
      },
      {
        code: "TN-53",
        alpha2code: "53",
        name: "Mahdia",
      },
      {
        code: "TN-82",
        alpha2code: "82",
        name: "Medenine",
      },
      {
        code: "TN-52",
        alpha2code: "52",
        name: "Monastir",
      },
      {
        code: "TN-21",
        alpha2code: "21",
        name: "Nabeul",
      },
      {
        code: "TN-61",
        alpha2code: "61",
        name: "Sfax",
      },
      {
        code: "TN-43",
        alpha2code: "43",
        name: "Sidi Bouzid",
      },
      {
        code: "TN-34",
        alpha2code: "34",
        name: "Siliana",
      },
      {
        code: "TN-51",
        alpha2code: "51",
        name: "Sousse",
      },
      {
        code: "TN-83",
        alpha2code: "83",
        name: "Tataouine",
      },
      {
        code: "TN-72",
        alpha2code: "72",
        name: "Tozeur",
      },
      {
        code: "TN-11",
        alpha2code: "11",
        name: "Tunis",
      },
      {
        code: "TN-22",
        alpha2code: "22",
        name: "Zaghouan",
      },
    ],
  },
  TR: {
    name: "Turkey",
    subdivisions: [
      {
        code: "TR-01",
        alpha2code: "01",
        name: "Adana",
      },
      {
        code: "TR-02",
        alpha2code: "02",
        name: "Adiyaman",
      },
      {
        code: "TR-03",
        alpha2code: "03",
        name: "Afyon",
      },
      {
        code: "TR-04",
        alpha2code: "04",
        name: "Ağrı",
      },
      {
        code: "TR-68",
        alpha2code: "68",
        name: "Aksaray",
      },
      {
        code: "TR-05",
        alpha2code: "05",
        name: "Amasya",
      },
      {
        code: "TR-06",
        alpha2code: "06",
        name: "Ankara",
      },
      {
        code: "TR-07",
        alpha2code: "07",
        name: "Antalya",
      },
      {
        code: "TR-75",
        alpha2code: "75",
        name: "Ardahan",
      },
      {
        code: "TR-08",
        alpha2code: "08",
        name: "Artvin",
      },
      {
        code: "TR-09",
        alpha2code: "09",
        name: "Aydin",
      },
      {
        code: "TR-10",
        alpha2code: "10",
        name: "Balıkesir",
      },
      {
        code: "TR-74",
        alpha2code: "74",
        name: "Bartın",
      },
      {
        code: "TR-72",
        alpha2code: "72",
        name: "Batman",
      },
      {
        code: "TR-69",
        alpha2code: "69",
        name: "Bayburt",
      },
      {
        code: "TR-11",
        alpha2code: "11",
        name: "Bilecik",
      },
      {
        code: "TR-12",
        alpha2code: "12",
        name: "Bingöl",
      },
      {
        code: "TR-13",
        alpha2code: "13",
        name: "Bitlis",
      },
      {
        code: "TR-14",
        alpha2code: "14",
        name: "Bolu",
      },
      {
        code: "TR-15",
        alpha2code: "15",
        name: "Burdur",
      },
      {
        code: "TR-16",
        alpha2code: "16",
        name: "Bursa",
      },
      {
        code: "TR-17",
        alpha2code: "17",
        name: "Çanakkale",
      },
      {
        code: "TR-18",
        alpha2code: "18",
        name: "Çankırı",
      },
      {
        code: "TR-19",
        alpha2code: "19",
        name: "Çorum",
      },
      {
        code: "TR-20",
        alpha2code: "20",
        name: "Denizli",
      },
      {
        code: "TR-21",
        alpha2code: "21",
        name: "Diyarbakır",
      },
      {
        code: "TR-22",
        alpha2code: "22",
        name: "Edirne",
      },
      {
        code: "TR-23",
        alpha2code: "23",
        name: "Elaziğ",
      },
      {
        code: "TR-24",
        alpha2code: "24",
        name: "Erzincan",
      },
      {
        code: "TR-25",
        alpha2code: "25",
        name: "Erzurum",
      },
      {
        code: "TR-26",
        alpha2code: "26",
        name: "Eskişehir",
      },
      {
        code: "TR-27",
        alpha2code: "27",
        name: "Gaziantep",
      },
      {
        code: "TR-28",
        alpha2code: "28",
        name: "Giresun",
      },
      {
        code: "TR-29",
        alpha2code: "29",
        name: "Gümüşhane",
      },
      {
        code: "TR-30",
        alpha2code: "30",
        name: "Hakkari",
      },
      {
        code: "TR-31",
        alpha2code: "31",
        name: "Hatay",
      },
      {
        code: "TR-76",
        alpha2code: "76",
        name: "Iğdir",
      },
      {
        code: "TR-32",
        alpha2code: "32",
        name: "Isparta",
      },
      {
        code: "TR-33",
        alpha2code: "33",
        name: "İçel",
      },
      {
        code: "TR-34",
        alpha2code: "34",
        name: "İstanbul",
      },
      {
        code: "TR-35",
        alpha2code: "35",
        name: "İzmir",
      },
      {
        code: "TR-46",
        alpha2code: "46",
        name: "Kahramanmaraş",
      },
      {
        code: "TR-78",
        alpha2code: "78",
        name: "Karabük",
      },
      {
        code: "TR-70",
        alpha2code: "70",
        name: "Karaman",
      },
      {
        code: "TR-36",
        alpha2code: "36",
        name: "Kars",
      },
      {
        code: "TR-37",
        alpha2code: "37",
        name: "Kastamonu",
      },
      {
        code: "TR-38",
        alpha2code: "38",
        name: "Kayseri",
      },
      {
        code: "TR-71",
        alpha2code: "71",
        name: "Kırıkkale",
      },
      {
        code: "TR-39",
        alpha2code: "39",
        name: "Kırklareli",
      },
      {
        code: "TR-40",
        alpha2code: "40",
        name: "Kırşehir",
      },
      {
        code: "TR-79",
        alpha2code: "79",
        name: "Kilis",
      },
      {
        code: "TR-41",
        alpha2code: "41",
        name: "Kocaeli",
      },
      {
        code: "TR-42",
        alpha2code: "42",
        name: "Konya",
      },
      {
        code: "TR-43",
        alpha2code: "43",
        name: "Kütahya",
      },
      {
        code: "TR-44",
        alpha2code: "44",
        name: "Malatya",
      },
      {
        code: "TR-4S",
        alpha2code: "4S",
        name: "Manisa",
      },
      {
        code: "TR-47",
        alpha2code: "47",
        name: "Mardin",
      },
      {
        code: "TR-48",
        alpha2code: "48",
        name: "Muğla",
      },
      {
        code: "TR-49",
        alpha2code: "49",
        name: "Muş",
      },
      {
        code: "TR-SO",
        alpha2code: "SO",
        name: "Nevşehir",
      },
      {
        code: "TR-51",
        alpha2code: "51",
        name: "Niğde",
      },
      {
        code: "TR-52",
        alpha2code: "52",
        name: "Ordu",
      },
      {
        code: "TR-53",
        alpha2code: "53",
        name: "Rize",
      },
      {
        code: "TR-54",
        alpha2code: "54",
        name: "Sakarya",
      },
      {
        code: "TR-SS",
        alpha2code: "SS",
        name: "Samsun",
      },
      {
        code: "TR-56",
        alpha2code: "56",
        name: "Siirt",
      },
      {
        code: "TR-57",
        alpha2code: "57",
        name: "Sinop",
      },
      {
        code: "TR-S8",
        alpha2code: "S8",
        name: "Sivas",
      },
      {
        code: "TR-63",
        alpha2code: "63",
        name: "Şanlıurfa",
      },
      {
        code: "TR-73",
        alpha2code: "73",
        name: "Şirnak",
      },
      {
        code: "TR-59",
        alpha2code: "59",
        name: "Tekirdağ",
      },
      {
        code: "TR-60",
        alpha2code: "60",
        name: "Tokat",
      },
      {
        code: "TR-61",
        alpha2code: "61",
        name: "Trabzon",
      },
      {
        code: "TR-62",
        alpha2code: "62",
        name: "Tunceli",
      },
      {
        code: "TR-64",
        alpha2code: "64",
        name: "Uşak",
      },
      {
        code: "TR-65",
        alpha2code: "65",
        name: "Van",
      },
      {
        code: "TR-77",
        alpha2code: "77",
        name: "Yalova",
      },
      {
        code: "TR-66",
        alpha2code: "66",
        name: "Yozgat",
      },
      {
        code: "TR-67",
        alpha2code: "67",
        name: "Zonguldak",
      },
    ],
  },
  TM: {
    name: "Turkmenistan",
    subdivisions: [
      {
        code: "TM-A",
        alpha2code: "A",
        name: "Ahal",
      },
      {
        code: "TM-B",
        alpha2code: "B",
        name: "Balkan",
      },
      {
        code: "TM-D",
        alpha2code: "D",
        name: "Daşhowuz",
      },
      {
        code: "TM-L",
        alpha2code: "L",
        name: "Lebap",
      },
      {
        code: "TM-M",
        alpha2code: "M",
        name: "Mary",
      },
    ],
  },
  TC: {
    name: "Turks & Caicos Islands",
    subdivisions: [
      {
        code: "TC-TC",
        alpha2code: "TC",
        name: "Turks & Caicos Islands",
      },
    ],
  },
  TV: {
    name: "Tuvalu",
    subdivisions: [
      {
        code: "TV-TV",
        alpha2code: "TV",
        name: "Tuvalu",
      },
    ],
  },
  UG: {
    name: "Uganda",
    subdivisions: [
      {
        code: "UG-APA",
        alpha2code: "APA",
        name: "Apac",
      },
      {
        code: "UG-ARU",
        alpha2code: "ARU",
        name: "Arua",
      },
      {
        code: "UG-BUN",
        alpha2code: "BUN",
        name: "Bundibugyo",
      },
      {
        code: "UG-BUS",
        alpha2code: "BUS",
        name: "Bushenyi",
      },
      {
        code: "UG-GUL",
        alpha2code: "GUL",
        name: "Gulu",
      },
      {
        code: "UG-HOI",
        alpha2code: "HOI",
        name: "Hoima",
      },
      {
        code: "UG-IGA",
        alpha2code: "IGA",
        name: "Iganga",
      },
      {
        code: "UG-JIN",
        alpha2code: "JIN",
        name: "Jinja",
      },
      {
        code: "UG-KBL",
        alpha2code: "KBL",
        name: "Kabale",
      },
      {
        code: "UG-KBR",
        alpha2code: "KBR",
        name: "Kabarole",
      },
      {
        code: "UG-KLG",
        alpha2code: "KLG",
        name: "Kalangala",
      },
      {
        code: "UG-KLA",
        alpha2code: "KLA",
        name: "Kampala",
      },
      {
        code: "UG-KLI",
        alpha2code: "KLI",
        name: "Kamuli",
      },
      {
        code: "UG-KAP",
        alpha2code: "KAP",
        name: "Kapchorwa",
      },
      {
        code: "UG-KAS",
        alpha2code: "KAS",
        name: "Kasese",
      },
      {
        code: "UG-KLE",
        alpha2code: "KLE",
        name: "Kibaale",
      },
      {
        code: "UG-KIB",
        alpha2code: "KIB",
        name: "Kiboga",
      },
      {
        code: "UG-KIS",
        alpha2code: "KIS",
        name: "Kisoro",
      },
      {
        code: "UG-KIT",
        alpha2code: "KIT",
        name: "Kitgum",
      },
      {
        code: "UG-KOT",
        alpha2code: "KOT",
        name: "Kotido",
      },
      {
        code: "UG-KUM",
        alpha2code: "KUM",
        name: "Kumi",
      },
      {
        code: "UG-LIR",
        alpha2code: "LIR",
        name: "Lira",
      },
      {
        code: "UG-LUW",
        alpha2code: "LUW",
        name: "Luwero",
      },
      {
        code: "UG-MSK",
        alpha2code: "MSK",
        name: "Masaka",
      },
      {
        code: "UG-MSI",
        alpha2code: "MSI",
        name: "Masindi",
      },
      {
        code: "UG-MBL",
        alpha2code: "MBL",
        name: "Mbale",
      },
      {
        code: "UG-MBR",
        alpha2code: "MBR",
        name: "Mbarara",
      },
      {
        code: "UG-MOR",
        alpha2code: "MOR",
        name: "Moroto",
      },
      {
        code: "UG-MOY",
        alpha2code: "MOY",
        name: "Moyo",
      },
      {
        code: "UG-MPI",
        alpha2code: "MPI",
        name: "Mpigi",
      },
      {
        code: "UG-MUB",
        alpha2code: "MUB",
        name: "Mubende",
      },
      {
        code: "UG-MUK",
        alpha2code: "MUK",
        name: "Mukono",
      },
      {
        code: "UG-NEB",
        alpha2code: "NEB",
        name: "Nebbi",
      },
      {
        code: "UG-NTU",
        alpha2code: "NTU",
        name: "Ntungamo",
      },
      {
        code: "UG-PAL",
        alpha2code: "PAL",
        name: "Pallisa",
      },
      {
        code: "UG-RAK",
        alpha2code: "RAK",
        name: "Rakai",
      },
      {
        code: "UG-RUK",
        alpha2code: "RUK",
        name: "Rukungiri",
      },
      {
        code: "UG-SOR",
        alpha2code: "SOR",
        name: "Soroti",
      },
      {
        code: "UG-TOR",
        alpha2code: "TOR",
        name: "Tororo",
      },
    ],
  },
  UA: {
    name: "Ukraine",
    subdivisions: [
      {
        code: "UA-71",
        alpha2code: "71",
        name: "Cherkas'ka Oblast'",
      },
      {
        code: "UA-74",
        alpha2code: "74",
        name: "Chernihivs'ka Oblast'",
      },
      {
        code: "UA-77",
        alpha2code: "77",
        name: "Chernivets'ka Oblast'",
      },
      {
        code: "UA-12",
        alpha2code: "12",
        name: "Dnipropetrovs'ka Oblast'",
      },
      {
        code: "UA-14",
        alpha2code: "14",
        name: "Donets'ka Oblast'",
      },
      {
        code: "UA-26",
        alpha2code: "26",
        name: "Ivano-Frankivs'ka Oblast'",
      },
      {
        code: "UA-63",
        alpha2code: "63",
        name: "Kharkivs'ka Oblast'",
      },
      {
        code: "UA-65",
        alpha2code: "65",
        name: "Khersons'ka Oblast'",
      },
      {
        code: "UA-68",
        alpha2code: "68",
        name: "Khmel'nyts'ka Oblast'",
      },
      {
        code: "UA-35",
        alpha2code: "35",
        name: "Kirovohrads'ka Oblast'",
      },
      {
        code: "UA-32",
        alpha2code: "32",
        name: "Kyïvs'ka Oblast'",
      },
      {
        code: "UA-09",
        alpha2code: "09",
        name: "Luhans'ka Oblast'",
      },
      {
        code: "UA-46",
        alpha2code: "46",
        name: "L'vivs'ka Oblast'",
      },
      {
        code: "UA-48",
        alpha2code: "48",
        name: "Mykolaïvs'ka Oblast'",
      },
      {
        code: "UA-51",
        alpha2code: "51",
        name: "Odes'ka Oblast'",
      },
      {
        code: "UA-53",
        alpha2code: "53",
        name: "Poltavs'ka Oblast'",
      },
      {
        code: "UA-56",
        alpha2code: "56",
        name: "Rivnens'ka Oblast'",
      },
      {
        code: "UA-59",
        alpha2code: "59",
        name: "Sums'ka Oblast'",
      },
      {
        code: "UA-61",
        alpha2code: "61",
        name: "Ternopil's'ka Oblast'",
      },
      {
        code: "UA-05",
        alpha2code: "05",
        name: "Vinnyts'ka Oblast'",
      },
      {
        code: "UA-07",
        alpha2code: "07",
        name: "Volyns'ka Oblast'",
      },
      {
        code: "UA-21",
        alpha2code: "21",
        name: "Zakarpats'ka Oblast'",
      },
      {
        code: "UA-23",
        alpha2code: "23",
        name: "Zaporiz'ka Oblast'",
      },
      {
        code: "UA-18",
        alpha2code: "18",
        name: "Zhytomyrs'ka Oblast'",
      },
      {
        code: "UA-43",
        alpha2code: "43",
        name: "Respublika Krym",
      },
      {
        code: "UA-30",
        alpha2code: "30",
        name: "Kyïv",
      },
      {
        code: "UA-40",
        alpha2code: "40",
        name: "Sevastopol'",
      },
    ],
  },
  AE: {
    name: "United Arab Emirates",
    subdivisions: [
      {
        code: "AE-AZ",
        alpha2code: "AZ",
        name: "Abū Zaby (Abu Dhabi)",
      },
      {
        code: "AE-AJ",
        alpha2code: "AJ",
        name: "‘Ajmān",
      },
      {
        code: "AE-FU",
        alpha2code: "FU",
        name: "Al Fujayrah",
      },
      {
        code: "AE-SH",
        alpha2code: "SH",
        name: "Ash Shāriqah (Sharjah)",
      },
      {
        code: "AE-DU",
        alpha2code: "DU",
        name: "Dubayy (Dubai)",
      },
      {
        code: "AE-RK",
        alpha2code: "RK",
        name: "R'as al Khaymah",
      },
      {
        code: "AE-UQ",
        alpha2code: "UQ",
        name: "Umm al Qaywayn",
      },
    ],
  },
  US: {
    name: "United States of America",
    subdivisions: [
      {
        code: "US-AL",
        alpha2code: "AL",
        name: "Alabama",
      },
      {
        code: "US-AK",
        alpha2code: "AK",
        name: "Alaska",
      },
      {
        code: "US-AZ",
        alpha2code: "AZ",
        name: "Arizona",
      },
      {
        code: "US-AR",
        alpha2code: "AR",
        name: "Arkansas",
      },
      {
        code: "US-CA",
        alpha2code: "CA",
        name: "California",
      },
      {
        code: "US-CO",
        alpha2code: "CO",
        name: "Colorado",
      },
      {
        code: "US-CT",
        alpha2code: "CT",
        name: "Connecticut",
      },
      {
        code: "US-DE",
        alpha2code: "DE",
        name: "Delaware",
      },
      {
        code: "US-FL",
        alpha2code: "FL",
        name: "Florida",
      },
      {
        code: "US-GA",
        alpha2code: "GA",
        name: "Georgia",
      },
      {
        code: "US-HI",
        alpha2code: "HI",
        name: "Hawaii",
      },
      {
        code: "US-ID",
        alpha2code: "ID",
        name: "Idaho",
      },
      {
        code: "US-IL",
        alpha2code: "IL",
        name: "Illinois",
      },
      {
        code: "US-IN",
        alpha2code: "IN",
        name: "Indiana",
      },
      {
        code: "US-IA",
        alpha2code: "IA",
        name: "Iowa",
      },
      {
        code: "US-KS",
        alpha2code: "KS",
        name: "Kansas",
      },
      {
        code: "US-KY",
        alpha2code: "KY",
        name: "Kentucky",
      },
      {
        code: "US-LA",
        alpha2code: "LA",
        name: "Louisiana",
      },
      {
        code: "US-ME",
        alpha2code: "ME",
        name: "Maine",
      },
      {
        code: "US-MD",
        alpha2code: "MD",
        name: "Maryland",
      },
      {
        code: "US-MA",
        alpha2code: "MA",
        name: "Massachusetts",
      },
      {
        code: "US-MI",
        alpha2code: "MI",
        name: "Michigan",
      },
      {
        code: "US-MN",
        alpha2code: "MN",
        name: "Minnesota",
      },
      {
        code: "US-MS",
        alpha2code: "MS",
        name: "Mississippi",
      },
      {
        code: "US-MO",
        alpha2code: "MO",
        name: "Missouri",
      },
      {
        code: "US-MT",
        alpha2code: "MT",
        name: "Montana",
      },
      {
        code: "US-NE",
        alpha2code: "NE",
        name: "Nebraska",
      },
      {
        code: "US-NV",
        alpha2code: "NV",
        name: "Nevada",
      },
      {
        code: "US-NH",
        alpha2code: "NH",
        name: "New Hampshire",
      },
      {
        code: "US-NJ",
        alpha2code: "NJ",
        name: "New Jersey",
      },
      {
        code: "US-NM",
        alpha2code: "NM",
        name: "New Mexico",
      },
      {
        code: "US-NY",
        alpha2code: "NY",
        name: "New York",
      },
      {
        code: "US-NC",
        alpha2code: "NC",
        name: "North Carolina",
      },
      {
        code: "US-ND",
        alpha2code: "ND",
        name: "North Dakota",
      },
      {
        code: "US-OH",
        alpha2code: "OH",
        name: "Ohio",
      },
      {
        code: "US-OK",
        alpha2code: "OK",
        name: "Oklahoma",
      },
      {
        code: "US-OR",
        alpha2code: "OR",
        name: "Oregon",
      },
      {
        code: "US-PA",
        alpha2code: "PA",
        name: "Pennsylvania",
      },
      {
        code: "US-RI",
        alpha2code: "RI",
        name: "Rhode Island",
      },
      {
        code: "US-SC",
        alpha2code: "SC",
        name: "South Carolina",
      },
      {
        code: "US-SD",
        alpha2code: "SD",
        name: "South Dakota",
      },
      {
        code: "US-TN",
        alpha2code: "TN",
        name: "Tennessee",
      },
      {
        code: "US-TX",
        alpha2code: "TX",
        name: "Texas",
      },
      {
        code: "US-UT",
        alpha2code: "UT",
        name: "Utah",
      },
      {
        code: "US-VT",
        alpha2code: "VT",
        name: "Vermont",
      },
      {
        code: "US-VA",
        alpha2code: "VA",
        name: "Virginia",
      },
      {
        code: "US-WA",
        alpha2code: "WA",
        name: "Washington",
      },
      {
        code: "US-WV",
        alpha2code: "WV",
        name: "West Virginia",
      },
      {
        code: "US-WI",
        alpha2code: "WI",
        name: "Wisconsin",
      },
      {
        code: "US-WY",
        alpha2code: "WY",
        name: "Wyoming",
      },
      {
        code: "US-DC",
        alpha2code: "DC",
        name: "District of Columbia",
      },
      {
        code: "US-AS",
        alpha2code: "AS",
        name: "American Samoa",
      },
      {
        code: "US-GU",
        alpha2code: "GU",
        name: "Guam",
      },
      {
        code: "US-MP",
        alpha2code: "MP",
        name: "Northern Mariana Islands",
      },
      {
        code: "US-PR",
        alpha2code: "PR",
        name: "Puerto Rico",
      },
      {
        code: "US-UM",
        alpha2code: "UM",
        name: "United States Minor Outlying Islands",
      },
      {
        code: "US-VI",
        alpha2code: "VI",
        name: "Virgin Islands, U.S.",
      },
    ],
  },
  UY: {
    name: "Uruguay",
    subdivisions: [
      {
        code: "UY-AR",
        alpha2code: "AR",
        name: "Artigas",
      },
      {
        code: "UY-CA",
        alpha2code: "CA",
        name: "Canelones",
      },
      {
        code: "UY-CL",
        alpha2code: "CL",
        name: "Cerro Largo",
      },
      {
        code: "UY-CO",
        alpha2code: "CO",
        name: "Colonia",
      },
      {
        code: "UY-DU",
        alpha2code: "DU",
        name: "Durazno",
      },
      {
        code: "UY-FS",
        alpha2code: "FS",
        name: "Flores",
      },
      {
        code: "UY-FD",
        alpha2code: "FD",
        name: "Florida",
      },
      {
        code: "UY-LA",
        alpha2code: "LA",
        name: "Lavalleja",
      },
      {
        code: "UY-MA",
        alpha2code: "MA",
        name: "Maldonado",
      },
      {
        code: "UY-MO",
        alpha2code: "MO",
        name: "Montevideo",
      },
      {
        code: "UY-PA",
        alpha2code: "PA",
        name: "Paysandú",
      },
      {
        code: "UY-RN",
        alpha2code: "RN",
        name: "Río Negro",
      },
      {
        code: "UY-RV",
        alpha2code: "RV",
        name: "Rivera",
      },
      {
        code: "UY-RO",
        alpha2code: "RO",
        name: "Rocha",
      },
      {
        code: "UY-SA",
        alpha2code: "SA",
        name: "Salto",
      },
      {
        code: "UY-SJ",
        alpha2code: "SJ",
        name: "San José",
      },
      {
        code: "UY-SO",
        alpha2code: "SO",
        name: "Soriano",
      },
      {
        code: "UY-TA",
        alpha2code: "TA",
        name: "Tacuarembó",
      },
      {
        code: "UY-TT",
        alpha2code: "TT",
        name: "Treinta y Tres",
      },
    ],
  },
  UZ: {
    name: "Uzbekistan",
    subdivisions: [
      {
        code: "UZ-QR",
        alpha2code: "QR",
        name: "Qoraqalpoghiston Respublikasi (Karakalpakstan, Respublika)",
      },
      {
        code: "UZ-AN",
        alpha2code: "AN",
        name: "Andijon (Andizhan)",
      },
      {
        code: "UZ-BU",
        alpha2code: "BU",
        name: "Bukhoro (Bukhara)",
      },
      {
        code: "UZ-FA",
        alpha2code: "FA",
        name: "Farghona (Fergana)",
      },
      {
        code: "UZ-JI",
        alpha2code: "JI",
        name: "Jizzakh (Dzhizak)",
      },
      {
        code: "UZ-KH",
        alpha2code: "KH",
        name: "Khorazm (Khorezm)",
      },
      {
        code: "UZ-NG",
        alpha2code: "NG",
        name: "Namangan",
      },
      {
        code: "UZ-NW",
        alpha2code: "NW",
        name: "Nawoiy (Navoi)",
      },
      {
        code: "UZ-QA",
        alpha2code: "QA",
        name: "Qashqadaryo (Kashkadar'ya)",
      },
      {
        code: "UZ-SA",
        alpha2code: "SA",
        name: "Samarqand (Samarkand)",
      },
      {
        code: "UZ-SI",
        alpha2code: "SI",
        name: "Sirdaryo (Syrdar'ya)",
      },
      {
        code: "UZ-SU",
        alpha2code: "SU",
        name: "Surkhondaryo (Surkhandar'ya)",
      },
      {
        code: "UZ-TO",
        alpha2code: "TO",
        name: "Toshkent (Tashkent)",
      },
    ],
  },
  VU: {
    name: "Vanuatu",
    subdivisions: [
      {
        code: "VU-MAP",
        alpha2code: "MAP",
        name: "Malampa",
      },
      {
        code: "VU-PAM",
        alpha2code: "PAM",
        name: "Pénama",
      },
      {
        code: "VU-SAM",
        alpha2code: "SAM",
        name: "Sanma",
      },
      {
        code: "VU-SEE",
        alpha2code: "SEE",
        name: "Shéfa",
      },
      {
        code: "VU-TAE",
        alpha2code: "TAE",
        name: "Taféa",
      },
      {
        code: "VU-TOB",
        alpha2code: "TOB",
        name: "Torba",
      },
    ],
  },
  VE: {
    name: "Venezuela",
    subdivisions: [
      {
        code: "VE-A",
        alpha2code: "A",
        name: "Distrito Federal",
      },
      {
        code: "VE-B",
        alpha2code: "B",
        name: "Anzoátegui",
      },
      {
        code: "VE-C",
        alpha2code: "C",
        name: "Apure",
      },
      {
        code: "VE-D",
        alpha2code: "D",
        name: "Aragua",
      },
      {
        code: "VE-E",
        alpha2code: "E",
        name: "Barinas",
      },
      {
        code: "VE-F",
        alpha2code: "F",
        name: "Bolívar",
      },
      {
        code: "VE-G",
        alpha2code: "G",
        name: "Carabobo",
      },
      {
        code: "VE-H",
        alpha2code: "H",
        name: "Cojedes",
      },
      {
        code: "VE-I",
        alpha2code: "I",
        name: "Falcón",
      },
      {
        code: "VE-J",
        alpha2code: "J",
        name: "Guárico",
      },
      {
        code: "VE-K",
        alpha2code: "K",
        name: "Lara",
      },
      {
        code: "VE-L",
        alpha2code: "L",
        name: "Mérida",
      },
      {
        code: "VE-M",
        alpha2code: "M",
        name: "Miranda",
      },
      {
        code: "VE-N",
        alpha2code: "N",
        name: "Monagas",
      },
      {
        code: "VE-O",
        alpha2code: "O",
        name: "Nueva Esparta",
      },
      {
        code: "VE-P",
        alpha2code: "P",
        name: "Portuguesa",
      },
      {
        code: "VE-R",
        alpha2code: "R",
        name: "Sucre",
      },
      {
        code: "VE-S",
        alpha2code: "S",
        name: "Táchira",
      },
      {
        code: "VE-T",
        alpha2code: "T",
        name: "Trujillo",
      },
      {
        code: "VE-U",
        alpha2code: "U",
        name: "Yaracuy",
      },
      {
        code: "VE-V",
        alpha2code: "V",
        name: "Zulia",
      },
      {
        code: "VE-Z",
        alpha2code: "Z",
        name: "Amazonas",
      },
      {
        code: "VE-Y",
        alpha2code: "Y",
        name: "Delta Amacuro",
      },
      {
        code: "VE-W",
        alpha2code: "W",
        name: "Dependencias Federales",
      },
    ],
  },
  VN: {
    name: "Viet Nam",
    subdivisions: [
      {
        code: "VN-44",
        alpha2code: "44",
        name: "An Giang",
      },
      {
        code: "VN-53",
        alpha2code: "53",
        name: "Bat Can",
      },
      {
        code: "VN-54",
        alpha2code: "54",
        name: "Bat Giang",
      },
      {
        code: "VN-55",
        alpha2code: "55",
        name: "Bat Lieu",
      },
      {
        code: "VN-56",
        alpha2code: "56",
        name: "Bat Ninh",
      },
      {
        code: "VN-43",
        alpha2code: "43",
        name: "Ba Ria - Vung Tau",
      },
      {
        code: "VN-50",
        alpha2code: "50",
        name: "Ben Tre",
      },
      {
        code: "VN-31",
        alpha2code: "31",
        name: "Binh Dinh",
      },
      {
        code: "VN-57",
        alpha2code: "57",
        name: "Binh Duong",
      },
      {
        code: "VN-58",
        alpha2code: "58",
        name: "Binh Phuoc",
      },
      {
        code: "VN-40",
        alpha2code: "40",
        name: "Binh Thuan",
      },
      {
        code: "VN-59",
        alpha2code: "59",
        name: "Ca Mau",
      },
      {
        code: "VN-48",
        alpha2code: "48",
        name: "Can Tho",
      },
      {
        code: "VN-04",
        alpha2code: "04",
        name: "Cao Bang",
      },
      {
        code: "VN-33",
        alpha2code: "33",
        name: "Dac Lac",
      },
      {
        code: "VN-60",
        alpha2code: "60",
        name: "Da Nang, thanh pho",
      },
      {
        code: "VN-39",
        alpha2code: "39",
        name: "Dong Nai",
      },
      {
        code: "VN-45",
        alpha2code: "45",
        name: "Dong Thap",
      },
      {
        code: "VN-30",
        alpha2code: "30",
        name: "Gia Lai",
      },
      {
        code: "VN-03",
        alpha2code: "03",
        name: "Ha Giang",
      },
      {
        code: "VN-61",
        alpha2code: "61",
        name: "Hai Duong",
      },
      {
        code: "VN-62",
        alpha2code: "62",
        name: "Hai Phong, thanh pho",
      },
      {
        code: "VN-63",
        alpha2code: "63",
        name: "Ha Nam",
      },
      {
        code: "VN-64",
        alpha2code: "64",
        name: "Ha Noi, thu do",
      },
      {
        code: "VN-15",
        alpha2code: "15",
        name: "Ha Tay",
      },
      {
        code: "VN-23",
        alpha2code: "23",
        name: "Ha Tinh",
      },
      {
        code: "VN-14",
        alpha2code: "14",
        name: "Hoa Binh",
      },
      {
        code: "VN-65",
        alpha2code: "65",
        name: "Ho Chi Minh, thanh po [Sai Gon]",
      },
      {
        code: "VN-66",
        alpha2code: "66",
        name: "Hung Yen",
      },
      {
        code: "VN-34",
        alpha2code: "34",
        name: "Khanh Hoa",
      },
      {
        code: "VN-47",
        alpha2code: "47",
        name: "Kien Giang",
      },
      {
        code: "VN-28",
        alpha2code: "28",
        name: "Kon Turn",
      },
      {
        code: "VN-01",
        alpha2code: "01",
        name: "Lai Chau",
      },
      {
        code: "VN-35",
        alpha2code: "35",
        name: "Lam Dong",
      },
      {
        code: "VN-09",
        alpha2code: "09",
        name: "Lang Son",
      },
      {
        code: "VN-02",
        alpha2code: "02",
        name: "Lao Cai",
      },
      {
        code: "VN-41",
        alpha2code: "41",
        name: "Long An",
      },
      {
        code: "VN-67",
        alpha2code: "67",
        name: "Nam Dinh",
      },
      {
        code: "VN-22",
        alpha2code: "22",
        name: "Nghe An",
      },
      {
        code: "VN-18",
        alpha2code: "18",
        name: "Ninh Binh",
      },
      {
        code: "VN-36",
        alpha2code: "36",
        name: "Ninh Thuan",
      },
      {
        code: "VN-68",
        alpha2code: "68",
        name: "Phu Tho",
      },
      {
        code: "VN-32",
        alpha2code: "32",
        name: "Phu Yen",
      },
      {
        code: "VN-24",
        alpha2code: "24",
        name: "Quang Ninh",
      },
      {
        code: "VN-27",
        alpha2code: "27",
        name: "Quang Nam",
      },
      {
        code: "VN-29",
        alpha2code: "29",
        name: "Quang Ngai",
      },
      {
        code: "VN-25",
        alpha2code: "25",
        name: "Quang Tri",
      },
      {
        code: "VN-52",
        alpha2code: "52",
        name: "Sec Trang",
      },
      {
        code: "VN-05",
        alpha2code: "05",
        name: "Son La",
      },
      {
        code: "VN-37",
        alpha2code: "37",
        name: "Tay Ninh",
      },
      {
        code: "VN-20",
        alpha2code: "20",
        name: "Thai Binh",
      },
      {
        code: "VN-69",
        alpha2code: "69",
        name: "Thai Nguyen",
      },
      {
        code: "VN-21",
        alpha2code: "21",
        name: "Thanh Hoa",
      },
      {
        code: "VN-26",
        alpha2code: "26",
        name: "Thua Thien-Hue",
      },
      {
        code: "VN-46",
        alpha2code: "46",
        name: "Tien Giang",
      },
      {
        code: "VN-51",
        alpha2code: "51",
        name: "Tra Vinh",
      },
      {
        code: "VN-07",
        alpha2code: "07",
        name: "Tuyen Quang",
      },
      {
        code: "VN-49",
        alpha2code: "49",
        name: "Vinh Long",
      },
      {
        code: "VN-70",
        alpha2code: "70",
        name: "Vinh Yen",
      },
      {
        code: "VN-06",
        alpha2code: "06",
        name: "Yen Bai",
      },
    ],
  },
  VG: {
    name: "Virgin Islands (British)",
    subdivisions: [
      {
        code: "VG-VG",
        alpha2code: "VG",
        name: "Virgin Islands (British)",
      },
    ],
  },
  VI: {
    name: "Virgin Islands (U.S.)",
    subdivisions: [
      {
        code: "VI-VI",
        alpha2code: "VI",
        name: "Virgin Islands (U.S.)",
      },
    ],
  },
  WF: {
    name: "Wallis & Futuna Islands",
    subdivisions: [
      {
        code: "WF-WF",
        alpha2code: "WF",
        name: "Wallis & Futuna Islands",
      },
    ],
  },
  EH: {
    name: "Western Sahara",
    subdivisions: [
      {
        code: "EH-EH",
        alpha2code: "EH",
        name: "Western Sahara",
      },
    ],
  },
  YE: {
    name: "Yemen",
    subdivisions: [
      {
        code: "YE-AB",
        alpha2code: "AB",
        name: "Abyān",
      },
      {
        code: "YE-AD",
        alpha2code: "AD",
        name: "‘Adan",
      },
      {
        code: "YE-BA",
        alpha2code: "BA",
        name: "Al Bayḑā'",
      },
      {
        code: "YE-HU",
        alpha2code: "HU",
        name: "Al Ḩudaydah",
      },
      {
        code: "YE-JA",
        alpha2code: "JA",
        name: "Al Jawf",
      },
      {
        code: "YE-MR",
        alpha2code: "MR",
        name: "Al Mahrah",
      },
      {
        code: "YE-MW",
        alpha2code: "MW",
        name: "Al Maḩwit",
      },
      {
        code: "YE-DH",
        alpha2code: "DH",
        name: "Dhamār",
      },
      {
        code: "YE-HD",
        alpha2code: "HD",
        name: "Ḩaḑramawt",
      },
      {
        code: "YE-HJ",
        alpha2code: "HJ",
        name: "Ḩajjah",
      },
      {
        code: "YE-IB",
        alpha2code: "IB",
        name: "Ibb",
      },
      {
        code: "YE-LA",
        alpha2code: "LA",
        name: "Laḩij",
      },
      {
        code: "YE-MA",
        alpha2code: "MA",
        name: "Ma'rib",
      },
      {
        code: "YE-SD",
        alpha2code: "SD",
        name: "Şa'dah",
      },
      {
        code: "YE-SN",
        alpha2code: "SN",
        name: "Şan‘ā'",
      },
      {
        code: "YE-SH",
        alpha2code: "SH",
        name: "Shabwah",
      },
      {
        code: "YE-TA",
        alpha2code: "TA",
        name: "Ta‘izz",
      },
    ],
  },
  YU: {
    name: "Yugoslavia",
    subdivisions: [
      {
        code: "YU-CG",
        alpha2code: "CG",
        name: "Crna Gora",
      },
      {
        code: "YU-SR",
        alpha2code: "SR",
        name: "Srbija",
      },
      {
        code: "YU-KM",
        alpha2code: "KM",
        name: "Kosovo-Metohija",
      },
      {
        code: "YU-VO",
        alpha2code: "VO",
        name: "Vojvodina",
      },
    ],
  },
  ZM: {
    name: "Zambia",
    subdivisions: [
      {
        code: "ZM-02",
        alpha2code: "02",
        name: "Central",
      },
      {
        code: "ZM-08",
        alpha2code: "08",
        name: "Copperbelt",
      },
      {
        code: "ZM-03",
        alpha2code: "03",
        name: "Eastern",
      },
      {
        code: "ZM-04",
        alpha2code: "04",
        name: "Luapula",
      },
      {
        code: "ZM-09",
        alpha2code: "09",
        name: "Lusaka",
      },
      {
        code: "ZM-05",
        alpha2code: "05",
        name: "Northern",
      },
      {
        code: "ZM-06",
        alpha2code: "06",
        name: "North-Western",
      },
      {
        code: "ZM-07",
        alpha2code: "07",
        name: "Southern",
      },
      {
        code: "ZM-01",
        alpha2code: "01",
        name: "Western",
      },
    ],
  },
  ZW: {
    name: "Zimbabwe",
    subdivisions: [
      {
        code: "ZW-BU",
        alpha2code: "BU",
        name: "Bulawayo",
      },
      {
        code: "ZW-HA",
        alpha2code: "HA",
        name: "Harare",
      },
      {
        code: "ZW-MA",
        alpha2code: "MA",
        name: "Manicaland",
      },
      {
        code: "ZW-MC",
        alpha2code: "MC",
        name: "Mashonaland Central",
      },
      {
        code: "ZW-ME",
        alpha2code: "ME",
        name: "Mashonaland East",
      },
      {
        code: "ZW-MW",
        alpha2code: "MW",
        name: "Mashonaland West",
      },
      {
        code: "ZW-MV",
        alpha2code: "MV",
        name: "Masvingo",
      },
      {
        code: "ZW-MN",
        alpha2code: "MN",
        name: "Matabeleland North",
      },
      {
        code: "ZW-MS",
        alpha2code: "MS",
        name: "Matabeleland South",
      },
      {
        code: "ZW-MI",
        alpha2code: "MI",
        name: "Midlands",
      },
    ],
  },
}

export default countries
