"use client";

import getCurrentContext from "lib/get-current-context"
import { atom, selector, useRecoilValueLoadable, useRecoilState } from "recoil"
import { AsyncReturnType } from "type-fest"

export const currentStateAtom = atom({
  key: "currentStateAtom",
  default: undefined,
})

export const currentStateSelector = selector({
  key: "currentStateSelector",
  get({ get }) {
    const current = get(currentStateAtom)
    if (current) {
      return current
    }
    return getCurrentContext()
  },
})

export function useSetCurrent() {
  // NOTE: Using less great names to avoid name collisions
  const [currentState, setCurrentState] = useRecoilState(currentStateAtom)
  return function (newCurrent: any) {
    if (currentState !== newCurrent) {
      setCurrentState(newCurrent)
    }
  }
}

export type Current = AsyncReturnType<typeof getCurrentContext>

export default function useCurrent(): Current | undefined | never {
  const { state, contents } = useRecoilValueLoadable(currentStateSelector)
  if (state === "hasError") {
    throw contents
  } else if (state === "loading") {
    return undefined
  }
  return contents as unknown as Current
}
