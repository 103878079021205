import { useState, useEffect } from "react"

// @ts-ignore PT - #180597146
const useChatNotifications = (client) => {
  const [count, setCount] = useState(client?.user?.total_unread_count || 0)

  useEffect(() => {
    setCount(client?.user?.total_unread_count || 0)
    // @ts-ignore PT - #180597146
    const onEvent = (event) => {
      if (event.total_unread_count !== undefined) {
        setCount(event.total_unread_count)
      }
    }

    if (client) {
      client.on("notification.added_to_channel", onEvent)
      client.on("notification.removed_from_channel", onEvent)
      client.on("notification.message_new", onEvent)
      client.on("notification.mark_read", onEvent)
    }

    return () => {
      if (client) {
        client.off(onEvent)
      }
    }
  }, [client])

  return count
}

export default useChatNotifications
