import emptyChatImage from "/public/assets/images/emptyChat.svg"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui-compat"
import Image from "next/image"

const useStyles = makeStyles()(
  (_theme) => ({
    root: {
      flexGrow: 1,
    },
    imageBox: {
      display: "flex",
    },
    imageWrapper: {
      margin: "34px auto",
    },
    title: {
      color: "#262B2D",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "17px",
      marginBottom: "10px",
    },
    text: {
      color: "#262B2D",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      letterSpacing: 0,
      lineHeight: "16px",
      padding: "0 46px",
    },
  })
)

interface IEmpty {}

const Empty = (_props: IEmpty) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.imageBox}>
            <Box className={classes.imageWrapper}>
              <Image src={emptyChatImage} alt="No conversations added Icon" role="presentation" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.title}>
            Looks like you don’t have any conversations.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.text}>
            To start live chatting with an exhibitor, go to their profile and hit “Chat”.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Empty
