import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui-compat"
import getChatAvailability from "lib/getChatAvailability"
import useCurrent from "lib/use-current"
import React, { useContext, useEffect, useState } from "react"
import { ChannelStateContext } from "stream-chat-react"

const useStyles = makeStyles()({
  root: {
    width: "100%",
    padding: "0 50px 20px 50px",
  },
  divider: {
    marginBottom: "20px",
  },
  chatMessage: {
    fontStyle: "italic",
    color: "#262B2D",
    fontFamily: "Helvetica Neue",
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "20px",
  },
})

const UnavailableMessage = () => {
  const [chatAvailable, setChatAvailable] = useState("off")

  // @ts-ignore
  const { channel } = useContext(ChannelStateContext)

  const current = useCurrent()

  useEffect(() => {
    // @ts-ignore PT - #180597146
    const getAvailability = async (institutionId) => {
      try {
        const data = await getChatAvailability({ current, institutionId })
        if (data && data[0]) {
          setChatAvailable(data[0].status)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (channel?.data?.institution_id) getAvailability(channel.data.institution_id)
  }, [channel])

  const { classes } = useStyles()

  if (chatAvailable === "off") {
    return (
      <Box className={classes.root}>
        <Divider className={classes.divider} />
        <Typography className={classes.chatMessage} align="center" paragraph>
          Chat is currently disabled for {channel?.data?.name || "this institution"}.
        </Typography>
      </Box>
    )
  }

  if (chatAvailable === "away") {
    return (
      <Box className={classes.root}>
        <Divider className={classes.divider} />
        <Typography className={classes.chatMessage} align="center" paragraph>
          We are currently not available to chat, please leave a message.
        </Typography>
      </Box>
    )
  }

  return null
}

export default UnavailableMessage
