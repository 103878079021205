"use client";

import { IncomingMessage } from "http"
import getAuthToken from "lib/get-auth-token"
import getConventionProviderForHost from "lib/get-convention-provider-for-host"
import getHost from "lib/get-host"
import getProviderConfig from "lib/get-provider-config"
import processApiResponse from "lib/process-api-response"
import sendConventionApiRequest from "lib/send-convention-api-request"

// @ts-ignore PT - #180597146
async function getCurrentUser({ authToken, provider, host }) {
  if (!authToken) {
    return null
  }

  // Need to jump through some hoops to get current user since the current
  // context object isn't fully available yet
  const response = await sendConventionApiRequest({
    current: { host, authToken },
    method: "GET",
    url: `v1/convention_providers/${provider.id}/convention_users/current`,
  })

  if (response.response.status === 401 || response.response.status === 404) {
    return null
  }

  return await processApiResponse<any>(response)
}
// @ts-ignore PT - #180597146
const getCurrentUserStreamChats = async ({ authToken, provider, host }) => {
  if (!authToken) {
    return null
  }

  // Need to jump through some hoops to get current user since the current
  // context object isn't fully available yet
  const response = await sendConventionApiRequest({
    current: { host, authToken },
    method: "GET",
    url: `v1/convention_providers/${provider.id}/stream_one_on_institution_channel`,
  })

  if (response.response.status === 401 || response.response.status === 404) {
    return null
  }

  return await processApiResponse<any>(response)
}

// @ts-ignore PT - #180597146
async function getCurrentUserStreamToken({ authToken, provider, host }) {
  if (!authToken) {
    return null
  }

  // Need to jump through some hoops to get current user since the current
  // context object isn't fully available yet
  const response = await sendConventionApiRequest({
    current: { host, authToken },
    method: "POST",
    url: `v1/convention_providers/${provider.id}/stream_chat_information`,
  })

  if (response.response.status === 401 || response.response.status === 404) {
    return null
  }

  return await processApiResponse<any>(response)
}

export interface currentContextProps {
  authToken: string;
  config: any;
  host: string;
  provider: ConventionProvider;
  user: any;
  hasChats: boolean;
  streamToken: any;
}

const  getCurrentContext = async ({ req }: { req?: IncomingMessage } = {}): Promise<currentContextProps> => {
  const host = getHost({ req })
  // @ts-ignore
  const authToken = getAuthToken({ req })
  // @ts-ignore
  const provider = await getConventionProviderForHost(host)
  const [config, user] = provider
    ? await Promise.all([
        getProviderConfig({ current: { host, provider } }),
        getCurrentUser({ authToken, provider, host }),
      ])
    : []
  const streamChats = user ? await getCurrentUserStreamChats({ authToken, provider, host }) : null
  const hasChats = streamChats?.length > 0
  const streamToken = user ? await getCurrentUserStreamToken({ authToken, provider, host }) : null

  return {
    authToken,
    config,
    host,
    provider,
    user,
    hasChats,
    streamToken,
  }
}

export default getCurrentContext;