import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { AuthDialog, AuthEmail } from "app-constants/constants"
import FormQuestions, {
  createFormQuestionsSchema,
  createPayloadFromFormQuestions,
  useFormQuestions,
} from "components/FormQuestions"
import LoadingStateFallback from "components/common/LoadingStateFallback"
import DialogHeader from "components/dialog/DialogHeader"
import useAuth from "hooks/useAuth"
import useAuthDialog from "hooks/useAuthDialog"
import useRegistrantTypes from "hooks/useRegistrantTypes"
import useSearchParams from "hooks/useSearchParams"
import useShowSnackbar from "hooks/useShowSnackbar"
import useCurrent from "lib/use-current"
import ConventionSignupQuestion from "models/convention-api/v1/ConventionSignupQuestion"
import ConventionSignupQuestionOption from "models/convention-api/v1/ConventionSignupQuestionOption"
import ProviderProgram from "models/convention-api/v1/ProviderProgram"
import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { pxToRem } from "utils/helpers"
import * as Yup from "yup"

const formValidationScheme = Yup.object().shape({
  role: Yup.string().required(),
  email: Yup.string().email().required(),
  privacyCode: Yup.string().notRequired(),
  requestInfoAcknowledgment: Yup.boolean(),
})

interface SignUpWithoutPasswordProps {}

const SignUpWithoutPassword = (props: SignUpWithoutPasswordProps) => {
  const {} = props
  const authDialogs = useAuthDialog()
  const showSnackbar = useShowSnackbar()
  const current = useCurrent()
  const auth = useAuth()
  const searchParams = useSearchParams()
  const [selectedRegistrantTypeId, setSelectedRegistrantTypeId] = useState<null | string>()

  const conventionSignupQuestions = ConventionSignupQuestion.useAll(() => {
    if (selectedRegistrantTypeId === null) return null
    return {
      filter: {
        convention_registrant_type_id: `${selectedRegistrantTypeId},all`,
      },
    }
  })

  const conventionSignupQuestionsOptions = ConventionSignupQuestionOption.useAll({
    filter: {
      active: "true",
    },
  })

  const providerPrograms = ProviderProgram.useAll({})

  const questions = useFormQuestions({
    conventionSignupQuestions: conventionSignupQuestions.data ?? [],
    conventionSignupQuestionsOptions: conventionSignupQuestionsOptions.data ?? [],
    providerPrograms: providerPrograms.data ?? [],
    user: authDialogs.payload?.user,
    role: selectedRegistrantTypeId,
  })

  const form = useForm({
    resolver: yupResolver(formValidationScheme.concat(createFormQuestionsSchema(questions))),
    mode: "onBlur",
    defaultValues: {
      role: "",
      email: authDialogs.payload?.email,
      privacyCode: "",
      requestInfoAcknowledgment: false,
    },
  })

  const formSelectedRegistrantTypeId = form.watch("role")
  const registrantTypes = useRegistrantTypes(() => {
    if (authDialogs.payload?.allRegistrantTypes) return {}

    return {
      filter: {
        convention_convention_id: authDialogs.payload?.conventionIdToRegister,
      },
    }
  })

  useEffect(() => {
    setSelectedRegistrantTypeId(formSelectedRegistrantTypeId)
  }, [formSelectedRegistrantTypeId, registrantTypes])

  useEffect(() => {
    if (registrantTypes?.length > 1) return
    if (formSelectedRegistrantTypeId) return
    form.setValue("role", registrantTypes?.[0]?.id)
  }, [registrantTypes?.length, formSelectedRegistrantTypeId])

  const onSubmit = async (values: any) => {
    try {
      const conventionIdToRegister = authDialogs.payload?.conventionIdToRegister

      const emailStatus = await auth.checkIfEmailRegistered({ email: values.email })
      if (emailStatus instanceof Error) return

      if (
        [AuthEmail.PASSWORD, AuthEmail.LINK].includes(emailStatus.data.attributes.need as AuthEmail)
      ) {
        const returnUrl = new URL(window.location.href)
        conventionIdToRegister &&
          returnUrl.searchParams.set("conventionIdToRegister", conventionIdToRegister)
        await auth.sendMagicLink(
          {
            email: values.email,
            returnUrl: returnUrl.toString(),
          },
          {
            customSuccessMessage: `Looks you already have an account. We'll send an email to ${values.email}`,
          },
        )
        authDialogs.reset()
        return
      }

      const conventionLogin = await auth.performBasicRegistration({
        email: values.email,
        privacyCode: values.privacyCode,
      })

      if (conventionLogin instanceof Error) return

      const [payload, relationships, textResponses, listResponses] = createPayloadFromFormQuestions(
        {
          questions,
          values,
          role: formSelectedRegistrantTypeId,
          conventionSignupQuestions: conventionSignupQuestionsOptions.data ?? [],
        },
      )
      payload.text_responses = textResponses

      relationships.question_options = {
        data: listResponses,
      }

      payload.registration_token = conventionLogin.data.attributes.registration_token
      payload.request_info_acknowledgment = values.requestInfoAcknowledgment

      const userRegistration = await auth.registerUser({ payload, relationships })
      if (userRegistration instanceof Error) return

      if (conventionIdToRegister) {
        await searchParams.set("conventionIdToRegister", conventionIdToRegister)
      }

      authDialogs.reset(false)
    } catch (error) {
      showSnackbar("Something went wrong", "error")
      console.error(error)
    }
  }

  const handleClose = () => {
    authDialogs.reset()
  }

  const handleSignIn = () => authDialogs.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})

  const questionsLoading =
    conventionSignupQuestions.isValidating || conventionSignupQuestionsOptions.isValidating
  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogHeader
        title="Register"
        onClose={handleClose}
        closeButtonDisabled={form.formState.isSubmitting}
      />
      <_DialogContent>
        <_SignInText>
          <span>Already have an account?</span>
          <_SignInButton size="small" onClick={handleSignIn}>
            Sign In
          </_SignInButton>
        </_SignInText>
        <_Form onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                variant="filled"
                placeholder="Enter your email"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
            name="email"
          />
          <FormControlLabel
            control={
              <Controller
                name="requestInfoAcknowledgment"
                control={form.control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <Typography color="gray.darker">
                I would like to receive information on scholarships, internships, jobs or other
                program opportunities.
              </Typography>
            }
          />
          {current.config?.privacy_level === "high" && (
            <Controller
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Privacy Code"
                  fullWidth
                  variant="filled"
                  placeholder="Enter privacy code"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
              name="privacyCode"
            />
          )}
          <LoadingStateFallback fallbackRendered={registrantTypes === null}>
            {registrantTypes?.length > 1 && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Which best describes you?</FormLabel>
                <Controller
                  control={form.control}
                  name="role"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {registrantTypes?.map((registrantType) => (
                        <FormControlLabel
                          key={registrantType.id}
                          value={registrantType.id}
                          control={<Radio />}
                          label={registrantType.name}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
            {formSelectedRegistrantTypeId && (
              <LoadingStateFallback fallbackRendered={questionsLoading}>
                <FormQuestions form={form} questions={questions} />
              </LoadingStateFallback>
            )}
          </LoadingStateFallback>

          <LoadingButton
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            size="large"
            color="primary"
            variant="contained"
            type="submit">
            Continue
          </LoadingButton>
        </_Form>
      </_DialogContent>
    </Dialog>
  )
}

export default SignUpWithoutPassword

const _DialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(25),
})) as typeof DialogContent

const _Form = styled("form")(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  "& > button": {
    marginTop: pxToRem(10),
  },
}))

const _SignInText = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  color: theme.palette.gray.darker,
  ...theme.typography.paragraphRegular,
})) as typeof Typography

const _SignInButton = styled(Button)(({ theme }) => ({
  ...theme.typography.paragraphRegular,
  textTransform: "capitalize",
}))
