import moment from "moment-timezone"
import { Component } from "react"

interface ChatErrorBoundaryState {
  hasError: boolean
  error?: Error
  errorTimestamp?: string
}

class ChatErrorBoundary extends Component<any, ChatErrorBoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    const now = moment().utc()
    return { hasError: true, error: error, errorTimestamp: now.format("LLL z") }
  }

  // NOTE: componentDidCatch doesn't work from server code
  // or in first render from SSR, so it's not a catch-all,
  // but it can catch things on re-renders
  // More info: https://reactjs.org/docs/error-boundaries.html
  componentDidCatch(error: Error, info: any) {
    // Send error to Airbrake
    //@ts-ignore
    if (typeof window !== "undefined" && window.airbrake) {
      //@ts-ignore
      window.airbrake.notify({
        error: error,
        context: { severity: "error", origin: "chat-error-boundary" },
        params: { info: info },
      })
    }
  }

  render() {
    if (this.state.hasError) {
      const fullMessage = `${this.state.error.name}: ${this.state.error.message}`
      const ignoredErrorMessages = [
        "TypeError: Cannot read properties of undefined (reading 'setError')",
        "TypeError: Cannot read properties of undefined (reading 'queryInProgress')",
      ];
      if (!ignoredErrorMessages.includes(fullMessage)) {
        window.location.href = '/conventions';
      }

      return <></>
    }
    return this.props.children
  }
}

export default ChatErrorBoundary
