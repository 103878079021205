import { Theme } from "@mui/material/styles"
import { colors } from "deprecated-theme"
import { createTheme } from "theme/createTheme"

const baseCuratorTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#0470C8",
      light: "#CDE2F4",
      lighter: "#E6F1F9",
      lightest: "#F2F8FC",
    },
    secondary: {
      main: colors.actionSecondary,
    },
    gray: {
      darkest: "#000000",
      darker: "#556064",
      dark: "#767676",
      main: "#acb1b3",
      light: "#eaeaeb",
      lighter: "#f2f2f3",
      lightest: "#FFFFFF",
    },
    error: {
      main: "#F45450",
    },
    light: {
      main: "#ffffff",
      contrastText: "#000000",
    },
    dark: {
      main: "#000000",
      contrastText: "#ffffff",
    },
  },
})

export default baseCuratorTheme
