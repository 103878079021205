import getEnvForHost from "lib/get-env-for-host"
import sendJsonApiRequest, { SendJsonApiRequestParams } from "lib/send-json-api-request"
import { mergeLeft } from "ramda"

function buildPath(...segments: string[]) {
  return segments.map((segment) => segment.replace(/(?:^\/+|\/+$)/g, "")).join("/")
}

export type SendConventionApiRequestParams = SendJsonApiRequestParams & {
  current: any
}

export default async function sendConventionApiRequest(request: SendConventionApiRequestParams) {
  let { current, headers, url } = request
  if (current?.authToken) {
    // @ts-ignore
    headers = mergeLeft(headers, {
      Authorization: `Bearer ${current.authToken}`,
    })
  }

  const env = getEnvForHost(current.host)
  // @ts-ignore
  url = buildPath(env.NEXT_PUBLIC_VISITDAYS_API_ENDPOINT, `convention_api`, url)

  return await sendJsonApiRequest({
    ...request,
    headers,
    url,
  })
}
