import { addParamsData, ParamsObject } from "app-constants/routing"
import { useRouter } from "next/router"

const useSearchParams = () => {
  const router = useRouter()
  const query = router.query

  return {
    get(name: string): string {
      return query[name] as string
    },
    async set(name: string, value: string) {
      await router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, [name]: value },
        },
        undefined,
        { shallow: true },
      )
    },
    async setMultiple(values: Record<string, string>){
      await router.replace(        {
          pathname: router.pathname,
          query: { ...router.query, ...values },
        },
        undefined,
        { shallow: true },
      )
    },
    async delete(name: string) {
      delete query[name]
      await router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    async clear() {
      await router.replace(addParamsData(router.pathname, query as ParamsObject), undefined, {
        shallow: true,
      })
    },
  }
}

export default useSearchParams
