"use client";

import { AuthDialog } from "app-constants/constants"
import JoinDialog from "components/auth-dialogs/JoinDialog"
import ResetPasswordDialog from "components/auth-dialogs/ResetPasswordDialog"
import SetPasswordDialog from "components/auth-dialogs/SetPasswordDialog"
import SignInDialog from "components/auth-dialogs/SignInDialog"
import SignUpDialog from "components/auth-dialogs/SignUpDialog"
import SignUpPasswordDialog from "components/auth-dialogs/SignUpPasswordDialog"
import SignUpWithoutPassword from "components/auth-dialogs/SignUpWithoutPassword"
import SignUpNewDialog from "components/auth-dialogs/SignupNewDialog"
import useAuthDialog from "hooks/useAuthDialog"

interface AuthDialogsProps {}

const AuthDialogs = (props: AuthDialogsProps) => {
  const {} = props
  const authDialogs = useAuthDialog()

  return (
    {
      [AuthDialog.SIGN_IN]: <SignInDialog />,
      [AuthDialog.RESET_PASSWORD]: <ResetPasswordDialog />,
      [AuthDialog.SET_PASSWORD]: <SetPasswordDialog />,
      [AuthDialog.SIGN_UP]: <SignUpDialog />,
      [AuthDialog.SIGN_UP_PASSWORD]: <SignUpPasswordDialog />,
      [AuthDialog.SIGN_UP_NEW]: <SignUpNewDialog />,
      [AuthDialog.JOIN]: <JoinDialog />,
      [AuthDialog.SIGN_UP_WITHOUT_PASSWORD]: <SignUpWithoutPassword />,
    }[authDialogs.dialog] ?? <></>
  )
}

export default AuthDialogs
