import { ReactNode } from "react"
import { makeStyles } from "tss-react/mui-compat"

interface Props {
  children: ReactNode | ReactNode[],
  variant?: "hiddenXLUp" | "hiddenLGUp" | "hiddenMDUp" | "hiddenSMUp" | 
    "hiddenXLDown" | "hiddenLGDown" | "hiddenMDDown" | "hiddenSMDown"
}

const useStyles = makeStyles()(
  (theme) => ({
    hiddenXLUp: {
      [theme.breakpoints.up("xl")]: {
        display: 'none',
      },
      [theme.breakpoints.down("xl")]: {
        display: 'block',
      },
    },
    hiddenLGUp: {
      [theme.breakpoints.up("lg")]: {
        display: 'none',
      },
      [theme.breakpoints.down("lg")]: {
        display: 'block',
      },
    },
    hiddenMDUp: {
      [theme.breakpoints.up("md")]: {
        display: 'none',
      },
      [theme.breakpoints.down("md")]: {
        display: 'block',
      },
    },
    hiddenSMUp: {
      [theme.breakpoints.up("sm")]: {
        display: 'none',
      },
      [theme.breakpoints.down("sm")]: {
        display: 'block',
      },
    },
    hiddenXLDown: {
      [theme.breakpoints.down("xl")]: {
        display: 'none',
      },
      [theme.breakpoints.up("xl")]: {
        display: 'block',
      },
    },
    hiddenLGDown: {
      [theme.breakpoints.down("lg")]: {
        display: 'none',
      },
      [theme.breakpoints.up("lg")]: {
        display: 'block',
      },
    },
    hiddenMDDown: {
      [theme.breakpoints.down("md")]: {
        display: 'none',
      },
      [theme.breakpoints.up("md")]: {
        display: 'block',
      },
    },
    hiddenSMDown: {
      [theme.breakpoints.down("sm")]: {
        display: 'none',
      },
      [theme.breakpoints.up("sm")]: {
        display: 'block',
      },
    },
  })
);

const HiddenBox = (props: Props) => {
  const { variant, children } = props
  const { classes: hiddenStyles } = useStyles();

  const variantStyle: string = hiddenStyles[variant];

  return (
    <div className={variantStyle}>
      {children}
    </div>
  )
}

export default HiddenBox;
