import isNil from "lib/isNil"
import buildQueryString from "models/convention-api/_internal/buildQueryString"
import buildUrl from "models/convention-api/_internal/buildUrl"
import processResponse from "models/convention-api/_internal/processResponse"
import attrs from "models/convention-api/attrs"
import { BuildHeadersParams } from "models/convention-api/types"
import { BuildUrlParams } from "models/convention-api/types"
import Constructor from "types/Constructor"

const stringAttr = attrs.string()
const momentAttr = attrs.moment()

export interface Fields {
  [key: string]: { deserialize(value: any): any; serialize(value: any): any }
}

export interface Rels {
  [key: string]: any
}

export default class BaseModel {
  // @ts-ignore
  protected static basePath: string = null

  protected static fields: Fields = {}
  protected static rels: Rels = {}

  id!: string

  protected static buildHeaders(params: BuildHeadersParams) {
    const headers: Record<string, string> = {
      Accept: "application/vnd.api+json, application/json",
      "Content-Type": "application/json; charset=utf-8",
    }
    if (params.authToken) {
      headers["Authorization"] = `Bearer ${params?.authToken}`
    }
    return headers
  }

  protected static buildUrl(params: BuildUrlParams) {
    const queryString = buildQueryString(params)
    return buildUrl({ ...params, path: this.basePath, queryString })
  }

  protected static deserialize<T extends BaseModel>(this: Constructor<T>, data: any): T {
    const model: any = {}
    const fields: Fields = (this as any).fields
    const keys = Object.keys(data)
    keys.forEach((key) => {
      let value = data[key]
      if (fields[key]) {
        value = fields[key].deserialize(value)
      } else if (isNil(value)) {
        value = null
      } else if (key === "id") {
        value = stringAttr.deserialize(value)
      } else if (/_id$/.test(key)) {
        value = stringAttr.deserialize(value)
      } else if (/_at$/.test(key)) {
        value = momentAttr.deserialize(value)
      }
      model[key] = value
    })
    return model as T
  }

  protected static async processResponse(response: {
    response: Response
    json: any
    camelCaseKeys?: boolean
  }): Promise<any> {
    const data = await processResponse(response)
    const models = data.map((item: any) => (this as any).deserialize(item))
    models.after = data.meta?.page?.after
    models.count = data.meta?.count
    return models
  }
}
